/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1RuleSet } from '../models/V1RuleSet';
import { V1Stats } from '../models/V1Stats';
import { V1SubscriptionNote } from '../models/V1SubscriptionNote';
import { HttpFile } from '../http/http';

export class AdvertisingPlatformServiceUpdateSubscriptionRequest {
    /**
    * RefId of the subscription
    */
    'refId'?: string;
    /**
    * OrgId of the subscription
    */
    'orgId'?: string;
    /**
    * Name of the subscription
    */
    'name'?: string;
    /**
    * whether subscription is enabled or not
    */
    'enabled'?: boolean;
    /**
    * Time the subscription was created
    */
    'created'?: Date;
    /**
    * Time the subscription was updated
    */
    'updated'?: Date;
    /**
    * Time Period of the subscription
    */
    'timePeriod'?: number;
    /**
    * Time the subscription started
    */
    'startTime'?: number;
    /**
    * How long to exclude ips found from a pixel
    */
    'excludedTime'?: number;
    /**
    * Whether to send results to the server at the end of a run or not
    */
    'sendToFileServer'?: boolean;
    /**
    * PublisherID of the subscription's publisher
    */
    'publisherId'?: string;
    /**
    * Id of the subscription's Pixel
    */
    'pixelId'?: string;
    /**
    * ID of the UUID associated with the subscription
    */
    'uuidId'?: string;
    /**
    * file path to put files on ftp server
    */
    'ftpPath'?: string;
    /**
    * Whether to include the ET hash in the files
    */
    'includeEthash'?: boolean;
    /**
    * Max results to target after all exclusions are made
    */
    'matchCap'?: number;
    /**
    * States filtered out
    */
    'statesFiltered'?: Array<string>;
    /**
    * Zips Filtered out
    */
    'zipsFiltered'?: Array<string>;
    'ruleSet'?: V1RuleSet;
    'latestStats'?: V1Stats;
    /**
    * The Id of the latest failed run for this subscription
    */
    'failId'?: number;
    /**
    * The Id of the latest failed run for this subscription
    */
    'succeeded'?: boolean;
    /**
    * The first day the subscription should start running. Only works if flight_dates_enabled is true This is not currently used.
    */
    'flightStartDate'?: Date;
    /**
    * The last day the subscription should run. Only works if flight_dates_enabled is true This is not currently used.
    */
    'flightEndDate'?: Date;
    /**
    * whether to run subscription during flight dates or to run indefinitely This is not currently used.
    */
    'flightDatesEnabled'?: boolean;
    /**
    * The first day the trial peroid should begin.
    */
    'trialPeriodStartDate'?: Date;
    /**
    * The last day of the trial period.
    */
    'trialPeriodEndDate'?: Date;
    /**
    * whether to keep running the subscription after the trial period ends or wait for approval before continuing service
    */
    'autoEnableAfterTrial'?: boolean;
    /**
    * a list of strings used to tag the subscription
    */
    'tags'?: Array<string>;
    /**
    * a list of notes associated with the subscription. notes are manages via the subscription notes endpoints.
    */
    'notes'?: Array<V1SubscriptionNote>;
    /**
    * Name of the Org in which this subscription belongs.
    */
    'orgName'?: string;
    /**
    * Whether this subscription is locked. This can only be set or updated by admin.
    */
    'locked'?: boolean;
    /**
    * Whether this subscription is hidden. This means non admin will not see this subscription. This can only be set or updated by admin.
    */
    'hidden'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "refId",
            "baseName": "ref_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "enabled",
            "baseName": "enabled",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "created",
            "baseName": "created",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updated",
            "baseName": "updated",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "timePeriod",
            "baseName": "time_period",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "startTime",
            "baseName": "start_time",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "excludedTime",
            "baseName": "excluded_time",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "sendToFileServer",
            "baseName": "send_to_file_server",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "publisherId",
            "baseName": "publisher_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "pixelId",
            "baseName": "pixel_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "uuidId",
            "baseName": "uuid_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "ftpPath",
            "baseName": "ftp_path",
            "type": "string",
            "format": ""
        },
        {
            "name": "includeEthash",
            "baseName": "include_ethash",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "matchCap",
            "baseName": "match_cap",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "statesFiltered",
            "baseName": "states_filtered",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "zipsFiltered",
            "baseName": "zips_filtered",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "ruleSet",
            "baseName": "rule_set",
            "type": "V1RuleSet",
            "format": ""
        },
        {
            "name": "latestStats",
            "baseName": "latest_stats",
            "type": "V1Stats",
            "format": ""
        },
        {
            "name": "failId",
            "baseName": "fail_id",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "succeeded",
            "baseName": "succeeded",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "flightStartDate",
            "baseName": "flight_start_date",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "flightEndDate",
            "baseName": "flight_end_date",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "flightDatesEnabled",
            "baseName": "flight_dates_enabled",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "trialPeriodStartDate",
            "baseName": "trial_period_start_date",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "trialPeriodEndDate",
            "baseName": "trial_period_end_date",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "autoEnableAfterTrial",
            "baseName": "auto_enable_after_trial",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "tags",
            "baseName": "tags",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "notes",
            "baseName": "notes",
            "type": "Array<V1SubscriptionNote>",
            "format": ""
        },
        {
            "name": "orgName",
            "baseName": "org_name",
            "type": "string",
            "format": ""
        },
        {
            "name": "locked",
            "baseName": "locked",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "hidden",
            "baseName": "hidden",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return AdvertisingPlatformServiceUpdateSubscriptionRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

