import { useEffect, useState, useRef } from 'react'
import {
  Button,
  Fa,
  Checkbox,
  Select,
  SelectOptionType,
  Text,
  CollapsibleContainer,
  TextHeader,
  Loader,
  showWarningMessage,
} from '@eltoro-ui/components'
import { useMediaQuery, useOnScreen } from '@eltoro-ui/hooks'
import {
  DetermineIfAllowedToAttachAudience,
  getJobCounts,
  getProductTypes,
  styleTailwind,
  userFriendlyAudienceName,
} from 'Helpers'
import {
  Campaignservicev1Audience,
  Targetjobservicev1Audience,
} from 'next-gen-sdk'

import { useAppContext } from 'Contexts'
import { ModifiedOrderLineType } from './AudienceEdit'

const HeaderText = styleTailwind(Text, 'EditOLTable__header-text')
const EditOLRowItem = styleTailwind(Text, 'EditOLRowItem__item')
const TableGrid = styleTailwind(
  'div',
  'EditOLTable__TableGrid laptopsmall:flex laptopsmall:flex-wrap laptopsmall:justify-between laptopsmall:gap-y-4',
)
const TabletHeader = styleTailwind(
  HeaderText,
  'EditOLTable__TabletHeader block',
)

const StackBtn = styleTailwind('div', 'StackedButtonIcon group')
const PixelText = styleTailwind(
  'div',
  'border-grey-500 text-grey-500 border-thin break-all rounded p-1 leading-normal bg-base w-full',
)

type AudienceEditOLTableProps = {
  modifiedOrderLines: ModifiedOrderLineType[]
  handleAddAudience: (
    newAudiences: Targetjobservicev1Audience[],
    orderLines?: {
      sourceOL: ModifiedOrderLineType
      targetOL?: ModifiedOrderLineType
    },
  ) => void
  handleRemoveAudience: (
    orderLine: ModifiedOrderLineType,
    audience?: Targetjobservicev1Audience,
  ) => void
  handleExcludeOrIncludeAudiences?: ({
    olId,
    audiencesToIncludeOrExclude,
  }: {
    olId: string
    audiencesToIncludeOrExclude: { id: string; exclude: boolean }[]
  }) => void
}

const ExclusionAudience = ({
  audience,
  onCheck,
}: {
  audience: Campaignservicev1Audience
  onCheck: ({ id, exclude }: { id: string; exclude: boolean }) => void
}) => {
  const [excluded, setExcluded] = useState(false)
  useEffect(() => {
    if (audience.exclude) {
      setExcluded(true)
    }
  }, [audience])
  return (
    <div className="EditOLTable__cell-remove flex">
      <Checkbox
        onChange={(checked) => {
          if (audience.id) {
            onCheck({ id: audience.id, exclude: checked })
            setExcluded(!excluded)
          }
        }}
        checked={excluded}
      />
    </div>
  )
}

const AudienceEditOLTableRow = ({
  orderLine,
  audience,
  index,
  audienceCount,
  modifiedOrderLines,
  handleAddAudience,
  handleRemoveAudience,
  handleCheck,
}: {
  orderLine: ModifiedOrderLineType
  audience: Campaignservicev1Audience
  index: number
  audienceCount?: number
  handleCheck: ({
    audience,
  }: {
    audience: { id: string; exclude: boolean }
  }) => void
} & AudienceEditOLTableProps) => {
  // prettier-ignore
  const [
    parentVRAudience,
    setParentVRAudience,
  ] = useState<Targetjobservicev1Audience>()
  // prettier-ignore
  const [fullOLAudience, setFullOLAudience] = useState<
    Targetjobservicev1Audience
  >()
  const [tagsOpen, setTagsOpen] = useState(false)
  const { audienceServiceApi, currentOrg, roles } = useAppContext()
  const isRemoveAndMoveDisabled = orderLine.audiences?.length === 1
  const fetchedFullAudienceId = useRef<string>()
  const parentIdFetched = useRef<string>()
  const ref = useRef<HTMLDivElement>(null)
  const isOnScreen = useOnScreen(ref)

  useEffect(() => {
    if (
      audience.productType === 'PRODUCT_TYPE_VENUE_REPLAY' &&
      parentIdFetched.current !== audience.audienceId &&
      isOnScreen
    ) {
      const handleGetParent = async () => {
        if (currentOrg?.id && audience.id) {
          if (!audience?.audienceId) return
          const parentVRAudience = await audienceServiceApi?.advertisingPlatformServiceGetAudience(
            audience.audienceId,
            currentOrg.id,
          )
          setParentVRAudience(parentVRAudience)
          parentIdFetched.current = parentVRAudience?.id
        }
      }
      handleGetParent()
    }
  }, [
    audience.audienceId,
    audience.id,
    audience.productType,
    audience.type,
    audienceServiceApi,
    currentOrg?.id,
    isOnScreen,
  ])

  useEffect(() => {
    const handleFetchResults = async () => {
      if (
        !currentOrg?.id ||
        !audience.id ||
        fullOLAudience?.id === audience.id ||
        (fetchedFullAudienceId.current &&
          fetchedFullAudienceId.current === audience.id) ||
        !isOnScreen
      )
        return
      // leaving this here for retargeting and locked
      const fetched = await audienceServiceApi?.advertisingPlatformServiceGetAudience(
        audience.id,
        currentOrg.id,
      )
      fetchedFullAudienceId.current = fetched?.id
      setFullOLAudience(fetched)
    }
    handleFetchResults()
  }, [
    audience,
    audience.id,
    audience.type,
    audienceServiceApi,
    currentOrg?.id,
    isOnScreen,
  ])
  const customAudienceDataSource = () => {
    const { targetDataSource } = audience
    if (targetDataSource === 'DATA_SOURCE_ARISTOTLE') {
      return '(Aristotle)'
    }
    if (targetDataSource === 'DATA_SOURCE_ASPIRE') {
      return '(Aspire)'
    }
    if (targetDataSource === 'DATA_SOURCE_L2') {
      return '(L2)'
    }
    if (targetDataSource === 'DATA_SOURCE_ONSPOT') {
      return '(Venue Replay+)'
    }
    if (targetDataSource === 'DATA_SOURCE_5X5') {
      return '(Intent-to-Home)'
    }
    if (targetDataSource === 'DATA_SOURCE_CORE_LOGIC') {
      return 'CoreLogic'
    }
    return ''
  }

  const getOrderLineOptions = (): SelectOptionType[] => {
    if (!modifiedOrderLines.length) return []
    const olAudienceType = orderLine.audiences?.[0]?.productType

    const otherOLOptions = modifiedOrderLines.reduce(
      (acc: SelectOptionType[], targetOL) => {
        // Do not include OL if the audience already exists on that order line,
        // if the audience is the wrong type,
        // or if it is this order line
        if (
          (!targetOL.id && !targetOL.tempId) ||
          !!targetOL.audiences?.find((aud) => aud.id === audience.id) ||
          (targetOL.id !== undefined &&
            orderLine.id !== undefined &&
            targetOL.id === orderLine.id) ||
          (targetOL.tempId !== undefined &&
            orderLine.tempId !== undefined &&
            targetOL.tempId === orderLine.tempId) ||
          targetOL.audiences?.[0]?.productType !== olAudienceType
        )
          return acc

        return [
          ...acc,
          {
            value: targetOL.tempId || targetOL.id,
            label: (
              <Text className="text-xs">
                Move to&nbsp;
                <span className="EditOLRowItem__item font-bold">
                  {targetOL.name || targetOL.id || 'No name'}
                </span>
              </Text>
            ),
          },
        ]
      },
      [],
    )

    return [
      // If the order line is a temp OL created in this modal by 'move to new order line,' and it has one audience,
      // do NOT allow it to create a new one
      ...(orderLine.audiences &&
      orderLine.audiences.length === 1 &&
      orderLine.tempId
        ? []
        : [
            {
              value: 'make_new_ol',
              label: <Text className="text-xs">Make new Order Line</Text>,
            },
          ]),
      ...otherOLOptions,
    ]
  }

  const olOptions = getOrderLineOptions()

  const handleSelectOnChange = async (option?: SelectOptionType) => {
    const audsIfDetached =
      orderLine.audiences?.filter((aud) => aud.id !== audience?.id) || []
    const productsIfDetached = getProductTypes(audsIfDetached)
    if (option?.value === 'make_new_ol') {
      if (
        orderLine?.highestCpmAudience &&
        !productsIfDetached.includes(
          orderLine?.highestCpmAudience?.audienceProductType,
        )
      ) {
        if (orderLine.status !== 'ORDERLINE_STATUS_DRAFT') {
          showWarningMessage(
            'Move Audience Failed',
            <>
              <p className="flex flex-col gap-3 text-sm">
                Unable to move the last &nbsp;
                {userFriendlyAudienceName(audience.productType || '')} audience,
                Please attach another &nbsp;
                {userFriendlyAudienceName(audience.productType || '')} audience
                before removing this audience
              </p>
            </>,
          )
        }
      }
      if (
        !DetermineIfAllowedToAttachAudience(
          audsIfDetached,
          audience,
          roles?.includes('nextgen_admin'),
        )
      ) {
        if (orderLine.status !== 'ORDERLINE_STATUS_DRAFT') {
          showWarningMessage(
            'Move Audience Failed',
            <>
              <p className="flex flex-col gap-3 text-sm">
                Moving this audience will remove an admin only audience
                combination, Please attach another &nbsp;
                {userFriendlyAudienceName(audience.productType || '')} before
                moving this audience
              </p>
            </>,
          )
        } else {
          showWarningMessage(
            'Move Audience Failed',
            <>
              <p className="flex flex-col gap-3 text-sm">
                Moving this audience will remove an admin only audience
                combination, Please attach another &nbsp;
                {userFriendlyAudienceName(audience.productType || '')} before
                moving this audience or if you are sure:
                <Button
                  onClick={() => {
                    handleAddAudience([audience], { sourceOL: orderLine })
                  }}
                >
                  Move Audience
                </Button>
              </p>
            </>,
          )
        }
        return
      }
      handleAddAudience([audience], { sourceOL: orderLine })
    } else {
      const targetOL = modifiedOrderLines.find(
        (ol) => ol.id === option?.value || ol.tempId === option?.value,
      )
      if (
        orderLine?.highestCpmAudience &&
        !productsIfDetached.includes(
          orderLine?.highestCpmAudience?.audienceProductType,
        )
      ) {
        if (orderLine.status !== 'ORDERLINE_STATUS_DRAFT') {
          showWarningMessage(
            'Move Audience Failed',
            <>
              <p className="flex flex-col gap-3 text-sm">
                Unable to move the last &nbsp;
                {userFriendlyAudienceName(audience.productType || '')} audience,
                Please attach another &nbsp;
                {userFriendlyAudienceName(audience.productType || '')} audience
                before removing this audience
              </p>
            </>,
          )
        }
      }
      if (
        !DetermineIfAllowedToAttachAudience(
          audsIfDetached,
          audience,
          roles?.includes('nextgen_admin'),
        )
      ) {
        if (orderLine.status !== 'ORDERLINE_STATUS_DRAFT') {
          showWarningMessage(
            'Move Audience Failed',
            <>
              <p className="flex flex-col gap-3 text-sm">
                Moving this audience will remove an admin only audience
                combination, Please attach another &nbsp;
                {userFriendlyAudienceName(audience.productType || '')} before
                moving this audience
              </p>
            </>,
          )
        } else {
          showWarningMessage(
            'Move Audience Failed',
            <>
              <p className="flex flex-col gap-3 text-sm">
                Moving this audience will remove an admin only audience
                combination, Please attach another &nbsp;
                {userFriendlyAudienceName(audience.productType || '')} before
                moving this audience or if you are sure:
                <Button
                  onClick={() => {
                    handleAddAudience([audience], {
                      sourceOL: orderLine,
                      targetOL,
                    })
                  }}
                >
                  Move Audience
                </Button>
              </p>
            </>,
          )
        }
        return
      }
      handleAddAudience([audience], { sourceOL: orderLine, targetOL })
    }
  }

  const subType =
    audience.productType === 'PRODUCT_TYPE_VENUE_REPLAY'
      ? parentVRAudience?.audiences?.find((subJob) => subJob.id === audience.id)
          ?.subType
      : undefined

  const [isTablet] = useMediaQuery('(max-width: 860px)')

  return (
    <>
      <TableGrid className="EditOLRowItem">
        <EditOLRowItem className="EditOLRowItem__name break-words">
          {isTablet && <TabletHeader>Order Line Name:</TabletHeader>}
          {index === 0 ? (
            <Text className="OrderLineName break-all">
              {orderLine.name || 'No name'}
            </Text>
          ) : undefined}
        </EditOLRowItem>
        <EditOLRowItem className="EditOLRowItem__file-name break-words">
          {isTablet && <TabletHeader>File name:</TabletHeader>}
          <div ref={ref} className="flex">
            <Text className="AudienceName break-all" tag="p">
              {(() => {
                if (
                  audience.productType === 'PRODUCT_TYPE_VENUE_REPLAY' &&
                  audience.type !== 'AUDIENCE_TYPE_VR'
                ) {
                  return parentVRAudience?.name || 'Loading'
                }

                return audience.name || audience.id || 'No name'
              })()}
            </Text>
            {fullOLAudience && fullOLAudience.locked && (
              <span data-tooltip="top" aria-label="This audience is locked">
                <Fa icon="lock" size={1} className="animate-fadein" />
              </span>
            )}
            {fullOLAudience &&
              fullOLAudience.dataProduct === 'DATA_PRODUCT_CUSTOM_AUDIENCE' && (
                <span
                  data-tooltip="top"
                  aria-label="This is a premium audience."
                >
                  <Fa icon="fa-gem" size={1} type="duotone" />
                </span>
              )}
          </div>

          <Text className="AudienceType">
            {(() => {
              if (subType) {
                if (subType === 'AUDIENCE_SUB_TYPE_HOMES') return 'Mapped Homes'
                if (subType === 'AUDIENCE_SUB_TYPE_DEVICES_WITH_HOMES')
                  return 'Mapped Device IDs'
                if (subType === 'AUDIENCE_SUB_TYPE_DEVICES_IN_HOMES_NOT_SEEN')
                  return 'Other Device IDs in Home'
                if (subType === 'AUDIENCE_SUB_TYPE_DEVICES_WITHOUT_HOMES')
                  return 'Unmapped Device IDs'
              }
              if (audience.type === 'AUDIENCE_TYPE_VR') return 'Quoting'
            })()}
          </Text>
        </EditOLRowItem>
        <EditOLRowItem className="EditOLRowItem__type break-words">
          {isTablet && <TabletHeader>Type</TabletHeader>}
          <div className="flex items-center justify-between gap-2">
            <Text className="font-bold">
              {(() => {
                if (audience.productType === 'PRODUCT_TYPE_VENUE_REPLAY')
                  return 'Venue Replay'
                return audience.type
                  ? `${userFriendlyAudienceName(
                      audience.type,
                    )} ${customAudienceDataSource()}`
                  : 'No type'
              })()}
              {fullOLAudience?.dataProduct ===
                'DATA_PRODUCT_CUSTOM_AUDIENCE' && (
                <>
                  <br />
                  {fullOLAudience.tag}
                </>
              )}
            </Text>
            {audience.type === 'AUDIENCE_TYPE_RETARGETING' && !tagsOpen && (
              <span
                data-tooltip="top"
                aria-label={!fullOLAudience ? 'Loading' : 'Show tags'}
              >
                <Button
                  iconOnly={<Fa icon="code" size={1} className="!text-xs" />}
                  onClick={() => setTagsOpen(true)}
                  disabled={!fullOLAudience}
                />
              </span>
            )}
          </div>
        </EditOLRowItem>

        <EditOLRowItem className="EditOLRowItem__count break-words">
          {isTablet && <TabletHeader>Count:</TabletHeader>}
          <Text>{audienceCount}</Text>
        </EditOLRowItem>
        <EditOLRowItem className="EditOLRowItem__move-audience flex flex-col gap-2">
          {isTablet && <TabletHeader>Move Audience</TabletHeader>}
          <Select
            placeholder="Select move option"
            clearButton={false}
            options={olOptions}
            selectedOption={undefined}
            onChange={handleSelectOnChange}
            maxHeight={200}
            disabled={
              olOptions.length === 0 ||
              (isRemoveAndMoveDisabled &&
                (orderLine.status === 'ORDERLINE_STATUS_REJECTED' ||
                  orderLine.status !== 'ORDERLINE_STATUS_DRAFT'))
            }
            className="max-w-[13rem] self-start"
            optionsClassName="z-[60]"
          />
        </EditOLRowItem>
        <EditOLRowItem className="EditOLRowItem__remove-audience flex gap-1 break-words">
          {isTablet && <TabletHeader>Remove Audiences:</TabletHeader>}
          <div className="StackedButtonIcon__wrap flex gap-1">
            <StackBtn
              className="group-hover:text-primary"
              data-tooltip="top"
              aria-label={
                isRemoveAndMoveDisabled
                  ? 'You are unable to remove all audiences from this order line. '
                  : 'Remove this Audience from Order Line'
              }
            >
              <Button
                iconCenter={
                  <Fa
                    icon="trash-xmark"
                    size={1}
                    className="StackedButtonIcon__icon"
                  />
                }
                onClick={() => handleRemoveAudience(orderLine, audience)}
                disabled={isRemoveAndMoveDisabled}
              >
                <span className="IconLabel text-s">Remove</span>
              </Button>
            </StackBtn>
            {index === 0 &&
              (orderLine.status === 'ORDERLINE_STATUS_DRAFT' ||
                orderLine.tempId) && (
                <StackBtn
                  className="group-hover:text-primary"
                  data-tooltip="top"
                  aria-label="Remove All Audiences from Order Line"
                >
                  <Button
                    iconCenter={
                      <Fa
                        icon="trash-xmark"
                        size={1}
                        className="StackedButtonIcon__icon"
                      />
                    }
                    onClick={() => handleRemoveAudience(orderLine)}
                  >
                    <span className="IconLabel text-s">Remove All</span>
                  </Button>
                </StackBtn>
              )}
          </div>
        </EditOLRowItem>
        <EditOLRowItem className="EditOLRowItem__exclude break-words">
          {isTablet &&
            orderLine.audiences &&
            orderLine.audiences.length > 1 && (
              <TabletHeader>Exclude</TabletHeader>
            )}
          {orderLine.audiences && orderLine.audiences.length > 1 && (
            <span
              aria-label="Exclude this Audience from Order Line"
              data-tooltip="top"
            >
              <ExclusionAudience
                audience={audience}
                onCheck={(test) => {
                  if (!orderLine.id) return
                  handleCheck({ audience: test })
                }}
              />
            </span>
          )}
        </EditOLRowItem>
        {/* Retargeting pixel tag section */}
        {audience.type === 'AUDIENCE_TYPE_RETARGETING' && (
          <CollapsibleContainer
            isOpen={tagsOpen}
            className="EditOLRowItem__retargeting-tags"
          >
            {!fullOLAudience ? (
              <Loader />
            ) : (
              <div className="p-4 pt-0">
                <div className="mb-2 flex items-center justify-between">
                  <TextHeader type={4}>Retargeting Pixel Tags</TextHeader>
                  <span data-tooltip="top" aria-label="Close tags">
                    <Button
                      iconOnly={<Fa icon="xmark" size={1} />}
                      onClick={() => setTagsOpen(false)}
                    />
                  </span>
                </div>
                {fullOLAudience.result && (
                  <>
                    {fullOLAudience.result?.scriptPixel && (
                      <>
                        <Text className="font-bold">Javascript Tag</Text>
                        <div className="mb-2 flex justify-between gap-2">
                          <PixelText>
                            {fullOLAudience.result.scriptPixel}
                          </PixelText>
                          <Button
                            type="button"
                            className="max-h-[6rem]"
                            onClick={() => {
                              if (fullOLAudience.result?.scriptPixel) {
                                navigator.clipboard.writeText(
                                  fullOLAudience.result?.scriptPixel,
                                )
                              }
                            }}
                          >
                            Copy
                          </Button>
                        </div>
                      </>
                    )}
                    {fullOLAudience.result?.imagePixel && (
                      <>
                        <Text className="font-bold">Image Tag</Text>
                        <div className="flex justify-between gap-2">
                          <PixelText>
                            {fullOLAudience.result.imagePixel}
                          </PixelText>
                          <Button
                            type="button"
                            className="max-h-[6rem]"
                            onClick={() => {
                              if (fullOLAudience.result?.imagePixel) {
                                navigator.clipboard.writeText(
                                  fullOLAudience.result?.imagePixel,
                                )
                              }
                            }}
                          >
                            Copy
                          </Button>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </CollapsibleContainer>
        )}
      </TableGrid>
    </>
  )
}

const AudienceEditOLTableOrderLineRow = ({
  orderLine,
  modifiedOrderLines,
  handleAddAudience,
  handleRemoveAudience,
  handleExcludeOrIncludeAudiences,
}: {
  orderLine: ModifiedOrderLineType
} & AudienceEditOLTableProps) => {
  const [includeOrExclude, setIncludeOrExclude] = useState<
    { id: string; exclude: boolean }[]
  >([])
  const handleCheck = ({
    audience,
  }: {
    audience: { id: string; exclude: boolean }
  }) => {
    const checkIfPresent = includeOrExclude.filter((curr) => {
      return curr.id !== audience.id
    })
    const y = [...new Set([...checkIfPresent, audience])]
    setIncludeOrExclude(y)
    if (handleExcludeOrIncludeAudiences) {
      handleExcludeOrIncludeAudiences({
        olId: orderLine.id || '',
        audiencesToIncludeOrExclude: y,
      })
    }
  }
  // Empty audience order line
  if (
    !orderLine.audiences ||
    (orderLine.audiences && orderLine.audiences.length === 0)
  )
    return (
      <TableGrid className="hover:bg-tint-gray-50 EditOLRowItem min-h-[3.5rem]">
        <Text className="EditOLRowItem__item font-bold">
          {orderLine.name || 'No name'}
        </Text>
        <Text className="EditOLRowItem__item col-span-5">
          (No audiences on this order line)
        </Text>
      </TableGrid>
    )
  return (
    <>
      {orderLine.audiences?.map((audience, index) => {
        return (
          <AudienceEditOLTableRow
            key={`AudienceEditOLTableRow_${audience.id}_${index}`}
            index={index}
            orderLine={orderLine}
            audience={audience}
            audienceCount={audience.matched || getJobCounts(audience)}
            modifiedOrderLines={modifiedOrderLines}
            handleAddAudience={handleAddAudience}
            handleRemoveAudience={handleRemoveAudience}
            handleCheck={handleCheck}
          />
        )
      })}
    </>
  )
}

export const AudienceEditOLTable = ({
  modifiedOrderLines,
  handleAddAudience,
  handleRemoveAudience,
  handleExcludeOrIncludeAudiences,
}: AudienceEditOLTableProps) => {
  const [isDesktop] = useMediaQuery('(min-width: 861px)')

  return (
    <div className="EditOLTable relative w-full">
      {isDesktop && (
        <TableGrid className="EditOLTable__header StickyHeader">
          <HeaderText>Order Line Name</HeaderText>
          <HeaderText>File Name</HeaderText>
          <HeaderText>Type</HeaderText>
          <HeaderText>Count</HeaderText>
          <HeaderText>Move audience</HeaderText>
          <HeaderText>Remove audiences</HeaderText>
          <HeaderText>Exclude</HeaderText>
        </TableGrid>
      )}
      <div className="EditOLTable__body">
        {modifiedOrderLines.map((orderLine, index) => (
          <AudienceEditOLTableOrderLineRow
            key={`AudienceEditOLTableOrderLineRow_${
              orderLine.id || orderLine.tempId
            }_${index}`}
            orderLine={orderLine}
            modifiedOrderLines={modifiedOrderLines}
            handleAddAudience={handleAddAudience}
            handleRemoveAudience={handleRemoveAudience}
            handleExcludeOrIncludeAudiences={handleExcludeOrIncludeAudiences}
          />
        ))}
      </div>
    </div>
  )
}
