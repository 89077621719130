import React from 'react'
import { Fa, Text } from '@eltoro-ui/components'
import {
  Creativeservicev1Creative,
  Creativeservicev1Type,
  Targetjobservicev1Audience,
  V2Target,
} from 'next-gen-sdk'
import { imageMimes, videoMimes, audioMimes } from 'Enums'
import { ETIcon } from 'Assets'
import { InfoPopover } from 'Components'
import { simplifyEnum } from './enum-helpers'
import { DownloadFileAudienceType } from 'Requests'
import { validationRegEx } from '@eltoro-ui/hooks'

export const cleanedMime = (mime: string) => mime?.replace('.', '')

export const isImage = (mime: string) =>
  imageMimes.some((imageMime) => imageMime === mime)
export const isVideo = (mime: string) =>
  videoMimes.some((videoMime) => videoMime === mime)
export const isAudio = (mime: string) =>
  audioMimes.some((audioMime) => audioMime === mime)
export const isHtml = (mime: string) =>
  ['text/html'].some((htmlMime) => htmlMime === mime)

export const readableCreativeFileMime = (mime: string) => {
  const [, mimeSplit] = mime.split('/')
  if (mimeSplit === 'x-zip-compressed') return 'zip'
  return mimeSplit
}

export const readableCreativeType = (type: Creativeservicev1Type) => {
  if (type === 'CREATIVE_TYPE_VAST_TAG') return 'VAST Tag'
  if (type === 'CREATIVE_TYPE_HTML5') return 'HTML5 zip file'
  return simplifyEnum(2, type, true)
}

export const readableCreativeStringType = (type: string) => {
  if (type) {
    const joinedKey = (type?.split('_').join(' ') || '')
      .slice(14, type.length)
      .toLowerCase()
    return `${joinedKey[0]?.toUpperCase()}${joinedKey.substring(1)}`
  }
  return 'No Creatives used yet'
}
export const DurationOrDimensionDetail = (
  creative: Creativeservicev1Creative,
  includeWarningIcon?: boolean,
) => {
  const { files, height, width, type } = creative
  if (!files) return
  const first =
    files.length === 1
      ? files?.[0]
      : files.find((file) => {
          const key =
            creative.id &&
            file.extension &&
            `${creative.id?.charAt(0)}/${creative.id.charAt(1)}/${
              creative.id
            }/${creative.id}-${creative.name
              ?.replace(`.${file.extension}`, '')
              // handle special characters and removals from v2 logic
              // abstained from regex for readability
              .replace('(', '-')
              .replace(')', '-')
              .replace('.', '_')
              .replace('×', '-')
              .toLocaleLowerCase()}._source.${file.extension}`
          return file.key === key
        })
  const cleanMime = first?.mime && cleanedMime(first.mime)
  const v2Duration =
    files.length > 1 ? files.find((file) => file.duration !== 0) : files[0]
  if (
    cleanMime &&
    (isAudio(cleanMime) || (isVideo(cleanMime) && type !== 'CREATIVE_TYPE_OTT'))
  ) {
    if (files.length === 1 && files?.[0].duration) {
      return (
        <div>
          <strong>Duration:</strong>&nbsp; {files[0].duration} seconds
          <br />
          {isVideo(cleanMime) && (
            <>
              <strong>Spec:</strong>&nbsp; {files[0].width} x {files[0].height}
            </>
          )}
        </div>
      )
    } else {
      return (
        <div>
          <strong>Duration:</strong>&nbsp; {v2Duration?.duration} seconds
          <br />
          {isVideo(cleanMime) && (
            <>
              <strong>Spec:</strong>&nbsp; {creative?.width} x{' '}
              {creative?.height}
            </>
          )}
        </div>
      )
    }
  }
  const getDimensions = () => {
    if (
      creative.type === 'CREATIVE_TYPE_NATIVE_BANNER' ||
      type === 'CREATIVE_TYPE_VAST_TAG'
    )
      return '1x1'
    if (type === 'CREATIVE_TYPE_AD_TAG') return ` ${width} x ${height}`
    if (files?.[0]?.width !== undefined && files?.[0]?.height !== undefined)
      return ` ${files[0].width} x ${files[0].height}`
  }
  if (cleanMime && isVideo(cleanMime) && type === 'CREATIVE_TYPE_OTT') {
    if (creative.files?.length === 1) {
      return (
        <>
          <strong>OTT Specs:</strong>&nbsp;
          {`${
            creative.files?.[0].width !== undefined &&
            creative.files?.[0].height !== undefined &&
            `${creative.files[0].width} x ${creative.files[0].height}`
          }, ${
            creative.files?.[0].size !== undefined &&
            creative.files?.[0].duration !== undefined &&
            `${(
              ((+creative.files?.[0].size / +creative.files?.[0].duration) *
                8) /
              1000
            ).toFixed(2)} kbps`
          }, ${
            creative.files?.[0].duration &&
            `${creative.files?.[0].duration} seconds`
          }`}
          {includeWarningIcon &&
            (isOTTAndNotRecommendedDimension(creative) ||
              isOTTAndNotRecommendedBitrate(creative) ||
              isOTTAndNotRecommendedDuration(creative)) && (
              <InfoPopover
                icon="warning"
                content={
                  <div className="AdSetPreview__file-size-popover max-w-sm">
                    <Text tag="p">
                      Your creative(s) do not meet our recommended
                      specifications for optimal delivery. This{' '}
                      <strong>may</strong> limit our serving capacity.
                    </Text>

                    <Text tag="p">
                      <strong>Recommended dimensions: </strong>
                      1280x720 (720p)
                    </Text>

                    <Text tag="p">
                      <strong>Recommended bitrate: </strong>
                      Greater than 14,000 kbps
                    </Text>

                    <Text tag="p">
                      <strong>Recommended duration: </strong>
                      15 or 30 seconds
                    </Text>
                  </div>
                }
              />
            )}
        </>
      )
    } else {
      if (!v2Duration || !v2Duration.duration) return
      return (
        <>
          <strong>OTT Specs:</strong>&nbsp;
          {`${
            creative.width !== undefined &&
            creative.height !== undefined &&
            `${creative.width} x ${creative.height}`
          }, ${
            creative.files?.[0].size !== undefined &&
            creative.files?.[0].duration !== undefined &&
            `${(
              ((+(first?.size || 0) / +v2Duration?.duration || 0) * 8) /
              1000
            ).toFixed(2)} kbps`
          }, ${v2Duration.duration && `${v2Duration.duration} seconds`}`}
          {includeWarningIcon &&
            (isOTTAndNotRecommendedDimension(creative) ||
              isOTTAndNotRecommendedBitrate(creative) ||
              isOTTAndNotRecommendedDuration(creative)) && (
              <InfoPopover
                icon="warning"
                content={
                  <div className="AdSetPreview__file-size-popover max-w-sm">
                    <Text tag="p">
                      Your creative(s) do not meet our recommended
                      specifications for optimal delivery. This{' '}
                      <strong>may</strong> limit our serving capacity.
                    </Text>

                    <Text tag="p">
                      <strong>Recommended dimensions: </strong>
                      1280x720 (720p)
                    </Text>

                    <Text tag="p">
                      <strong>Recommended bitrate: </strong>
                      Greater than 14,000 kbps
                    </Text>

                    <Text tag="p">
                      <strong>Recommended duration: </strong>
                      15 or 30 seconds
                    </Text>
                  </div>
                }
              />
            )}
        </>
      )
    }
  }
  return (
    <>
      <span className="ThumbnailLabel">Spec:</span>
      <div className="Spec_wrap flex gap-2">
        {getDimensions()}
        {includeWarningIcon && isBannerAndMissingATop5Dimension(creative) && (
          <InfoPopover
            className="warning"
            icon="warning"
            content={
              <div className="AdSetPreview__dimension-popover max-w-sm">
                <Text>
                  Your creative is not in the top 5 recommended sizes. This{' '}
                  <strong>may</strong> limit our serving capacity.
                </Text>
                <br />
                <Text>
                  <strong>Top 5 recommended banner sizes: </strong>
                  <ul className="list-inside list-disc">
                    <li>300x250</li>
                    <li>728x90</li>
                    <li>160x600</li>
                    <li>320x50</li>
                    <li>300x600</li>
                  </ul>
                </Text>
              </div>
            }
          />
        )}
      </div>
    </>
  )
}

export const getCreativeFaETIcons = (
  creativeType: Creativeservicev1Type | undefined,
) => {
  if (creativeType === 'CREATIVE_TYPE_BANNER')
    return <Fa icon="image" size={1} type="duotone" />
  if (creativeType === 'CREATIVE_TYPE_NATIVE_BANNER')
    return <Fa icon="browser" size={1} type="duotone" />
  if (creativeType === 'CREATIVE_TYPE_AD_TAG') return <ETIcon icon="ad-tag" />
  if (creativeType === 'CREATIVE_TYPE_NATIVE_VIDEO')
    return <Fa icon="video" size={1} type="duotone" />
  if (creativeType === 'CREATIVE_TYPE_VAST_TAG')
    return <ETIcon icon="vast-tag" />
  if (creativeType === 'CREATIVE_TYPE_VIDEO')
    return <Fa icon="video" size={1} type="duotone" />
  if (creativeType === 'CREATIVE_TYPE_OTT')
    return <Fa className="OTTicon text-lg" icon="tv" size={1} type="duotone" />
  if (creativeType === 'CREATIVE_TYPE_AUDIO')
    return <Fa icon="volume" size={1} type="duotone" />
  if (creativeType === 'CREATIVE_TYPE_MAIL_PIECE')
    return <ETIcon className="DirectMailIcon" icon="direct-mail" />
  if (creativeType === 'CREATIVE_TYPE_HTML5')
    return <ETIcon className="HTML5Icon" icon="html5" />
  if (creativeType === 'CREATIVE_TYPE_UNSPECIFIED')
    return <ETIcon className="QuestionIcon" icon="question" />
  return <ETIcon className="campaignIcon" icon="campaign" />
}

export const checkIfCreative = (
  item:
    | V2Target
    | Targetjobservicev1Audience
    | Creativeservicev1Creative
    | DownloadFileAudienceType,
): item is Creativeservicev1Creative => {
  return !!(item as Creativeservicev1Creative).files
}

// if banner/html5/ad tag banner && if file's dimensions are not found in the suggested sizes
export const isBannerAndMissingATop5Dimension = (
  creative: Creativeservicev1Creative,
) => {
  const suggestedSizes = ['300x250', '728x90', '160x600', '320x50', '300x600']
  if (
    checkIfCreative(creative) &&
    (creative.type === 'CREATIVE_TYPE_BANNER' ||
      creative.type === 'CREATIVE_TYPE_AD_TAG' ||
      creative.type === 'CREATIVE_TYPE_HTML5')
  ) {
    if (
      creative.type === 'CREATIVE_TYPE_AD_TAG' &&
      creative.width !== undefined &&
      creative.height !== undefined
    ) {
      const dimensions = `${creative.width}x${creative.height}`
      return !suggestedSizes.find((s) => s === dimensions)
    }
    if (
      creative.files?.[0]?.width !== undefined &&
      creative.files?.[0]?.height !== undefined
    ) {
      const dimensions = `${creative.files?.[0]?.width}x${creative.files?.[0]?.height}`
      return !suggestedSizes.find((s) => s === dimensions)
    }
  }
  return false
}

export const isOTTAndNotRecommendedDimension = (
  creative: Creativeservicev1Creative,
) => {
  if (creative.files && creative.type === 'CREATIVE_TYPE_OTT') {
    const [file] = creative.files
    return file.width !== 1280 || file.height !== 720
  }
  return false
}

export const isOTTAndNotRecommendedBitrate = (
  creative: Creativeservicev1Creative,
) => {
  if (creative.files && creative.type === 'CREATIVE_TYPE_OTT') {
    const [file] = creative.files
    if (file.size && file.duration) {
      const kbps = ((+file.size / +file.duration) * 8) / 1000
      return kbps < 14000
    }
    return false
  }
  return false
}

export const isOTTAndNotRecommendedDuration = (
  creative: Creativeservicev1Creative,
) => {
  if (creative.files && creative.type === 'CREATIVE_TYPE_OTT') {
    const [file] = creative.files
    if (file.duration) {
      return file.duration !== 15 && file.duration !== 30
    }
  }
  return false
}

export const getFileType = (creative: Creativeservicev1Creative) => {
  if (creative.type === 'CREATIVE_TYPE_AD_TAG') return 'Third Party Tag'
  if (creative.type === 'CREATIVE_TYPE_VAST_TAG') return 'Third Party URL'
  if (creative.type === 'CREATIVE_TYPE_NATIVE_BANNER') return 'Native'
  if (creative.type === 'CREATIVE_TYPE_HTML5') {
    return 'zip'
  }
  if (!creative.files?.[0]?.mime) return ''
  if (
    creative.files?.[0]?.mime === 'application/octet-stream' &&
    (creative.type === 'CREATIVE_TYPE_OTT' ||
      creative.type === 'CREATIVE_TYPE_VIDEO')
  ) {
    const noOctets = creative.files.filter(
      (curCreative) =>
        curCreative.mime !== 'application/octet-stream' &&
        curCreative.mime !== 'image/png',
    )
    if (!noOctets?.[0].mime) return ''
    return readableCreativeFileMime(noOctets?.[0]?.mime)
  }
  if (
    (creative.files?.[0]?.mime === 'application/octet-stream' ||
      creative.files?.[0].mime === 'binary/octet-stream') &&
    creative.type === 'CREATIVE_TYPE_BANNER'
  ) {
    if (!creative.files?.[0].extension) return ''
    return creative.files?.[0].extension
  }
  return readableCreativeFileMime(creative.files?.[0]?.mime)
}

export const urlHasUTM = (url: string) => {
  const params =
    url && validationRegEx['url'].test(url) ? new URL(url).searchParams : null
  const source = params?.get('utm_source')
  const medium = params?.get('utm_medium')
  const campaign = params?.get('utm_campaign')
  return source && medium && campaign
}
