import { FC, MouseEventHandler, ReactNode } from 'react'
import classNames from 'classnames'
import './DropdownInput.scss'
import { Fa } from '../FontAwesome'

export const DropdownInput: FC<{
  options: any[]
  loading?: boolean
  className?: string
  optionClassName?: string
  children?: ReactNode
}> = ({ options, loading = false, className, optionClassName, children }) => {
  return (
    <div className={classNames('DropdownInput', className)}>
      {children}
      {options.length > 0 && !loading && (
        <div className="DropdownInput__dropdown">
          {options.map(
            (
              option: {
                label: string
                onClick: MouseEventHandler<HTMLButtonElement>
              },
              index: number,
            ) => (
              <button
                key={index}
                type="button"
                className={classNames(
                  'DropdownInput__result flex items-center justify-between',
                  optionClassName,
                )}
                onClick={option.onClick}
                value={option.label}
              >
                {option.label}
                <Fa icon="check" size={1} className="checkmark" />
              </button>
            ),
          )}
        </div>
      )}
      {loading && (
        <div className="DropdownInput__dropdown DropdownInput__dropdown--loading">
          <p>Loading results...</p>
        </div>
      )}
    </div>
  )
}
