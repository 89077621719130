import { showErrorMessage } from '@eltoro-ui/components'
import { V1Pixel } from 'next-gen-sdk'
import { ApiFactory } from 'apiFactory'

export const createPixel = async (
  orgId: string,
  incomingPixel: Partial<V1Pixel>,
) => {
  const token = localStorage.getItem('eltoro_token')
  const apis = ApiFactory(token)
  const { webToHomeAPI } = apis
  if (!webToHomeAPI) return
  let pixel: V1Pixel | undefined
  try {
    pixel = await webToHomeAPI.advertisingPlatformServiceCreatePixel(
      {
        ...incomingPixel,
        orgId,
      },
      orgId,
    )

    if (pixel) {
      // for some reason the fields are not working on create, so i'm updating after for now
      pixel = await webToHomeAPI.advertisingPlatformServiceUpdatePixel(
        pixel.id!,
        incomingPixel,
        undefined, // update mask
        orgId,
      )
    }
    return pixel
  } catch (error) {
    // @ts-ignore
    showErrorMessage('Error creating pixel', error?.message || '')
  }
}
