// TODO: better import syntax?
import {BaseAPIRequestFactory, RequiredError, COLLECTION_FORMATS} from './baseapi';
import {Configuration} from '../configuration';
import {RequestContext, HttpMethod, ResponseContext, HttpFile} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {canConsumeForm, isCodeInRange} from '../util';
import {SecurityAuthentication} from '../auth/auth';


import { GooglerpcStatus } from '../models/GooglerpcStatus';
import { TheAutomationToUpdate } from '../models/TheAutomationToUpdate';
import { V1Automation } from '../models/V1Automation';

/**
 * no description
 */
export class AudienceAutomationsApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * ### Description Creates an Automation as a subresource of a Job. An Automation holds information used to automate recurring actions related to the job.  ### Key Points - **Allowed Product Types:** Currently, only audiences with the product type `PRODUCT_TYPE_PROSPECTING` can have automations. - **Deletion:** Deletion of automations is not supported at this time. - **Disabling Automations:** If you want to stop an automation from running, set `enabled` to `false` using the `UpdateAudienceAutomation` endpoint. - **Requoting Audiences:** Automations that requote an audience will only run if the audience has been generated at least once. - **Prospecting Audiences:** Automations for prospecting audiences will not run instantly; they will be queued in the default queue because their child audiences need to be quoted first.
     * Create  Audience Automation
     * @param audienceId The Audience this automation belongs to.
     * @param orgId The Organization where this Audience belongs to.
     * @param automation The automation to create.
     */
    public async advertisingPlatformServiceCreateAudienceAutomation(audienceId: string, orgId: string, automation: V1Automation, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'audienceId' is not null or undefined
        if (audienceId === null || audienceId === undefined) {
            throw new RequiredError("AudienceAutomationsApi", "advertisingPlatformServiceCreateAudienceAutomation", "audienceId");
        }


        // verify required parameter 'orgId' is not null or undefined
        if (orgId === null || orgId === undefined) {
            throw new RequiredError("AudienceAutomationsApi", "advertisingPlatformServiceCreateAudienceAutomation", "orgId");
        }


        // verify required parameter 'automation' is not null or undefined
        if (automation === null || automation === undefined) {
            throw new RequiredError("AudienceAutomationsApi", "advertisingPlatformServiceCreateAudienceAutomation", "automation");
        }


        // Path Params
        const localVarPath = '/v1/audiences/{audience_id}/automations'
            .replace('{' + 'audience_id' + '}', encodeURIComponent(String(audienceId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (orgId !== undefined) {
            requestContext.setQueryParam("org_id", ObjectSerializer.serialize(orgId, "string", ""));
        }


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(automation, "V1Automation", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["OAuth2"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Update Audience Automation
     * @param audienceId The Audience to which this automation belongs.
     * @param automationId The Automation&#39;s ID.
     * @param orgId The Organization to which this Audience belongs.
     * @param automation The automation to update.
     * @param updateMask The list of fields to update.
     */
    public async advertisingPlatformServiceUpdateAudienceAutomation(audienceId: string, automationId: string, orgId: string, automation: TheAutomationToUpdate, updateMask?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'audienceId' is not null or undefined
        if (audienceId === null || audienceId === undefined) {
            throw new RequiredError("AudienceAutomationsApi", "advertisingPlatformServiceUpdateAudienceAutomation", "audienceId");
        }


        // verify required parameter 'automationId' is not null or undefined
        if (automationId === null || automationId === undefined) {
            throw new RequiredError("AudienceAutomationsApi", "advertisingPlatformServiceUpdateAudienceAutomation", "automationId");
        }


        // verify required parameter 'orgId' is not null or undefined
        if (orgId === null || orgId === undefined) {
            throw new RequiredError("AudienceAutomationsApi", "advertisingPlatformServiceUpdateAudienceAutomation", "orgId");
        }


        // verify required parameter 'automation' is not null or undefined
        if (automation === null || automation === undefined) {
            throw new RequiredError("AudienceAutomationsApi", "advertisingPlatformServiceUpdateAudienceAutomation", "automation");
        }



        // Path Params
        const localVarPath = '/v1/audiences/{audience_id}/automations/{automation.id}'
            .replace('{' + 'audience_id' + '}', encodeURIComponent(String(audienceId)))
            .replace('{' + 'automation.id' + '}', encodeURIComponent(String(automationId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.PATCH);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (updateMask !== undefined) {
            requestContext.setQueryParam("update_mask", ObjectSerializer.serialize(updateMask, "string", ""));
        }

        // Query Params
        if (orgId !== undefined) {
            requestContext.setQueryParam("org_id", ObjectSerializer.serialize(orgId, "string", ""));
        }


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(automation, "TheAutomationToUpdate", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["OAuth2"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

}

export class AudienceAutomationsApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to advertisingPlatformServiceCreateAudienceAutomation
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async advertisingPlatformServiceCreateAudienceAutomation(response: ResponseContext): Promise<V1Automation > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: V1Automation = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "V1Automation", ""
            ) as V1Automation;
            return body;
        }
        if (isCodeInRange("403", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Returned when the user does not have permission to access the resource.", body, response.headers);
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: string = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "string", ""
            ) as string;
            throw new ApiException<string>(response.httpStatusCode, "Returned when the resource does not exist.", body, response.headers);
        }
        if (isCodeInRange("500", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Server error", body, response.headers);
        }
        if (isCodeInRange("0", response.httpStatusCode)) {
            const body: GooglerpcStatus = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "GooglerpcStatus", ""
            ) as GooglerpcStatus;
            throw new ApiException<GooglerpcStatus>(response.httpStatusCode, "An unexpected error response.", body, response.headers);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: V1Automation = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "V1Automation", ""
            ) as V1Automation;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to advertisingPlatformServiceUpdateAudienceAutomation
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async advertisingPlatformServiceUpdateAudienceAutomation(response: ResponseContext): Promise<V1Automation > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: V1Automation = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "V1Automation", ""
            ) as V1Automation;
            return body;
        }
        if (isCodeInRange("403", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Returned when the user does not have permission to access the resource.", body, response.headers);
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: string = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "string", ""
            ) as string;
            throw new ApiException<string>(response.httpStatusCode, "Returned when the resource does not exist.", body, response.headers);
        }
        if (isCodeInRange("500", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Server error", body, response.headers);
        }
        if (isCodeInRange("0", response.httpStatusCode)) {
            const body: GooglerpcStatus = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "GooglerpcStatus", ""
            ) as GooglerpcStatus;
            throw new ApiException<GooglerpcStatus>(response.httpStatusCode, "An unexpected error response.", body, response.headers);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: V1Automation = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "V1Automation", ""
            ) as V1Automation;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

}
