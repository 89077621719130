import { useState, useRef } from 'react'
import { useOnClickOutside } from '@eltoro-ui/hooks'
import { SuggestionsList } from './components'
import './AutoCompleteInput.scss'

type AutoCompleteInputType = {
  suggestions?: string[]
  onChange?: (e?: React.ChangeEvent<HTMLInputElement>) => void
  onInputSelect?: (e?: React.KeyboardEvent<HTMLInputElement>) => void
  onSuggestionSelect: (selectedValue: string) => void
  disabled?: boolean
  value: string
  maxSuggestions?: number
  id?: string
}
export const AutoCompleteInput: React.FC<AutoCompleteInputType> = ({
  suggestions = [''],
  maxSuggestions,
  onChange,
  onInputSelect,
  onSuggestionSelect,
  disabled,
  value,
  id,
}) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([])
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1)
  const [isKeyNavigating, setIsKeyNavigating] = useState(false)
  const [showSuggestions, setShowSuggestions] = useState(false)
  const suggestionListRef = useRef(null)
  useOnClickOutside([suggestionListRef], () => {
    if (isKeyNavigating) setIsKeyNavigating(false)
    setShowSuggestions(false)
  })
  const filterList = (input: string) => {
    const filteredList = suggestions.filter(
      (suggestion: string) =>
        input.toLowerCase() === suggestion.toLowerCase().slice(0, input.length),
    )
    if (maxSuggestions) return filteredList.slice(0, maxSuggestions)
    return filteredList
  }
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isKeyNavigating) setIsKeyNavigating(false)
    setShowSuggestions(false)
    if (onChange) {
      onChange(e)
    }
    // debounces autoComplete search
    setTimeout(() => {
      setFilteredSuggestions(filterList(e.target.value))
      setActiveSuggestionIndex(-1)
      setShowSuggestions(true)
    }, 300)
  }
  const onClickSuggestion = (e: React.MouseEvent<Element>) => {
    if (isKeyNavigating) setIsKeyNavigating(false)
    setFilteredSuggestions([])
    // typescript Bug https://stackoverflow.com/questions/54886637/error-property-innertext-does-not-exist-on-type-eventtarget
    setActiveSuggestionIndex(-1)
    setShowSuggestions(false)
    onSuggestionSelect((e?.target as HTMLElement)?.innerText || '')
  }

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown') {
      setIsKeyNavigating(true)
      if (activeSuggestionIndex < filteredSuggestions.length - 1) {
        setActiveSuggestionIndex(activeSuggestionIndex + 1)
      } else {
        setActiveSuggestionIndex(0)
      }
    } else if (e.key === 'ArrowUp') {
      if (activeSuggestionIndex > 0) {
        setActiveSuggestionIndex(activeSuggestionIndex - 1)
      } else {
        setActiveSuggestionIndex(filteredSuggestions.length - 1)
      }
    } else if (e.key === 'Enter') {
      if (activeSuggestionIndex === -1) {
        if (onInputSelect) {
          setShowSuggestions(false)
          onInputSelect(e)
        }
      } else {
        onSuggestionSelect(filteredSuggestions[activeSuggestionIndex] || '')
        setActiveSuggestionIndex(-1)
        setShowSuggestions(false)
      }
    } else if (e.key === 'Escape') {
      setActiveSuggestionIndex(-1)
      setShowSuggestions(false)
    }
  }
  return (
    <div className="AutoCompleteInput">
      <input
        disabled={disabled}
        type="text"
        id={id}
        onChange={(e) => handleOnChange(e)}
        onKeyDown={(e) => handleOnKeyDown(e)}
        value={value}
      />
      {suggestions.length > 1 && value && showSuggestions && (
        <div className="ListContainer" ref={suggestionListRef}>
          <SuggestionsList
            isKeyNavigating={isKeyNavigating}
            filteredSuggestions={filteredSuggestions}
            activeSuggestionIndex={activeSuggestionIndex}
            onClick={(e) => onClickSuggestion(e)}
          />
        </div>
      )}
    </div>
  )
}
