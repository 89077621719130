import { useEffect, useState } from 'react'
import {
  Button,
  Fa,
  Loader,
  showErrorMessage,
  Table,
  Text,
  TextHeader,
  Toggle,
} from '@eltoro-ui/components'
import { usePagination } from '@eltoro-ui/hooks'
import { Hagridv1Subscription, V1Stats } from 'next-gen-sdk'
import { useParams } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import { PixelFormItem, TablePagination } from 'Components'
import { useAppContext } from 'Contexts'
import { getSubscriptionTableMetricsColumns } from './getSubscriptionTableMetricsColumns'
import { exportAndDownloadStatsCsv } from './exportAndDownloadStatsCsv'

export const SubscriptionMetricsCard = ({
  subscription,
  isPartner,
}: {
  subscription: Hagridv1Subscription
  isPartner: boolean
}) => {
  const [showAggregatedMetrics, setShowAggregatedMetrics] = useState(true)
  const [aggregatedStats, setAggregatedStats] = useState<V1Stats>()
  const [dateFilter, setDateFilter] = useState('yesterday')
  const [customDateInput, setCustomDateInput] = useState({
    start: new Date(),
    end: new Date(),
  })
  const [stats, setStats] = useState<V1Stats[]>([])
  const [loading, setLoading] = useState(true)

  let params = useParams()

  const pixelId = params.pixelId || ''
  const subscriptionId = params.subscriptionId || ''

  const { webToHomeApi, webToHomePrintPartnerApi } = useAppContext()

  const aggregatedStatsOptions = [
    { value: 'yesterday', label: 'Yesterday' },
    { value: 'current-week', label: 'Current Week' },
    { value: 'previous-week', label: 'Previous Week' },
    { value: 'month-to-date', label: 'Month to Date' },
    { value: 'quarter-to-date', label: 'Quarter to Date' },
    { value: 'previous-quarter', label: 'Previous Quarter' },
    { value: 'custom', label: 'Custom' },
    { value: 'lifetime', label: 'Lifetime' },
  ]

  useEffect(() => {
    if (!webToHomeApi || !subscription.id) return

    const handleFetchStats = async () => {
      let nextPageToken = ''
      let stat: V1Stats[] = []
      let aggregatedStat: V1Stats | undefined
      setLoading(true)
      const startDate = new Date()
      const endDate = new Date()
      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(23, 59, 59, 999)
      if (
        dateFilter === 'custom' &&
        customDateInput.start &&
        customDateInput.end
      ) {
        startDate.setDate(customDateInput.start.getDate())
        startDate.setMonth(customDateInput.start.getMonth())
        startDate.setFullYear(customDateInput.start.getFullYear())
        endDate.setDate(customDateInput.end.getDate())
        endDate.setMonth(customDateInput.end.getMonth())
        endDate.setFullYear(customDateInput.end.getFullYear())
      }
      switch (dateFilter) {
        case 'yesterday':
          startDate.setDate(startDate.getDate() - 1)
          endDate.setDate(endDate.getDate() - 1)
          break
        case 'current-week':
          startDate.setDate(startDate.getDate() - startDate.getDay())
          break
        case 'previous-week':
          startDate.setDate(startDate.getDate() - startDate.getDay() - 7)
          endDate.setDate(endDate.getDate() - endDate.getDay() - 1)
          break
        case 'month-to-date':
          startDate.setDate(1)
          break
        case 'quarter-to-date':
          startDate.setMonth(Math.floor(startDate.getMonth() / 3) * 3)
          startDate.setDate(1)
          break
        case 'previous-quarter':
          startDate.setMonth(Math.floor(startDate.getMonth() / 3) * 3 - 3)
          startDate.setDate(1)
          endDate.setMonth(Math.floor(endDate.getMonth() / 3) * 3)
          endDate.setDate(0)
          break
        case 'lifetime':
          startDate.setFullYear(1970)
          break
        default:
          break
      }

      try {
        const fetchStats = async (pageToken: string) => {
          const res =
            isPartner && webToHomePrintPartnerApi && subscription.publisherId
              ? await webToHomePrintPartnerApi?.advertisingPlatformServiceListPublisherSubscriptionStats(
                  subscription.publisherId, // publisherId
                  subscriptionId, // subscriptionId
                  100, // pageSize
                  pageToken, // nextPageToken
                  subscription.publisherId, // orgId
                  startDate.toISOString(), // startReportDate
                  endDate.toISOString(), // endReportDate
                  'run_date desc', // orderBy
                )
              : await webToHomeApi.advertisingPlatformServiceListStats(
                  pixelId,
                  subscriptionId,
                  100, // pageSize
                  pageToken,
                  subscription.orgId, // orgId
                  startDate.toISOString(), // startReportDate
                  endDate.toISOString(), // endReportDate
                  'run_date desc', // orderBy
                )
          return res
        }
        do {
          const res = await fetchStats(nextPageToken)
          stat = [...stat, ...(res.stats || [])]
          aggregatedStat = {
            hits: stat.reduce((acc, curr) => acc + (curr.hits || 0), 0),
            matcherTotal: stat.reduce(
              (acc, curr) => acc + (curr.matcherTotal || 0),
              0,
            ),
            matcherMatches: stat.reduce(
              (acc, curr) => acc + (curr.matcherMatches || 0),
              0,
            ),
            matcherDuplicates: stat.reduce(
              (acc, curr) => acc + (curr.matcherDuplicates || 0),
              0,
            ),
            matches: stat.reduce((acc, curr) => acc + (curr.matches || 0), 0),
            stateFilterReductions: stat.reduce(
              (acc, curr) => acc + (curr.stateFilterReductions || 0),
              0,
            ),
            zipFilterReductions: stat.reduce(
              (acc, curr) => acc + (curr.zipFilterReductions || 0),
              0,
            ),
          }
          nextPageToken = res.nextPageToken || ''
        } while (nextPageToken !== '')
        setStats(stat)
        setAggregatedStats(aggregatedStat)
      } catch (error) {
        showErrorMessage('Error fetching subscription stats', '')
      } finally {
        setLoading(false)
      }
    }

    handleFetchStats().catch(() => {
      showErrorMessage('Error fetching stats', '')
    })
  }, [subscription, dateFilter, customDateInput])

  const pagination = usePagination(
    {
      sourceData: stats,
      pageSize: 5,
    },
    [stats],
  )

  const columns = getSubscriptionTableMetricsColumns()

  return (
    <div className="mb-6 overflow-hidden rounded-lg bg-[color:var(--base-bg)] shadow-lg">
      <div className="p-6">
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-semibold text-[color:var(--base-text)]">
            Subscription Metrics
          </h2>
          <div className="flex items-center space-x-4">
            <div className="flex items-center">
              <div className="relative inline-block select-none align-middle">
                <Toggle
                  isChecked={showAggregatedMetrics}
                  onChange={(checked) => setShowAggregatedMetrics(checked)}
                  size="s"
                  label="Show Aggregated Metrics"
                  isDisabled={loading}
                  className={loading ? 'opacity-50' : ''}
                />
              </div>
            </div>
            <select
              value={dateFilter}
              disabled={loading}
              onChange={(e) => setDateFilter(e.target.value)}
              className="rounded-md border border-[color:var(--color-grey-200)] bg-[color:var(--base-bg)] px-3 py-1.5 text-sm text-[color:var(--color-grey-400)] transition-colors hover:border-[color:var(--color-grey-300)]"
            >
              {aggregatedStatsOptions.map((option) => (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <Button
              iconLeft={
                <Fa icon="download" size={1} className="mr-2 h-4 w-4" />
              }
              className="btn-primary"
              disabled={loading}
              onClick={() => {
                exportAndDownloadStatsCsv(
                  stats,
                  subscription.name?.replace(' ', '_') || 'No_Name',
                  new Date(),
                  new Date(),
                )
              }}
            >
              Export CSV
            </Button>
          </div>
        </div>

        {dateFilter === 'custom' && (
          <div className="animate-slidedown flex justify-end">
            <div className="mt-2 flex w-max gap-2">
              <PixelFormItem htmlFor="stats-start-date" label="Start Date">
                {/* @ts-ignore */}
                <DatePicker
                  onChange={(e) => {
                    if (e) {
                      setCustomDateInput((oldArr) => {
                        oldArr.start = e
                        return { ...oldArr }
                      })
                    }
                  }}
                  selected={customDateInput.start}
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy"
                  placeholderText="Start date"
                  portalId="start-date-popper"
                  maxDate={customDateInput.end}
                />
              </PixelFormItem>
              <PixelFormItem htmlFor="stats-end-date" label="End Date">
                {/* @ts-ignore */}
                <DatePicker
                  onChange={(e) => {
                    if (e) {
                      setCustomDateInput((oldArr) => {
                        oldArr.end = e
                        return { ...oldArr }
                      })
                    }
                  }}
                  selected={customDateInput.end}
                  timeInputLabel="Time:"
                  dateFormat="MM/dd/yyyy"
                  placeholderText="End date"
                  maxDate={new Date()}
                  minDate={customDateInput.start}
                  portalId="end-date-popper"
                />
              </PixelFormItem>
            </div>
          </div>
        )}

        {loading ? (
          <Loader />
        ) : (
          <>
            {/* Metrics Table */}
            {showAggregatedMetrics ? (
              <div className="mt-6 flex justify-between gap-2">
                <div className="flex flex-col gap-2">
                  <TextHeader type={6} className="font-regular">
                    Total Hits
                  </TextHeader>
                  <Text size="xl" weight="bold">
                    {aggregatedStats?.hits === undefined
                      ? 'N/A'
                      : aggregatedStats.hits.toLocaleString()}
                  </Text>
                </div>
                <div className="flex flex-col gap-2">
                  <TextHeader type={6} className="font-regular">
                    Filtered Audience
                  </TextHeader>
                  <Text size="xl" weight="bold">
                    {aggregatedStats?.matcherTotal === undefined
                      ? 'N/A'
                      : aggregatedStats.matcherTotal.toLocaleString()}
                  </Text>
                </div>
                <div className="flex flex-col gap-2">
                  <TextHeader type={6} className="font-regular">
                    Matched Audience
                  </TextHeader>
                  <Text size="xl" weight="bold">
                    {aggregatedStats?.matcherMatches === undefined
                      ? 'N/A'
                      : aggregatedStats.matcherMatches.toLocaleString()}
                  </Text>
                </div>
                <div className="flex flex-col gap-2">
                  <TextHeader type={6} className="font-regular">
                    Previously Matched
                  </TextHeader>
                  <Text size="xl" weight="bold">
                    {aggregatedStats?.matcherDuplicates === undefined
                      ? 'N/A'
                      : aggregatedStats.matcherDuplicates.toLocaleString()}
                  </Text>
                </div>
                <div className="flex flex-col gap-2">
                  <TextHeader type={6} className="font-regular">
                    Targetable Audience
                  </TextHeader>
                  <Text size="xl" weight="bold">
                    {aggregatedStats?.matches === undefined
                      ? 'N/A'
                      : aggregatedStats.matches.toLocaleString()}
                  </Text>
                </div>
                <div className="flex flex-col gap-2">
                  <TextHeader type={6} className="font-regular">
                    Geo-Filtered
                  </TextHeader>
                  <Text size="xl" weight="bold">
                    {(
                      (aggregatedStats?.stateFilterReductions || 0) +
                      (aggregatedStats?.zipFilterReductions || 0)
                    ).toLocaleString()}
                  </Text>
                </div>
              </div>
            ) : (
              <div className="SubscriptionMetricsTable">
                {!pagination.data.length ? (
                  <div className="PixelLibraryPage__empty-state flex flex-col items-center py-6">
                    <Fa
                      icon="folder"
                      size={5}
                      type="duotone"
                      className="text-primary-200"
                    />
                    <p className="text-grey-600">No stats to display.</p>
                  </div>
                ) : (
                  <>
                    <Table
                      className="SubscriptionTable__table"
                      columns={columns}
                      rows={pagination.data}
                    />
                    {pagination.totalItems > 5 ? (
                      <div className="PixelLibraryPage__pagination-footer">
                        <TablePagination pagination={pagination} />
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
