/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class V3AuctionEvent {
    'kpiAuctionEventType'?: string;
    'kpiAuctionEventTypeCode'?: string;
    'kpiAuctionTypeId'?: number;
    'kpiValue'?: number;
    'paymentAuctionEventType'?: string;
    'paymentAuctionEventTypeCode'?: string;
    'paymentAuctionTypeId'?: number;
    'revenueAuctionEventType'?: string;
    'revenueAuctionEventTypeCode'?: string;
    'revenueAuctionTypeId'?: number;
    'kpiValueType'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "kpiAuctionEventType",
            "baseName": "kpi_auction_event_type",
            "type": "string",
            "format": ""
        },
        {
            "name": "kpiAuctionEventTypeCode",
            "baseName": "kpi_auction_event_type_code",
            "type": "string",
            "format": ""
        },
        {
            "name": "kpiAuctionTypeId",
            "baseName": "kpi_auction_type_id",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "kpiValue",
            "baseName": "kpi_value",
            "type": "number",
            "format": "double"
        },
        {
            "name": "paymentAuctionEventType",
            "baseName": "payment_auction_event_type",
            "type": "string",
            "format": ""
        },
        {
            "name": "paymentAuctionEventTypeCode",
            "baseName": "payment_auction_event_type_code",
            "type": "string",
            "format": ""
        },
        {
            "name": "paymentAuctionTypeId",
            "baseName": "payment_auction_type_id",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "revenueAuctionEventType",
            "baseName": "revenue_auction_event_type",
            "type": "string",
            "format": ""
        },
        {
            "name": "revenueAuctionEventTypeCode",
            "baseName": "revenue_auction_event_type_code",
            "type": "string",
            "format": ""
        },
        {
            "name": "revenueAuctionTypeId",
            "baseName": "revenue_auction_type_id",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "kpiValueType",
            "baseName": "kpi_value_type",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3AuctionEvent.attributeTypeMap;
    }

    public constructor() {
    }
}

