import { Fa } from '@eltoro-ui/components'
import { v4 as uuid } from 'uuid'
import classNames from 'classnames'
import { useAppContext } from 'Contexts'
import { RuleRow } from './RuleRow'
import { RuleSetRow } from './RuleSetRow'
import { PixelFormItem, RuleSetButton } from './shared'
import { RuleSetWithTempId, RuleWithTempId } from './types'

// * All Subscriptions should contain at least an \"And\" rule set. Other options are \"Or\" and \"Not\".

export const RuleSets = ({
  parentRuleSet,
  setParentRuleSet,
  showLabel = true,
  onDeleteRule,
  onDeleteRuleSet,
}: {
  parentRuleSet: Partial<RuleSetWithTempId>
  setParentRuleSet: React.Dispatch<
    React.SetStateAction<Partial<RuleSetWithTempId>>
  >
  showLabel?: boolean
  onDeleteRule?: (rule: RuleWithTempId) => void
  onDeleteRuleSet?: (ruleSet: RuleSetWithTempId) => void
}) => {
  const debugMode = false // turn this on to show rule set and rule ids for debugging
  const { isReadOnly } = useAppContext()
  let level = 0
  return (
    <div
      className={classNames('RuleSets w-full', {
        'm-1 border border-[red] p-1': debugMode,
      })}
    >
      <PixelFormItem htmlFor="rule-sets" label={showLabel ? 'Rule Sets' : ''}>
        {debugMode && (
          <span className="text-[red]">
            parent rule set id: {parentRuleSet.id}
          </span>
        )}
        <div className="flex flex-col gap-2">
          {(() => {
            if (
              !(parentRuleSet?.rules || []).length &&
              !(parentRuleSet?.ruleSet || []).length
            )
              return (
                <div className="text-grey-500 w-full p-4">
                  <em>Add rules or groups to define conditions</em>
                </div>
              )

            return parentRuleSet?.rules?.map((r) => {
              return (
                <RuleRow
                  key={(r as RuleWithTempId).tempId}
                  rule={r as RuleWithTempId}
                  debugMode={debugMode}
                  handleDeleteRule={(ruleToDelete) => {
                    setParentRuleSet((prev) => {
                      const filtered = prev?.rules?.filter(
                        (r) =>
                          (r as RuleWithTempId).tempId !== ruleToDelete.tempId,
                      ) as RuleWithTempId[]
                      return { ...prev, rules: filtered }
                    })
                    if (onDeleteRule) onDeleteRule(ruleToDelete)
                  }}
                  handleUpdateRule={(ruleToUpdate) => {
                    setParentRuleSet((prev) => {
                      const updatedRules = prev?.rules?.map((r) =>
                        (r as RuleWithTempId).tempId === ruleToUpdate.tempId
                          ? ruleToUpdate
                          : r,
                      ) as RuleWithTempId[]
                      return { ...prev, rules: updatedRules }
                    })
                  }}
                />
              )
            })
          })()}
        </div>
        {parentRuleSet.ruleSet?.map((r: RuleSetWithTempId) => {
          return (
            <RuleSetRow
              level={level}
              key={r.tempId}
              ruleSet={r}
              setParentRuleSet={setParentRuleSet}
              debugMode={debugMode}
              onDeleteRule={onDeleteRule}
              onDeleteRuleSet={onDeleteRuleSet}
            />
          )
        })}

        <div className="RuleSets__menu flex w-full gap-1">
          <RuleSetButton
            kind="text-icon"
            onClick={() => {
              setParentRuleSet((prev) => ({
                operator: 'and',
                ...prev,
                rules: [
                  ...(prev?.rules || []),
                  { term: '', tempId: uuid(), type: 'term' },
                ],
              }))
            }}
            iconLeft={<Fa icon="plus" size={1} />}
            disabled={isReadOnly}
          >
            Add Rule
          </RuleSetButton>
          <RuleSetButton
            kind="text-icon"
            onClick={() => {
              setParentRuleSet((prev) => ({
                operator: 'and',
                ...prev,
                ruleSet: [
                  ...(prev?.ruleSet || []),
                  {
                    operator: 'and',
                    tempId: uuid(),
                  },
                ],
              }))
            }}
            iconLeft={<Fa icon="layer-group" size={1} />}
            disabled={isReadOnly}
          >
            Add Group
          </RuleSetButton>
          {/* <span aria-label="Coming soon!" data-tooltip="top">
            <RuleSetButton
              kind="text-icon"
              onClick={() => {}}
              iconLeft={<Fa icon="plus" size={1} />}
              disabled
            >
              Bulk Import
            </RuleSetButton>
          </span> */}
        </div>
      </PixelFormItem>
    </div>
  )
}
