/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { TypeDate } from '../models/TypeDate';
import { HttpFile } from '../http/http';

export class V1Entry {
    'productName'?: string;
    'unit'?: string;
    'price'?: number;
    'quantity'?: number;
    'itemCost'?: number;
    'memo'?: string;
    'orderLineId'?: string;
    'billDateStart'?: TypeDate;
    'billDateEnd'?: TypeDate;
    'childOrgId'?: string;
    'childOrgName'?: string;
    'campaignStart'?: TypeDate;
    'campaignEnd'?: TypeDate;
    'isPolitical'?: boolean;
    'poNumber'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "productName",
            "baseName": "product_name",
            "type": "string",
            "format": ""
        },
        {
            "name": "unit",
            "baseName": "unit",
            "type": "string",
            "format": ""
        },
        {
            "name": "price",
            "baseName": "price",
            "type": "number",
            "format": "double"
        },
        {
            "name": "quantity",
            "baseName": "quantity",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "itemCost",
            "baseName": "item_cost",
            "type": "number",
            "format": "double"
        },
        {
            "name": "memo",
            "baseName": "memo",
            "type": "string",
            "format": ""
        },
        {
            "name": "orderLineId",
            "baseName": "order_line_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "billDateStart",
            "baseName": "bill_date_start",
            "type": "TypeDate",
            "format": ""
        },
        {
            "name": "billDateEnd",
            "baseName": "bill_date_end",
            "type": "TypeDate",
            "format": ""
        },
        {
            "name": "childOrgId",
            "baseName": "child_org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "childOrgName",
            "baseName": "child_org_name",
            "type": "string",
            "format": ""
        },
        {
            "name": "campaignStart",
            "baseName": "campaign_start",
            "type": "TypeDate",
            "format": ""
        },
        {
            "name": "campaignEnd",
            "baseName": "campaign_end",
            "type": "TypeDate",
            "format": ""
        },
        {
            "name": "isPolitical",
            "baseName": "is_political",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "poNumber",
            "baseName": "po_number",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1Entry.attributeTypeMap;
    }

    public constructor() {
    }
}

