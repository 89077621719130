import { useState } from 'react'
import { Button, Checkbox, Fa } from '@eltoro-ui/components'
import { V1WebToHomeNoteType } from 'next-gen-sdk'
import classNames from 'classnames'
import { useAppContext } from 'Contexts'
import { PixelFormItem } from './shared'

export const PixelNotes = ({
  type,
  onChange,
  showLabel = true,
  onSaveNote,
  saving,
}: {
  type: 'pixel' | 'subscription'
  onChange: (notes: string, type: V1WebToHomeNoteType) => void
  showLabel?: boolean
  onSaveNote?: (notes: string, type: V1WebToHomeNoteType) => void
  saving?: boolean
}) => {
  // TODO: pass in subscription or pixel notes from above and disabled the create button if notes are not valid
  const [note, setNote] = useState('')
  const [adminNote, setAdminNote] = useState(false)
  const { isAdmin, isReadOnly } = useAppContext()
  const isNotesValid = note.length <= 500

  return (
    <div className="PixelNotes flex flex-col gap-2">
      <div className="flex gap-2">
        <PixelFormItem
          label={showLabel ? 'Notes' : ''}
          htmlFor="notes"
          valid={isNotesValid}
          counter
          className="w-full"
        >
          <textarea
            className={classNames('h-32 w-full', {
              '!border-warning': !isNotesValid,
            })}
            value={note}
            onChange={(e) => {
              setNote(e.target.value)
              onChange(
                e.target.value,
                adminNote ? 'NOTE_TYPE_ADMIN' : 'NOTE_TYPE_USER',
              )
            }}
            id="notes"
            placeholder={`Add notes about this ${type}`}
            maxLength={501}
            disabled={isReadOnly || saving}
          />
        </PixelFormItem>
        {onSaveNote && (
          <Button
            disabled={!isNotesValid || saving || note.length < 1}
            onClick={() => {
              onSaveNote(note, adminNote ? 'NOTE_TYPE_ADMIN' : 'NOTE_TYPE_USER')
            }}
            className="rounded-md !p-4"
            iconOnly={<Fa icon="save" size={1} />}
            kind="primary"
          />
        )}
      </div>
      {isAdmin && (
        <Checkbox
          label="Mark as admin note"
          onChange={(bool) => {
            setAdminNote(bool)
            onChange(note, bool ? 'NOTE_TYPE_ADMIN' : 'NOTE_TYPE_USER')
          }}
          checked={adminNote}
          disabled={isReadOnly || saving}
        />
      )}
    </div>
  )
}
