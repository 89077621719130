import { useState, useEffect, useCallback } from 'react'
import {
  Button,
  Fa,
  Toggle,
  TagInput,
  Select,
  Table,
  showErrorMessage,
  showInfoMessage,
  showSuccessMessage,
  Loader,
} from '@eltoro-ui/components'
import {
  V1Pixel,
  Hagridv1Subscription,
  V1WebToHomeNoteType,
} from 'next-gen-sdk'
import { Link, useParams } from 'react-router-dom'
import {
  Loading,
  EmptyField,
  PageContent,
  PixelNotes,
  PixelSubscriptionCreate,
  TablePagination,
  TextInputWithErrorText,
} from 'Components'
import { useAppContext } from 'Contexts'
import { WebToHomeDisabled } from 'Pages/PixelLibraryPage'
import { useTokenPagination } from '@eltoro-ui/hooks'
import { formatDate } from 'Helpers'
import { useValidatedState } from '@eltoro-ui/hooks'
import dayjs from 'dayjs'
import classNames from 'classnames'

export interface PixelType {
  name: string
  status: 'approved' | 'pending' | 'rejected'
  dateCreated: string
  url: string
  tags: string[]
  isSensitive: boolean
  isLocked: boolean
  notes: string[]
  code: string
  history: PixelHistoryEventType[]
}

export interface PixelHistoryEventType {
  user: string
  timestamp: string
  action: string
}

export interface SubscriptionType {
  name: string
  type: string
  dateCreated: string
  id: string
}

export const PixelViewPage = () => {
  const { pixelId } = useParams<{
    pixelId?: string
  }>()
  const [status, setStatus] = useState<{ label: string; value: string }>()
  const [pixel, setPixel] = useState<V1Pixel>()
  const [isSensitive, setIsSensitive] = useState(false)
  const [isLocked, setIsLocked] = useState(false)
  const [isHidden, setIsHidden] = useState(false)
  const [url, setUrl, isValidUrl] = useValidatedState('https://', 'url')
  const [tags, setTags] = useState<string[]>()
  const [showAddNotes, setShowAddNotes] = useState(false)
  // const [isHistoryOpen, setIsHistoryOpen] = useState(false)
  // const [showHistory, setShowHistory] = useState(false)
  const [notes, setNotes] = useState<{
    content: string
    type: V1WebToHomeNoteType
  }>()
  const [editUrl, setEditUrl] = useState(false)
  const [createSubscription, setCreateSubscription] = useState(false)
  const [saving, setSaving] = useState(false)
  const [refresh, setRefresh] = useState<string>('')
  const {
    webToHomeApi,
    currentOrg,
    isAdmin,
    webToHomeEnabled,
    isReadOnly,
  } = useAppContext()
  const query = currentOrg?.id ? `?org_id=${currentOrg.id}` : ''

  useEffect(() => {
    if (pixelId && currentOrg) {
      webToHomeApi
        ?.advertisingPlatformServiceGetPixel(pixelId, currentOrg?.id)
        .then((res) => {
          setPixel(res)
          setUrl(res?.topLevelUrl || '')
          setTags(res?.tags || [])
          setIsSensitive(res?.sensitive || false)
          setIsLocked(res?.locked || false)
          if (res.status && res?.status !== 'submitted')
            setStatus({
              label: res?.status,
              value: res?.status,
            })
          setIsHidden(res?.hidden || false)
        })
    }
  }, [])

  const handleFetchSubscriptions = useCallback(
    async ({
      pageSize,
      nextPageToken,
    }: {
      pageSize: number
      nextPageToken?: string
    }) => {
      const empty = {
        data: [],
        totalItems: 0,
      }

      if (!currentOrg?.id || !webToHomeApi) return empty
      try {
        const res = await webToHomeApi?.advertisingPlatformServiceListOrgSubscriptions(
          pageSize,
          nextPageToken,
          currentOrg.id,
          'created desc', // order by
        )
        if (res) {
          return {
            data: res.subscriptions || [],
            totalItems: res.totalSize || 0,
            nextPageToken: res.nextPageToken,
          }
        }
      } catch {
        showErrorMessage('Error fetching your subscriptions', '')
      }

      return empty
    },
    [currentOrg?.id, webToHomeApi],
  )

  const pagination = useTokenPagination<Hagridv1Subscription>(
    {
      pageSize: 10,
      fetchData: handleFetchSubscriptions,
    },
    [currentOrg, refresh],
  )

  const handleCopyCode = () => {
    navigator.clipboard.writeText(pixel?.html || '').catch((err) => {
      console.error('Failed to copy code:', err)
    })
  }
  const handleUpdatePixelField = (
    field: 'sensitive' | 'locked' | 'hidden',
    value: boolean,
    setState: React.Dispatch<React.SetStateAction<boolean>>,
    errorMessage: string,
  ) => {
    if (!webToHomeApi || !pixelId) return
    setState(value)
    setSaving(true)
    showInfoMessage('Updating pixel...', '')
    webToHomeApi
      .advertisingPlatformServiceUpdatePixel(
        pixelId,
        { [field]: value },
        field,
        currentOrg?.id,
      )
      .then(() => showSuccessMessage('Pixel updated', ''))
      .catch(() => {
        showErrorMessage(errorMessage, '')
        setState(!value)
      })
      .finally(() => setSaving(false))
  }

  const handleSetSensitiveIndustry = (value: boolean) => {
    handleUpdatePixelField(
      'sensitive',
      value,
      setIsSensitive,
      'Error updating Sensitive Industry',
    )
  }

  const handleSetLocked = (value: boolean) => {
    handleUpdatePixelField(
      'locked',
      value,
      setIsLocked,
      'Error updating pixel locked field',
    )
  }

  const handleSetHidden = (value: boolean) => {
    handleUpdatePixelField(
      'hidden',
      value,
      setIsHidden,
      'Error updating pixel hidden field',
    )
  }

  const handleSaveTopLevelUrl = () => {
    if (!webToHomeApi || !pixelId) return
    setSaving(true)
    showInfoMessage('Updating pixel...', '')
    webToHomeApi
      .advertisingPlatformServiceUpdatePixel(
        pixelId,
        { topLevelUrl: url },
        'top_level_url',
        currentOrg?.id,
      )
      .then((res) => {
        setPixel(res)
        showSuccessMessage('Pixel updated', '')
      })
      .catch(() => {
        showErrorMessage('Error updating top level URL', '')
      })
      .finally(() => setSaving(false))
  }

  const handleUpdateTags = (tags: string[]) => {
    if (!webToHomeApi || !pixelId) return
    setTags(tags)
    setSaving(true)
    showInfoMessage('Updating pixel...', '')
    webToHomeApi
      .advertisingPlatformServiceUpdatePixel(
        pixelId,
        { tags },
        'tags',
        currentOrg?.id,
      )
      .then((res) => {
        setPixel(res)
        showSuccessMessage('Pixel updated', '')
      })
      .catch(() => {
        setTags(pixel?.tags)
        showErrorMessage('Error updating pixel tags', '')
      })
      .finally(() => setSaving(false))
  }

  const handleUpdateStatus = (status: string) => {
    if (!webToHomeApi || !pixelId) return
    setStatus({ label: status, value: status })
    setSaving(true)
    showInfoMessage('Updating pixel...', '')
    webToHomeApi
      .advertisingPlatformServiceUpdatePixel(
        pixelId,
        { status },
        'status',
        currentOrg?.id,
      )
      .then((res) => {
        setPixel(res)
        showSuccessMessage('Pixel updated', '')
      })
      .catch(() => {
        setStatus(undefined)
        showErrorMessage('Error updating pixel status', '')
      })
      .finally(() => setSaving(false))
  }

  const handleSaveNote = () => {
    if (!pixelId || !currentOrg?.id || !notes?.content || !webToHomeApi) return
    setSaving(true)
    webToHomeApi
      .advertisingPlatformServiceCreatePixelNote(
        pixelId,
        {
          content: notes?.content || '',
          type: notes?.type || 'NOTE_TYPE_USER',
        },
        currentOrg!.id!,
      )
      .then((res) => {
        setPixel((prev) => ({
          ...prev,
          notes: [...(prev?.notes || []), res],
        }))
        setShowAddNotes(false)
      })
      .catch(() => showErrorMessage('Error saving pixel note', ''))
      .finally(() => setSaving(false))
  }

  const handleDeleteNote = (noteId: string) => {
    if (!pixelId || !currentOrg?.id || !webToHomeApi) return
    setSaving(true)

    webToHomeApi
      ?.advertisingPlatformServiceDeletePixelNote(
        pixelId,
        noteId,
        currentOrg!.id!,
      )
      .then((res) => {
        setPixel((prev) => ({
          ...prev,
          notes: prev?.notes?.filter((note) => note.id !== noteId),
        }))
      })
      .catch(() => showErrorMessage('Error deleting pixel note', ''))
      .finally(() => setSaving(false))
  }

  if (!isAdmin && !webToHomeEnabled) return <WebToHomeDisabled />

  if (!pixel) return <Loading />

  return (
    <PageContent className="mx-auto max-w-7xl">
      {createSubscription && (
        <PixelSubscriptionCreate
          offClick={() => setCreateSubscription(false)}
          incomingPixel={pixel}
          setRefresh={setRefresh}
        />
      )}
      <div className="flex items-center gap-4 pl-4">
        <Link
          to={`/pixel-library/pixels${query}`}
          className="flex items-center"
        >
          <Fa icon="arrow-left" size={1} className="mr-2 h-5 w-5" />
          Back to Pixel Library
        </Link>
      </div>
      <div
        className="Box--flex-direction-column items-between mx-6 mt-6 flex justify-between gap-4 rounded-lg p-4 shadow-sm transition-colors"
        style={{
          background: 'var(--highlight-bg)',
          borderColor: 'var(--color-grey-200)',
          color: 'var(--base-text)',
        }}
      >
        {/* Header */}
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <h1
              className="text-xl font-semibold"
              style={{ color: 'var(--base-text)' }}
            >
              {pixel?.name}
            </h1>
          </div>
          <div className="Box--flex-direction-row flex items-center justify-end gap-2">
            <Select
              disabled={isReadOnly || saving}
              placeholder="Pending"
              maxHeight={300}
              selectedOption={status}
              onChange={(option) =>
                option?.value && handleUpdateStatus(option.value)
              }
              options={[
                { label: 'Approved', value: 'approved' },
                { label: 'Rejected', value: 'denied' },
              ]}
            />
            {!isReadOnly && (
              <Button
                onClick={() => setCreateSubscription(true)}
                className="rounded-md"
                kind="primary"
              >
                Create Subscription
              </Button>
            )}
          </div>
        </div>
        {/* Toggles */}
        <div className="flex justify-end gap-4">
          <div className="flex items-center gap-2">
            <Toggle
              isDisabled={isReadOnly || saving}
              size="s"
              isChecked={isSensitive}
              onChange={handleSetSensitiveIndustry}
            />
            <span className="text-sm text-gray-600 dark:text-gray-400">
              Sensitive Industry
            </span>
          </div>
          <div className="flex items-center gap-2">
            <Toggle
              isDisabled={isReadOnly || saving}
              size="s"
              isChecked={isLocked}
              onChange={handleSetLocked}
            />
            <span className="text-sm text-gray-600 dark:text-gray-400">
              Lock Pixel
            </span>
          </div>
          {isAdmin && (
            <div className="flex items-center gap-2">
              <Toggle
                size="s"
                isChecked={isHidden}
                onChange={handleSetHidden}
                isDisabled={saving}
              />
              <span className="text-sm text-gray-600 dark:text-gray-400">
                Hide Pixel
              </span>
            </div>
          )}
        </div>
        {/* Date and URL */}
        <div className="grid grid-cols-2 gap-6">
          <div className="p-4">
            <h2 className="text-sm font-medium text-gray-500 dark:text-gray-400">
              Date Created
            </h2>
            <p className="mt-1 text-gray-900 dark:text-white">
              {pixel?.created ? formatDate(pixel.created) : 'N/A'}
            </p>
          </div>
          <div className="flex items-center justify-end p-4">
            <div className="Box--flex-direction-column justify-end">
              <h2 className="text-sm font-medium text-gray-500 dark:text-gray-400">
                Pixel Placement Top-Level URL
              </h2>
              <p className="Box--flex-direction-row mt-1 flex items-center text-gray-900 dark:text-white">
                {editUrl ? (
                  <TextInputWithErrorText
                    id="topLevelUrl"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    isValid={isValidUrl && url.length <= 1000}
                    baseValues={['http://', 'https://']}
                    maxLength={1000}
                    popoverContent={(() => {
                      return (
                        <div className="text-warning">
                          Please enter a valid url under 1,000 characters:
                          &quot;https://www.websitename.com&quot;
                        </div>
                      )
                    })()}
                  />
                ) : (
                  <span>{pixel?.topLevelUrl || 'N/A'}</span>
                )}
                {!isReadOnly && (
                  <button
                    disabled={saving || (editUrl && !isValidUrl)}
                    onClick={() => {
                      setEditUrl(!editUrl)
                      if (editUrl) handleSaveTopLevelUrl()
                    }}
                    type="button"
                    className="text-tint-khaki-500 ml-2 transition-opacity hover:opacity-80"
                  >
                    {editUrl ? 'Save' : 'Edit'}
                  </button>
                )}
                {editUrl && (
                  <button
                    onClick={() => {
                      setEditUrl(!editUrl)
                      if (editUrl)
                        if (pixel?.topLevelUrl) setUrl(pixel.topLevelUrl)
                    }}
                    type="button"
                    className="text-tint-khaki-500 ml-2 transition-opacity hover:opacity-80"
                  >
                    Cancel
                  </button>
                )}
              </p>
            </div>
          </div>
        </div>
        {/* Tags */}
        <div className="p-4">
          <h2 className="text-sm font-medium text-gray-500 dark:text-gray-400">
            Tags
          </h2>
          <TagInput
            disabled={isReadOnly || saving}
            value={tags || []}
            addTagsInBulk
            onAddTag={(tagArray: string[]) => {
              handleUpdateTags(tagArray)
            }}
            onRemoveTag={(tagToRemove) => {
              handleUpdateTags(tags?.filter((tag) => tag !== tagToRemove) || [])
            }}
            limit={1000}
          />
        </div>
        {/* Notes */}
        <div className="p-4">
          <div className="mb-2 flex items-center justify-between">
            <h2 className="text-sm font-medium text-gray-500 dark:text-gray-400">
              Notes
            </h2>
            <Button
              onClick={() => setShowAddNotes(!showAddNotes)}
              className="text-center"
            >
              {showAddNotes ? 'Close' : 'Add Note'}
            </Button>
          </div>
          {showAddNotes && (
            <div className="animate-slidedown mb-2 flex flex-col py-1">
              <PixelNotes
                type="pixel"
                onChange={(content, type) => setNotes({ content, type })}
                showLabel={false}
                onSaveNote={handleSaveNote}
                saving={saving}
              />
            </div>
          )}
          <div className="PixelViewPage__notes-table mt-2 flex flex-col gap-2">
            {(pixel?.notes || []).length > 0 ? (
              pixel?.notes?.map(
                ({ content, type, userName, updateTime, id }) => {
                  if (!isAdmin && type === 'NOTE_TYPE_ADMIN') return null
                  return (
                    <div
                      className={classNames(
                        'bg-tint-gray-100 flex items-center justify-between rounded p-2',
                        {
                          'bg-base-info-50': type === 'NOTE_TYPE_ADMIN',
                        },
                      )}
                      key={id}
                    >
                      <div className="p-1 text-sm">
                        <p>{content}</p>
                        <p className="text-tint-gray-700 mt-1 text-xs">
                          {userName}{' '}
                          {isAdmin && type === 'NOTE_TYPE_ADMIN' && (
                            <span className="text-base-info font-bold">
                              (Admin)
                            </span>
                          )}{' '}
                          - {dayjs(updateTime).format('MM/DD/YYYY, H:mm:ss A')}
                        </p>
                      </div>
                      {!isReadOnly && (
                        <button
                          onClick={() => id && handleDeleteNote(id)}
                          disabled={saving}
                          className="text-danger self-end pb-2 text-sm"
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  )
                },
              )
            ) : (
              <span>No notes</span>
            )}
          </div>
        </div>
        {/* Pixel Code */}
        <div className="bg-tint-gray-50 rounded-lg p-4 shadow-sm transition-colors">
          <div className="mb-2 flex items-center justify-between">
            <h2 className="text-sm font-medium text-gray-500 dark:text-gray-400">
              JavaScript Pixel Code
            </h2>
            <button
              onClick={handleCopyCode}
              className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
            >
              <Fa icon="copy" size={1} />
            </button>
          </div>
          <pre
            className="overflow-x-auto rounded-md p-4 text-sm transition-colors"
            style={{
              background: 'var(--toggle-bg)',
              color: 'var(--base-text)',
            }}
          >
            {pixel?.html}
          </pre>
        </div>
        {/* Pixel History */}
        {/* <div
          className="rounded-lg border shadow-sm transition-colors"
          style={{
            background: 'var(--base-bg)',
            borderColor: 'var(--base-bg)',
          }}
        >
          <Button
            disabled
            className="flex w-full items-center justify-between rounded-lg p-4"
            iconRight={
              <Fa
                icon={isHistoryOpen ? 'chevron-down' : 'chevron-left'}
                size="sm"
              />
            }
            onClick={() => setIsHistoryOpen(!isHistoryOpen)}
          >
            <div className="flex items-center gap-2">
              <h2 className="text-sm font-medium text-gray-700 dark:text-gray-200">
                Pixel History
              </h2>
              <span className="text-sm text-gray-500 dark:text-gray-400">
                not available
              </span>
            </div>
          </Button>
          {isHistoryOpen && (
            <div className="mt-4 space-y-4">
              {pixel.history.map((event: any, index: any) => (
                <div key={index} className="flex items-start gap-3">
                  <div
                    className="mt-2 h-2 w-2 flex-shrink-0 rounded-full"
                    style={{ background: 'var(--button-bg)' }}
                  />
                  <div>
                    <div className="flex items-center gap-2">
                      <span className="font-medium text-gray-900 dark:text-white">
                        {event.user}
                      </span>
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        {event.timestamp}
                      </span>
                    </div>
                    <p className="text-gray-600 dark:text-gray-300">
                      {event.action}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div> */}
      </div>
      {/* Active Subscriptions */}
      <div
        className="mx-6 mt-4 mb-6 rounded-lg border p-6 shadow-sm transition-colors"
        style={{
          background: 'var(--base-bg)',
          borderColor: 'var(--base-bg)',
        }}
      >
        <h2 className="mb-4 text-xl font-semibold text-gray-900 dark:text-white">
          Active Subscriptions
        </h2>
        <Table
          className={classNames('PixelViewPage__SubscriptionTable', {
            'opacity-30': pagination.loading,
          })}
          columns={[
            {
              path: 'name',
              label: 'Subscription Name',
              RowCell: ({ id, name, pixelId }) => (
                <Link
                  to={`/pixel-library/pixel/${pixelId}/subscription/${id}${query}`}
                >
                  {name}
                </Link>
              ),
            },
            // N/A - all are web-to-home for now
            { path: 'type', label: 'Type', RowCell: () => 'Web-to-Home' },
            {
              path: 'created',
              label: 'Date Created',
              RowCell: (row) => {
                if (!row.created) return <EmptyField />
                return formatDate(row.created)
              },
            },
            {
              path: 'id',
              label: 'Subscription ID',
            },
          ]}
          rows={pagination.currentPageData}
        />
        {pagination.loading && !pagination.currentPageData.length && <Loader />}
        {!pagination.currentPageData.length && !pagination.loading ? (
          <div className="PixelViewPage__empty-state flex flex-col items-center py-6">
            <Fa
              icon="folder"
              size={5}
              type="duotone"
              className="text-primary-200"
            />
            <p className="text-grey-600">No Active Subscriptions.</p>
          </div>
        ) : null}
        {pagination.totalItems > 10 ? (
          <div className="PixelViewPage__pagination-footer">
            <TablePagination pagination={pagination} />
          </div>
        ) : null}
      </div>
    </PageContent>
  )
}
