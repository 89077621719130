/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { TypeDate } from '../models/TypeDate';
import { V1Entry } from '../models/V1Entry';
import { HttpFile } from '../http/http';

export class V1AdminInvoice {
    'id'?: string;
    'commissionGroupId'?: string;
    'termName'?: string;
    'state'?: string;
    'whenCreated'?: TypeDate;
    'entries'?: Array<V1Entry>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "commissionGroupId",
            "baseName": "commission_group_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "termName",
            "baseName": "term_name",
            "type": "string",
            "format": ""
        },
        {
            "name": "state",
            "baseName": "state",
            "type": "string",
            "format": ""
        },
        {
            "name": "whenCreated",
            "baseName": "when_created",
            "type": "TypeDate",
            "format": ""
        },
        {
            "name": "entries",
            "baseName": "entries",
            "type": "Array<V1Entry>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1AdminInvoice.attributeTypeMap;
    }

    public constructor() {
    }
}

