import { useEffect, useState, useRef } from 'react'
import {
  Button,
  Fa,
  Text,
  showErrorMessage,
  showSuccessMessage,
} from '@eltoro-ui/components'
import { formatByteString } from '@eltoro-ui/helpers'
import { useValidatedState } from '@eltoro-ui/hooks'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import { Campaignservicev1OrderLine, V1CreativeFile } from 'next-gen-sdk'
import { ETIcon } from 'Assets'
import {
  BrandURLPop,
  CreativePreview,
  CreativeThumbnail,
  InfoPopover,
  EditOLCreatives,
  TextInputWithErrorText,
} from 'Components'
import { ClickThroughWithUTM } from 'Components/AdSetForms/components/ClickThroughWithUTM'
import {
  DurationOrDimensionDetail,
  readableCreativeFileMime,
  readableCreativeType,
  styleTailwind,
} from 'Helpers'
import { useAppContext } from 'Contexts'
import { useGetFullOLCreatives } from 'Hooks'
import './OrderlineCreativeDisplay.scss'

const OLCreativeField = styleTailwind(
  'div',
  'OrderlineCreativeDisplay__fields flex w-full items-start justify-start gap-2 pr-1',
)

export const OrderlineCreativeDisplay = ({
  orderline,
  setCurrentOrderLine,
}: {
  orderline: Campaignservicev1OrderLine
  setCurrentOrderLine: React.Dispatch<
    React.SetStateAction<Campaignservicev1OrderLine>
  >
}) => {
  const [
    clickThruInput,
    setClickThruInput,
    isValidClickThrough,
  ] = useValidatedState(orderline.clickThroughUrl || '', 'url')
  const [currentOLClickthru, setCurrentOLClickthru] = useState(
    orderline.clickThroughUrl || clickThruInput,
  )
  // Brand URL:
  const [brandUrl, setBrandUrl, isValidBrandURL] = useValidatedState(
    'https://',
    'url',
  )
  const [editBrand, setEditBrand] = useState(false)
  const [editClickThruShow, setEditClickThruShow] = useState(false)
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [utmOpen, setUTMOpen] = useState<boolean>(false)
  const [showEditModal, setShowEditModal] = useState<'library' | 'create'>()
  const [creativeIndex, setCreativeIndex] = useState(0)
  const [animateCreative, setAnimateCreative] = useState<'right' | 'left'>()
  const { campaignServiceApi, roles, creativeServiceApi } = useAppContext()
  const { pathname } = useLocation()
  const isOnSearchPage = pathname.includes('search')
  const isMounted = useRef(false)

  const [creatives, loading] = useGetFullOLCreatives(
    orderline.creatives,
    orderline.orgId,
  )

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (animateCreative) {
        setAnimateCreative(undefined)
      }
    }, 200)

    return () => {
      clearTimeout(timeout)
    }
  }, [animateCreative])
  useEffect(() => {
    if (isMounted.current && setBrandUrl && !creatives.length) {
      const allBrandURLs = creatives
        .filter(
          (creative) =>
            creative.nativeMetadata?.alternativeMobileLandingPageUrl !== '',
        )
        .map(
          (creative) =>
            creative.nativeMetadata?.alternativeMobileLandingPageUrl,
        )
        .filter((c) => c !== undefined)
      const brandURLSWithOutDupes = [...new Set(allBrandURLs)]
      if (
        brandURLSWithOutDupes &&
        brandURLSWithOutDupes.length === 1 &&
        brandURLSWithOutDupes[0] !== undefined
      ) {
        setBrandUrl(brandURLSWithOutDupes[0])
      }
    } else {
      isMounted.current = true
    }
  }, [creatives, setBrandUrl])

  const currentCreative = creatives[creativeIndex]

  const currentCreativeFile = currentCreative?.files?.[0]

  const handleSaveClickthrough = (newUrl: string) => {
    if (!orderline.id || !newUrl || !campaignServiceApi || !orderline.orgId)
      return
    const ol = orderline
    ol.clickThroughUrl = newUrl
    campaignServiceApi
      .advertisingPlatformServiceUpdateOrderLine(
        orderline.id,
        orderline.orgId,
        {
          clickThroughUrl: newUrl,
        },
      )
      .then((res) => {
        setEditClickThruShow(!editClickThruShow)
        if (res.clickThroughUrl) {
          setCurrentOLClickthru(res.clickThroughUrl)
          setClickThruInput(res.clickThroughUrl ?? orderline.clickThroughUrl)
        }
      })
      .then((res) => {
        showSuccessMessage('Click-through Url updated successfully', '')
      })
      .catch((err) => {
        if (err) {
          showErrorMessage('Click-through Url did not update correctly', '')
        }
      })
  }
  const handleSaveBrand = (newBrand: string) => {
    if (!creatives || !newBrand || !creativeServiceApi) return
    creatives.map((currentCreative) => {
      if (!currentCreative.id || !orderline.orgId) return undefined

      creativeServiceApi?.advertisingPlatformServiceUpdateCreative(
        currentCreative.id,
        orderline.orgId,
        {
          nativeMetadata: {
            alternativeMobileLandingPageUrl: newBrand,
          },
        },
        currentCreative.nativeMetadata !== null
          ? 'native_metadata.alternative_mobile_landing_page_url'
          : '',
      )
      return currentCreative
    })
    setEditBrand(false)
  }
  const iconDecider = (type: 'click' | 'brand') => {
    if (orderline.locked) {
      return <Fa icon="lock" size={1} />
    }
    if (type === 'click') {
      if (editClickThruShow) {
        return <Fa icon="save" size={1} />
      }
      if (!editClickThruShow) {
        return <Fa icon="pencil" size={1} />
      }
    }
    if (type === 'brand') {
      if (editBrand) {
        return <Fa icon="save" size={1} />
      }
      if (!editBrand) {
        return <Fa icon="pencil" size={1} />
      }
    }
  }

  return (
    <>
      <div className="OrderlineCreativeDisplay">
        <div className="OrderlineCreativeDisplay__inner-container ml-6">
          {(() => {
            if (loading)
              return (
                <div className="OrderlineCreativeDisplay__loading-empty-state">
                  <Fa icon="circle-notch" size={3} />
                </div>
              )

            if (!creatives.length)
              return (
                <div className="OrderlineCreativeDisplay__loading-empty-state">
                  This order line does not have creatives.
                </div>
              )
            return (
              <>
                <Text
                  className="OrderlineCreativeDisplay__header border-b-thin border-tint-gray-200 mb-3 flex justify-between pb-2"
                  tag="h3"
                >
                  <span className="CreativeLabel text-xl">Creative</span>
                  {creatives[0].type && (
                    <span className="text-m flex items-center gap-1">
                      <span>Type:</span>
                      <span className="font-normal">
                        {readableCreativeType(creatives[0].type)}
                      </span>
                    </span>
                  )}
                </Text>
                {currentCreative && (
                  <div className="OrderlineCreativeDisplay__full-creative-details flex flex-col">
                    {currentCreative.type !== 'CREATIVE_TYPE_AD_TAG' &&
                      currentCreative.type !== 'CREATIVE_TYPE_VAST_TAG' && (
                        <OLCreativeField>
                          <Text
                            className="text-m ClickThruLabel whitespace-nowrap"
                            tag="h4"
                          >
                            Click-through URL:
                          </Text>
                          {editClickThruShow ? (
                            <ClickThroughWithUTM
                              className="CreativeUTMEdit"
                              OLName={''}
                              clickThru={clickThruInput}
                              setClickThru={setClickThruInput}
                              isValidClickThru={isValidClickThrough}
                              preview
                            />
                          ) : (
                            <a
                              href={currentOLClickthru}
                              className="OrderLine__click-through-url truncate pb-1"
                              title={currentOLClickthru}
                            >
                              {orderline.clickThroughUrl || currentOLClickthru}
                            </a>
                          )}
                          {roles && !roles.includes('nextgen_read_only') && (
                            <>
                              <div
                                className="ButtonWrapTooltip ClickThruButton ml-auto"
                                data-tooltip="top"
                                aria-label={`${
                                  editClickThruShow
                                    ? 'Save URL'
                                    : 'Click to edit URL'
                                }`}
                              >
                                <Button
                                  size="m"
                                  rounded
                                  iconOnly={iconDecider('click')}
                                  onClick={
                                    editClickThruShow
                                      ? () => {
                                          handleSaveClickthrough(clickThruInput)
                                          if (utmOpen) {
                                            return setUTMOpen(false)
                                          }
                                        }
                                      : () => {
                                          setEditClickThruShow(true)
                                          setClickThruInput(
                                            orderline.clickThroughUrl || '',
                                          )
                                        }
                                  }
                                  disabled={
                                    !isValidClickThrough ||
                                    orderline.locked ||
                                    (roles &&
                                      roles.includes('nextgen_read_only'))
                                  }
                                />
                              </div>
                              {editClickThruShow && (
                                <div
                                  className="ButtonWrapTooltip ClickThruButton"
                                  data-tooltip="top"
                                  aria-label={`${
                                    editClickThruShow ? 'Cancel' : ''
                                  }`}
                                >
                                  <Button
                                    size="s"
                                    rounded
                                    iconOnly={<Fa icon="times" size={1} />}
                                    onClick={() => {
                                      setEditClickThruShow(false)
                                      setClickThruInput(
                                        orderline.clickThroughUrl || '',
                                      )
                                    }}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </OLCreativeField>
                      )}
                    {brandUrl && brandUrl !== 'https://' && (
                      <OLCreativeField>
                        <BrandURLPop />

                        {editBrand ? (
                          <TextInputWithErrorText
                            value={brandUrl}
                            onChange={(e) => setBrandUrl(e.target.value)}
                            isValid={isValidBrandURL}
                            popoverContent={(() => {
                              if (!brandUrl.includes('http'))
                                return (
                                  <div>URL requires &quot;http(s)://&quot;</div>
                                )
                              return (
                                <div>
                                  Please enter a valid url in the format:
                                  &quot;https://www.websitename.com&quot;
                                </div>
                              )
                            })()}
                          />
                        ) : (
                          <a
                            href={brandUrl}
                            className="OrderLine__brand-url truncate pb-1"
                            title={brandUrl}
                          >
                            {brandUrl}
                          </a>
                        )}

                        {roles && !roles.includes('nextgen_read_only') && (
                          <div
                            className="ButtonWrapTooltip BrandButton ml-auto"
                            data-tooltip="top"
                            aria-label={`${
                              editBrand
                                ? 'Save Brand URL'
                                : 'Click to Brand URL'
                            }`}
                          >
                            <Button
                              size="m"
                              rounded
                              iconOnly={iconDecider('brand')}
                              onClick={
                                editBrand
                                  ? () => handleSaveBrand(brandUrl)
                                  : () => {
                                      setEditBrand(true)
                                      setBrandUrl(brandUrl || '')
                                    }
                              }
                              disabled={
                                !isValidBrandURL ||
                                orderline.locked ||
                                (roles && roles.includes('nextgen_read_only'))
                              }
                            />
                          </div>
                        )}
                        {editBrand && brandUrl !== 'https://' && (
                          <div
                            className="ButtonWrapTooltip BrandButton"
                            data-tooltip="top"
                            aria-label="Remove Brand URL"
                          >
                            <Button
                              size="s"
                              rounded
                              iconOnly={<Fa icon="trash-xmark" size={1} />}
                              onClick={() => {
                                creatives?.map((creative) => {
                                  if (
                                    isValidBrandURL &&
                                    creative.orgId &&
                                    creative.id
                                  ) {
                                    creativeServiceApi?.advertisingPlatformServiceUpdateCreative(
                                      creative.id,
                                      creative.orgId,
                                      {
                                        nativeMetadata: {
                                          alternativeMobileLandingPageUrl: undefined,
                                        },
                                      },
                                      creative.nativeMetadata !== null
                                        ? 'native_metadata.alternative_mobile_landing_page_url'
                                        : '',
                                    )
                                  }
                                  return undefined
                                })

                                setEditBrand(false)
                                setBrandUrl('')
                              }}
                            ></Button>
                          </div>
                        )}
                        {editBrand && (
                          <div
                            className="ButtonWrapTooltip BrandButton"
                            data-tooltip="top"
                            aria-label={`${editBrand ? 'Cancel' : ''}`}
                          >
                            <Button
                              size="s"
                              rounded
                              iconOnly={<Fa icon="times" size={1} />}
                              onClick={() => {
                                setEditBrand(false)
                                setBrandUrl(brandUrl || '')
                              }}
                            />
                          </div>
                        )}
                      </OLCreativeField>
                    )}
                  </div>
                )}
                <div className="OrderlineCreativeDisplay__thumbnail-container">
                  {creativeIndex > 0 && (
                    <button
                      type="button"
                      className={`OrderlineCreativeDisplay__arrow-btn ${
                        creatives.length <= 1
                          ? 'OrderlineCreativeDisplay__arrow-btn--hide'
                          : ''
                      }`}
                      onClick={() => {
                        if (
                          creativeIndex - 1 >= 0 &&
                          creativeIndex - 1 <= creatives.length - 1
                        ) {
                          setCreativeIndex((prev) => prev - 1)
                          setAnimateCreative('left')
                        }
                      }}
                      disabled={creativeIndex === 0}
                    >
                      <Fa icon="caret-left" size={3} />
                    </button>
                  )}

                  <div
                    className={classNames(
                      'OrderlineCreativeDisplay__thumbnail-wrapper',
                      {
                        'OrderlineCreativeDisplay__thumbnail-wrapper--animate-left':
                          animateCreative === 'left',
                        'OrderlineCreativeDisplay__thumbnail-wrapper--animate-right':
                          animateCreative === 'right',
                      },
                    )}
                  >
                    {(() => {
                      if (
                        currentCreative?.type === 'CREATIVE_TYPE_AD_TAG' ||
                        currentCreative?.type === 'CREATIVE_TYPE_VAST_TAG'
                      ) {
                        return <ETIcon className="scale-125" icon="ad-tag" />
                      }
                      if (currentCreative?.type === 'CREATIVE_TYPE_HTML5')
                        return <ETIcon className="scale-125" icon="html5" />
                      if (currentCreativeFile)
                        return (
                          <CreativeThumbnail
                            file={currentCreativeFile as V1CreativeFile}
                            onClick={() => setShowPreviewModal(true)}
                          />
                        )
                      return <Fa icon="image" size={5} type="duotone" />
                    })()}
                  </div>

                  {creatives.length > 1 &&
                    creativeIndex !== creatives.length - 1 && (
                      <button
                        type="button"
                        className={`OrderlineCreativeDisplay__arrow-btn ${
                          creatives.length <= 1
                            ? 'OrderlineCreativeDisplay__arrow-btn--hide'
                            : ''
                        }`}
                        onClick={() => {
                          if (creativeIndex + 1 <= creatives.length - 1) {
                            setCreativeIndex((prev) => prev + 1)
                            setAnimateCreative('right')
                          }
                        }}
                        disabled={
                          creatives.length < 1 ||
                          creativeIndex === creatives.length - 1
                        }
                      >
                        <Fa icon="caret-right" size={3} />
                      </button>
                    )}
                </div>
                {currentCreative && (
                  <div className="OrderlineCreativeDisplay__caption">
                    <div className="OrderlineCreativeDisplay__caption-description">
                      <Text
                        className="CreativeChange__label text-m text-tint-gray-500 pb-2"
                        tag="h6"
                      >
                        Creative details
                      </Text>
                      <p className="leading-none">
                        <strong className="leading-none">File name:</strong>{' '}
                        {currentCreative.name}
                      </p>
                      {currentCreativeFile && (
                        <>
                          <p>
                            <strong>File type:</strong>&nbsp;
                            {currentCreative.files?.[0]?.mime &&
                              readableCreativeFileMime(
                                currentCreative.files?.[0]?.mime,
                              )}
                          </p>
                          <div className="flex items-center">
                            <strong>File size:</strong>&nbsp;
                            <div className="FileSize_wrap flex gap-2">
                              {currentCreativeFile &&
                                formatByteString(
                                  Number(currentCreativeFile.size),
                                )}
                              {currentCreative.type ===
                                'CREATIVE_TYPE_BANNER' &&
                                currentCreative.files?.[0].size &&
                                +currentCreative.files[0].size > 100000 && (
                                  <InfoPopover
                                    className="warning"
                                    icon="warning"
                                    content={
                                      <div className="AdSetPreview__file-size-popover max-w-sm">
                                        <Text>
                                          Your creative file size is above our
                                          recommended specifications. This{' '}
                                          <strong>may</strong> limit our serving
                                          capacity.
                                        </Text>
                                        <br />
                                        <Text>
                                          <strong>
                                            Recommended banner file size:{' '}
                                          </strong>{' '}
                                          less than 100kb
                                        </Text>
                                      </div>
                                    }
                                  />
                                )}
                            </div>
                          </div>
                          <div className="flex items-center">
                            {DurationOrDimensionDetail(currentCreative, true)}
                          </div>
                        </>
                      )}
                    </div>
                    {roles &&
                      !roles.includes('nextgen_read_only') &&
                      !isOnSearchPage && (
                        <div className="OrderlineCreativeDisplay__creative-change">
                          <Text
                            className="CreativeChange__label text-m text-tint-gray-400 pb-1"
                            tag="h6"
                          >
                            Change Creative
                          </Text>
                          <div className="OrderlineCreativeDisplay__creative-change-links">
                            <Button
                              className="GetFromLibrary"
                              size="s"
                              kind="text-icon"
                              iconLeft={<Fa icon="folders" size={1} />}
                              disabled={
                                orderline.locked ||
                                (roles && roles.includes('nextgen_read_only'))
                              }
                              onClick={() => setShowEditModal('library')}
                            >
                              Select from Creative Library
                            </Button>

                            <Button
                              className="UploadNew"
                              size="s"
                              kind="text-icon"
                              iconLeft={<Fa icon="cloud-arrow-up" size={1} />}
                              disabled={
                                orderline.locked ||
                                (roles && roles.includes('nextgen_read_only'))
                              }
                              onClick={() => setShowEditModal('create')}
                            >
                              Upload new
                            </Button>
                          </div>
                        </div>
                      )}
                  </div>
                )}
              </>
            )
          })()}
        </div>
      </div>
      {showPreviewModal && currentCreative && (
        <CreativePreview
          file={currentCreativeFile as V1CreativeFile}
          offClick={() => setShowPreviewModal(false)}
        />
      )}

      {showEditModal && (
        <EditOLCreatives
          orderLine={orderline}
          onClose={() => setShowEditModal(undefined)}
          startingState={showEditModal}
          setCurrentOrderLine={setCurrentOrderLine}
        />
      )}
    </>
  )
}
