import { TablePagination, WarningModal } from 'Components'
import { SectionCollapse } from '../SectionCollapse'
import { useState } from 'react'
import { Orgmanagerv1User } from 'next-gen-sdk'
import { useDebounce, useTokenPagination } from '@eltoro-ui/hooks'
import { useAppContext } from 'Contexts'
import { useAuth } from 'react-oidc-context'
import {
  Avatar,
  Button,
  Fa,
  Loader,
  Select,
  Table,
  TableColumnType,
  Text,
  showErrorMessage,
} from '@eltoro-ui/components'
import { AddUserModal } from '../AddUserModal'
import { orgRolesOptions } from 'searchOptions'

export const OrgUsers = ({
  toggleOpen,
  isOpen,
}: {
  toggleOpen: () => void
  isOpen: boolean
}) => {
  const [openUserModal, setOpenUserModal] = useState(false)
  const [userToRemove, setUserToRemove] = useState<Orgmanagerv1User>()
  //   TODO
  //   const [sort, setSort] = useState<string>()
  //   const [filter, setFilter] = useState<{
  //     [field: string]: Array<string | number>
  //   }>()
  //   const debouncedFilter = useDebounce(filter, 200)
  const {
    isUser,
    isAdmin,
    isOnHold,
    isReadOnly,
    currentOrg,
    setCurrentOrg,
    orgServiceApi,
    userServiceApi,
  } = useAppContext()
  const auth = useAuth()

  const handleRemoveUser = (userId: string) => {
    if (!currentOrg?.id || !orgServiceApi) return
    orgServiceApi
      .advertisingPlatformServiceRemoveUser(currentOrg.id, {
        userId,
      })
      .then((updatedOrg) => {
        setUserToRemove(undefined)
        setCurrentOrg(updatedOrg)
      })
  }

  const handleChangeRole = (
    user: Orgmanagerv1User,
    role: 'nextgen_user' | 'nextgen_read_only',
  ) => {
    if (!currentOrg?.id || !userServiceApi || !user.id) return
    userServiceApi
      .advertisingPlatformServiceUpdateUser(
        user.id,
        {
          realmRoles: [role, 'default-roles-eltoro'],
        },
        'realm_roles',
      )
      .catch((err) =>
        showErrorMessage('Error changing user role', err?.body?.message || ''),
      )
      .then(() => refetch())
  }

  const selectRoles = [
    {
      label: 'Org Admin',
      value: 'nextgen_user',
    },
    {
      label: 'Read Only',
      value: 'nextgen_read_only',
    },
  ]

  const handleFetchOrgUsers = async ({
    pageSize,
    nextPageToken,
  }: {
    pageSize: number
    nextPageToken?: string
  }) => {
    const empty = {
      data: [],
      totalItems: 0,
    }
    if (!orgServiceApi || !currentOrg?.id) return empty

    const res = await orgServiceApi.advertisingPlatformServiceListOrgUsers(
      currentOrg.id,
      pageSize, // pageSize
      nextPageToken, // pageToken
      'create_time', // orderBy
      undefined, // filter
    )
    if (res && res?.users) {
      return {
        data: res.users || [],
        totalItems: res.totalSize || 0,
        nextPageToken: res.nextPageToken,
      }
    }
    return empty
  }

  const orgUsersPagination = useTokenPagination<Orgmanagerv1User>(
    {
      pageSize: 10,
      fetchData: handleFetchOrgUsers,
    },
    // [debouncedFilter],
    [currentOrg],
  )

  const {
    currentPageData: orgUsers,
    loading: loadingOrgUsers,
    // setPage,
    refetch,
    totalItems,
  } = orgUsersPagination
  return (
    <>
      <SectionCollapse
        title={
          <>
            Org users&nbsp;
            <span className="font-light">({totalItems})</span>
          </>
        }
        editButtons={
          (isUser || isAdmin) && isOpen && !isReadOnly ? (
            <>
              <span
                aria-label={
                  isOnHold && !isAdmin
                    ? 'Org is on hold: cannot add users'
                    : undefined
                }
                data-tooltip="left"
              >
                <Button
                  iconLeft={<Fa icon="user-plus" size={1} />}
                  onClick={() => setOpenUserModal(true)}
                  disabled={isOnHold && !isAdmin}
                >
                  Add Org User
                </Button>
              </span>
              {openUserModal && (
                <AddUserModal
                  refetch={refetch}
                  setOpenUserModal={setOpenUserModal}
                />
              )}
            </>
          ) : null
        }
        isOpen={isOpen}
        toggleOpen={() => toggleOpen()}
        className="OrgSettingsPage__org-users"
        sectionName="Org Users"
      >
        {!loadingOrgUsers && orgUsers?.length > 0 && (
          <Table
            columns={[
              {
                path: 'name',
                label: 'Name',
                removeFilter: true,
                RowCell: ({ firstName, lastName }) => {
                  return (
                    <div className="ml-2 flex items-center gap-2">
                      <Avatar
                        name={`${firstName} ${lastName}`}
                        size="s"
                        className="p-2"
                        rounded
                      />
                      {`${firstName} ${lastName}`}
                    </div>
                  )
                },
              },
              {
                path: 'email',
                label: 'Email Address',
                removeFilter: true,
              },
              {
                path: 'realmRoles',
                label: 'Org Role',
                filterOn: orgRolesOptions,
                RowCell: (user) => {
                  const { realmRoles } = user
                  // If current user is allowed to edit
                  if (
                    (isUser || isAdmin) &&
                    auth.user?.profile.sub !== user.id &&
                    !isOnHold &&
                    !isReadOnly &&
                    // if there are no roles on the user, do not allow (this should not happen)
                    (user.realmRoles || []).filter((n) => n).length > 0 &&
                    // do not allow changing an admin's roles (this should also not happen)
                    !(user.realmRoles || []).includes('nextgen_admin')
                  ) {
                    return (
                      <Select
                        placeholder="Select role"
                        selectedOption={selectRoles.find(({ value }) =>
                          realmRoles?.includes(value),
                        )}
                        options={selectRoles}
                        onChange={(change) => {
                          if (change?.value)
                            handleChangeRole(
                              user,
                              change.value as
                                | 'nextgen_user'
                                | 'nextgen_read_only',
                            )
                        }}
                        maxHeight={200}
                        clearButton={false}
                      />
                    )
                  }
                  if (!realmRoles) return null
                  if (
                    realmRoles.includes('nextgen_user') &&
                    !realmRoles.includes('nextgen_read_only')
                  ) {
                    return <>Org Admin</>
                  }
                  if (realmRoles.includes('nextgen_read_only')) {
                    return <>Read Only</>
                  }
                  if (realmRoles.includes('nextgen_admin')) {
                    return <>Admin</>
                  }
                  return <>{realmRoles.join(', ')}</>
                },
              },
              {
                path: 'id',
                label: 'ID',
                removeFilter: true,
              },
              ...((isUser && !isOnHold) || isAdmin
                ? ([
                    {
                      path: '',
                      label: 'Remove User',
                      RowCell: (user) => {
                        return (
                          user.id && (
                            <Button
                              onClick={() => setUserToRemove(user)}
                              disabled={
                                auth.user?.profile.sub === user.id || isReadOnly
                              }
                            >
                              Remove
                            </Button>
                          )
                        )
                      },
                    },
                  ] as TableColumnType<Orgmanagerv1User>[])
                : []),
            ]}
            rows={orgUsers || []}
            // onSort={(columnIndex, sort, path) => {}}
            // onFilter={(columnIndex, path, filterOn) => {}}
          />
        )}
        {loadingOrgUsers && <Loader />}
        {!orgUsers?.length && !loadingOrgUsers ? (
          <div className="bg-grey-50 mb-2 mt-2 flex items-center justify-center gap-4 rounded p-4">
            <Fa icon="users" type="duotone" size={4} />
            <p className="font-bold">No users found.</p>
          </div>
        ) : null}
        {orgUsers?.length > 0 && !loadingOrgUsers && (
          <TablePagination pagination={orgUsersPagination} />
        )}
      </SectionCollapse>
      {userToRemove && (
        <WarningModal
          onConfirm={() => {
            if (!userToRemove.id) return
            handleRemoveUser(userToRemove.id)
          }}
          onCancel={() => setUserToRemove(undefined)}
        >
          <Text on="white" kind="subdued" size="l">
            Are you sure you want to remove
            <strong>
              {userToRemove.firstName
                ? ` ${userToRemove.firstName} ${userToRemove.lastName} `
                : ' this user '}
            </strong>
            from {currentOrg?.name || 'this org'}?
          </Text>
        </WarningModal>
      )}
    </>
  )
}
