import { useState, useEffect } from 'react'
import {
  Select,
  Table,
  PreviewType,
  SelectOptionType,
  Checkbox,
  Button,
  Loader,
} from '@eltoro-ui/components'
import { formatByteString } from '@eltoro-ui/helpers'
import papa from 'papaparse'
import { Targetjobservicev1Audience, V2Target } from 'next-gen-sdk'
import classNames from 'classnames'
import { CurrentStepType, UploadStatusBar } from 'Components'
import { useAppContext } from 'Contexts'
import { ReplaceOptions, uploadTargetFile } from 'Requests'
import {
  guessHeader,
  hasRequiredColumns,
  matchesChosenType,
  columnTypes,
  handleUploadTarget,
  getConvertedColumnTarget,
} from './helpers'
import './AudienceColumnSelector.scss'
import {
  ADDRESS_LIMIT,
  DEVICE_LIMIT,
  DIGITAL_CANVASSING_LIMIT,
  IP_LIMIT,
  MAX_UPLOAD_TARGET_FILE_SIZE,
  REVERSE_LIMIT,
  ZIP_LIMIT,
  getTargetFileType,
} from 'Helpers'

type UploaderColumnsType = {
  src_key: string
  src_index: number
  src_field: string
  payload_key: string
}

type TargetPayloadType = {
  rows: string[][]
  columns: UploaderColumnsType[]
  fileData: PreviewType
}

export type TargetPreviewType = TargetPayloadType & {
  hasHeader?: boolean
  updated?: boolean
}

export const getColumnSelectorTypes = (
  type: Targetjobservicev1Audience['type'],
) => {
  const audienceType = () => {
    switch (type) {
      case 'AUDIENCE_TYPE_ADDRESS':
      case 'AUDIENCE_TYPE_B2B':
      case 'AUDIENCE_TYPE_B2C':
      case 'AUDIENCE_TYPE_DC':
        return 'address'
      case 'AUDIENCE_TYPE_DEVICE':
        return 'device'
      case 'AUDIENCE_TYPE_IP':
        return 'ip'
      case 'AUDIENCE_TYPE_REVERSE':
        return 'reverseIp'
      case 'AUDIENCE_TYPE_ADDRESSES_FOR_IP':
        return 'ipForMailing'
      case 'AUDIENCE_TYPE_ZIP':
        return 'zip'
      default:
        return 'address'
    }
  }
  const jobType = () => {
    switch (type) {
      case 'AUDIENCE_TYPE_B2B':
        return 'b2b'
      case 'AUDIENCE_TYPE_B2C':
        return 'b2c'
      default:
        return audienceType()
    }
  }
  return {
    audienceType: audienceType(),
    jobType: jobType(),
  }
}

export const AudienceColumnSelector = ({
  audienceType,
  jobType,
  droppedFile,
  onError,
  onCancel,
  onBack,
  onUploadComplete,
  replaceFileOptions,
  customAudienceTag,
}: {
  audienceType: string
  jobType: string
  droppedFile: PreviewType
  onError?: (value: boolean) => void
  onCancel?: () => void
  onBack?: () => void
  onUploadComplete?: (audience: Targetjobservicev1Audience) => void
  replaceFileOptions?: ReplaceOptions & {
    existingTarget?: V2Target // if existing target, will import those columns. otherwise, replaces the file based on audId and targId
    audienceName?: string
  }
  customAudienceTag?: string
}) => {
  // prettier-ignore
  const [
    targetFilePreview,
    setTargetFilePreview,
  ] = useState<TargetPreviewType>()
  const [loading, setLoading] = useState(true)
  const [columnError, setColumnError] = useState<string>()
  const [uploading, setUploading] = useState(false)
  const [uploadError, setUploadError] = useState<string>()
  const [uploadComplete, setUploadComplete] = useState(false)
  const [uploadStatus, setUploadStatus] = useState({
    percent: 0,
    status: 'upload',
  })
  const [keepName, setKeepName] = useState(true)
  const [locked, setLocked] = useState(
    audienceType === 'ipForMailing' ||
      audienceType === 'reverseIp' ||
      audienceType === 'device'
      ? true
      : false,
  )
  const [hidden, setHidden] = useState(
    audienceType === 'ipForMailing' || audienceType === 'reverseIp'
      ? true
      : false,
  )

  const { currentOrg, tok, audienceServiceApi, isAdmin } = useAppContext()

  // Handle target file upload
  const handleUpload = async () => {
    const orgId = currentOrg?.id
    if (!orgId || !targetFilePreview) return

    setUploading(true)
    if (replaceFileOptions) {
      const file = getConvertedColumnTarget(targetFilePreview)
      // if name change, change audience's name first (otherwise it will get flipped back to original, maybe because of quoting issues?)
      if (
        !keepName &&
        file.fileData.name &&
        audienceServiceApi &&
        replaceFileOptions.audienceIds
      ) {
        await Promise.all(
          replaceFileOptions.audienceIds.map((audId) => {
            if (!currentOrg?.id || !audienceServiceApi)
              return Promise.resolve({})
            return audienceServiceApi.advertisingPlatformServiceUpdateAudience(
              audId,
              currentOrg.id,
              {
                name: file.fileData.name,
              },
            )
          }),
        )
      }
      await uploadTargetFile(
        file.fileData.name,
        file.fileData,
        tok,
        orgId,
        getTargetFileType(audienceType),
        file.hasHeader,
        file.columns,
        (percent) => {
          setUploadStatus({ percent, status: 'upload' })
        },
        audienceType !== 'custom' ? 'DATA_SOURCE_CLIENT' : jobType,
        replaceFileOptions,
      )
        .then(() => onUploadComplete && onUploadComplete({}))
        .catch((e) => {
          setUploadError(
            `Error replacing target file${
              e?.response?.data?.message ? ` - ${e.response.data.message}` : ''
            }`,
          )
        })
      return
    }

    await handleUploadTarget(
      getConvertedColumnTarget(targetFilePreview),
      audienceType,
      jobType,
      orgId,
      // onUpload
      (_, job) => {
        if (onUploadComplete) onUploadComplete(job)
        setUploading(false)
        setUploadComplete(true)
      },
      // onError
      (e) => {
        const errorText =
          e?.error?.body?.message || e?.error?.response?.statusText
        setUploadError(
          `Error uploading target file${errorText ? ` - ${errorText}` : ''}`,
        )
      },
      // onProgress
      (percent, status) => {
        setUploadStatus({ percent, status: status || 'upload' })
      },
      tok,
      locked,
      hidden,
      customAudienceTag,
    )
  }

  // Updates columns when user selects new header
  const updateColumns = (newColumns: UploaderColumnsType[]) => {
    if (targetFilePreview) {
      let updatedColumns = targetFilePreview.columns
      newColumns.forEach((newColumn) => {
        updatedColumns = Object.assign([...updatedColumns], {
          [newColumn.src_index]: newColumn,
        })
      })
      setTargetFilePreview({
        ...targetFilePreview,
        columns: updatedColumns,
        updated: !!replaceFileOptions?.existingTarget ? true : undefined,
      })
      if (!hasRequiredColumns(updatedColumns, audienceType)) {
        if (onError) onError(true)
        setColumnError('missing-columns')
      }
      if (hasRequiredColumns(updatedColumns, audienceType)) {
        if (onError) onError(false)
        setColumnError(undefined)
      }
    }
  }

  // Updates if the file has a header row
  const updateHasHeader = (newValue: boolean) => {
    if (targetFilePreview) {
      setTargetFilePreview({
        ...targetFilePreview,
        hasHeader: newValue,
      })
    }
  }
  // Reads the csv, checks for errors and sets preview
  const handleTargetFile = (file: PreviewType) => {
    return new Promise<void>((resolve, reject) => {
      papa.parse(file, {
        worker: true,
        skipEmptyLines: true,
        complete: ({ data }: { data: string[][] }) => {
          const rows = data.slice(0, 6)
          const columns =
            rows && rows[0]
              ? guessHeader(rows[0], replaceFileOptions?.existingTarget)
              : []
          const hasHeader = rows && rows[0] && columns.length > 0
          const payload_keys = columns.map((column) => column.payload_key)

          const getError = () => {
            if (data.length === 0) return 'empty-file'
            if (!hasHeader) return 'no-header'
            if (
              (audienceType === 'zip' && data.length > ZIP_LIMIT + 1) ||
              (audienceType === 'dc' &&
                data.length > DIGITAL_CANVASSING_LIMIT + 1) ||
              (audienceType === 'address' && data.length > ADDRESS_LIMIT + 1) ||
              ((audienceType === 'ip' || audienceType === 'ipForMailing') &&
                data.length > IP_LIMIT + 1) ||
              (audienceType === 'device' && data.length > DEVICE_LIMIT + 1) ||
              (audienceType === 'reverseIp' && data.length > REVERSE_LIMIT + 1)
            )
              return 'too-many-rows'
            if (rows.length === 1 && hasHeader) return 'has-only-header'
            if (file.size > MAX_UPLOAD_TARGET_FILE_SIZE) return 'too-large'
            if (
              (audienceType === 'address' || audienceType === 'dc') &&
              columns.length <= 1 &&
              (payload_keys.includes('a') || payload_keys.includes('z'))
            ) {
              return 'file-missing-columns'
            }
            if (!hasRequiredColumns(columns, audienceType)) {
              return 'missing-columns'
            }
            if (
              hasRequiredColumns(columns, audienceType) &&
              !matchesChosenType(audienceType, columns)
            )
              return 'type-mismatch'
            return undefined
          }

          const processingError = getError()

          if (processingError) {
            setColumnError(processingError)
            if (onError) onError(true)
          }

          setTargetFilePreview({
            rows,
            columns,
            fileData: file,
            hasHeader,
          })
          resolve()
        },
        error: (err) => {
          reject(err)
        },
      })
    })
  }

  useEffect(() => {
    setLoading(true)
    handleTargetFile(droppedFile).then(() => {
      setLoading(false)
    })
  }, [droppedFile])

  const getInfoClass = () => {
    const base = 'AudienceColumnSelector__file-info-msg'
    if (columnError) return `${base}--error`
    return `${base}--success`
  }

  const getFieldName = (selection: SelectOptionType) => {
    return columnTypes.find(
      (ct) => ct.label === selection.label && ct.value === selection.value,
    )
  }

  if (!targetFilePreview)
    return (
      <>
        {loading && (
          <div className="AudienceColumnSelector__loading flex h-full w-full flex-col items-center justify-center gap-2">
            <div className="AudienceColumnSelector__loading-bar w-[8rem]">
              <Loader />
            </div>
          </div>
        )}
      </>
    )

  const tableRows = targetFilePreview.rows.reduce(
    (result: { [key: number]: string }[], row) => {
      if (row.filter((r) => r).length) result.push({ ...row })
      return result
    },
    [],
  )

  const isRequiredColumn = (payload_key: string) => {
    if (audienceType === 'address' || audienceType === 'dc')
      return payload_key === 'a' || payload_key === 'z'
    if (
      audienceType === 'ip' ||
      audienceType === 'reverseIp' ||
      audienceType === 'ipForMailing'
    )
      return payload_key === 'i'
    if (audienceType === 'device')
      return payload_key === 'maid' || payload_key === 'did'
    if (audienceType === 'zip') return payload_key === 'z'
  }

  const tableColumns =
    tableRows && tableRows[0]
      ? Object.keys(tableRows[0]).map((key) => {
          const {
            payload_key,
            src_field,
            src_index,
          } = targetFilePreview.columns[+key]
          return {
            path: key,
            label: (
              <div
                className={classNames(
                  'AudienceColumnSelector__select-wrapper',
                  {
                    'AudienceColumnSelector__select-wrapper--required-column': isRequiredColumn(
                      payload_key,
                    ),
                  },
                )}
              >
                {columnError !== 'too-large' &&
                  columnError !== 'too-many-rows-zip' &&
                  columnError !== 'file-missing-columns' &&
                  columnError !== 'type-mismatch' && (
                    <Select
                      clearButton={false}
                      placeholder="Select label"
                      options={columnTypes.map((col) => {
                        return { value: col.value, label: col.label }
                      })}
                      selectedOption={columnTypes.find(
                        (ct) => ct.value === payload_key,
                      )}
                      onChange={(selected) => {
                        const duplicateColumn = targetFilePreview.columns.find(
                          (col) => {
                            if (
                              col.payload_key === 'did' ||
                              col.payload_key === 'maid'
                            ) {
                              return (
                                selected?.value === 'did' ||
                                selected?.value === 'maid'
                              )
                            }
                            return col.payload_key === selected?.value
                          },
                        )
                        if (selected) {
                          if (duplicateColumn) {
                            updateColumns([
                              {
                                src_key: 'other_ignore',
                                src_index: duplicateColumn.src_index,
                                src_field: duplicateColumn.src_field,
                                payload_key: 'ig',
                              },
                              {
                                src_key:
                                  getFieldName(selected)?.fieldName || '',
                                src_index,
                                src_field,
                                payload_key: selected?.value || '',
                              },
                            ])
                          } else {
                            updateColumns([
                              {
                                src_key:
                                  getFieldName(selected)?.fieldName || '',
                                src_index,
                                src_field,
                                payload_key: selected?.value || '',
                              },
                            ])
                          }
                        }
                      }}
                      maxHeight={250}
                    />
                  )}
              </div>
            ),
          }
        })
      : []

  const getSupportText = () => (
    <span>
      Please ensure you followed the{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://eltoro.com/el-toro-segment-data-file-specification/"
        className="font-bold"
      >
        following data format
      </a>
      . If you have any questions, please submit a{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://eltoro.zendesk.com/hc/en-us"
        className="font-bold"
      >
        support request
      </a>
    </span>
  )

  const getErrorText = () => {
    if (columnError) {
      if (columnError === 'no-header')
        return (
          <span>
            <strong>Oh no!</strong> Your list does not contain column
            headers.&nbsp;
            {getSupportText()}
          </span>
        )
      if (columnError === 'empty-file')
        return (
          <span>
            <strong>Oh no!</strong> Your list appears to be empty.&nbsp;
            {getSupportText()}
          </span>
        )
      if (columnError === 'has-only-header')
        return (
          <span>
            <strong>Oh no!</strong> Your list only has a header row.&nbsp;
            {getSupportText()}
          </span>
        )
      if (columnError === 'file-missing-columns')
        return (
          <span>
            <strong>Oh no!</strong> Your list is missing required columns.&nbsp;
            {getSupportText()}
          </span>
        )
      if (columnError === 'too-large')
        return (
          <span>
            <strong>Oh no!</strong> Your list exceeds the max file size. Please
            try a different list.
          </span>
        )
      if (columnError === 'too-many-rows') {
        const limit = () => {
          if (audienceType === 'zip') return ZIP_LIMIT
          if (audienceType === 'dc') return DIGITAL_CANVASSING_LIMIT
          if (audienceType === 'ip' || audienceType === 'ipAddressForMailing')
            return IP_LIMIT
          if (audienceType === 'device') return DEVICE_LIMIT
          if (audienceType === 'reverseIp') return REVERSE_LIMIT
          return ADDRESS_LIMIT
        }
        return (
          <span>
            <strong>Oh no!</strong>{' '}
            {`Your list exceeds the maximum rows of ${limit().toLocaleString()}. Please try a different list.`}
          </span>
        )
      }
      if (columnError === 'missing-columns') {
        if (audienceType === 'dc' || audienceType === 'address')
          return (
            <span>
              <strong>Oh no!</strong> Your list needs both{' '}
              <strong>Street address</strong> and{' '}
              <strong>Zip/postal code</strong> columns. {getSupportText()}
            </span>
          )
        if (
          audienceType === 'ip' ||
          audienceType === 'reverseIp' ||
          audienceType === 'ipForMailing'
        )
          return (
            <span>
              <strong>Oh no!</strong> Your list needs an{' '}
              <strong>IP address</strong> column. {getSupportText()}
            </span>
          )
        if (audienceType === 'zip')
          return (
            <span>
              <strong>Oh no!</strong> Your list needs a <strong>ZIP</strong>{' '}
              column. Please try selecting the correct header.{' '}
              {getSupportText()}
            </span>
          )
        if (audienceType === 'device')
          return (
            <span>
              <strong>Oh no!</strong> Your list needs a{' '}
              <strong>Device ID</strong> or <strong>MAID</strong> column. Please
              try selecting the correct header. {getSupportText()}
            </span>
          )
      }
      if (columnError === 'type-mismatch')
        return (
          <span>
            <strong>Oh no!</strong> Your list cannot be used for
            {` ${(() => {
              if (audienceType === 'ip') return 'IP'
              if (audienceType === 'reverseIp') return 'Reverse IP'
              if (audienceType === 'ipForMailing') return 'IP List for Mailing'
              if (audienceType === 'zip') return 'ZIP'
              if (audienceType === 'device') return 'Device ID'
              return 'address'
            })()} lists.`}
          </span>
        )
    }
    return ''
  }

  return (
    <div className="AudienceColumnSelector mt-1 mr-1 mb-4">
      <div>
        {uploading && (
          <div className="AudienceColumnSelector__uploading">
            <UploadStatusBar
              file={targetFilePreview.fileData}
              complete={uploadComplete}
              errored={!!uploadError}
              uploadPercentage={uploadStatus.percent}
              currentStep={uploadStatus.status as CurrentStepType}
              reason={{ message: uploadError }}
            />
          </div>
        )}
        <div
          className={`AudienceColumnSelector__file-info-msg ${getInfoClass()}`}
        >
          {!columnError ? (
            <span>
              <strong>
                {`${targetFilePreview.fileData.name} (${formatByteString(
                  targetFilePreview.fileData.size,
                )})`}
              </strong>{' '}
              is ready to go!
            </span>
          ) : (
            getErrorText()
          )}
        </div>
        <div
          className={`AudienceColumnSelector__table-wrapper${
            targetFilePreview.hasHeader
              ? ' AudienceColumnSelector__table-wrapper--has-header'
              : ''
          }`}
        >
          <Table className="mb-4" rows={tableRows} columns={tableColumns} />
        </div>
        {!columnError && (
          <div className="AudienceColumnSelector__checkbox--row flex justify-end gap-3">
            <Checkbox
              label="First row is header row"
              checked={targetFilePreview.hasHeader || false}
              onChange={(val) => updateHasHeader(val)}
            />
            {replaceFileOptions?.audienceName && (
              <Checkbox
                label={`Keep original name: ${replaceFileOptions?.audienceName}`}
                checked={keepName}
                onChange={(val) => setKeepName(val)}
              />
            )}
            {isAdmin && !replaceFileOptions && (
              <>
                <Checkbox
                  label="Lock Audience"
                  checked={audienceType === 'custom' ? true : locked}
                  onChange={(val) => setLocked(val)}
                  // Ensure that these are locked on creation (admins can unlock these later)
                  disabled={
                    audienceType === 'ipForMailing' ||
                    audienceType === 'reverseIp' ||
                    audienceType === 'device' ||
                    audienceType === 'custom'
                  }
                />
                <Checkbox
                  label="Hide Audience"
                  checked={hidden}
                  onChange={(val) => setHidden(val)}
                  // Ensure that these are hidden on creation (admins can unhide these later)
                  disabled={
                    audienceType === 'ipForMailing' ||
                    audienceType === 'reverseIp'
                  }
                />
              </>
            )}
          </div>
        )}
      </div>
      <div className="AudienceColumnSelector__btn-container">
        {onBack ? (
          <Button kind="default-grey" onClick={onBack}>
            Back
          </Button>
        ) : (
          <div />
        )}
        <div className="flex gap-4">
          {onCancel && (
            <Button kind="default" onClick={onCancel}>
              Cancel
            </Button>
          )}
          <Button
            kind="primary"
            onClick={() => handleUpload()}
            disabled={
              !targetFilePreview ||
              !!columnError ||
              (!!replaceFileOptions?.existingTarget &&
                !targetFilePreview.updated)
            }
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}
