import { useState } from 'react'
import { useDebounce } from '@eltoro-ui/hooks'
import { FilterType, SortType } from 'types'

export const useTableSearch = () => {
  const [searchInput, setSearchInput] = useState('')
  const [childOrgsOnly, setChildOrgsOnly] = useState(false)
  const [showArchived, setShowArchived] = useState(false)
  const [sort, setSort] = useState<SortType>()
  const [tableFilter, setTableFilter] = useState<FilterType>()
  const debouncedTableFilter = useDebounce(tableFilter, 200)
  const debouncedSearchInput = useDebounce(searchInput, 200)

  return {
    searchInput,
    setSearchInput,
    childOrgsOnly,
    setChildOrgsOnly,
    showArchived,
    setShowArchived,
    sort,
    setSort,
    tableFilter,
    setTableFilter,
    debouncedTableFilter,
    debouncedSearchInput,
  } as const
}

export type TableSearchType = {
  searchInput: string
  setSearchInput: React.Dispatch<React.SetStateAction<string>>
  childOrgsOnly: boolean
  setChildOrgsOnly: React.Dispatch<React.SetStateAction<boolean>>
  showArchived: boolean
  setShowArchived: React.Dispatch<React.SetStateAction<boolean>>
  sort?: SortType
  setSort: React.Dispatch<React.SetStateAction<SortType | undefined>>
  tableFilter?: FilterType
  setTableFilter: React.Dispatch<React.SetStateAction<FilterType | undefined>>
  debouncedTableFilter: FilterType | undefined
  debouncedSearchInput: string
}
