/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Campaignservicev1OrderLine } from '../models/Campaignservicev1OrderLine';
import { HttpFile } from '../http/http';

/**
* Request to create or update an order lines.
*/
export class V1BatchOrderLineRequest {
    'orderLine'?: Campaignservicev1OrderLine;
    /**
    * The organization where this order line will be created.
    */
    'orgId': string;
    /**
    * The campaign that the order line will be auto attached to.
    */
    'campaignId'?: string;
    /**
    * The audiences that will be attached to the order line.
    */
    'creatives'?: Array<string>;
    /**
    * The creatives that will be attached to the order line.
    */
    'audiences'?: Array<string>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "orderLine",
            "baseName": "order_line",
            "type": "Campaignservicev1OrderLine",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "campaignId",
            "baseName": "campaign_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "creatives",
            "baseName": "creatives",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "audiences",
            "baseName": "audiences",
            "type": "Array<string>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1BatchOrderLineRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

