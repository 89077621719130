/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GoogletypeMoney } from '../models/GoogletypeMoney';
import { V1DataSourcesHighestUpcharge } from '../models/V1DataSourcesHighestUpcharge';
import { HttpFile } from '../http/http';

export class V1OrderLineMigrated {
    'requestId'?: string;
    'userId'?: string;
    'timestamp'?: Date;
    'orderLineId'?: string;
    'cpm'?: GoogletypeMoney;
    'dataSourcesHighestUpcharge'?: Array<V1DataSourcesHighestUpcharge>;
    'audienceUpcharges'?: GoogletypeMoney;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "requestId",
            "baseName": "request_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "userId",
            "baseName": "user_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "timestamp",
            "baseName": "timestamp",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "orderLineId",
            "baseName": "order_line_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "cpm",
            "baseName": "cpm",
            "type": "GoogletypeMoney",
            "format": ""
        },
        {
            "name": "dataSourcesHighestUpcharge",
            "baseName": "data_sources_highest_upcharge",
            "type": "Array<V1DataSourcesHighestUpcharge>",
            "format": ""
        },
        {
            "name": "audienceUpcharges",
            "baseName": "audience_upcharges",
            "type": "GoogletypeMoney",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1OrderLineMigrated.attributeTypeMap;
    }

    public constructor() {
    }
}

