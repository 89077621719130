/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1AutomationType } from '../models/V1AutomationType';
import { V1IntervalUnit } from '../models/V1IntervalUnit';
import { HttpFile } from '../http/http';

export class TheAutomationToUpdate {
    /**
    * The time the Automation was created.
    */
    'createTime'?: Date;
    /**
    * The last time the Automation was updated.
    */
    'updateTime'?: Date;
    /**
    * The Org's ID that is associated with the Automation.
    */
    'orgId'?: string;
    /**
    * The Audience's ID that is associated with the Automation.
    */
    'audienceId': string;
    /**
    * Whether the automation is enabled or not. This is set by the client. If the automation is enabled but the active field is false, it will not run. Defaults to false.
    */
    'enabled'?: boolean;
    /**
    * The interval value for the automation. A whole number. Combines with interval_unit. Example: {interval_value: 1, interval_unit: INTERVAL_UNIT_DAY} = automation is scheduled to run one day after the last time the automation finished.  If automation requires the audience to be queued up, the automation will run as soon as it can after the wait period of 1 day. Defaults to 1. Cannot be a negative number or 0. On creation, negative numbers or 0 will be increased to 1.
    */
    'intervalValue': number;
    'intervalUnit'?: V1IntervalUnit;
    /**
    * The last time the automation was completed successfully.
    */
    'lastCompleted'?: Date;
    'type'?: V1AutomationType;
    /**
    * Whether the automation is active or not. This is set by the system. If the automation is enabled but the active field is false, it will not run.
    */
    'active'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updateTime",
            "baseName": "update_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "audienceId",
            "baseName": "audience_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "enabled",
            "baseName": "enabled",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "intervalValue",
            "baseName": "interval_value",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "intervalUnit",
            "baseName": "interval_unit",
            "type": "V1IntervalUnit",
            "format": ""
        },
        {
            "name": "lastCompleted",
            "baseName": "last_completed",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "V1AutomationType",
            "format": ""
        },
        {
            "name": "active",
            "baseName": "active",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return TheAutomationToUpdate.attributeTypeMap;
    }

    public constructor() {
    }
}

