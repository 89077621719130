import { useEffect } from 'react'
import { SSEMessageType } from 'types'
import { Event } from '../types/events'

// This hook provides an event listener for events dispatched from SSEContext
export const useSSEEventReactor = (
  event: Event,
  handler: (e: SSEMessageType) => void,
) => {
  useEffect(() => {
    const handleEvent: EventListener = ((e: CustomEvent<SSEMessageType>) => {
      handler(e.detail)
    }) as EventListener

    window.addEventListener(event, handleEvent)
    return () => {
      window.removeEventListener(event, handleEvent)
    }
  }, [event, handler])
}
