import { useCallback, useEffect, useState } from 'react'
import {
  Fa,
  showErrorMessage,
  Table,
  TableColumnType,
} from '@eltoro-ui/components'
import { useTokenPagination } from '@eltoro-ui/hooks'
import { V1Pixel } from 'next-gen-sdk'
import { useNavigate } from 'react-router-dom'
import { EmptyField, TablePagination, TableRowLoadingIcon } from 'Components'
import { useAppContext } from 'Contexts'
import { SearchBar } from './SearchBar'
import { useTableSearch } from './useTableSearch'
import { capitalize, formatDate } from 'Helpers'

type PopulatedPixelRow = V1Pixel & {
  loading: boolean
  totalSubscriptions?: number
  activeSubscriptions?: number
}

export const PixelTable = ({ refresh }: { refresh: string }) => {
  const [populatedRows, setPopulatedRows] = useState<PopulatedPixelRow[]>([])
  const { webToHomeApi, currentOrg } = useAppContext()
  const nav = useNavigate()
  const tableSearchProps = useTableSearch()
  const query = currentOrg?.id ? `?org_id=${currentOrg.id}` : ''

  const handleFetchPixels = useCallback(
    async ({
      pageSize,
      nextPageToken,
    }: {
      pageSize: number
      nextPageToken?: string
    }) => {
      const empty = {
        data: [],
        totalItems: 0,
      }

      if (!currentOrg?.id || !webToHomeApi) return empty
      try {
        const res = await webToHomeApi?.advertisingPlatformServiceListPixels(
          pageSize,
          nextPageToken,
          currentOrg.id,
          'created desc',
        )
        if (res) {
          return {
            data: (res.pixels || []).map(
              (pix) => ({ ...pix, loading: true } as PopulatedPixelRow),
            ),
            totalItems: res.totalSize || 0,
            nextPageToken: res.nextPageToken,
          }
        }
      } catch {
        showErrorMessage('Error fetching your pixels', '')
      }

      return empty
    },
    [currentOrg?.id, webToHomeApi],
  )

  const pagination = useTokenPagination<PopulatedPixelRow>(
    {
      pageSize: 10,
      fetchData: handleFetchPixels,
    },
    [currentOrg, refresh],
  )

  useEffect(() => {
    let ignore = false
    // populate rows
    const populateRows = async (pixels: V1Pixel[]) => {
      if (currentOrg?.id) {
        const completedRows = await Promise.all(
          pixels.map(async (pixel) => {
            if (!currentOrg?.id || !webToHomeApi || !pixel.id)
              return Promise.resolve(pixel)
            let totalSubscriptions = 0
            let activeSubscriptions = 0
            let nextPageToken = ''

            // list subscriptions for pixel
            // total comes from total
            // but active would be easier if we had filter
            do {
              await webToHomeApi
                .advertisingPlatformServiceListSubscriptions(
                  pixel.id,
                  100, // pageSize
                  nextPageToken, // pageToken
                  currentOrg?.id,
                )
                .then((res) => {
                  totalSubscriptions = res.totalSize || 0
                  activeSubscriptions += (res.subscriptions || []).filter(
                    (s) => s.enabled,
                  ).length
                  nextPageToken = res.nextPageToken || ''
                })
            } while (nextPageToken !== '')

            return {
              ...pixel,
              totalSubscriptions,
              activeSubscriptions,
              loading: false,
            } as PopulatedPixelRow
          }),
        )
        return completedRows as PopulatedPixelRow[]
      }
      return [] as PopulatedPixelRow[]
    }

    if (pagination.currentPageData) {
      populateRows(pagination.currentPageData).then((newRows) => {
        if (!ignore) setPopulatedRows(newRows)
      })
    }
    return () => {
      ignore = true
    }
  }, [pagination.currentPageData])

  const columns: TableColumnType<PopulatedPixelRow>[] = [
    {
      path: 'name',
      label: 'Pixel Name',
      RowCell: ({ name, locked, hidden }) => (
        <div>
          {locked && (
            <span data-tooltip="right" aria-label="This pixel is locked.">
              <Fa icon="lock" size={1} />
            </span>
          )}
          {hidden && (
            <span data-tooltip="right" aria-label="This pixel is hidden.">
              <Fa icon="user-secret" size={1} />
            </span>
          )}
          {name || <EmptyField />}
        </div>
      ),
    },
    {
      path: 'status',
      label: 'Status',
      RowCell: ({ status }) => (status ? capitalize(status) : <EmptyField />),
    },
    {
      path: 'totalSubscriptions',
      label: 'Total Subscriptions',
      RowCell: ({ loading, totalSubscriptions }) => {
        if (loading) return <TableRowLoadingIcon />
        if (totalSubscriptions === undefined) return <EmptyField />
        return totalSubscriptions
      },
    },
    {
      path: 'activeSubscriptions',
      label: 'Active Subscriptions',
      RowCell: ({ loading, activeSubscriptions }) => {
        if (loading) return <TableRowLoadingIcon />
        if (activeSubscriptions === undefined) return <EmptyField />
        return activeSubscriptions
      },
    },
    {
      path: 'created',
      label: 'Date Created',
      RowCell: (row) => {
        if (!row.created) return <EmptyField />
        return formatDate(row.created)
      },
    },
    {
      path: 'id',
      label: 'Pixel ID',
    },
  ]

  return (
    <div className="PixelTable">
      {/* TODO: Filtering is not supported in pixel, or we don't have a BFF yet */}
      <SearchBar type="pixels" {...tableSearchProps} />
      <div>
        <Table
          className="PixelTable__table"
          columns={columns}
          rows={
            populatedRows.length > 0
              ? populatedRows
              : pagination.currentPageData
          }
          // onSort={() => {}}
          onClick={(row) => nav(`pixel/${row.id}${query}`)}
        />
      </div>
      {!pagination.loading && !pagination.totalItems ? (
        <div className="PixelLibraryPage__empty-state flex flex-col items-center py-6">
          <Fa
            icon="folder"
            size={5}
            type="duotone"
            className="text-primary-200"
          />
          <p className="text-grey-600">Your Pixel Library is empty.</p>
        </div>
      ) : null}
      {pagination.totalItems > 10 ? (
        <div className="PixelLibraryPage__pagination-footer pb-2">
          <TablePagination pagination={pagination} />
        </div>
      ) : null}
    </div>
  )
}
