import { useState, useEffect } from 'react'
import {
  Select,
  Table,
  PreviewType,
  SelectOptionType,
  Checkbox,
  Button,
  Loader,
  TextHeader,
  CollapsibleContainer,
} from '@eltoro-ui/components'
import { formatByteString } from '@eltoro-ui/helpers'
import papa from 'papaparse'
import { Targetjobservicev1Audience, V2Target } from 'next-gen-sdk'
import classNames from 'classnames'
import { v4 as uuid } from 'uuid'
import { CurrentStepType, UploadStatusBar } from 'Components'
import { useAppContext } from 'Contexts'
import {
  guessHeader,
  hasRequiredColumns,
  matchesChosenType,
  columnTypes,
  handleUploadTarget,
  getConvertedColumnTarget,
} from './helpers'
import './AudienceColumnSelector.scss'
import {
  ADDRESS_LIMIT,
  DEVICE_LIMIT,
  DIGITAL_CANVASSING_LIMIT,
  IP_LIMIT,
  MAX_UPLOAD_TARGET_FILE_SIZE,
  REVERSE_LIMIT,
  ZIP_LIMIT,
} from 'Helpers'

type UploaderColumnsType = {
  src_key: string
  src_index: number
  src_field: string
  payload_key: string
}

type TargetPayloadType = {
  rows: string[][]
  columns: UploaderColumnsType[]
  fileData: PreviewType
}

type TargetPreviewType = TargetPayloadType & {
  id: string // for react key and updating row purposes
  // settings
  hasHeader?: boolean
  locked?: boolean
  hidden?: boolean
  // reading file client side
  columnError?: string
  // uploading to target service
  uploading: boolean
  uploadError?: string
  uploadComplete: boolean
  uploadStatus: { percent: number; status: string }
}

const TargetFileRow = ({
  targetFilePreview,
  audienceType,
  onError,
  updateTargetFilePreview,
  removeTargetFilePreview,
  index,
}: {
  targetFilePreview: TargetPreviewType
  audienceType: string
  onError?: (value: boolean) => void
  updateTargetFilePreview: (targetFilePreview: TargetPreviewType) => void
  removeTargetFilePreview: (id: string) => void
  index: number
}) => {
  const [columnEditOpen, setColumnEditOpen] = useState(false)
  const { isAdmin } = useAppContext()

  const {
    uploading,
    fileData,
    uploadComplete,
    uploadError,
    uploadStatus,
    hasHeader,
    locked,
    hidden,
    columnError,
    id,
  } = targetFilePreview
  const getErrorText = () => {
    if (columnError) {
      if (columnError === 'no-header')
        return (
          <span>
            <strong>Oh no!</strong> Your list does not contain column
            headers.&nbsp;
            {getSupportText()}
          </span>
        )
      if (columnError === 'empty-file')
        return (
          <span>
            <strong>Oh no!</strong> Your list appears to be empty.&nbsp;
            {getSupportText()}
          </span>
        )
      if (columnError === 'has-only-header')
        return (
          <span>
            <strong>Oh no!</strong> Your list only has a header row.&nbsp;
            {getSupportText()}
          </span>
        )
      if (columnError === 'file-missing-columns')
        return (
          <span>
            <strong>Oh no!</strong> Your list is missing required columns.&nbsp;
            {getSupportText()}
          </span>
        )
      if (columnError === 'too-large')
        return (
          <span>
            <strong>Oh no!</strong> Your list exceeds the max file size. Please
            remove and try a different list.
          </span>
        )
      if (columnError === 'too-many-rows') {
        const limit = () => {
          if (audienceType === 'zip') return ZIP_LIMIT
          if (audienceType === 'dc') return DIGITAL_CANVASSING_LIMIT
          if (audienceType === 'ip' || audienceType === 'ipAddressForMailing')
            return IP_LIMIT
          if (audienceType === 'device') return DEVICE_LIMIT
          if (audienceType === 'reverseIp') return REVERSE_LIMIT
          return ADDRESS_LIMIT
        }
        return (
          <span>
            <strong>Oh no!</strong>{' '}
            {`Your list exceeds the maximum rows of ${limit().toLocaleString()}. Please remove and try a different list.`}
          </span>
        )
      }
      if (columnError === 'missing-columns') {
        if (audienceType === 'dc' || audienceType === 'address')
          return (
            <span>
              <strong>Oh no!</strong> Your list needs both{' '}
              <strong>Street address</strong> and{' '}
              <strong>Zip/postal code</strong> columns. {getSupportText()}
            </span>
          )
        if (
          audienceType === 'ip' ||
          audienceType === 'reverseIp' ||
          audienceType === 'ipForMailing'
        )
          return (
            <span>
              <strong>Oh no!</strong> Your list needs an{' '}
              <strong>IP address</strong> column. {getSupportText()}
            </span>
          )
        if (audienceType === 'zip')
          return (
            <span>
              <strong>Oh no!</strong> Your list needs a <strong>ZIP</strong>{' '}
              column. Please try selecting the correct header.{' '}
              {getSupportText()}
            </span>
          )
        if (audienceType === 'device')
          return (
            <span>
              <strong>Oh no!</strong> Your list needs a{' '}
              <strong>Device ID</strong> or <strong>MAID</strong> column. Please
              try selecting the correct header. {getSupportText()}
            </span>
          )
      }
      if (columnError === 'type-mismatch')
        return (
          <span>
            <strong>Oh no!</strong> Your list cannot be used for
            {` ${(() => {
              if (audienceType === 'ip') return 'IP'
              if (audienceType === 'reverseIp') return 'Reverse IP'
              if (audienceType === 'ipForMailing') return 'IP List for Mailing'
              if (audienceType === 'zip') return 'ZIP'
              if (audienceType === 'device') return 'Device ID'
              return 'address'
            })()} lists.`}
          </span>
        )
    }
    return ''
  }
  // Updates columns when user selects new header
  const updateColumns = (newColumns: UploaderColumnsType[]) => {
    if (targetFilePreview) {
      let updatedColumns = targetFilePreview.columns
      newColumns.forEach((newColumn) => {
        updatedColumns = Object.assign([...updatedColumns], {
          [newColumn.src_index]: newColumn,
        })
      })
      updateTargetFilePreview({
        ...targetFilePreview,
        columns: updatedColumns,
        columnError: !hasRequiredColumns(updatedColumns, audienceType)
          ? 'missing-columns'
          : undefined,
      })

      if (onError) {
        onError(hasRequiredColumns(updatedColumns, audienceType) ? false : true)
      }
    }
  }
  const isRequiredColumn = (payload_key: string) => {
    if (audienceType === 'address' || audienceType === 'dc')
      return payload_key === 'a' || payload_key === 'z'
    if (
      audienceType === 'ip' ||
      audienceType === 'reverseIp' ||
      audienceType === 'ipForMailing'
    )
      return payload_key === 'i'
    if (audienceType === 'device')
      return payload_key === 'maid' || payload_key === 'did'
    if (audienceType === 'zip') return payload_key === 'z'
  }
  const tableRows = targetFilePreview.rows.reduce(
    (result: { [key: number]: string }[], row) => {
      if (row.filter((r) => r).length) result.push({ ...row })
      return result
    },
    [],
  )
  const tableColumns =
    tableRows && tableRows[0]
      ? Object.keys(tableRows[0]).map((key) => {
          const {
            payload_key,
            src_field,
            src_index,
          } = targetFilePreview.columns[+key]
          return {
            path: key,
            label: (
              <div
                className={classNames(
                  'AudienceColumnSelector__select-wrapper',
                  {
                    'AudienceColumnSelector__select-wrapper--required-column': isRequiredColumn(
                      payload_key,
                    ),
                  },
                )}
              >
                {columnError !== 'too-large' &&
                  columnError !== 'too-many-rows-zip' &&
                  columnError !== 'file-missing-columns' &&
                  columnError !== 'type-mismatch' && (
                    <Select
                      clearButton={false}
                      placeholder="Select label"
                      options={columnTypes.map((col) => {
                        return { value: col.value, label: col.label }
                      })}
                      selectedOption={columnTypes.find(
                        (ct) => ct.value === payload_key,
                      )}
                      onChange={(selected) => {
                        const duplicateColumn = targetFilePreview.columns.find(
                          (col) => {
                            if (
                              col.payload_key === 'did' ||
                              col.payload_key === 'maid'
                            ) {
                              return (
                                selected?.value === 'did' ||
                                selected?.value === 'maid'
                              )
                            }
                            return col.payload_key === selected?.value
                          },
                        )
                        if (selected) {
                          if (duplicateColumn) {
                            updateColumns([
                              {
                                src_key: 'other_ignore',
                                src_index: duplicateColumn.src_index,
                                src_field: duplicateColumn.src_field,
                                payload_key: 'ig',
                              },
                              {
                                src_key:
                                  getFieldName(selected)?.fieldName || '',
                                src_index,
                                src_field,
                                payload_key: selected?.value || '',
                              },
                            ])
                          } else {
                            updateColumns([
                              {
                                src_key:
                                  getFieldName(selected)?.fieldName || '',
                                src_index,
                                src_field,
                                payload_key: selected?.value || '',
                              },
                            ])
                          }
                        }
                      }}
                      maxHeight={250}
                    />
                  )}
              </div>
            ),
          }
        })
      : []

  const getInfoClass = (targetFilePreview: TargetPreviewType) => {
    const base = 'AudienceColumnSelector__file-info-msg'
    if (targetFilePreview.columnError) return `${base}--error`
    return `${base}--success`
  }

  const getFieldName = (selection: SelectOptionType) => {
    return columnTypes.find(
      (ct) => ct.label === selection.label && ct.value === selection.value,
    )
  }

  const getSupportText = () => (
    <span>
      Please ensure you followed the{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://eltoro.com/el-toro-segment-data-file-specification/"
        className="font-bold"
      >
        following data format
      </a>
      . If you have any questions, please submit a{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://eltoro.zendesk.com/hc/en-us"
        className="font-bold"
      >
        support request
      </a>
    </span>
  )

  if (uploading || uploadComplete || uploadError)
    return (
      <UploadStatusBar
        file={fileData}
        complete={uploadComplete}
        errored={!!uploadError}
        uploadPercentage={uploadStatus.percent}
        currentStep={uploadStatus.status as CurrentStepType}
        reason={{ message: uploadError }}
        className="MultiAudienceColumnSelector__uploading bg-gray"
      />
    )

  return (
    <div className="MultiAudienceColumnSelector__file-container bg-gray rounded p-2">
      <div className="MultiAudienceColumnSelector__file-header flex items-center justify-between pb-1">
        <TextHeader type={5} className="flex items-center gap-2">
          <div className="bg-primary group-hover:bg-base group-hover:text-primary flex min-h-[1.5rem] min-w-[1.5rem] items-center justify-center rounded-full font-bold text-white">
            {index + 1}
          </div>
          {`${fileData.name} (${formatByteString(fileData.size)})`}
        </TextHeader>
        <div className="flex gap-2">
          {!columnError && (
            <div className="AudienceColumnSelector__checkbox--row flex justify-end gap-3">
              <Checkbox
                label="First row is header row"
                checked={hasHeader || false}
                onChange={(val) =>
                  updateTargetFilePreview({
                    ...targetFilePreview,
                    hasHeader: val,
                  })
                }
              />
              {isAdmin && (
                <>
                  <Checkbox
                    label="Lock Audience"
                    checked={locked || false}
                    onChange={(val) =>
                      updateTargetFilePreview({
                        ...targetFilePreview,
                        locked: val,
                      })
                    }
                    // Ensure that these are locked on creation (admins can unlock these later)
                    disabled={
                      audienceType === 'ipForMailing' ||
                      audienceType === 'reverseIp' ||
                      audienceType === 'device' ||
                      audienceType === 'custom'
                    }
                  />
                  <Checkbox
                    label="Hide Audience"
                    checked={hidden || false}
                    onChange={(val) =>
                      updateTargetFilePreview({
                        ...targetFilePreview,
                        hidden: val,
                      })
                    }
                    // Ensure that these are hidden on creation (admins can unhide these later)
                    disabled={
                      audienceType === 'ipForMailing' ||
                      audienceType === 'reverseIp'
                    }
                  />
                </>
              )}
            </div>
          )}
          <Button onClick={() => removeTargetFilePreview(id)} kind="danger">
            Remove
          </Button>
        </div>
      </div>
      <div
        className={`MultiAudienceColumnSelector__file-info-msg flex items-center justify-between rounded p-2 ${getInfoClass(
          targetFilePreview,
        )}`}
      >
        {!columnError ? <span>List is ready to go!</span> : getErrorText()}
        {!columnError && (
          <Button
            kind="primary"
            onClick={() => setColumnEditOpen(!columnEditOpen)}
          >
            {`${columnEditOpen ? 'Close' : 'Open'} column editor`}
          </Button>
        )}
      </div>
      <CollapsibleContainer isOpen={columnEditOpen || !!columnError}>
        <div
          className={`MultiAudienceColumnSelector__table-wrapper${
            hasHeader
              ? ' MultiAudienceColumnSelector__table-wrapper--has-header'
              : ''
          }`}
        >
          <Table className="mb-4" rows={tableRows} columns={tableColumns} />
        </div>
      </CollapsibleContainer>
    </div>
  )
}

export const MultiAudienceColumnSelector = ({
  audienceType,
  jobType,
  droppedFiles,
  onError,
  onCancel,
  onBack,
  onUploadComplete,
  clearStepError,
  customAudienceTag,
}: {
  audienceType: string
  jobType: string
  droppedFiles: PreviewType[]
  onError?: (value: boolean) => void
  onCancel?: () => void
  onBack?: () => void
  onUploadComplete?: (audiences: Targetjobservicev1Audience[]) => void
  clearStepError?: () => void
  customAudienceTag?: string
}) => {
  // prettier-ignore
  const [
    targetFilePreviews,
    setTargetFilePreviews,
  ] = useState<TargetPreviewType[]>([])
  const [processingFiles, setProcessingFiles] = useState(false)

  const { currentOrg, tok } = useAppContext()

  // Warn user on  navigating away from this page
  useEffect(() => {
    const unloadCallback = (event: any) => {
      event.preventDefault()
      event.returnValue = ''
      return ''
    }
    window.addEventListener('beforeunload', unloadCallback)
    return () => window.removeEventListener('beforeunload', unloadCallback)
  }, [])

  // Handle target files upload
  const handleUpload = async () => {
    const orgId = currentOrg?.id
    if (!orgId) return

    // this seems to only update one of the audiences, probably a state problem

    Promise.allSettled(
      targetFilePreviews.map((targetFilePreview) => {
        return new Promise<{
          uploadedJob: Targetjobservicev1Audience
          uploadedTarget: V2Target
        }>((resolve, reject) => {
          handleUploadTarget(
            getConvertedColumnTarget(targetFilePreview),
            audienceType,
            jobType,
            orgId,
            // onUpload
            (uploadedTarget, uploadedJob) => {
              updateTargetFilePreview({
                ...targetFilePreview,
                uploadComplete: true,
                uploading: false,
              })
              resolve({ uploadedTarget, uploadedJob })
            },
            (err) => {
              const errorText =
                err?.error?.body?.message || err?.error?.response?.statusText
              updateTargetFilePreview({
                ...targetFilePreview,
                uploadError: `Error during ${
                  err?.step ? `${err.step} step` : 'audience creation'
                } ${errorText ? ` - ${errorText}.` : '.'} ${
                  err.step === 'lock'
                    ? ' Please try locking the audience from the library.'
                    : ''
                }`,
              })
            },
            // onProgress
            (percent, status) =>
              updateTargetFilePreview({
                ...targetFilePreview,
                uploadStatus: {
                  percent,
                  status: status || 'upload',
                },
                uploading: true,
              }),
            tok,
            targetFilePreview.locked,
            targetFilePreview.hidden,
            customAudienceTag,
          ).catch((e) => reject(e))
        })
      }),
    ).then((results) => {
      if (onUploadComplete && results.every((r) => r.status === 'fulfilled')) {
        const uploadedJobs = results.reduce(
          (acc: Targetjobservicev1Audience[], result) => {
            if (result.status === 'fulfilled' && result.value?.uploadedJob) {
              acc.push(result.value.uploadedJob)
            }
            return acc
          },
          [],
        )
        onUploadComplete(uploadedJobs)
      }
    })
  }

  // Reads the csv, checks for errors and sets preview
  const handleTargetFile = (file: PreviewType) => {
    return new Promise<TargetPreviewType>((resolve, reject) => {
      papa.parse(file, {
        worker: true,
        skipEmptyLines: true,
        complete: ({ data }: { data: string[][] }) => {
          const rows = data.slice(0, 6)
          const columns = rows && rows[0] ? guessHeader(rows[0]) : []
          const hasHeader = rows && rows[0] && columns.length > 0
          const payload_keys = columns.map((column) => column.payload_key)

          const getError = () => {
            if (data.length === 0) return 'empty-file'
            if (!hasHeader) return 'no-header'
            if (
              (audienceType === 'zip' && data.length > ZIP_LIMIT + 1) ||
              (audienceType === 'dc' &&
                data.length > DIGITAL_CANVASSING_LIMIT + 1) ||
              (audienceType === 'address' && data.length > ADDRESS_LIMIT + 1) ||
              ((audienceType === 'ip' || audienceType === 'ipForMailing') &&
                data.length > IP_LIMIT + 1) ||
              (audienceType === 'device' && data.length > DEVICE_LIMIT + 1) ||
              (audienceType === 'reverseIp' && data.length > REVERSE_LIMIT + 1)
            )
              return 'too-many-rows'
            if (rows.length === 1 && hasHeader) return 'has-only-header'
            if (file.size > MAX_UPLOAD_TARGET_FILE_SIZE) return 'too-large'
            if (
              (audienceType === 'address' || audienceType === 'dc') &&
              columns.length <= 1 &&
              (payload_keys.includes('a') || payload_keys.includes('z'))
            ) {
              return 'file-missing-columns'
            }
            if (!hasRequiredColumns(columns, audienceType)) {
              return 'missing-columns'
            }
            if (
              hasRequiredColumns(columns, audienceType) &&
              !matchesChosenType(audienceType, columns)
            )
              return 'type-mismatch'
            return undefined
          }

          const processingError = getError()

          if (processingError) {
            if (onError) onError(true) // alert parent component of error
          }

          const targetFilePreview = {
            rows,
            columns,
            fileData: file,
            hasHeader,
            columnError: processingError,
            id: uuid(),
            uploading: false,
            uploadComplete: false,
            uploadStatus: { percent: 0, status: 'upload' },
            locked:
              audienceType === 'ipForMailing' ||
              audienceType === 'reverseIp' ||
              audienceType === 'device' ||
              audienceType === 'custom'
                ? true
                : false,
            hidden:
              audienceType === 'ipForMailing' || audienceType === 'reverseIp'
                ? true
                : false,
          }
          resolve(targetFilePreview)
        },
        error: (err) => {
          reject(err)
        },
      })
    })
  }

  const updateTargetFilePreview = (targetFilePreview: TargetPreviewType) => {
    setTargetFilePreviews((prev) => {
      const foundIndex = prev.findIndex((p) => p.id === targetFilePreview.id)
      if (foundIndex > -1) {
        const newTargetFilePreviews = [...prev]
        newTargetFilePreviews[foundIndex] = {
          ...newTargetFilePreviews[foundIndex],
          ...targetFilePreview,
        }
        return newTargetFilePreviews
      }
      return prev
    })
  }

  const removeTargetFilePreview = (id: string) => {
    const newTargetFilePreviews = targetFilePreviews.filter((p) => p.id !== id)
    setTargetFilePreviews(newTargetFilePreviews)
    // if this is the last one, go back to uploader
    if (newTargetFilePreviews.length === 0 && onBack) onBack()
  }

  // process files on client side to get possible column errors, row counts, display rows
  useEffect(() => {
    setProcessingFiles(true)
    Promise.all(droppedFiles.map((file) => handleTargetFile(file))).then(
      (result) => {
        setTargetFilePreviews(result)
        setProcessingFiles(false)
      },
    )
  }, [droppedFiles])

  // clear the stepper error if errors fixed
  useEffect(() => {
    if (targetFilePreviews.every((tfp) => !tfp.columnError) && clearStepError) {
      clearStepError()
    }
  }, [targetFilePreviews])

  if (processingFiles)
    return (
      <div className="MultiAudienceColumnSelector__loading flex h-full w-full flex-col items-center justify-center gap-2">
        <div className="MultiAudienceColumnSelector__loading-bar w-[8rem]">
          <Loader />
        </div>
      </div>
    )

  return (
    <div className="MultiAudienceColumnSelector mt-1 mr-1 mb-4 flex flex-col gap-2">
      {targetFilePreviews.map((targetFilePreview, index) => (
        <TargetFileRow
          key={targetFilePreview.id}
          targetFilePreview={targetFilePreview}
          audienceType={audienceType}
          updateTargetFilePreview={updateTargetFilePreview}
          removeTargetFilePreview={removeTargetFilePreview}
          index={index}
        />
      ))}

      <div className="MultiAudienceColumnSelector__btn-container flex justify-between">
        {onBack ? (
          <Button kind="default-grey" onClick={onBack}>
            Back
          </Button>
        ) : (
          <div />
        )}
        {!targetFilePreviews.some(
          (tfp) => tfp.uploading || tfp.uploadError,
        ) && (
          <div className="flex gap-4">
            {onCancel && (
              <Button kind="default" onClick={onCancel}>
                Cancel
              </Button>
            )}
            <Button
              kind="primary"
              onClick={() => handleUpload()}
              disabled={
                !targetFilePreviews.length ||
                targetFilePreviews.some((tfp) => !!tfp.columnError)
              }
            >
              Save
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
