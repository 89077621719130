import { TableColumnType } from '@eltoro-ui/components'
import { V1Stats } from 'next-gen-sdk'
import { EmptyField } from 'Components'
import { formatDate } from 'Helpers'

export const getSubscriptionTableMetricsColumns = () => {
  const columns: TableColumnType<V1Stats>[] = [
    {
      path: 'reportDate',
      label: 'Date',
      RowCell: (stat) =>
        stat.reportDate ? formatDate(stat.reportDate) : <EmptyField />,
    },
    {
      path: 'hits',
      label: 'Total Hits',
      RowCell: (stat) =>
        stat.hits === undefined ? <EmptyField /> : stat.hits.toLocaleString(),
    },
    {
      path: 'matcherTotal',
      label: 'Filtered Audience',
      RowCell: (stat) =>
        stat.matcherTotal === undefined ? (
          <EmptyField />
        ) : (
          stat.matcherTotal.toLocaleString()
        ),
    },
    {
      path: 'matcherMatches',
      label: 'Matched Audience',
      RowCell: (stat) =>
        stat.matcherMatches === undefined ? (
          <EmptyField />
        ) : (
          stat.matcherMatches.toLocaleString()
        ),
    },
    {
      path: 'matcherDuplicates',
      label: 'Previously Matched',
      RowCell: (stat) =>
        stat.matcherDuplicates === undefined ? (
          <EmptyField />
        ) : (
          stat.matcherDuplicates.toLocaleString()
        ),
    },
    {
      path: 'matches',
      label: 'Targetable Audience',
      RowCell: (stat) =>
        stat.matches === undefined ? (
          <EmptyField />
        ) : (
          stat.matches.toLocaleString()
        ),
    },
    {
      path: '',
      label: 'Geo-Filtered',
      RowCell: (stat) => {
        if (
          stat.stateFilterReductions === undefined &&
          stat.zipFilterReductions === undefined
        ) {
          return <EmptyField />
        }
        const stateFilterReductions = stat.stateFilterReductions || 0
        const zipFilterReductions = stat.zipFilterReductions || 0
        return (stateFilterReductions + zipFilterReductions).toLocaleString()
      },
    },
  ]
  return columns
}
