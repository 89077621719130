/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class V1MigrationSummary {
    'startTime'?: Date;
    'stopTime'?: Date;
    'childOrgsMigrated'?: number;
    'childOrgsTotal'?: number;
    'creativesMigrated'?: number;
    'creativesTotal'?: number;
    'targetsMigrated'?: number;
    'targetsTotal'?: number;
    'usersMigrated'?: number;
    'usersTotal'?: number;
    'percentageDone'?: number;
    'orgId'?: string;
    'campaignsTotal'?: number;
    'campaignsMigrated'?: number;
    'orderLinesMigrated'?: number;
    'orderLinesTotal'?: number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "startTime",
            "baseName": "start_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "stopTime",
            "baseName": "stop_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "childOrgsMigrated",
            "baseName": "child_orgs_migrated",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "childOrgsTotal",
            "baseName": "child_orgs_total",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "creativesMigrated",
            "baseName": "creatives_migrated",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "creativesTotal",
            "baseName": "creatives_total",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "targetsMigrated",
            "baseName": "targets_migrated",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "targetsTotal",
            "baseName": "targets_total",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "usersMigrated",
            "baseName": "users_migrated",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "usersTotal",
            "baseName": "users_total",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "percentageDone",
            "baseName": "percentage_done",
            "type": "number",
            "format": "double"
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "campaignsTotal",
            "baseName": "campaigns_total",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "campaignsMigrated",
            "baseName": "campaigns_migrated",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "orderLinesMigrated",
            "baseName": "order_lines_migrated",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "orderLinesTotal",
            "baseName": "order_lines_total",
            "type": "number",
            "format": "int32"
        }    ];

    static getAttributeTypeMap() {
        return V1MigrationSummary.attributeTypeMap;
    }

    public constructor() {
    }
}

