import { useEffect, useState } from 'react'
import { Button, Fa, Text } from '@eltoro-ui/components'
import {
  Targetjobservicev1Audience,
  V2Target,
  V1AudienceType,
} from 'next-gen-sdk'
import { DIGITAL_CANVASSING_LIMIT, getJobCounts, styleTailwind } from 'Helpers'
import { createAudience, getFullJobs } from 'Requests'
import { LinkButton } from 'Components'
import { ETIcon } from 'Assets'
import { useAppContext, useLibraryContext } from 'Contexts'

const Row = styleTailwind('div', 'AudienceType__row flex gap-2 py-2')

export const RelatedAudiences = ({
  target,
  job,
}: {
  target: V2Target
  job: Targetjobservicev1Audience
}) => {
  const [relatedAudiences, setRelatedAudiences] = useState<
    Targetjobservicev1Audience[]
  >([])
  const [loading, setLoading] = useState(true)
  const { tok, audienceServiceApi, currentOrg, isAdmin } = useAppContext()
  const {
    pagination: { refetch },
  } = useLibraryContext()
  const isIPTargeting =
    job.type === 'AUDIENCE_TYPE_B2B' || job.type === 'AUDIENCE_TYPE_B2C'
  const targetName = target.locations?.[0]?.filename
  // Figure out if there is already a related audience
  const handleGetRelatedAudiences = async () => {
    setRelatedAudiences([])
    setLoading(true)
    if (
      target.audiences &&
      target.audiences.length > 1 &&
      target.id &&
      currentOrg?.id
    ) {
      const fullJobs = await getFullJobs(target.audiences, tok, currentOrg.id)
      const related = fullJobs.reduce(
        (acc: Targetjobservicev1Audience[], j: Targetjobservicev1Audience) => {
          if (j.type === 'AUDIENCE_TYPE_DIRECTMAIL') return [...acc, j]
          if (isIPTargeting) {
            if (j.type === 'AUDIENCE_TYPE_DC') return [...acc, j]
          }
          if (j.type === 'AUDIENCE_TYPE_B2B' || j.type === 'AUDIENCE_TYPE_B2C')
            return [...acc, j]
          return acc
        },
        [],
      )
      setRelatedAudiences(related)
    }
  }
  useEffect(() => {
    handleGetRelatedAudiences().finally(() => setLoading(false))
  }, [target, job])

  const handleRunNewJob = async (type: V1AudienceType, clone?: boolean) => {
    const nameAppend = () => {
      if (type === 'AUDIENCE_TYPE_DC') return '_DC'
      if (type === 'AUDIENCE_TYPE_B2B') return '_B2B'
      if (type === 'AUDIENCE_TYPE_B2C') return '_B2C'
      if (type === 'AUDIENCE_TYPE_DEVICES_FOR_HOUSEHOLDS')
        return '_DEVICES_FOR_HOUSEHOLDS'
      // if (type === 'AUDIENCE_TYPE_DIRECTMAIL') return '_DM'
      return ''
    }
    if (target.id && currentOrg?.id && type && audienceServiceApi) {
      const createdAudience = await createAudience(
        currentOrg.id,
        type,
        target.id,
        `${job.name || targetName}${nameAppend()}${clone ? '-clone' : ''}`,
      )
      // Step 4: If job created, add it to the queue
      if (
        createdAudience?.id &&
        createdAudience.status === 'AUDIENCE_STATUS_CREATED'
      ) {
        audienceServiceApi
          ?.advertisingPlatformServiceGenerateAudience(
            createdAudience.id,
            currentOrg.id,
          )
          .then(() => {
            if (job && job.locked && createdAudience.id && currentOrg.id) {
              audienceServiceApi.advertisingPlatformServiceUpdateAudience(
                createdAudience.id,
                currentOrg.id,
                {
                  locked: job.locked,
                },
                'locked',
              )
            }
          })
          .then(() => {
            refetch()
          })
      }
    }
  }

  const existingB2CJob = relatedAudiences.find(
    (j) => j.type === 'AUDIENCE_TYPE_B2C',
  )
  const existingB2BJob = relatedAudiences.find(
    (j) => j.type === 'AUDIENCE_TYPE_B2B',
  )
  // const existingDirectMailJob = relatedAudiences.find(
  //   (j) => j.type === 'AUDIENCE_TYPE_DIRECTMAIL',
  // )
  const existingDCJob = relatedAudiences.find(
    (j) => j.type === 'AUDIENCE_TYPE_DC',
  )

  const canGenerateDigitalCanvassing =
    isIPTargeting &&
    job.result?.submitted &&
    job.result?.submitted < DIGITAL_CANVASSING_LIMIT

  const ExistingRow = ({
    existingJob,
    title,
  }: {
    existingJob: Targetjobservicev1Audience
    title: string
  }) => {
    const matchCount = getJobCounts(existingJob)

    return (
      <div className="AudienceType__description flex flex-col">
        <Text className="normal-case" tag="h6">
          {existingJob.name || 'No name'}
        </Text>
        <Text className="text-m font-light">{title}</Text>
        {matchCount && (
          <Text className="text-m">
            {matchCount.toLocaleString()} households
          </Text>
        )}
      </div>
    )
  }
  return (
    <div className="RelatedAudiences">
      <Text className="RelatedAudiences__header flex flex-1 flex-col gap-2 pt-2 font-bold">
        <span className="PreviewPanel__section-header">Related Audiences</span>
        <span className="font-light">
          Other audiences that share the same address-list source file
        </span>
      </Text>
      {loading ? (
        <div className="flex min-h-[10rem] items-center justify-center">
          <Fa
            icon="circle-notch"
            size={4}
            className="text-grey-400 animate-spin"
          />
        </div>
      ) : (
        <>
          {isAdmin && (
            <Button
              iconLeft={<ETIcon icon="maid" />}
              className="my-4 w-full"
              onClick={() =>
                handleRunNewJob('AUDIENCE_TYPE_DEVICES_FOR_HOUSEHOLDS')
              }
            >
              Address to device
            </Button>
          )}
          {isIPTargeting ? (
            <Row>
              <ETIcon
                icon="digital-canvassing"
                className={
                  !canGenerateDigitalCanvassing ? 'opacity-50 grayscale' : ''
                }
              />
              {existingDCJob ? (
                <ExistingRow
                  existingJob={existingDCJob}
                  title="Digital Canvassing"
                />
              ) : (
                <div className="ml-2 leading-4">
                  <p className="font-bold">Digital Canvassing</p>
                  {canGenerateDigitalCanvassing ? (
                    <LinkButton
                      text="Add to Library"
                      onClick={() => handleRunNewJob('AUDIENCE_TYPE_DC')}
                      size="s"
                      dataToolTip="top"
                    />
                  ) : (
                    <p className="text-warning mt-2 text-xs font-bold">
                      This audiene's source file is too large to generate a
                      Digital Canvassing audience.
                    </p>
                  )}
                </div>
              )}
            </Row>
          ) : (
            <>
              <Row>
                <ETIcon icon="b2c" />
                {existingB2CJob ? (
                  <ExistingRow
                    existingJob={existingB2CJob}
                    title="IP Targeting: B2C"
                  />
                ) : (
                  <div className="ml-2 leading-4">
                    <p className="font-bold">IP Targeting: B2C</p>
                    <LinkButton
                      text="Add to Library"
                      onClick={() => handleRunNewJob('AUDIENCE_TYPE_B2C')}
                      size="s"
                      dataToolTip="top"
                    />
                  </div>
                )}
              </Row>
              {job.type !== 'AUDIENCE_TYPE_DC' && (
                <Row>
                  <ETIcon icon="b2b" />
                  {existingB2BJob ? (
                    <ExistingRow
                      existingJob={existingB2BJob}
                      title="IP Targeting: B2B"
                    />
                  ) : (
                    <div className="ml-2 leading-4">
                      <p className="font-bold">IP Targeting: B2B</p>
                      <LinkButton
                        text="Add to Library"
                        onClick={() => handleRunNewJob('AUDIENCE_TYPE_B2B')}
                        size="s"
                        dataToolTip="top"
                      />
                    </div>
                  )}
                </Row>
              )}
            </>
          )}

          {/* direct mail- removing for now https://eltoro.testrail.io/index.php?/tests/view/30127 */}
          {/* <Row>
            <ETIcon icon="direct-mail" />
            {existingDirectMailJob ? (
              <ExistingRow
                existingJob={existingDirectMailJob}
                title="Direct Mail"
              />
            ) : (
              <div className="ml-2 leading-4">
                <p className="font-bold">Direct Mail Mailing List</p>
                <LinkButton
                  text="Add to Library"
                  onClick={() => handleRunNewJob('AUDIENCE_TYPE_DIRECTMAIL')}
                  size="s"
                />
              </div>
            )}
          </Row> */}
        </>
      )}
    </div>
  )
}
