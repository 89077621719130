/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* The info about what is in each column in a csv file.
*/
export class Targetorchestratorv2Column {
    /**
    * The ID of the Column.
    */
    'id'?: string;
    /**
    * The ID of the Location this column is associated with.
    */
    'locationId'?: string;
    /**
    * The index of the column in the file. (starts at 0)
    */
    'index'?: number;
    /**
    * The type of data in the column address1: the first line of a mailing address with out any apartment number. address2:the second line or portion of a mailing address such as an apartment or building number. city: the city in which the address is locatedstate: the state in which the address is locatedzip: the 5 digit zipcode in which the address is locatedzip4: the additional 4 digits of a zipcode in which the address is locatedip: an ip addressid: a unique identifier for the rowdeviceid: an id representing a device. usually formatted as a UUIDwkb: a string format representation of geographical and geometrical data such as a polygon. email: an email address phone: a 10 digit string representation of a phone number not including any dashes or white spaces ethash or etHash: the ethash representation of an address delete: specify if you want to delete a column from the file Any other column that you would like to keep can be passed through with 'other' or the value of the header as long as it does not conflict with the reserved types above. 
    */
    'type'?: string;
    /**
    * The actual value in the column header.
    */
    'value'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "locationId",
            "baseName": "location_id",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "index",
            "baseName": "index",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "string",
            "format": ""
        },
        {
            "name": "value",
            "baseName": "value",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Targetorchestratorv2Column.attributeTypeMap;
    }

    public constructor() {
    }
}

