/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GoogletypeMoney } from '../models/GoogletypeMoney';
import { V1AudienceType } from '../models/V1AudienceType';
import { V1DataSource } from '../models/V1DataSource';
import { V1ProductType } from '../models/V1ProductType';
import { HttpFile } from '../http/http';

export class V1HighestCPMAudience {
    'audienceId'?: string;
    'audienceBasePrice'?: GoogletypeMoney;
    'audienceUpcharge'?: GoogletypeMoney;
    'audienceType'?: V1AudienceType;
    'audienceProductType'?: V1ProductType;
    'audienceDataSource'?: V1DataSource;
    'audienceAdditionalDataSources'?: Array<V1DataSource>;
    'timestamp'?: Date;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "audienceId",
            "baseName": "audience_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "audienceBasePrice",
            "baseName": "audience_base_price",
            "type": "GoogletypeMoney",
            "format": ""
        },
        {
            "name": "audienceUpcharge",
            "baseName": "audience_upcharge",
            "type": "GoogletypeMoney",
            "format": ""
        },
        {
            "name": "audienceType",
            "baseName": "audience_type",
            "type": "V1AudienceType",
            "format": ""
        },
        {
            "name": "audienceProductType",
            "baseName": "audience_product_type",
            "type": "V1ProductType",
            "format": ""
        },
        {
            "name": "audienceDataSource",
            "baseName": "audience_data_source",
            "type": "V1DataSource",
            "format": ""
        },
        {
            "name": "audienceAdditionalDataSources",
            "baseName": "audience_additional_data_sources",
            "type": "Array<V1DataSource>",
            "format": ""
        },
        {
            "name": "timestamp",
            "baseName": "timestamp",
            "type": "Date",
            "format": "date-time"
        }    ];

    static getAttributeTypeMap() {
        return V1HighestCPMAudience.attributeTypeMap;
    }

    public constructor() {
    }
}

