import { Fa, TableColumnType } from '@eltoro-ui/components'
import classNames from 'classnames'
import { EmptyField, TableRowLoadingIcon } from 'Components'
import { formatDate } from 'Helpers'
import { PopulatedSubscriptionRow } from './SubscriptionTable'

export const getSubscriptionTableColumns = (partner?: boolean) => {
  const nameColumn: TableColumnType<PopulatedSubscriptionRow> = {
    path: 'name',
    label: 'Subscription Name',
    RowCell: ({ name, locked, hidden }) => (
      <div>
        {locked && (
          <span data-tooltip="right" aria-label="This pixel is locked.">
            <Fa icon="lock" size={1} />
          </span>
        )}
        {hidden && (
          <span data-tooltip="right" aria-label="This pixel is hidden.">
            <Fa icon="user-secret" size={1} />
          </span>
        )}
        {name || 'N/A'}
      </div>
    ),
  }

  const pixelNameColumn: TableColumnType<PopulatedSubscriptionRow> = {
    path: ['pixel', 'name'],
    label: 'Pixel Name',
    RowCell: ({ pixelName, loading }) => {
      if (loading) return <TableRowLoadingIcon />
      return pixelName || <EmptyField />
    },
  }

  const countColumn: TableColumnType<PopulatedSubscriptionRow> = {
    path: '',
    label: 'Count',
    RowCell: ({ latestStats }) =>
      latestStats?.matches === undefined ? <EmptyField /> : latestStats.matches,
  }

  const statusColumn: TableColumnType<PopulatedSubscriptionRow> = {
    path: '',
    label: 'Status',
    RowCell: ({ failId }) => {
      const status = (() => {
        if (failId) return 'Failed'
        return 'Ready'
      })()
      return (
        <div
          className={classNames('max-w-min rounded-full py-1 px-3 font-bold', {
            'bg-danger-50 text-current': status === 'Failed',
            'text-success-500 bg-success-50': status === 'Ready',
          })}
        >
          {status}
        </div>
      )
    },
  }

  const dateCreatedColumn: TableColumnType<PopulatedSubscriptionRow> = {
    path: 'created',
    label: 'Date Created',
    RowCell: (row) => {
      if (!row.created) return <EmptyField />
      return formatDate(row.created)
    },
  }

  const idColumn: TableColumnType<PopulatedSubscriptionRow> = {
    path: 'id',
    label: 'Subscription ID',
  }

  // partner-specific columns
  const customerNameColumn: TableColumnType<PopulatedSubscriptionRow> = {
    path: 'orgName',
    label: 'Customer Name',
    // i think this might only show up on partner only api subscriptions
    RowCell: ({ orgName }) => orgName || <EmptyField />,
  }

  const yesterdayCountColumn: TableColumnType<PopulatedSubscriptionRow> = {
    path: '',
    label: "Yesterday's Counts",
    // TODO: not sure what this is
    RowCell: ({ latestStats }) => <EmptyField />,
  }

  const columns: TableColumnType<PopulatedSubscriptionRow>[] = partner
    ? [
        nameColumn,
        customerNameColumn,
        yesterdayCountColumn,
        statusColumn,
        dateCreatedColumn,
        pixelNameColumn,
      ]
    : [
        nameColumn,
        pixelNameColumn,
        countColumn,
        statusColumn,
        dateCreatedColumn,
        idColumn,
      ]

  return columns
}
