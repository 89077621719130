import { useEffect, useState } from 'react'
import { Fa, Text } from '@eltoro-ui/components'
import classNames from 'classnames'
import { Campaignservicev1OrderLine, V1StatsResponse } from 'next-gen-sdk'
import { useAppContext } from 'Contexts'
import {
  AudienceSelector,
  OrderLineFlightDatesEdit,
  OrderLineBudgetEdit,
  OrderlineAudiences,
} from 'Components'
import {
  getTypeMoneyCost,
  readableCreativeType,
  styleTailwind,
  durationOrDaysLeft,
  getOrderLineSpent,
  formatDate,
} from 'Helpers'
import { OrderLineImpressionsEdit } from '../OrderLineImpressionsEdit'
import './OrderlineStats.scss'
import { dayjs } from 'Tools/dateUtils'

const OrderlineStatsContainer = styleTailwind(
  'div',
  'OrderlineStats__stat-container',
)
const OrderlineStatNumber = styleTailwind(
  Text,
  'OrderlineStats__large-stat text-xl text-tint-gray-600 tracking-tight',
)
const OrderlineStatsDrawer = styleTailwind('div', 'OrderlineStats__drawer')
const EditCircleIcon = styleTailwind(
  'span',
  'EditCircleIcon text-primary circle border-primary border-thin relative ml-auto',
)

export const OrderlineStats = ({
  stats,
  orderline,
  setCurrentOrderLine,
  setRefreshOL,
}: {
  stats?: V1StatsResponse | undefined
  orderline: Campaignservicev1OrderLine
  setCurrentOrderLine: React.Dispatch<
    React.SetStateAction<Campaignservicev1OrderLine>
  >
  setRefreshOL: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const [openDrawer, setOpenDrawer] = useState<
    'budget' | 'days_left' | 'impressions' | 'targets' | null
  >(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { roles, isReadOnly, isAdmin } = useAppContext()

  const jobCountIncluded = (orderline.audiences || []).reduce(
    (acc: number, aud) => {
      if (aud && !aud.exclude) {
        return (aud.matched || 0) + acc
      }
      return acc
    },
    0,
  )
  const jobCountExcluded = (orderline.audiences || []).reduce(
    (acc: number, aud) => {
      if (aud && aud.exclude) {
        return (aud.matched || 0) + acc
      }
      return acc
    },
    0,
  )
  useEffect(() => {
    if (!isOpen && openDrawer !== null) setOpenDrawer(null)
  }, [isOpen, openDrawer])
  const budget = orderline.costRange?.estimate
    ? `${getTypeMoneyCost(orderline.costRange.estimate).toFixed(2)}`
    : 0

  const servedRatio = stats
    ? (
        ((Number(stats?.totals?.imps) || 0) / (orderline.impressions || 1)) *
        100
      ).toFixed()
    : 0

  const disableEdit =
    orderline.locked ||
    (roles && roles.includes('nextgen_read_only')) ||
    (roles && roles?.includes('nextgen_user') && orderline.paid) // if paid for, end user should not be editing

  const prePayDeployDisable =
    !isAdmin &&
    (orderline.prepay || orderline.political) &&
    orderline.status !== 'ORDERLINE_STATUS_DEPLOYED' &&
    orderline.status !== 'ORDERLINE_STATUS_PAUSED' &&
    (orderline.state === 'ORDERLINE_STATE_IN_REVIEW' ||
      orderline.state === 'ORDERLINE_STATE_APPROVED')
  return (
    <div className="OrderlineStats flex flex-col">
      <div className="OrderlineStats__row py-3">
        {/* Budget */}
        <OrderlineStatsContainer
          role="button"
          tabIndex={0}
          disabled={
            roles && roles.includes('nextgen_admin')
              ? false
              : disableEdit || prePayDeployDisable
          }
          aria-label={(() => {
            if (isReadOnly || prePayDeployDisable) {
              return `Budget`
            }
            if (isOpen && openDrawer === 'budget') {
              return 'Click to cancel'
            }
            return 'Click to edit budget'
          })()}
          data-tooltip="top"
          className={classNames({
            'OrderlineStats__stat-container--inactive':
              openDrawer !== null && openDrawer !== 'budget',
            active: openDrawer === 'budget',
            'hover:cursor-not-allowed':
              roles && roles.includes('nextgen_admin')
                ? false
                : disableEdit || prePayDeployDisable,
          })}
          onClick={() => {
            if (
              (roles &&
                !roles.includes('nextgen_admin') &&
                orderline.political) ||
              disableEdit ||
              prePayDeployDisable
            ) {
              return
            }
            setOpenDrawer('budget')
            setIsOpen(!isOpen)
          }}
        >
          <OrderlineStatNumber tag="h4">${budget}</OrderlineStatNumber>
          <p className="text-base-info">Budget</p>
          <span>
            {stats?.totals?.imps !== undefined
              ? getOrderLineSpent(orderline, stats.totals.imps).toFixed(2)
              : '$0.00'}{' '}
            spent
          </span>
          {!isReadOnly && (
            <EditCircleIcon>
              <Fa
                icon={isOpen && openDrawer === 'budget' ? 'times' : 'pencil'}
                size={1}
              />
            </EditCircleIcon>
          )}
        </OrderlineStatsContainer>
        {/* Days left */}
        <OrderlineStatsContainer
          role="button"
          tabIndex={0}
          disabled={orderline.locked || isReadOnly || prePayDeployDisable}
          aria-label={(() => {
            if (isReadOnly || prePayDeployDisable) {
              return 'Duration'
            }
            if (isOpen && openDrawer === 'days_left') {
              return 'Click to cancel'
            }
            return 'Click to edit duration'
          })()}
          data-tooltip="top"
          className={classNames({
            'OrderlineStats__stat-container--inactive':
              openDrawer !== null && openDrawer !== 'days_left',
            active: openDrawer === 'days_left',
            'hover:cursor-not-allowed':
              orderline.locked || isReadOnly || prePayDeployDisable,
          })}
          onClick={() => {
            if (orderline.locked) return
            if (isReadOnly || prePayDeployDisable) return
            setOpenDrawer('days_left')
            setIsOpen(!isOpen)
          }}
        >
          {orderline.endTime && (
            <OrderlineStatNumber tag="h4">
              {durationOrDaysLeft(orderline)}
            </OrderlineStatNumber>
          )}
          <p className="text-base-info">
            {dayjs().tz().isBefore(dayjs.tz(orderline.startTime))
              ? 'Duration'
              : 'Days left'}
          </p>

          <span className="flex flex-col">
            <span>
              {orderline.startTime && formatDate(orderline.startTime)} &ndash;
            </span>
            <span> {orderline.endTime && formatDate(orderline.endTime)}</span>
          </span>

          {!isReadOnly && (
            <EditCircleIcon>
              <Fa
                icon={isOpen && openDrawer === 'days_left' ? 'times' : 'pencil'}
                size={1}
              />
            </EditCircleIcon>
          )}
        </OrderlineStatsContainer>
        {/* Impressions */}
        <OrderlineStatsContainer
          role="button"
          tabIndex={0}
          disabled={
            roles && roles.includes('nextgen_admin')
              ? false
              : disableEdit || prePayDeployDisable
          }
          aria-label={(() => {
            if (isReadOnly || prePayDeployDisable) {
              return `Impressions`
            }
            if (isOpen && openDrawer === 'impressions') {
              return 'Click to cancel'
            }
            return 'Click to edit impressions'
          })()}
          data-tooltip="top"
          className={classNames({
            'OrderlineStats__stat-container--inactive':
              openDrawer !== null && openDrawer !== 'impressions',
            active: openDrawer === 'impressions',
            'hover:cursor-not-allowed':
              roles && roles.includes('nextgen_admin')
                ? false
                : disableEdit || prePayDeployDisable,
          })}
          onClick={() => {
            if (
              (roles &&
                !roles.includes('nextgen_admin') &&
                orderline.political) ||
              disableEdit ||
              prePayDeployDisable
            ) {
              return
            }
            setOpenDrawer('impressions')
            setIsOpen(!isOpen)
          }}
        >
          <OrderlineStatNumber tag="h4">{servedRatio}%</OrderlineStatNumber>
          <p className="text-base-info">Impressions Served</p>
          <span className="flex justify-between gap-1">
            <span className="flex flex-col">
              {stats
                ? `${stats?.totals?.imps || 0} / ${orderline.impressions || 0}`
                : ''}
            </span>
            {!isReadOnly && (
              <EditCircleIcon>
                <Fa
                  icon={
                    isOpen && openDrawer === 'impressions' ? 'times' : 'pencil'
                  }
                  size={1}
                />
              </EditCircleIcon>
            )}
          </span>
        </OrderlineStatsContainer>
        {/* Audiences */}
        <OrderlineStatsContainer
          role="button"
          tabIndex={0}
          disabled={
            isReadOnly ? false : orderline.locked || prePayDeployDisable
          }
          aria-label={(() => {
            if (isReadOnly || prePayDeployDisable) {
              return 'Audiences'
            }
            if (isOpen && openDrawer === 'targets') {
              return 'Click to cancel'
            }
            return 'Click to edit audience'
          })()}
          data-tooltip="top"
          className={classNames({
            'OrderlineStats__stat-container--inactive':
              openDrawer !== null && openDrawer !== 'targets',
            active: openDrawer === 'targets',
            'hover:cursor-not-allowed':
              (orderline.locked && !isReadOnly) || prePayDeployDisable,
          })}
          onClick={() => {
            if (isReadOnly) {
              setOpenDrawer('targets')
              setIsOpen(!isOpen)
            }
            if (prePayDeployDisable) return
            if (orderline.locked) return
            setOpenDrawer('targets')
            setIsOpen(!isOpen)
          }}
        >
          {jobCountIncluded !== undefined ? (
            <OrderlineStatNumber tag="h4">
              {jobCountIncluded.toLocaleString()}
            </OrderlineStatNumber>
          ) : (
            <div className="OrderlineStats__loading">
              <Fa icon="circle-notch" size={2} />
            </div>
          )}
          {jobCountExcluded !== undefined && jobCountExcluded !== 0 ? (
            <small>
              <strong>Excluded:</strong>
              {jobCountExcluded.toLocaleString()}
            </small>
          ) : (
            <></>
          )}
          <p className="text-base-info">Audiences</p>
          <span className="flex justify-between gap-1">
            {orderline.audiences && (
              <OrderlineAudiences
                olTargets={orderline.audiences}
                displayEmptyField={false}
              />
            )}
            {!isReadOnly && (
              <EditCircleIcon>
                <Fa
                  icon={isOpen && openDrawer === 'targets' ? 'times' : 'pencil'}
                  size={1}
                />
              </EditCircleIcon>
            )}
            {isReadOnly && (
              <EditCircleIcon>
                <Fa
                  icon={
                    isOpen && openDrawer === 'targets'
                      ? 'times'
                      : 'magnifying-glass'
                  }
                  size={1}
                />
              </EditCircleIcon>
            )}
          </span>
        </OrderlineStatsContainer>
      </div>
      {/* Edit container */}

      <>
        {openDrawer === 'budget' && isOpen && (
          <OrderlineStatsDrawer>
            <OrderLineBudgetEdit
              orderLine={orderline}
              onUpdateOrderLine={async (updated) => {
                setCurrentOrderLine(updated)
                setOpenDrawer(null)
                // Timeout to refresh because the cost isn't immediately updated
                // I tried using SSE cost event here, but it refreshed the entire page
                setTimeout(() => {
                  setRefreshOL(true)
                }, 1000)
              }}
              onCancel={() => setOpenDrawer(null)}
            />
          </OrderlineStatsDrawer>
        )}
        {openDrawer === 'days_left' && isOpen ? (
          <OrderlineStatsDrawer>
            <OrderLineFlightDatesEdit
              orderLine={orderline}
              onUpdateOrderLine={(updated) => {
                setCurrentOrderLine(updated)
                setOpenDrawer(null)
              }}
              onCancel={() => setOpenDrawer(null)}
            />
          </OrderlineStatsDrawer>
        ) : null}
        {openDrawer === 'impressions' && orderline.creatives && isOpen && (
          <OrderlineStatsDrawer>
            <OrderLineImpressionsEdit
              orderLine={orderline}
              editDeployedOL
              matches={jobCountIncluded || 0}
              orderLineCreativeType={
                orderline.creatives?.[0].type &&
                readableCreativeType(orderline.creatives?.[0].type)
              }
              drawerClose={() => setOpenDrawer(null)}
              onUpdateOrderLine={(updated) => {
                setCurrentOrderLine(updated)
                setOpenDrawer(null)
                setTimeout(() => {
                  setRefreshOL(true)
                }, 1000)
              }}
            />
          </OrderlineStatsDrawer>
        )}
        {openDrawer === 'targets' && isOpen ? (
          <OrderlineStatsDrawer>
            <AudienceSelector
              orderline={orderline}
              refreshOrderLine={() => setRefreshOL(true)}
              onClose={() => setOpenDrawer(null)}
            />
          </OrderlineStatsDrawer>
        ) : null}
      </>
    </div>
  )
}
