/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1Stats } from '../models/V1Stats';
import { V1SubscriptionNote } from '../models/V1SubscriptionNote';
import { HttpFile } from '../http/http';

export class AdvertisingPlatformServiceUpdatePublisherSubscriptionRequest {
    'name'?: string;
    'orgName'?: string;
    'created'?: Date;
    'pixelName'?: string;
    'latestStats'?: V1Stats;
    'enabled'?: boolean;
    'ftpPath'?: string;
    'sendToFileServer'?: boolean;
    'publisherId'?: string;
    /**
    * a list of notes associated with the subscription. notes are managed via the publisher and subscription notes endpoints.
    */
    'notes'?: Array<V1SubscriptionNote>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgName",
            "baseName": "org_name",
            "type": "string",
            "format": ""
        },
        {
            "name": "created",
            "baseName": "created",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "pixelName",
            "baseName": "pixel_name",
            "type": "string",
            "format": ""
        },
        {
            "name": "latestStats",
            "baseName": "latest_stats",
            "type": "V1Stats",
            "format": ""
        },
        {
            "name": "enabled",
            "baseName": "enabled",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "ftpPath",
            "baseName": "ftp_path",
            "type": "string",
            "format": ""
        },
        {
            "name": "sendToFileServer",
            "baseName": "send_to_file_server",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "publisherId",
            "baseName": "publisher_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "notes",
            "baseName": "notes",
            "type": "Array<V1SubscriptionNote>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return AdvertisingPlatformServiceUpdatePublisherSubscriptionRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

