import { useCallback, useEffect, useState } from 'react'
import { Fa, showErrorMessage, Table } from '@eltoro-ui/components'
import { useTokenPagination } from '@eltoro-ui/hooks'
import { Hagridv1Subscription, V1PublisherSubscription } from 'next-gen-sdk'
import { Link, useNavigate } from 'react-router-dom'
import { TablePagination } from 'Components'
import { useAppContext } from 'Contexts'
import { SearchBar } from './SearchBar'
import { getSubscriptionTableColumns } from './getSubscriptionTableColumns'
import { useTableSearch } from './useTableSearch'

export type PopulatedSubscriptionRow = Hagridv1Subscription &
  V1PublisherSubscription & {
    loading: boolean
    pixelName?: string
  }

export const checkIfPublisherSubscription = (
  item: Hagridv1Subscription | V1PublisherSubscription,
): item is V1PublisherSubscription => {
  return !!(item as V1PublisherSubscription).pixelName
}

export const SubscriptionTable = (props: {
  refresh: string
  partner?: boolean
}) => {
  const [populatedRows, setPopulatedRows] = useState<
    PopulatedSubscriptionRow[]
  >([])
  const { webToHomeApi, webToHomePrintPartnerApi, currentOrg } = useAppContext()
  const nav = useNavigate()
  const tableSearchProps = useTableSearch()
  const query = currentOrg?.id ? `?org_id=${currentOrg.id}` : ''

  const { partner, refresh } = props

  const handleFetchSubscriptions = useCallback(
    async ({
      pageSize,
      nextPageToken,
    }: {
      pageSize: number
      nextPageToken?: string
    }) => {
      const empty = {
        data: [],
        totalItems: 0,
      }

      if (!currentOrg?.id || !webToHomeApi) return empty
      setPopulatedRows([])
      try {
        const res = partner
          ? await webToHomePrintPartnerApi?.advertisingPlatformServiceListPublisherSubscriptions(
              currentOrg.id,
              pageSize,
              nextPageToken,
              currentOrg.id,
              'created desc', // newest -> oldest
            )
          : await webToHomeApi?.advertisingPlatformServiceListOrgSubscriptions(
              pageSize,
              nextPageToken,
              currentOrg.id,
              'created desc', // newest -> oldest
            )
        if (res) {
          return {
            data: (res.subscriptions || []).map(
              (sub: Hagridv1Subscription | V1PublisherSubscription) =>
                ({ ...sub, loading: true } as PopulatedSubscriptionRow),
            ),
            totalItems: res.totalSize || 0,
            nextPageToken: res.nextPageToken,
          }
        }
      } catch {
        showErrorMessage('Error fetching your subscriptions', '')
      }

      return empty
    },
    [currentOrg?.id, webToHomeApi, webToHomePrintPartnerApi, partner],
  )

  const pagination = useTokenPagination<PopulatedSubscriptionRow>(
    {
      pageSize: 10,
      fetchData: handleFetchSubscriptions,
    },
    [currentOrg, refresh, partner],
  )

  useEffect(() => {
    let ignore = false
    // populate rows
    const populateRows = async (
      subscriptions: (Hagridv1Subscription | V1PublisherSubscription)[],
    ) => {
      if (currentOrg?.id) {
        const completedRows = await Promise.all(
          subscriptions.map(async (subscription) => {
            if (!currentOrg?.id || !webToHomeApi || !subscription.id)
              return Promise.resolve(subscription)
            let pixelName = 'N/A'

            if (
              checkIfPublisherSubscription(subscription) &&
              subscription.pixelName
            ) {
              pixelName = subscription.pixelName
            }

            if (
              !checkIfPublisherSubscription(subscription) &&
              subscription.pixelId &&
              subscription.orgId
            ) {
              const fullPixel = await webToHomeApi
                ?.advertisingPlatformServiceGetPixel(
                  subscription.pixelId,
                  subscription.orgId,
                )
                .catch((e) => {
                  // if not found, it could be a hidden pixel. skip showing error msg
                  if (e.code !== 404) {
                    showErrorMessage('Error fetching pixel', '')
                  }
                  return undefined
                })
              if (fullPixel?.name) {
                pixelName = fullPixel.name
              }
            }

            return {
              ...subscription,
              pixelName,
              loading: false,
            } as PopulatedSubscriptionRow
          }),
        )
        return completedRows as PopulatedSubscriptionRow[]
      }
      return [] as PopulatedSubscriptionRow[]
    }

    if (pagination.currentPageData) {
      populateRows(pagination.currentPageData).then((newRows) => {
        if (!ignore) setPopulatedRows(newRows)
      })
    }
    return () => {
      ignore = true
    }
  }, [pagination.currentPageData])

  const columns = getSubscriptionTableColumns(partner)

  return (
    <div className="SubscriptionTable">
      {/* TODO: Filtering is not supported in pixel, or we don't have a BFF yet */}
      <SearchBar
        type={`${partner ? 'partner ' : ''}subscriptions`}
        {...tableSearchProps}
      />
      <div className={pagination.loading ? 'animate-pulse opacity-50' : ''}>
        <Table
          className="SubscriptionTable__table"
          columns={columns}
          rows={
            populatedRows.length > 0
              ? populatedRows
              : pagination.currentPageData
          }
          // onSort={() => {}}
          onClick={(row) =>
            (row as Hagridv1Subscription).pixelId && !partner
              ? nav(
                  `pixel/${
                    (row as Hagridv1Subscription).pixelId
                  }/subscription/${row.id}${query}`,
                )
              : nav(
                  `publisher/${row.publisherId}/subscription/${row.id}${query}`,
                )
          }
        />
      </div>
      {!pagination.loading && !pagination.totalItems ? (
        <div className="PixelLibraryPage__empty-state flex flex-col items-center py-6">
          <Fa
            icon="folder"
            size={5}
            type="duotone"
            className="text-primary-200"
          />
          <p className="text-grey-600">
            Your Pixel Library is empty.
            <Link className="LibraryTableLink pl-1" to="/audiences/create">
              Create some now!
            </Link>
          </p>
        </div>
      ) : null}
      {pagination.totalItems > 10 ? (
        <div className="PixelLibraryPage__pagination-footer mb-2">
          <TablePagination pagination={pagination} />
        </div>
      ) : null}
    </div>
  )
}
