// TODO: better import syntax?
import {BaseAPIRequestFactory, RequiredError, COLLECTION_FORMATS} from './baseapi';
import {Configuration} from '../configuration';
import {RequestContext, HttpMethod, ResponseContext, HttpFile} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {canConsumeForm, isCodeInRange} from '../util';
import {SecurityAuthentication} from '../auth/auth';


import { GooglerpcStatus } from '../models/GooglerpcStatus';
import { Job } from '../models/Job';
import { StreamResultOfV1DownloadJobFileResponse } from '../models/StreamResultOfV1DownloadJobFileResponse';
import { V1Job } from '../models/V1Job';

/**
 * no description
 */
export class JobsApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * Create Job
     * @param orgId The Organization where this job will be created.
     * @param job The job to create.
     */
    public async advertisingPlatformServiceCreateJob(orgId: string, job: V1Job, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'orgId' is not null or undefined
        if (orgId === null || orgId === undefined) {
            throw new RequiredError("JobsApi", "advertisingPlatformServiceCreateJob", "orgId");
        }


        // verify required parameter 'job' is not null or undefined
        if (job === null || job === undefined) {
            throw new RequiredError("JobsApi", "advertisingPlatformServiceCreateJob", "job");
        }


        // Path Params
        const localVarPath = '/v1/jobs';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (orgId !== undefined) {
            requestContext.setQueryParam("org_id", ObjectSerializer.serialize(orgId, "string", ""));
        }


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(job, "V1Job", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["OAuth2"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Delete Job
     * @param jobId The ID of the job to delete.
     * @param orgId The Organization where this Job belongs to.
     */
    public async advertisingPlatformServiceDeleteJob(jobId: string, orgId: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'jobId' is not null or undefined
        if (jobId === null || jobId === undefined) {
            throw new RequiredError("JobsApi", "advertisingPlatformServiceDeleteJob", "jobId");
        }


        // verify required parameter 'orgId' is not null or undefined
        if (orgId === null || orgId === undefined) {
            throw new RequiredError("JobsApi", "advertisingPlatformServiceDeleteJob", "orgId");
        }


        // Path Params
        const localVarPath = '/v1/jobs/{job_id}'
            .replace('{' + 'job_id' + '}', encodeURIComponent(String(jobId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.DELETE);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (orgId !== undefined) {
            requestContext.setQueryParam("org_id", ObjectSerializer.serialize(orgId, "string", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["OAuth2"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Download Job File
     * @param jobId The ID of the job.
     * @param jobFileType File type to download.   - JOB_FILE_TYPE_UNKNOWN: Unknown File Type.  - JOB_FILE_TYPE_ANNOTATED: File of the original source file data with a boolean that specifies whether the row was matched or not.  - JOB_FILE_TYPE_SELECTED: File of the ip addresses and ethashs.  - JOB_FILE_TYPE_VALIDATED: File of the validated addresses.  - JOB_FILE_TYPE_RESULTS: File of the results of digital canvas.  - JOB_FILE_TYPE_DIRECT_MAIL: File of direct mail addresses.  - JOB_FILE_TYPE_HOMES: File of the addresses of devices found in venue replay.  - JOB_FILE_TYPE_DEVICES_IN_HOMES_NOT_SEEN: File of the devices found in homes but not found with venue replay.  - JOB_FILE_TYPE_DEVICES_WITH_HOMES: File of the devices with homes found in venue replay.  - JOB_FILE_TYPE_DEVICES_WITHOUT_HOMES: File of the devices without homes found in venue replay.  - JOB_FILE_TYPE_MAIDS: File of MAIDS addresses.  - JOB_FILE_TYPE_ENRICHED: File of Enriched addresses.  - JOB_FILE_TYPE_SALES_MATCHBACK: CSV that joins the ethashed sales file to the compiled audience file on the ethash column.  - JOB_FILE_TYPE_ORDER_LINE_MATCHBACK: JSON file that encompasses the orderline and audience relations for the matchbacking job.  - JOB_FILE_TYPE_ETHASHED_SALES: CSV file that contains the original sales file with the ethash and the match code for each row.  - JOB_FILE_TYPE_COMPILED_AUDIENCE: CSV file that contains the following columns generated by an ethash job: AudienceID, OLID, CurrentlyAttached, Deployed, Served, Excluded, ETHash, Matched, Address, Zip, ServeTimeFrames
     * @param orgId The ID of the target&#39;s org
     */
    public async advertisingPlatformServiceDownloadJobFile(jobId?: string, jobFileType?: 'JOB_FILE_TYPE_UNKNOWN' | 'JOB_FILE_TYPE_ANNOTATED' | 'JOB_FILE_TYPE_SELECTED' | 'JOB_FILE_TYPE_VALIDATED' | 'JOB_FILE_TYPE_RESULTS' | 'JOB_FILE_TYPE_DIRECT_MAIL' | 'JOB_FILE_TYPE_HOMES' | 'JOB_FILE_TYPE_DEVICES_IN_HOMES_NOT_SEEN' | 'JOB_FILE_TYPE_DEVICES_WITH_HOMES' | 'JOB_FILE_TYPE_DEVICES_WITHOUT_HOMES' | 'JOB_FILE_TYPE_MAIDS' | 'JOB_FILE_TYPE_ENRICHED' | 'JOB_FILE_TYPE_SALES_MATCHBACK' | 'JOB_FILE_TYPE_ORDER_LINE_MATCHBACK' | 'JOB_FILE_TYPE_ETHASHED_SALES' | 'JOB_FILE_TYPE_COMPILED_AUDIENCE', orgId?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;




        // Path Params
        const localVarPath = '/v1/jobs:downloadFile';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (jobId !== undefined) {
            requestContext.setQueryParam("job_id", ObjectSerializer.serialize(jobId, "string", ""));
        }

        // Query Params
        if (jobFileType !== undefined) {
            requestContext.setQueryParam("job_file_type", ObjectSerializer.serialize(jobFileType, "'JOB_FILE_TYPE_UNKNOWN' | 'JOB_FILE_TYPE_ANNOTATED' | 'JOB_FILE_TYPE_SELECTED' | 'JOB_FILE_TYPE_VALIDATED' | 'JOB_FILE_TYPE_RESULTS' | 'JOB_FILE_TYPE_DIRECT_MAIL' | 'JOB_FILE_TYPE_HOMES' | 'JOB_FILE_TYPE_DEVICES_IN_HOMES_NOT_SEEN' | 'JOB_FILE_TYPE_DEVICES_WITH_HOMES' | 'JOB_FILE_TYPE_DEVICES_WITHOUT_HOMES' | 'JOB_FILE_TYPE_MAIDS' | 'JOB_FILE_TYPE_ENRICHED' | 'JOB_FILE_TYPE_SALES_MATCHBACK' | 'JOB_FILE_TYPE_ORDER_LINE_MATCHBACK' | 'JOB_FILE_TYPE_ETHASHED_SALES' | 'JOB_FILE_TYPE_COMPILED_AUDIENCE'", ""));
        }

        // Query Params
        if (orgId !== undefined) {
            requestContext.setQueryParam("org_id", ObjectSerializer.serialize(orgId, "string", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["OAuth2"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Get Job
     * @param jobId The ID of the job to retrieve.
     * @param orgId The Organization where this Job belongs to.
     */
    public async advertisingPlatformServiceGetJob(jobId: string, orgId: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'jobId' is not null or undefined
        if (jobId === null || jobId === undefined) {
            throw new RequiredError("JobsApi", "advertisingPlatformServiceGetJob", "jobId");
        }


        // verify required parameter 'orgId' is not null or undefined
        if (orgId === null || orgId === undefined) {
            throw new RequiredError("JobsApi", "advertisingPlatformServiceGetJob", "orgId");
        }


        // Path Params
        const localVarPath = '/v1/jobs/{job_id}'
            .replace('{' + 'job_id' + '}', encodeURIComponent(String(jobId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (orgId !== undefined) {
            requestContext.setQueryParam("org_id", ObjectSerializer.serialize(orgId, "string", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["OAuth2"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Run Job
     * @param jobId The ID of the Job.
     * @param orgId The ID of the Org associated to the Job.
     * @param version (OPTIONAL) The version of the target file to use. If empty, the most recent version is used.
     * @param queryId (OPTIONAL) Additional field you might need in order to run a job. The only one that uses this currently is the enrichment job.
     * @param seenCount (OPTIONAL) Additional field you might need in order to run a job. The only one that uses this currently is the enrichment job.
     * @param locType (OPTIONAL) Additional field you might need in order to run a job. The only one that uses this currently is the enrichment job.
     * @param radius (OPTIONAL) Additional field you might need in order to run a job. The only one that could use this currently is the digital canvassing job.
     * @param queryType (OPTIONAL) Additional field you might need in order to run a job. The only one that could use this currently is the digital canvassing job.
     * @param queryArgs (OPTIONAL) Additional field you might need in order to run a job.
     */
    public async advertisingPlatformServiceRunJob(jobId?: string, orgId?: string, version?: number, queryId?: string, seenCount?: number, locType?: string, radius?: number, queryType?: string, queryArgs?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;










        // Path Params
        const localVarPath = '/v1/jobs:run';

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (jobId !== undefined) {
            requestContext.setQueryParam("job_id", ObjectSerializer.serialize(jobId, "string", ""));
        }

        // Query Params
        if (orgId !== undefined) {
            requestContext.setQueryParam("org_id", ObjectSerializer.serialize(orgId, "string", ""));
        }

        // Query Params
        if (version !== undefined) {
            requestContext.setQueryParam("version", ObjectSerializer.serialize(version, "number", "int32"));
        }

        // Query Params
        if (queryId !== undefined) {
            requestContext.setQueryParam("query_id", ObjectSerializer.serialize(queryId, "string", ""));
        }

        // Query Params
        if (seenCount !== undefined) {
            requestContext.setQueryParam("seen_count", ObjectSerializer.serialize(seenCount, "number", "int32"));
        }

        // Query Params
        if (locType !== undefined) {
            requestContext.setQueryParam("loc_type", ObjectSerializer.serialize(locType, "string", ""));
        }

        // Query Params
        if (radius !== undefined) {
            requestContext.setQueryParam("radius", ObjectSerializer.serialize(radius, "number", "double"));
        }

        // Query Params
        if (queryType !== undefined) {
            requestContext.setQueryParam("query_type", ObjectSerializer.serialize(queryType, "string", ""));
        }

        // Query Params
        if (queryArgs !== undefined) {
            requestContext.setQueryParam("query_args", ObjectSerializer.serialize(queryArgs, "string", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["OAuth2"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Update Job
     * @param jobId The Job ID.
     * @param job Job  Job holds info about a specific Job.
     * @param updateMask 
     * @param orgId 
     */
    public async advertisingPlatformServiceUpdateJob(jobId: string, job: Job, updateMask?: string, orgId?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'jobId' is not null or undefined
        if (jobId === null || jobId === undefined) {
            throw new RequiredError("JobsApi", "advertisingPlatformServiceUpdateJob", "jobId");
        }


        // verify required parameter 'job' is not null or undefined
        if (job === null || job === undefined) {
            throw new RequiredError("JobsApi", "advertisingPlatformServiceUpdateJob", "job");
        }




        // Path Params
        const localVarPath = '/v1/jobs/{job.id}'
            .replace('{' + 'job.id' + '}', encodeURIComponent(String(jobId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.PATCH);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (updateMask !== undefined) {
            requestContext.setQueryParam("updateMask", ObjectSerializer.serialize(updateMask, "string", ""));
        }

        // Query Params
        if (orgId !== undefined) {
            requestContext.setQueryParam("orgId", ObjectSerializer.serialize(orgId, "string", ""));
        }


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(job, "Job", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["OAuth2"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

}

export class JobsApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to advertisingPlatformServiceCreateJob
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async advertisingPlatformServiceCreateJob(response: ResponseContext): Promise<V1Job > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: V1Job = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "V1Job", ""
            ) as V1Job;
            return body;
        }
        if (isCodeInRange("403", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Returned when the user does not have permission to access the resource.", body, response.headers);
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: string = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "string", ""
            ) as string;
            throw new ApiException<string>(response.httpStatusCode, "Returned when the resource does not exist.", body, response.headers);
        }
        if (isCodeInRange("500", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Server error", body, response.headers);
        }
        if (isCodeInRange("0", response.httpStatusCode)) {
            const body: GooglerpcStatus = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "GooglerpcStatus", ""
            ) as GooglerpcStatus;
            throw new ApiException<GooglerpcStatus>(response.httpStatusCode, "An unexpected error response.", body, response.headers);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: V1Job = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "V1Job", ""
            ) as V1Job;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to advertisingPlatformServiceDeleteJob
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async advertisingPlatformServiceDeleteJob(response: ResponseContext): Promise<any > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            return body;
        }
        if (isCodeInRange("403", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Returned when the user does not have permission to access the resource.", body, response.headers);
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: string = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "string", ""
            ) as string;
            throw new ApiException<string>(response.httpStatusCode, "Returned when the resource does not exist.", body, response.headers);
        }
        if (isCodeInRange("500", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Server error", body, response.headers);
        }
        if (isCodeInRange("0", response.httpStatusCode)) {
            const body: GooglerpcStatus = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "GooglerpcStatus", ""
            ) as GooglerpcStatus;
            throw new ApiException<GooglerpcStatus>(response.httpStatusCode, "An unexpected error response.", body, response.headers);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to advertisingPlatformServiceDownloadJobFile
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async advertisingPlatformServiceDownloadJobFile(response: ResponseContext): Promise<StreamResultOfV1DownloadJobFileResponse > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: StreamResultOfV1DownloadJobFileResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "StreamResultOfV1DownloadJobFileResponse", ""
            ) as StreamResultOfV1DownloadJobFileResponse;
            return body;
        }
        if (isCodeInRange("403", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Returned when the user does not have permission to access the resource.", body, response.headers);
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: string = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "string", ""
            ) as string;
            throw new ApiException<string>(response.httpStatusCode, "Returned when the resource does not exist.", body, response.headers);
        }
        if (isCodeInRange("500", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Server error", body, response.headers);
        }
        if (isCodeInRange("0", response.httpStatusCode)) {
            const body: GooglerpcStatus = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "GooglerpcStatus", ""
            ) as GooglerpcStatus;
            throw new ApiException<GooglerpcStatus>(response.httpStatusCode, "An unexpected error response.", body, response.headers);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: StreamResultOfV1DownloadJobFileResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "StreamResultOfV1DownloadJobFileResponse", ""
            ) as StreamResultOfV1DownloadJobFileResponse;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to advertisingPlatformServiceGetJob
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async advertisingPlatformServiceGetJob(response: ResponseContext): Promise<V1Job > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: V1Job = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "V1Job", ""
            ) as V1Job;
            return body;
        }
        if (isCodeInRange("403", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Returned when the user does not have permission to access the resource.", body, response.headers);
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: string = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "string", ""
            ) as string;
            throw new ApiException<string>(response.httpStatusCode, "Returned when the resource does not exist.", body, response.headers);
        }
        if (isCodeInRange("500", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Server error", body, response.headers);
        }
        if (isCodeInRange("0", response.httpStatusCode)) {
            const body: GooglerpcStatus = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "GooglerpcStatus", ""
            ) as GooglerpcStatus;
            throw new ApiException<GooglerpcStatus>(response.httpStatusCode, "An unexpected error response.", body, response.headers);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: V1Job = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "V1Job", ""
            ) as V1Job;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to advertisingPlatformServiceRunJob
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async advertisingPlatformServiceRunJob(response: ResponseContext): Promise<any > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            return body;
        }
        if (isCodeInRange("403", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Returned when the user does not have permission to access the resource.", body, response.headers);
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: string = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "string", ""
            ) as string;
            throw new ApiException<string>(response.httpStatusCode, "Returned when the resource does not exist.", body, response.headers);
        }
        if (isCodeInRange("500", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Server error", body, response.headers);
        }
        if (isCodeInRange("0", response.httpStatusCode)) {
            const body: GooglerpcStatus = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "GooglerpcStatus", ""
            ) as GooglerpcStatus;
            throw new ApiException<GooglerpcStatus>(response.httpStatusCode, "An unexpected error response.", body, response.headers);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to advertisingPlatformServiceUpdateJob
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async advertisingPlatformServiceUpdateJob(response: ResponseContext): Promise<V1Job > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: V1Job = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "V1Job", ""
            ) as V1Job;
            return body;
        }
        if (isCodeInRange("403", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Returned when the user does not have permission to access the resource.", body, response.headers);
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: string = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "string", ""
            ) as string;
            throw new ApiException<string>(response.httpStatusCode, "Returned when the resource does not exist.", body, response.headers);
        }
        if (isCodeInRange("500", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Server error", body, response.headers);
        }
        if (isCodeInRange("0", response.httpStatusCode)) {
            const body: GooglerpcStatus = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "GooglerpcStatus", ""
            ) as GooglerpcStatus;
            throw new ApiException<GooglerpcStatus>(response.httpStatusCode, "An unexpected error response.", body, response.headers);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: V1Job = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "V1Job", ""
            ) as V1Job;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

}
