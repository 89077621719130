/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* See [Budget scheduling settings] https://learn.microsoft.com/en-us/xandr/digital-platform-api/line-item-service---ali#budget-scheduling-settings in Xandr's documentation
*/
export class V3BudgetSchedulingSettings {
    'underspendCatchupType'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "underspendCatchupType",
            "baseName": "underspend_catchup_type",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3BudgetSchedulingSettings.attributeTypeMap;
    }

    public constructor() {
    }
}

