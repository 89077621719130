import { useEffect, useState } from 'react'
import {
  Button,
  showErrorMessage,
  showSuccessMessage,
  showWarningMessage,
  TextHeader,
} from '@eltoro-ui/components'
import { Campaignservicev1OrderLine, Orgmanagerv1Org } from 'next-gen-sdk'
import { PriceTextInput } from 'Components'
import { useAppContext } from 'Contexts'
import { styleTailwind, getTypeMoneyCost, useOrderLineCost } from 'Helpers'
import { IMPRESSION_MAX } from 'Components/OrderLineEditWrapper/components/shared'

const WarningText = styleTailwind(
  'p',
  'WarningText text-warning flex py-4 !text-xs font-bold',
)

export const OrderLineBudgetEdit: React.FC<{
  orderLine: Campaignservicev1OrderLine
  onUpdateOrderLine: (updated: Campaignservicev1OrderLine) => void
  onCancel: () => void
}> = ({ orderLine, onUpdateOrderLine, onCancel }) => {
  const {
    currentOrg,
    campaignServiceApi,
    roles,
    orgServiceApi,
  } = useAppContext()
  const [input, setInput] = useState<number>(
    getTypeMoneyCost(orderLine?.costRange?.estimate || {}),
  )
  const [org, setOrg] = useState<Orgmanagerv1Org>()
  const [showBelowLimitWarning, setLimitWarning] = useState<boolean>(false)

  // TODO: There will be restrictions based on if OL is prepay
  // also this should look at the order line's min impressions
  // OL min imps || org min imps || default
  const { minCost, maxCost } = useOrderLineCost(
    orderLine,
    orderLine?.minimumImpressions || 50000,
    IMPRESSION_MAX,
    org || {},
  )
  useEffect(() => {
    if (
      currentOrg?.id &&
      orderLine.orgId &&
      currentOrg.id !== orderLine.orgId
    ) {
      orgServiceApi
        ?.advertisingPlatformServiceGetOrg(orderLine.orgId)
        .then((res) => setOrg(res))
      return
    }
    setOrg(currentOrg)
  }, [orgServiceApi, orderLine, currentOrg])

  const cpmCost = !orderLine.cpm ? 0 : getTypeMoneyCost(orderLine.cpm)
  const handleOnSave = () => {
    const value = Number(input)
    const newUnits: Number = value - (value % 1)
    const newNanos = Math.round(+(value % 1) * 10 ** 9)
    const costRange = {
      estimate: {
        units: newUnits.toString(),
        nanos: newNanos,
      },
    }
    // cost = (imps/1000) * cpm
    const minImps = (value / cpmCost) * 1000
    if (!orderLine.id || !campaignServiceApi || !orderLine.orgId) return
    if (
      orderLine.minimumImpressions &&
      roles &&
      roles.includes('nextgen_admin')
    ) {
      return campaignServiceApi
        .advertisingPlatformServiceUpdateOrderLine(
          orderLine.id,
          orderLine?.orgId,
          {
            minimumImpressions: Math.round(minImps),
            costRange,
          },
        )
        .then((updated) => {
          showSuccessMessage(
            'The Budget has been successfully updated',
            `The budget is now $${input} `,
          )
          onUpdateOrderLine(updated)
        })
        .catch((err) => {
          if (err) {
            showErrorMessage(
              'The Budget update failed',
              'Please try again, or contact support',
            )
          }
        })
    }
    campaignServiceApi
      .advertisingPlatformServiceUpdateOrderLine(
        orderLine.id,
        orderLine?.orgId,
        {
          costRange,
        },
      )
      .then((updated) => {
        showSuccessMessage(
          'The Budget has been successfully updated',
          `The budget is now $${input} `,
        )
        onUpdateOrderLine(updated)
      })
      .catch((err) => {
        if (err) {
          showErrorMessage(
            'The Budget update failed',
            'Please try again, or contact support',
          )
        }
      })
  }
  return (
    <div className="OrderLineBudgetEdit">
      <TextHeader className="border-b-thin border-primary mb-6 pb-2" type={4}>
        Edit budget
      </TextHeader>
      {cpmCost ? (
        <>
          <div className="OrderLineBudgetEdit__main flex items-center gap-2">
            <p>
              <strong>Adjust approximate budget:</strong> Budget at ~$
              {cpmCost.toFixed(2)} CPM
            </p>
            <PriceTextInput
              value={input}
              doNotResetOnBlur
              onChange={(val) => {
                if (
                  val < Number(minCost.replace(/,/g, '')) &&
                  roles?.includes('nextgen_admin')
                ) {
                  if (!showBelowLimitWarning) {
                    showWarningMessage(
                      'Below Minimum Budget',
                      `You have set the budget to lower than the minimum of ${minCost}`,
                    )
                  }
                  setLimitWarning(true)
                  setInput(val)
                  return
                }
                if (
                  val < Number(minCost.replace(/,/g, '')) &&
                  !roles?.includes('nextgen_admin')
                ) {
                  showWarningMessage(
                    'Unable to update order line',
                    `You may not set the budget to lower than ${minCost}`,
                  )
                  setInput(val)
                  setTimeout(() => {
                    setInput(Number(minCost.replace(/,/g, '')))
                  }, 8000)
                  return
                }
                if (val > Number(minCost.replace(/,/g, ''))) {
                  setLimitWarning(false)
                  setInput(val)
                  return
                }
                return
              }}
              min={
                roles?.includes('nextgen_admin')
                  ? 1
                  : Number(minCost.replace(/,/g, ''))
              }
              max={
                process.env.NODE_ENV === 'development'
                  ? Number(maxCost)
                  : undefined
              }
            />
          </div>
          <WarningText className="OrderLineBudgetEdit__disclaimer">
            {showBelowLimitWarning || input < Number(minCost.replace(/,/g, ''))
              ? `The budget for this order line is now below the minimum of ${minCost}`
              : ''}
          </WarningText>
        </>
      ) : (
        <WarningText>Missing CPM cost for this order line</WarningText>
      )}
      <div className="OrderLineBudgetEdit__footer-buttons my-2 flex items-center justify-end gap-2">
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          kind="primary"
          onClick={handleOnSave}
          disabled={
            (roles?.includes('nextgen_admin')
              ? false
              : input < Number(minCost.replace(/,/g, ''))) || !cpmCost
          }
        >
          Save
        </Button>
      </div>
    </div>
  )
}
