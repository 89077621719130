import { useEffect, useState } from 'react'
import { SSEMessageType } from 'types'
import { Event } from '../types/events'
import { useAuth } from 'react-oidc-context'

// This hook provides an event listener for events dispatched from SSEContext and holds the latest event in state
// NOTE: please consider useSSEEventReactor instead of this solution, would like to remove this hook in the future
export const useSSEListener = (
  event: Event,
  options?: {
    limitToUser?: boolean
  },
) => {
  const [currentSSEMessage, setCurrentSSEMessage] = useState<SSEMessageType>()
  const auth = useAuth()

  const handleClearCurrentMessage = () => {
    setCurrentSSEMessage(undefined)
  }

  useEffect(() => {
    const handleEvent: EventListener = ((e: CustomEvent<SSEMessageType>) => {
      if (
        options?.limitToUser &&
        e.detail.message.user_id !== auth.user?.profile.sub
      ) {
        // let VR through
        if (e.detail.message.user_id !== 'onspot') return
      }
      // do not let child VR finished through
      if (
        e.detail.eventType === 'JobFinished' &&
        e.detail.message.user_id === 'onspot' &&
        (e.detail.message.audience.sub_type === 1 ||
          e.detail.message.audience.sub_type === 3 ||
          e.detail.message.audience.sub_type === 5 ||
          e.detail.message.audience.sub_type === 7)
      )
        return
      setCurrentSSEMessage(e.detail)
    }) as EventListener

    window.addEventListener(event, handleEvent)
    return () => {
      window.removeEventListener(event, handleEvent)
    }
  }, [event, auth.user?.profile.sub, options?.limitToUser])

  return [currentSSEMessage, handleClearCurrentMessage] as const
}
