import { useEffect, useState } from 'react'
import {
  TextHeader,
  Button,
  showErrorMessage,
  showSuccessMessage,
  triggerPrompt,
  Fa,
  showWarningMessage,
} from '@eltoro-ui/components'
import {
  Campaignservicev1Audience,
  Campaignservicev1OrderLine,
  Targetjobservicev1Audience,
  V1AudienceStatus,
  V1AudienceSubType,
  V1AudienceType,
  V1DataProduct,
  V1ProductType,
} from 'next-gen-sdk'
import { useAppContext } from 'Contexts'
import { EditOLAudiences, WarningModal, LinkButton } from 'Components'
import {
  checkIfJobIsAttached,
  DetermineIfAllowedToAttachAudience,
  getJobCounts,
  getProductTypes,
  styleTailwind,
  userFriendlyAudienceName,
} from 'Helpers'
import { AudienceRow } from './components'
import { getFullJobs } from 'Requests'
import classNames from 'classnames'

export type AudienceRowType = {
  id?: string
  audienceId?: string
  attached: boolean
  excluded: boolean
  matched?: number
  type?: V1AudienceType
  subType?: V1AudienceSubType
  name?: string
  subJobs?: AudienceRowType[]
  quoted?: boolean
  status?: V1AudienceStatus
  locked?: boolean
  productType?: V1ProductType
  dataProduct?: V1DataProduct
}

const ColumnHeader = styleTailwind(
  'div',
  'AudienceSelector__Audiences-column-header font-bold',
)

// A minimum of 30000 devices matched and selected are required to unselect matched homes
// This checks if the checkbox for the mapped homes job is disabled or not
// https://eltorocorp.atlassian.net/wiki/spaces/PNG/pages/1874460991/Portal+Business+Rules#Order-Lines
export const checkIfMappedHomesIsDisabled = (parentJob: AudienceRowType) => {
  const MIN_DEVICES = 30000
  const totalDevicesOnOtherDevices = (parentJob.subJobs || []).reduce(
    (acc: number, subJob) => {
      if (subJob.subType === 'AUDIENCE_SUB_TYPE_HOMES' || !subJob.matched)
        return acc
      return acc + subJob.matched
    },
    0,
  )
  return totalDevicesOnOtherDevices < MIN_DEVICES
}

// Updates a job in its place in the array instead of appending to the end
const updateJobInPlace = (
  array: AudienceRowType[],
  update: AudienceRowType,
) => {
  const prevIndex = array.findIndex((item) => item.id === update.id)
  if (prevIndex !== undefined && prevIndex >= 0) {
    return [...array.slice(0, prevIndex), update, ...array.slice(prevIndex + 1)]
  }
  return array
}

type SplitAudiences = {
  audiencesToRemove: string[] // string of audience ids
  audiencesToAdd: { id: string; exclude: boolean }[]
  audiencesToInclude: { id: string; exclude: boolean }[]
  audiencesToExclude: { id: string; exclude: boolean }[]
}

export const AudienceSelector: React.FC<{
  orderline: Campaignservicev1OrderLine
  onClose: () => void
  refreshOrderLine: () => void
}> = ({ orderline, onClose, refreshOrderLine }) => {
  const [loading, setLoading] = useState(false)
  const [showTargetModal, setShowTargetModal] = useState<boolean>(false)
  const [targetModalStart, setTargetModalStart] = useState<
    'library' | 'create'
  >()
  const [showDetachWarning, setShowDetachWarning] = useState<string>()
  const [audienceRows, setAudienceRows] = useState<AudienceRowType[]>()
  const {
    campaignServiceApi,
    audienceServiceApi,
    roles,
    isReadOnly,
  } = useAppContext()

  const handleSaveChanges = () => {
    if (!orderline.id || !orderline.orgId || !campaignServiceApi) return
    setLoading(true)

    const audiences = (audienceRows || []).reduce(
      (acc: AudienceRowType[], current) => {
        if (current.type === 'AUDIENCE_TYPE_VR' && current.subJobs)
          return [...acc, ...current.subJobs]
        return [...acc, current]
      },
      [],
    )
    const olCopy = Object.assign({}, orderline)
    let error = false
    let highestCPMProdType: V1ProductType | undefined = undefined
    let warning = false
    let warningAdminCombo: V1ProductType | undefined = undefined
    const {
      audiencesToRemove,
      audiencesToAdd,
      audiencesToInclude,
      audiencesToExclude,
    }: SplitAudiences = audiences.reduce(
      (acc: SplitAudiences, current) => {
        if (!current.id) return acc
        const isAlreadyAttached = checkIfJobIsAttached(orderline, current.id)
        if (isAlreadyAttached) {
          const olAudienceWithExcludeOrInclude = orderline?.audiences?.find(
            (olAud) => {
              return olAud.id === current.id
            },
          )
          if (current.excluded && !olAudienceWithExcludeOrInclude?.exclude) {
            return {
              ...acc,
              audiencesToRemove: [...acc.audiencesToRemove, current.id],
              audiencesToExclude: [
                ...acc.audiencesToExclude,
                { id: current.id, exclude: current.excluded },
              ],
            }
          }
          if (!current.excluded && olAudienceWithExcludeOrInclude?.exclude) {
            return {
              ...acc,
              audiencesToRemove: [...acc.audiencesToRemove, current.id],
              audiencesToInclude: [
                ...acc.audiencesToInclude,
                { id: current.id, exclude: current.excluded },
              ],
            }
          }
        }
        if (current.attached && !isAlreadyAttached)
          return {
            ...acc,
            audiencesToAdd: [
              ...acc.audiencesToAdd,
              { id: current.id, exclude: false },
            ],
          }
        if (!current.attached && isAlreadyAttached) {
          const audsIfDetached =
            olCopy.audiences?.filter((aud) => aud.id !== current?.id) || []
          const productsIfDetached = getProductTypes(audsIfDetached)
          if (
            orderline?.highestCpmAudience &&
            !productsIfDetached.includes(
              orderline?.highestCpmAudience?.audienceProductType,
            )
          ) {
            if (orderline.status !== 'ORDERLINE_STATUS_DRAFT') {
              error = true
              highestCPMProdType = current.productType
              current.attached = true
              return acc
            }
            return {
              ...acc,
              audiencesToRemove: [...acc.audiencesToRemove, current.id],
            }
          }
          if (
            !DetermineIfAllowedToAttachAudience(
              audsIfDetached,
              current,
              roles?.includes('nextgen_admin'),
            )
          ) {
            warning = true
            warningAdminCombo = current.productType
            olCopy.audiences = olCopy.audiences?.filter(
              (x) => x.id !== current.id,
            )
            current.attached = true
            if (current.productType === 'PRODUCT_TYPE_VENUE_REPLAY') {
              setAudienceRows(
                audienceRows?.map((currentRow) => {
                  if (
                    current.audienceId === currentRow.id &&
                    currentRow.subJobs
                  ) {
                    currentRow.subJobs.map((c) => (c.attached = true))
                    currentRow.attached = true
                  }
                  return currentRow
                }),
              )
            }
            return acc
          }
          olCopy.audiences = olCopy.audiences?.filter(
            (x) => x.id !== current.id,
          )
          return {
            ...acc,
            audiencesToRemove: [...acc.audiencesToRemove, current.id],
          }
        }
        return acc
      },
      {
        audiencesToRemove: [],
        audiencesToAdd: [],
        audiencesToInclude: [],
        audiencesToExclude: [],
      },
    )
    if (error) {
      setLoading(false)
      showWarningMessage(
        'Unable to detach',
        <>
          <p className="flex flex-col gap-3 text-sm">
            Unable to remove the last{' '}
            {userFriendlyAudienceName(highestCPMProdType || '')} audience,
            Please attach another &nbsp;
            {userFriendlyAudienceName(highestCPMProdType || '')} audience before
            removing this audience
          </p>
        </>,
      )
      return
    }
    if (warning) {
      setLoading(false)
      showWarningMessage(
        'Unable to detach',
        <>
          <p className="flex flex-col gap-3 text-sm">
            Removing this audience will remove an admin only audience
            combination, Please attach another &nbsp;
            {userFriendlyAudienceName(warningAdminCombo || '')} before removing
            this audience
          </p>
        </>,
      )
      return
    }
    if (
      audiencesToAdd.length === 0 &&
      audiencesToRemove.length === 0 &&
      audiencesToExclude.length === 0 &&
      audiencesToInclude.length === 0
    ) {
      showErrorMessage('No changes to save', '')
      return
    }
    Promise.allSettled([
      ...(audiencesToAdd.length > 0
        ? [
            campaignServiceApi.advertisingPlatformServiceBatchAddAudiences(
              orderline.id,
              {
                orgId: orderline.orgId,
                audiences: audiencesToAdd,
              },
            ),
          ]
        : []),
      ...(audiencesToRemove.length > 0
        ? [
            campaignServiceApi.advertisingPlatformServiceBatchRemoveAudiences(
              orderline.id,
              {
                orgId: orderline.orgId,
                audienceIds: audiencesToRemove,
              },
            ),
          ]
        : []),
    ])
      .then(() => {
        if (!orderline.id) return
        Promise.allSettled([
          ...(audiencesToInclude.length > 0
            ? [
                campaignServiceApi.advertisingPlatformServiceBatchAddAudiences(
                  orderline.id,
                  {
                    orgId: orderline.orgId,
                    audiences: audiencesToInclude,
                  },
                ),
              ]
            : []),
          ...(audiencesToExclude.length > 0
            ? [
                campaignServiceApi.advertisingPlatformServiceBatchAddAudiences(
                  orderline.id,
                  {
                    orgId: orderline.orgId,
                    audiences: audiencesToExclude,
                  },
                ),
              ]
            : []),
        ])
          .then(() => {
            refreshOrderLine()
            onClose()
          })
          .then(() => {
            showSuccessMessage(
              'Your order line audiences have been updated',
              '',
            )
          })
          .catch((e) =>
            showErrorMessage(
              "Error updating this order line's audiences",
              e?.message || e?.body?.message || '',
            ),
          )
          .finally(() => {
            setLoading(false)
          })
      })
      .then(() => {
        if (
          audiencesToExclude.length === 0 &&
          audiencesToInclude.length === 0
        ) {
          showSuccessMessage('Your order line audiences have been updated', '')
        }
      })
      .then(() => {
        if (
          audiencesToExclude.length === 0 &&
          audiencesToInclude.length === 0
        ) {
          refreshOrderLine()
          onClose()
        }
      })
      .catch((e) =>
        showErrorMessage(
          "Error updating this order line's audiences",
          e?.message || e?.body?.message || '',
        ),
      )
      .finally(() => {
        if (
          audiencesToExclude.length === 0 &&
          audiencesToInclude.length === 0
        ) {
          setLoading(false)
        }
      })
  }

  const allDetached = audienceRows?.every((row) => row.attached === false)
  const shouldExcludeBeDisabled =
    orderline && orderline.audiences && orderline.audiences?.length <= 1

  const totalAudiences =
    audienceRows?.reduce((mainAcc, job) => {
      if (job.type === 'AUDIENCE_TYPE_RETARGETING') return mainAcc
      if (job.type === 'AUDIENCE_TYPE_VR' && job.subJobs) {
        // count the attached sub jobs instead
        const subJobCount = job.subJobs.reduce((acc, j) => {
          return acc + (j.matched || 0)
        }, 0)
        return mainAcc + subJobCount
      }
      return mainAcc + (job.matched || 0)
    }, 0) || 0

  const totalAudiencesToRemove = (audienceRows || []).reduce((acc, row) => {
    if (
      !row.attached &&
      row.matched &&
      row.type !== 'AUDIENCE_TYPE_RETARGETING'
    ) {
      return acc + (row.matched || 0)
    }
    return acc
  }, 0)

  const handleRowOnChange = (
    jobToChange: AudienceRowType,
    attached: boolean,
    exclude?: boolean,
  ) => {
    // If the job to change is VR, then update the sub jobs
    if (jobToChange.type === 'AUDIENCE_TYPE_VR' && jobToChange.subJobs) {
      const VRParentRow: AudienceRowType = {
        ...jobToChange,
        attached,
        excluded: exclude || false,
        subJobs: jobToChange.subJobs.map((subJob) => {
          return {
            ...subJob,
            attached,
            excluded: exclude || false,
          }
        }),
      }

      setAudienceRows((prev) => updateJobInPlace(prev || [], VRParentRow))
    } else if (jobToChange.audienceId) {
      // If job to change is a VR sub job
      setAudienceRows((prev) => {
        const parent = prev?.find((row) => row.id === jobToChange.audienceId)
        let subJobs: AudienceRowType[] = parent?.subJobs || []
        if (
          jobToChange.subType !== 'AUDIENCE_SUB_TYPE_HOMES' &&
          parent?.subJobs?.every((job) => !job.attached) &&
          checkIfMappedHomesIsDisabled(parent)
        ) {
          const homesJob = subJobs.find(
            (subJob) => subJob.subType === 'AUDIENCE_SUB_TYPE_HOMES',
          )
          // if all were detached and the user attached any of the other sub jobs, then re-attach the mapped homes address job,
          // because it cannot be detached when all device sub jobs on the VR are < 30000
          // (this covers the case where a user could deselect all sub jobs when deselecting the parent. if the user adds any of the other
          // sub jobs back, the homes job will auto-attach again.)
          if (homesJob) {
            subJobs = updateJobInPlace(subJobs, {
              ...homesJob,
              attached: true,
              excluded: exclude || false,
            })
          }
        }
        // if deselecting mapped homes is not disabled, then just update the job/sub jobs attach status
        subJobs = updateJobInPlace(subJobs, {
          ...jobToChange,
          attached,
          excluded: exclude || false,
        })

        const parentAttached = !!subJobs?.some((subJob) => subJob.attached)

        return updateJobInPlace(prev || [], {
          ...parent,
          attached: parentAttached,
          excluded: subJobs.every((subJob) => {
            return subJob.excluded
          }),
          subJobs,
        })
      })
    } else {
      // if not VR, update the attach status of the job
      setAudienceRows((prev) =>
        updateJobInPlace(prev || [], {
          ...jobToChange,
          attached,
          excluded: exclude || false,
        }),
      )
    }
  }

  // Order line limits via this document:
  // https://eltorocorp.atlassian.net/wiki/spaces/PNG/pages/1874460991/Portal+Business+Rules#Order-Lines
  const audienceMinimum = () => {
    // retargeting (no minimum, just have to have at least one audience attached)
    if (audienceRows?.every((job) => job.type === 'AUDIENCE_TYPE_RETARGETING'))
      return 0
    // b2b, map polygon
    if (
      audienceRows?.every(
        (job) =>
          job.type === 'AUDIENCE_TYPE_IPSFORWKB' ||
          job.type === 'AUDIENCE_TYPE_IPSFORGEOJSON' ||
          job.type === 'AUDIENCE_TYPE_B2B',
      )
    )
      return 1
    // b2c, dc, new mover, VR, web to home, device, ip
    return 500
  }

  useEffect(() => {
    // This takes a list of audiences and replaces the sub jobs with the the parent
    const groupVRAudiences = async (
      audiences: Targetjobservicev1Audience[],
      orgId: string,
    ) => {
      const [audiencesWithParent, otherAudiences] = audiences?.reduce(
        (
          [withParent, withoutParent]: [
            Array<Targetjobservicev1Audience>,
            Array<Targetjobservicev1Audience>,
          ],
          aud,
        ) => {
          return aud.audienceId
            ? [[...withParent, aud], withoutParent]
            : [withParent, [...withoutParent, aud]]
        },
        [[], []],
      )

      // if in the audiences with parent array, fetch the parent to return in the final array
      if (audiencesWithParent.length > 0 && audienceServiceApi) {
        const uniqueParentIds = [
          ...new Set(
            audiencesWithParent.reduce((acc: string[], job) => {
              if (job.audienceId) return [...acc, job.audienceId]
              return acc
            }, []),
          ),
        ]
        const parentAudiences = await Promise.all(
          uniqueParentIds.map((parentId) => {
            return audienceServiceApi.advertisingPlatformServiceGetAudience(
              parentId,
              orgId,
            )
          }),
        )

        return [...parentAudiences, ...otherAudiences]
      } else {
        return audiences
      }
    }
    // Builds rows for table: gets quote count and sorts out what VR sub jobs are attached/detached
    const buildAudienceRows = async (
      orderline: Campaignservicev1OrderLine,
      orgId: string,
    ) => {
      const token = localStorage.getItem('eltoro_token')
      if (orderline.audiences && token && orgId) {
        const groupedJobs = await groupVRAudiences(
          await getFullJobs(orderline.audiences, token, orgId), // needed to get subType, locked, status
          orgId,
        )
        const convert = (job: Targetjobservicev1Audience) => {
          const quoted =
            job.result?.processCompleted === 1 ||
            job.status === 'AUDIENCE_STATUS_COMPLETED' ||
            job.status === 'AUDIENCE_STATUS_READY'
          const matched = getJobCounts(job)
          const {
            type,
            subType,
            name,
            id,
            audienceId,
            audiences: subJobs,
            status,
            locked,
            productType,
            dataProduct,
          } = job
          let attached: boolean
          let excluded: boolean
          if (type === 'AUDIENCE_TYPE_VR' && subJobs && subJobs.length) {
            const matched = orderline.audiences?.filter((aud) => {
              const isFoundOnAudience = job.audiences?.find(
                (subJob) => aud.id === subJob.id,
              )
              return isFoundOnAudience
            })
            const determineParentExcludeInclude = orderline.audiences?.filter(
              (olAud) => {
                return job.id === olAud.audienceId
              },
            )

            attached = !!(matched && matched.length > 0)
            excluded = !!determineParentExcludeInclude?.every(
              (current) => current.exclude,
            )
          } else {
            const matchingOLtarget = orderline.audiences
              ? orderline.audiences.find((aud) => aud.id === job.id)
              : undefined
            attached = !!matchingOLtarget
            excluded = !!orderline?.audiences?.find(
              (olAud) => olAud.exclude && olAud.id === job.id,
            )
          }

          return {
            id,
            excluded,
            audienceId,
            matched,
            type,
            name,
            subType,
            attached,
            quoted,
            status,
            locked,
            productType,
            dataProduct,
          }
        }
        const rows = groupedJobs.map((job) => {
          const subJobs = job.audiences?.map((subJob) => convert(subJob))
          return { ...convert(job), subJobs }
        })
        return rows as AudienceRowType[]
      }
    }
    if (orderline.orgId) {
      buildAudienceRows(orderline, orderline.orgId).then(setAudienceRows)
    }
  }, [audienceServiceApi, orderline])

  if (!orderline.audiences)
    return <div>This order line does not have audiences attached.</div>

  return (
    <>
      <div className="AudienceSelector h-auto">
        <TextHeader type={4}>Edit audiences</TextHeader>
        <div className="AudienceSelector__ol-menu border-t-thin border-primary mt-2 flex items-center gap-4 pt-2 font-bold">
          <p>Add audiences:</p>
          <div className="ml-auto flex gap-8 uppercase">
            <LinkButton
              text="Select from Audience Library"
              onClick={() => {
                setTargetModalStart('library')
                setShowTargetModal(true)
              }}
            />
            <LinkButton
              text="Create"
              onClick={() => {
                setTargetModalStart('create')
                setShowTargetModal(true)
              }}
            />
          </div>
        </div>
        <div className="AudienceSelector__Audiences">
          <div className="AudienceSelector__Audiences-grid my-4">
            <div className="grid grid-cols-[2fr-1fr-1fr-1fr-1fr_1fr] items-center gap-x-4">
              <ColumnHeader>Name</ColumnHeader>
              <ColumnHeader>Type</ColumnHeader>
              <ColumnHeader>Matched</ColumnHeader>
              <ColumnHeader className="justify-self-end">Attach</ColumnHeader>
              <ColumnHeader className="justify-self-end">Detach</ColumnHeader>
              <ColumnHeader className="justify-self-end">Exclude</ColumnHeader>

              <div className="box border-grey-200 col-start-1 col-end-7 border-b pt-2" />
              {audienceRows?.map((currentJob, index) => {
                return (
                  <AudienceRow
                    key={index}
                    row={currentJob}
                    audienceRows={audienceRows}
                    onChange={handleRowOnChange}
                    isExcludeDisabled={shouldExcludeBeDisabled}
                  />
                )
              })}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end gap-2">
          <Button type="submit" onClick={onClose}>
            {isReadOnly ? 'Close' : 'Cancel'}
          </Button>
          {!isReadOnly && (
            <Button
              type="button"
              kind="primary"
              onClick={() => {
                if (allDetached) {
                  setShowDetachWarning('removingAll')
                  return
                }
                if (
                  totalAudiences - totalAudiencesToRemove <
                  audienceMinimum()
                ) {
                  triggerPrompt(
                    'Low audience count',
                    <p>
                      Your audiences should collectively match at least{' '}
                      {audienceMinimum()} match(es). Are you sure you want to
                      remove these audiences?
                    </p>,
                    async () => handleSaveChanges(),
                  )
                  return
                }
                handleSaveChanges()
              }}
              iconLeft={
                <Fa
                  className={classNames({
                    'animate-spin': loading,
                  })}
                  icon={loading ? 'circle-notch' : 'save'}
                  size={1}
                />
              }
              disabled={loading}
            >
              Save
            </Button>
          )}
        </div>
      </div>
      {showTargetModal && (
        <EditOLAudiences
          orderLine={orderline}
          onClose={() => setShowTargetModal(false)}
          startingState={targetModalStart}
          refreshTableOL={() => refreshOrderLine()}
        />
      )}
      {/* Warning for detaching all */}
      {showDetachWarning === 'removingAll' && (
        <WarningModal onConfirm={() => setShowDetachWarning(undefined)}>
          You need at least one audience attached to the order line.
        </WarningModal>
      )}
    </>
  )
}
