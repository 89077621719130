import {
  Campaignservicev1Audience,
  Campaignservicev1CPM,
  Campaignservicev1OrderLine,
  GoogletypeMoney,
  Orgmanagerv1CPM,
  Orgmanagerv1Org,
  Targetjobservicev1Audience,
} from 'next-gen-sdk'
import { CPMObjectType } from 'types'

export const getTypeMoneyCost = (
  typeMoney:
    | Partial<Orgmanagerv1CPM>
    | Partial<GoogletypeMoney>
    | Partial<Campaignservicev1CPM>,
  upcharge?: Partial<GoogletypeMoney | undefined>[],
) => {
  if (upcharge?.length === 1) {
    return (
      Number(upcharge[0]?.units || 0) +
      Number(typeMoney?.units || 0) +
      ((typeMoney?.nanos || 0) + (upcharge[0]?.nanos || 0)) / 10 ** 9
    )
  }
  if (upcharge && upcharge?.length > 1) {
    const combinedUpcharges = upcharge.reduce((a, b) => {
      const newUnits = (Number(a?.units) || 0) + (Number(b?.units) || 0)
      const newNanos = (a?.nanos || 0) + (b?.nanos || 0)
      return {
        nanos: newNanos,
        units: newUnits.toString(),
      }
    })
    return (
      Number(combinedUpcharges?.units || 0) +
      Number(typeMoney?.units || 0) +
      ((typeMoney?.nanos || 0) + (combinedUpcharges?.nanos || 0)) / 10 ** 9
    )
  }
  // this is needed because prettier will make this a crazy number without the parens.
  // prettier-ignore
  return +(typeMoney?.units || 0) + ((typeMoney?.nanos || 0) / 10 ** 9)
}

export const separatePoliticalCPMs = (cpms: CPMObjectType) => {
  const politicalCPMs = Object.fromEntries(
    Object.entries(cpms).filter(([key]) => key.includes('political')),
  )
  const nonPoliticalCPMs = Object.fromEntries(
    Object.entries(cpms).filter(([key]) => !key.includes('political')),
  )
  return { nonPoliticalCPMs, politicalCPMs }
}

export const getCPMForAudience = (
  aud: Targetjobservicev1Audience | Campaignservicev1Audience,
  org: Orgmanagerv1Org,
  ol: Campaignservicev1OrderLine,
) => {
  const { cpms } = org
  const { productType } = aud
  const { creatives, political } = ol
  if (!creatives) return undefined
  const creativeType = creatives[0].adType?.replace('CREATIVE_ADTYPE_', '')
  const cpmName = political
    ? `political ${productType?.replace(
        'PRODUCT_TYPE_',
        '',
      )} ${creativeType}`.toLowerCase()
    : `${productType?.replace(
        'PRODUCT_TYPE_',
        '',
      )} ${creativeType}`.toLowerCase()
  if (!cpms || !cpms[cpmName.toLowerCase()]) {
    return undefined
  }
  const productCPM: Partial<GoogletypeMoney> = {
    units: cpms[cpmName.toLowerCase()].units
      ? cpms[cpmName.toLowerCase()].units
      : '0',
    nanos: cpms[cpmName.toLowerCase()].nanos,
  }
  const upchargeObj: Partial<GoogletypeMoney> = {
    units: (aud as Targetjobservicev1Audience).cpmPlus?.units,
    nanos: (aud as Targetjobservicev1Audience).cpmPlus?.nanos,
  }

  return {
    base: productCPM,
    upcharge: upchargeObj,
  }
}
