import { useEffect, useState } from 'react'
import { Modal, Text, TextHeader, triggerPrompt } from '@eltoro-ui/components'
import {
  Targetjobservicev1Audience,
  V2Target,
  V1AudienceType,
  Targetjobservicev1Timeframe,
} from 'next-gen-sdk'
import {
  getTargetIconET,
  styleTailwind,
  userFriendlyAudienceName,
} from 'Helpers'
import { LinkButton, PolygonMap } from 'Components'
import { ETIcon } from 'Assets'
import { useAppContext, useLibraryContext } from 'Contexts'
import { Feature } from 'geojson'
import './CloneAudience.scss'
import { createAudience } from 'Requests'

const Row = styleTailwind('div', 'AudienceType__row flex gap-2 py-2')

export const CloneAudience = ({
  target,
  job,
  DBFeatures,
  hasDeployments,
}: {
  target: V2Target
  job: Targetjobservicev1Audience
  DBFeatures?: Feature[]
  hasDeployments?: boolean
}) => {
  const [disable, setDisable] = useState(false)
  const [modalVR, setModalVR] = useState(false)
  const [features, setMapFeatures] = useState<Feature[]>([])
  const [timeFrames, setTimeFrames] = useState<Targetjobservicev1Timeframe[]>(
    [],
  )
  const {
    audienceServiceApi,
    currentOrg,
    isAdmin,
    isReadOnly,
  } = useAppContext()
  const {
    pagination: { refetch, updateRow, findCachedRow },
    handleSetSelectedItem,
  } = useLibraryContext()
  const targetName = target.locations?.[0]?.filename
  useEffect(() => {
    if (DBFeatures) {
      setMapFeatures(DBFeatures)
    }
    if (job.timeframes) {
      setTimeFrames(job.timeframes)
    }

    setDisable(false)
  }, [job, target, DBFeatures])
  const handleRunNewJob = async (type: V1AudienceType, clone?: boolean) => {
    if (target.id && currentOrg?.id && type && audienceServiceApi) {
      const createdAudience = await createAudience(
        currentOrg.id,
        type,
        target.id,
        `${job.name || targetName || job.id}${clone && '-clone'}`,
      )
      // Step 4: If job created, add it to the queue
      if (
        createdAudience?.id &&
        createdAudience.status === 'AUDIENCE_STATUS_CREATED'
      ) {
        audienceServiceApi
          ?.advertisingPlatformServiceGenerateAudience(
            createdAudience.id,
            currentOrg.id,
          )
          .then(() => {
            if (job && job.locked && createdAudience.id && currentOrg.id) {
              audienceServiceApi
                .advertisingPlatformServiceUpdateAudience(
                  createdAudience.id,
                  currentOrg.id,
                  {
                    locked: job.locked,
                  },
                  'locked',
                )
                .then(() => {
                  refetch()
                })
            }
          })
          .then(() => {
            refetch()
          })
      }
    }
  }

  const canBeEdited =
    !hasDeployments &&
    (job.type === 'AUDIENCE_TYPE_VR' ||
      job.type === 'AUDIENCE_TYPE_IPSFORGEOJSON')
  const canBeCloned = job.type !== 'AUDIENCE_TYPE_IPSFORGEOJSON'

  const onSaveEdit = () => {
    const existingAudience = findCachedRow((aud) => aud?.id === job.id)
      ?.existingItem
    if (existingAudience) {
      const updatedAudience = {
        ...existingAudience,
        status: 'AUDIENCE_STATUS_RUNNING',
      } as Targetjobservicev1Audience

      updateRow((aud) => aud?.id === job.id, updatedAudience)

      handleSetSelectedItem(updatedAudience)
    }
    setModalVR(false)
  }

  return (
    <div className="CloneAudience flex flex-col gap-2">
      <Text className="RelatedAudiences__header flex flex-1 flex-col gap-2 pt-2 font-bold">
        <span className="PreviewPanel__section-header">
          {(() => {
            if (canBeCloned && canBeEdited) return 'Clone or edit audience'
            if (canBeCloned && !canBeEdited) return 'Clone audience'
            if (canBeEdited && !canBeCloned) return 'Edit audience'
          })()}
        </span>
      </Text>

      {job &&
        job.type &&
        job.type !== 'AUDIENCE_TYPE_VR' &&
        job.type !== 'AUDIENCE_TYPE_IPSFORGEOJSON' &&
        job.type !== 'AUDIENCE_TYPE_RETARGETING' && (
          <>
            <Text className="text-m font-light">
              Create another audience from the same source file.
            </Text>

            <Row>
              {job && job.type && <ETIcon icon={getTargetIconET(job.type)} />}
              <div className="ml-2 leading-4">
                <p className="font-bold">
                  {job && job.type && userFriendlyAudienceName(job.type)}
                </p>
                <LinkButton
                  text="Clone"
                  disabled={disable || isReadOnly}
                  onClick={() => {
                    if (job.type) {
                      handleRunNewJob(job?.type, true)
                      setDisable(true)
                    }
                  }}
                  size="s"
                  dataToolTip="top"
                />
              </div>
            </Row>
          </>
        )}
      {job &&
        job.type &&
        (job.type === 'AUDIENCE_TYPE_VR' ||
          job.type === 'AUDIENCE_TYPE_IPSFORGEOJSON') && (
          <>
            <Text className="text-m font-light">
              {(() => {
                if (canBeCloned && canBeEdited)
                  return 'Edit this audience or create another audience from the same source file.'
                if (canBeCloned && !canBeEdited)
                  return 'Create another audience from the same source file.'
                if (canBeEdited && !canBeCloned) return ''
              })()}
            </Text>
            <Row>
              <ETIcon icon={getTargetIconET(job.type)} />
              <div className="ml-2 leading-4">
                <p className="font-bold">
                  {userFriendlyAudienceName(job.type)}
                </p>
                <LinkButton
                  text="Open editor"
                  onClick={() => {
                    if (job.locked && isAdmin) {
                      triggerPrompt(
                        'Edit Audience Columns',
                        <p>
                          This audience is currently locked. Are you sure you
                          want to edit the audience <strong>{job.name}</strong>?
                        </p>,
                        async () => setModalVR(true),
                      )
                    } else {
                      setModalVR(true)
                    }
                  }}
                  size="s"
                  dataToolTip="top"
                />
              </div>
            </Row>
            {modalVR && (
              <Modal
                offClick={() => setModalVR(false)}
                header={
                  <TextHeader className="py-1">
                    {`${(() => {
                      if (canBeCloned && canBeEdited) return 'Clone/Edit'
                      if (canBeCloned && !canBeEdited) return 'Clone'
                      if (canBeEdited && !canBeCloned) return 'Edit'
                    })()} ${
                      job.type === 'AUDIENCE_TYPE_VR'
                        ? 'Venue Replay'
                        : 'Map Polygon'
                    } Audience`}
                  </TextHeader>
                }
                className="CloneAudience__modal !w-full !max-w-[unset]"
              >
                <div className="CloneMap">
                  {job.type === 'AUDIENCE_TYPE_VR' ? (
                    <PolygonMap
                      DBFeatures={features}
                      DBTimeFrames={timeFrames}
                      existingAudience={job}
                      hasDeployments={hasDeployments}
                      onSaveEdit={onSaveEdit}
                      vr
                    />
                  ) : (
                    <PolygonMap
                      DBFeatures={features}
                      existingAudience={job}
                      hasDeployments={hasDeployments}
                      onSaveEdit={onSaveEdit}
                      vr={false}
                    />
                  )}
                </div>
              </Modal>
            )}
          </>
        )}
    </div>
  )
}
