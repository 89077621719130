/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1FTP } from '../models/V1FTP';
import { HttpFile } from '../http/http';

export class V1Publisher {
    /**
    * Id of the publisher
    */
    'id'?: string;
    /**
    * RefId of the publisher
    */
    'refId'?: string;
    /**
    * Name of the publisher
    */
    'name'?: string;
    'ftp'?: V1FTP;
    /**
    * Time the publisher was created
    */
    'created'?: Date;
    /**
    * Time the publisher was updated
    */
    'updated'?: Date;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "refId",
            "baseName": "ref_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "ftp",
            "baseName": "ftp",
            "type": "V1FTP",
            "format": ""
        },
        {
            "name": "created",
            "baseName": "created",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updated",
            "baseName": "updated",
            "type": "Date",
            "format": "date-time"
        }    ];

    static getAttributeTypeMap() {
        return V1Publisher.attributeTypeMap;
    }

    public constructor() {
    }
}

