import { Button, Fa } from '@eltoro-ui/components'
import { v4 as uuid } from 'uuid'
import classNames from 'classnames'
import { RuleRow } from './RuleRow'
import { RuleSetWithTempId, RuleWithTempId } from './types'
import { RuleRowSelect, RuleSetButton } from './shared'

export const RuleSetRow = ({
  level,
  ruleSet,
  setParentRuleSet,
  debugMode,
  onDeleteRule,
  onDeleteRuleSet,
}: {
  level: number
  ruleSet: RuleSetWithTempId // the rule set this row is representing
  setParentRuleSet: React.Dispatch<React.SetStateAction<RuleSetWithTempId>> // the setter for MAIN parent rule set that is to be set on subscription
  debugMode: boolean
  onDeleteRule?: (rule: RuleWithTempId) => void
  onDeleteRuleSet?: (ruleSet: RuleSetWithTempId) => void
}) => {
  const handleAddRuleSet = () => {
    const addNewRuleSet = (
      currentRuleSet: RuleSetWithTempId,
    ): RuleSetWithTempId => {
      if (ruleSet.tempId === currentRuleSet.tempId) {
        return {
          ...currentRuleSet,
          ruleSet: [
            ...(currentRuleSet.ruleSet || []),
            {
              operator: 'and',
              tempId: uuid(),
            },
          ],
        }
      }
      return {
        ...currentRuleSet,
        ruleSet: currentRuleSet.ruleSet?.map((r) => addNewRuleSet(r)),
      }
    }
    setParentRuleSet((parentRuleSet) => {
      return addNewRuleSet(parentRuleSet)
    })
  }

  const handleAddRule = () => {
    const addNewRule = (
      currentRuleSet: RuleSetWithTempId,
    ): RuleSetWithTempId => {
      if (ruleSet.tempId === currentRuleSet.tempId) {
        return {
          ...currentRuleSet,
          rules: [
            ...(currentRuleSet.rules || []),
            { term: '', tempId: uuid(), type: 'term' },
          ],
        }
      }
      return {
        ...currentRuleSet,
        ruleSet: currentRuleSet.ruleSet?.map((r) => addNewRule(r)),
      }
    }
    setParentRuleSet((parentRuleSet) => {
      return addNewRule(parentRuleSet)
    })
  }

  const handleDeleteRuleSet = () => {
    const removeRuleSet = (
      currentRuleSet: RuleSetWithTempId,
    ): RuleSetWithTempId => {
      if (
        currentRuleSet.ruleSet?.find(
          (r: RuleSetWithTempId) => r.tempId === ruleSet.tempId,
        )
      ) {
        return {
          ...currentRuleSet,
          ruleSet: currentRuleSet?.ruleSet?.filter(
            (r) => (r as RuleSetWithTempId).tempId !== ruleSet.tempId,
          ),
        }
      }
      return {
        ...currentRuleSet,
        ruleSet: currentRuleSet.ruleSet?.map((r) => removeRuleSet(r)),
      }
    }
    setParentRuleSet((parentRuleSet) => {
      return removeRuleSet(parentRuleSet)
    })
    if (onDeleteRuleSet) {
      onDeleteRuleSet(ruleSet)
    }
  }

  const handleDeleteRule = (ruleToDelete: RuleWithTempId) => {
    const removeRule = (
      currentRuleSet: RuleSetWithTempId,
    ): RuleSetWithTempId => {
      if (ruleSet.tempId === currentRuleSet.tempId) {
        return {
          ...currentRuleSet,
          rules: currentRuleSet?.rules?.filter(
            (r) => (r as RuleWithTempId).tempId !== ruleToDelete.tempId,
          ),
        }
      }
      return {
        ...currentRuleSet,
        ruleSet: currentRuleSet.ruleSet?.map((r) => removeRule(r)),
      }
    }
    setParentRuleSet((parentRuleSet) => {
      return removeRule(parentRuleSet)
    })
    if (onDeleteRule) {
      onDeleteRule(ruleToDelete)
    }
  }

  const handleUpdateRuleSet = (ruleSetToUpdate: RuleSetWithTempId) => {
    const updateRuleSet = (
      currentRuleSet: RuleSetWithTempId,
    ): RuleSetWithTempId => {
      if (
        currentRuleSet.ruleSet?.find(
          (r: RuleSetWithTempId) => r.tempId === ruleSet.tempId,
        )
      ) {
        return {
          ...currentRuleSet,
          ruleSet: currentRuleSet?.ruleSet?.map((r) =>
            (r as RuleSetWithTempId).tempId === ruleSetToUpdate.tempId
              ? ruleSetToUpdate
              : r,
          ),
        }
      }
      return {
        ...currentRuleSet,
        ruleSet: currentRuleSet.ruleSet?.map((r) => updateRuleSet(r)),
      }
    }
    setParentRuleSet((parentRuleSet) => {
      return updateRuleSet(parentRuleSet)
    })
  }

  const handleUpdateRule = (ruleToUpdate: RuleWithTempId) => {
    const updateRule = (
      currentRuleSet: RuleSetWithTempId,
    ): RuleSetWithTempId => {
      if (ruleSet.tempId === currentRuleSet.tempId) {
        return {
          ...currentRuleSet,
          rules: currentRuleSet?.rules?.map((r) =>
            (r as RuleWithTempId).tempId === ruleToUpdate.tempId
              ? ruleToUpdate
              : r,
          ),
        }
      }
      return {
        ...currentRuleSet,
        ruleSet: currentRuleSet.ruleSet?.map((r) => updateRule(r)),
      }
    }
    setParentRuleSet((parentRuleSet) => {
      return updateRule(parentRuleSet)
    })
  }

  const updaters = {
    setParentRuleSet,
    handleAddRule,
    handleDeleteRule,
    handleUpdateRule,
    handleAddRuleSet,
    handleDeleteRuleSet,
    handleUpdateRuleSet,
  }

  level++

  const operatorPhrase = (() => {
    if (ruleSet.operator === 'and') return 'Match all of: '
    if (ruleSet.operator === 'or') return 'Match any of: '
    if (ruleSet.operator === 'not') return 'Exclude all of: '
  })()

  return (
    <div
      className={classNames('RuleSetRow w-full', {
        'm-1 border border-[coral] p-1': debugMode,
      })}
    >
      {debugMode && (
        <span className="text-[coral]">rule set id: {ruleSet.id}</span>
      )}
      <div
        className={classNames(
          'RuleSetRow bg-grey-50 border-l-gray my-2 flex w-full flex-col items-center gap-2 rounded-sm border-l-4 p-2',
          {
            'border-l-[rgb(96,165,250)]': level === 1,
            'border-l-[rgb(74,222,128)]': level === 2,
            'border-l-[rgb(192,132,252)]': level === 3,
          },
        )}
      >
        <div className="flex w-full items-center justify-between gap-2">
          <div className="flex items-center gap-2">
            <span className="whitespace-nowrap">{operatorPhrase}</span>
            <RuleRowSelect
              value={ruleSet.operator}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                handleUpdateRuleSet({
                  ...ruleSet,
                  operator: e.target.value as 'and' | 'or' | 'not',
                })
              }
            >
              <option value="and">AND</option>
              <option value="or">OR</option>
              <option value="not">NOT</option>
            </RuleRowSelect>
            <RuleSetButton
              kind="text-icon"
              onClick={handleAddRule}
              iconLeft={<Fa icon="plus" size={1} />}
            >
              Add Rule
            </RuleSetButton>
            {level < 3 && (
              <RuleSetButton
                kind="text-icon"
                onClick={handleAddRuleSet}
                iconLeft={<Fa icon="layer-group" size={1} />}
              >
                Add Group
              </RuleSetButton>
            )}
          </div>
          <Button
            iconOnly={<Fa icon="xmark" size={1} />}
            onClick={handleDeleteRuleSet}
          />
        </div>
        {(() => {
          if (
            (!ruleSet.rules || !ruleSet.rules.length) &&
            (!ruleSet.ruleSet || !ruleSet.ruleSet.length)
          ) {
            return (
              <div className="text-grey-500 w-full">
                <em>{`Add rules ${
                  level !== 3 ? 'or groups ' : ''
                }to define conditions`}</em>
              </div>
            )
          }
          return (
            <>
              {ruleSet?.rules?.map((r, i) => {
                return (
                  <RuleRow
                    key={(r as RuleWithTempId).tempId}
                    rule={r as RuleWithTempId}
                    debugMode={debugMode}
                    {...updaters}
                  />
                )
              })}
              {ruleSet?.ruleSet?.map((r) => {
                return (
                  <RuleSetRow
                    level={level}
                    key={(r as RuleSetWithTempId).tempId}
                    ruleSet={r}
                    setParentRuleSet={setParentRuleSet}
                    debugMode={debugMode}
                    onDeleteRule={onDeleteRule}
                    onDeleteRuleSet={onDeleteRuleSet}
                  />
                )
              })}
            </>
          )
        })()}
      </div>
    </div>
  )
}
