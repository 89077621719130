/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1JobTemplate } from '../models/V1JobTemplate';
import { HttpFile } from '../http/http';

export class V1BatchOrderLinesJobRequest {
    /**
    * The actual source file.
    */
    'file'?: string;
    'template'?: V1JobTemplate;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "file",
            "baseName": "file",
            "type": "string",
            "format": "byte"
        },
        {
            "name": "template",
            "baseName": "template",
            "type": "V1JobTemplate",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1BatchOrderLinesJobRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

