import React, { useEffect } from 'react'
import { Button, Modal, Fa } from '@eltoro-ui/components'
import { Creativeservicev1Creative, V1CreativeFile } from 'next-gen-sdk'
import { isVideo, isImage, cleanedMime, isHtml } from 'Helpers'
import './CreativePreview.scss'
import JSZip from 'jszip'

interface CreativePreviewBasic {
  offClick: () => void
  creative?: Creativeservicev1Creative
  blobUrl?: string
}

interface CreativePreviewMultiple extends CreativePreviewBasic {
  file?: never
  creatives: Creativeservicev1Creative[]
}

interface CreativePreviewFile extends CreativePreviewBasic {
  file: V1CreativeFile
  creatives?: never
}

export const CreativePreview = ({
  file,
  creatives,
  offClick,
  creative,
  blobUrl,
}: CreativePreviewFile | CreativePreviewMultiple) => {
  const [previewUrl, setPreviewUrl] = React.useState<string | null>(null)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)
  const [objectUrls, setObjectUrls] = React.useState<string[]>([])
  const iframeRef = React.useRef<HTMLIFrameElement>(null)
  useEffect(() => {
    return () => {
      objectUrls.forEach(URL.revokeObjectURL)
    }
  }, [file])

  useEffect(() => {
    if (creative?.type !== 'CREATIVE_TYPE_HTML5') return
    const handUnzipAndPreview = async (file: V1CreativeFile) => {
      if (!file) return

      setLoading(true)
      setError(null)

      // Cleanup previous object URLs
      objectUrls.forEach(URL.revokeObjectURL)
      setObjectUrls([])

      try {
        const zip = new JSZip()
        const dFile = await fetch(file.uri || '')
        const dBlob = await dFile.blob()
        const contents = await zip.loadAsync(dBlob)

        // Initialize arrays and maps first
        const newUrls: string[] = []
        const fileMap: { [key: string]: string } = {}

        // Find the index.html file and image files
        const indexFile = Object.values(contents.files).find(
          (f) =>
            !f.dir &&
            (f.name.toLowerCase() === 'index.html' ||
              f.name.toLowerCase().endsWith('/index.html')),
        )
        const imageFiles = Object.values(contents.files).filter(
          (f) => !f.dir && /\.(jpg|jpeg|png|gif|svg|webp)$/i.test(f.name),
        )

        if (!indexFile) {
          throw new Error('No index.html found in the zip file')
        }

        // Process images first to ensure URLs are ready
        for (const imageFile of imageFiles) {
          const blob = await imageFile.async('blob')
          const imageType =
            imageFile.name.split('.').pop()?.toLowerCase() || 'jpeg'
          const mimeType = `image/${imageType === 'jpg' ? 'jpeg' : imageType}`
          const imageBlob = new Blob([blob], { type: mimeType })
          const url = URL.createObjectURL(imageBlob)
          newUrls.push(url)
          fileMap[imageFile.name] = url
        }

        for (const [path, file] of Object.entries(contents.files)) {
          if (!file.dir && !fileMap[path]) {
            // Skip if already processed as image
            const blob = await file.async('blob')
            const url = URL.createObjectURL(blob)
            newUrls.push(url)
            fileMap[path] = url
          }
        }

        setObjectUrls(newUrls)

        // Get and process HTML content
        let htmlContent = await indexFile.async('text')

        // Replace all possible path variations
        for (const [path, url] of Object.entries(fileMap)) {
          const relativePath = path.split('/').pop() || path
          const dirPath = path.includes('/')
            ? path.substring(path.indexOf('/') + 1)
            : path
          const escapedPath = path.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
          const escapedRelativePath = relativePath.replace(
            /[.*+?^${}()|[\]\\]/g,
            '\\$&',
          )
          const escapedDirPath = dirPath.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

          // Handle different path formats
          htmlContent = htmlContent.replace(
            new RegExp(
              `(src|href)=["'](?:\\.{0,2}/)?(?:${escapedPath}|${escapedRelativePath}|${escapedDirPath})["']`,
              'gi',
            ),
            `$1="${url}"`,
          )

          // Handle background-image CSS
          htmlContent = htmlContent.replace(
            new RegExp(
              `url\\(['"]?(?:\\.{0,2}/)?(?:${escapedPath}|${escapedRelativePath}|${escapedDirPath})['"]?\\)`,
              'gi',
            ),
            `url("${url}")`,
          )
        }

        // Add script to ensure DOM is ready before initializing CreateJS
        const domReadyScript = `
   <script>
     (function() {
       // Create and initialize canvas
       function initCanvas() {
         const canvas = document.createElement('canvas');
         canvas.id = 'canvas';
         const context = canvas.getContext('2d');
         if (context) {
          canvas.width = contentWidth;
          canvas.height = contentHeight;
       }
         canvas.style.display = 'block';
         canvas.style.backgroundColor = '#520909';
         document.body.insertBefore(canvas, document.body.firstChild);
         return canvas;
       }

       // Setup CreateJS
       function setupCreateJS() {
         if (!window.createjs) return false;
         
         const canvas = document.getElementById('canvas') || initCanvas();
         if (!canvas) return false;

         // Patch CreateJS stage initialization
         const originalStage = window.createjs.Stage;
         window.createjs.Stage = function(canvasId) {
           return new originalStage(
             typeof canvasId === 'string' ? document.getElementById(canvasId) || canvas : canvasId
           );
         }
         return true;
       }

       // Initialize when DOM is ready
       if (document.readyState === 'loading') {
         document.addEventListener('DOMContentLoaded', setupCreateJS);
       } else {
         setupCreateJS();
       }
     })();
   </script>
 `

        // Insert the script right after the opening body tag
        htmlContent = htmlContent.replace(/<head[^>]*>/i, `$&${domReadyScript}`)

        const htmlBlob = new Blob([htmlContent], { type: 'text/html' })
        const previewUrl = URL.createObjectURL(htmlBlob)
        newUrls.push(previewUrl)
        setObjectUrls(newUrls)
        setPreviewUrl(previewUrl)
      } catch (err) {
        setError(
          err instanceof Error ? err.message : 'Failed to process zip file',
        )
      } finally {
        setLoading(false)
      }
    }
    if (!file) return
    handUnzipAndPreview(file)
  }, [])
  if (file?.extension === 'zip' && creative?.files?.length === 1) {
    return (
      <Modal
        className="CreativePreview__modal"
        scrollable={false}
        offClick={offClick}
      >
        {previewUrl && !loading && (
          <div className="relative overflow-hidden rounded-lg bg-gray-800">
            <iframe
              ref={iframeRef}
              src={previewUrl}
              height={file.height}
              width={file.width}
              sandbox="allow-scripts allow-same-origin"
              title="Ad Preview"
            />
            <div className="CreativePreview__dimensions">
              {file?.width} x {file?.height}, {file.extension}
            </div>
          </div>
        )}
        <div className="CreativePreview__close-button">
          <Button
            iconOnly={<Fa icon="times" size={1} />}
            rounded
            onClick={() => {
              setObjectUrls([])
              offClick()
            }}
          />
        </div>
      </Modal>
    )
  }
  // Single file view
  if (file) {
    const { mime, height, width, bucket, key, extension } = file
    const url = `https://s3.amazonaws.com/${bucket}/${key}`
    if (!mime) return null
    const cleanMime = cleanedMime(mime)
    return (
      <Modal
        className="CreativePreview__modal"
        scrollable={false}
        offClick={offClick}
      >
        {file && isHtml(cleanMime) && (
          <div className="mt-2 flex flex-col overflow-y-scroll">
            <iframe
              title="html5"
              src={file.uri}
              sandbox="allow-scripts"
              frameBorder="0"
              style={{
                width: creative?.width || 100,
                height: creative?.height || 100,
              }}
            />
            <div className="CreativePreview__dimensions">
              {creative?.width} x {creative?.height}, {mime}
            </div>
          </div>
        )}
        {file &&
          isImage(
            cleanMime === 'application/octet-stream' || cleanMime === ''
              ? extension || ''
              : cleanMime,
          ) && (
            <>
              <img
                src={url}
                width={width}
                height={height}
                alt="full size"
                className="CreativePreview__full-image"
              />

              <div className="CreativePreview__dimensions">
                {width} x {height},{' '}
                {mime ||
                cleanMime === 'application/octet-stream' ||
                cleanMime === ''
                  ? extension || ''
                  : cleanMime}
              </div>
            </>
          )}
        {file && isVideo(cleanMime) && (
          <video className="CreativePreview__video" controls>
            <source src={url} type={cleanMime} />
            <track default kind="captions" />
            Your browser does not support the video element.
          </video>
        )}
        <div
          className={`CreativePreview__close-button ${
            isHtml(cleanMime) && 'top-0'
          }`}
        >
          <Button
            iconOnly={<Fa icon="times" size={1} />}
            rounded
            onClick={offClick}
          />
        </div>
      </Modal>
    )
  }
  // Multiple file view
  return (
    <Modal
      className="CreativePreview__modal"
      scrollable={false}
      offClick={offClick}
    >
      {creatives &&
        creatives?.map((currentCreative: Creativeservicev1Creative) => {
          const imgFile = currentCreative.files && currentCreative.files[0]
          if (!imgFile) return null
          const { mime } = imgFile
          if (!mime) return null
          const cleanMime = cleanedMime(mime)
          return (
            <div className="CreativePreview__item" key={currentCreative.id}>
              {isImage(cleanMime) && (
                <div className="CreativePreview__image-wrap">
                  {/* second map needed to drill down the nested array for files in Creativeservicev1Creative[] to get the images' urls, widths, heights in V1CreativeFile */}
                  {currentCreative?.files?.map(
                    (creativeImage: V1CreativeFile) => (
                      <React.Fragment key={`figure-${creativeImage.id}`}>
                        <figure className="CreativePreview__image">
                          <img
                            key={currentCreative.id}
                            // second map provides the prop from V1CreativeFile
                            src={`https://s3.amazonaws.com/${creativeImage.bucket}/${creativeImage.key}`}
                            alt={currentCreative?.name}
                          />
                          <figcaption>{currentCreative?.name}</figcaption>
                        </figure>
                        <div className="CreativePreview__dimensions">
                          {/* second map provides the props from V1CreativeFile */}
                          {creativeImage?.width} x {creativeImage?.height}
                        </div>
                      </React.Fragment>
                    ),
                  )}
                </div>
              )}
            </div>
          )
        })}
      <div className="CreativePreview__close-button">
        <Button
          iconOnly={<Fa icon="times" size={1} />}
          rounded
          onClick={offClick}
        />
      </div>
    </Modal>
  )
}
