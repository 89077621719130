/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1AudienceAdded } from '../models/V1AudienceAdded';
import { V1AudienceRemoved } from '../models/V1AudienceRemoved';
import { V1CreativeAdded } from '../models/V1CreativeAdded';
import { V1CreativeRemoved } from '../models/V1CreativeRemoved';
import { V1DeploymentCreated } from '../models/V1DeploymentCreated';
import { V1OrderLineAdTypeSet } from '../models/V1OrderLineAdTypeSet';
import { V1OrderLineApproved } from '../models/V1OrderLineApproved';
import { V1OrderLineArchived } from '../models/V1OrderLineArchived';
import { V1OrderLineAudienceSet } from '../models/V1OrderLineAudienceSet';
import { V1OrderLineAuditConditionsSet } from '../models/V1OrderLineAuditConditionsSet';
import { V1OrderLineCPMOverrideRemoved } from '../models/V1OrderLineCPMOverrideRemoved';
import { V1OrderLineCPMOverriden } from '../models/V1OrderLineCPMOverriden';
import { V1OrderLineCPMSet } from '../models/V1OrderLineCPMSet';
import { V1OrderLineCampaignAttached } from '../models/V1OrderLineCampaignAttached';
import { V1OrderLineCampaignDetached } from '../models/V1OrderLineCampaignDetached';
import { V1OrderLineCampaignSet } from '../models/V1OrderLineCampaignSet';
import { V1OrderLineClickThroughURLSet } from '../models/V1OrderLineClickThroughURLSet';
import { V1OrderLineCloned } from '../models/V1OrderLineCloned';
import { V1OrderLineCompleted } from '../models/V1OrderLineCompleted';
import { V1OrderLineConfigured } from '../models/V1OrderLineConfigured';
import { V1OrderLineConsoleCampaignSet } from '../models/V1OrderLineConsoleCampaignSet';
import { V1OrderLineConsoleClickThroughURLSet } from '../models/V1OrderLineConsoleClickThroughURLSet';
import { V1OrderLineConsoleCreativesSet } from '../models/V1OrderLineConsoleCreativesSet';
import { V1OrderLineConsoleSegmentsSet } from '../models/V1OrderLineConsoleSegmentsSet';
import { V1OrderLineCostSet } from '../models/V1OrderLineCostSet';
import { V1OrderLineCreated } from '../models/V1OrderLineCreated';
import { V1OrderLineCreating } from '../models/V1OrderLineCreating';
import { V1OrderLineCreativeAttached } from '../models/V1OrderLineCreativeAttached';
import { V1OrderLineCreativeDetached } from '../models/V1OrderLineCreativeDetached';
import { V1OrderLineCreativeSet } from '../models/V1OrderLineCreativeSet';
import { V1OrderLineDeleted } from '../models/V1OrderLineDeleted';
import { V1OrderLineDeployRetried } from '../models/V1OrderLineDeployRetried';
import { V1OrderLineDeployed } from '../models/V1OrderLineDeployed';
import { V1OrderLineDeployedToDestinationXandrCurate } from '../models/V1OrderLineDeployedToDestinationXandrCurate';
import { V1OrderLineDeployedToDestinationXandrInvest } from '../models/V1OrderLineDeployedToDestinationXandrInvest';
import { V1OrderLineDeployedWithConfig } from '../models/V1OrderLineDeployedWithConfig';
import { V1OrderLineDeploymentCancelled } from '../models/V1OrderLineDeploymentCancelled';
import { V1OrderLineDeploymentCancelling } from '../models/V1OrderLineDeploymentCancelling';
import { V1OrderLineDeploymentDestinationConfigurationSet } from '../models/V1OrderLineDeploymentDestinationConfigurationSet';
import { V1OrderLineDeploymentDestinationSet } from '../models/V1OrderLineDeploymentDestinationSet';
import { V1OrderLineDestinationXandrConsoleCreated } from '../models/V1OrderLineDestinationXandrConsoleCreated';
import { V1OrderLineDestinationXandrCurateCreated } from '../models/V1OrderLineDestinationXandrCurateCreated';
import { V1OrderLineDestinationXandrCurateSet } from '../models/V1OrderLineDestinationXandrCurateSet';
import { V1OrderLineDestinationXandrInvestCreated } from '../models/V1OrderLineDestinationXandrInvestCreated';
import { V1OrderLineDestinationXandrInvestSet } from '../models/V1OrderLineDestinationXandrInvestSet';
import { V1OrderLineEndTimeSet } from '../models/V1OrderLineEndTimeSet';
import { V1OrderLineErroredDeployingToDestinationXandrCurate } from '../models/V1OrderLineErroredDeployingToDestinationXandrCurate';
import { V1OrderLineErroredDeployingToDestinationXandrInvest } from '../models/V1OrderLineErroredDeployingToDestinationXandrInvest';
import { V1OrderLineFlightDatesSet } from '../models/V1OrderLineFlightDatesSet';
import { V1OrderLineFreeImpressionsSet } from '../models/V1OrderLineFreeImpressionsSet';
import { V1OrderLineImpressionsPerDaySet } from '../models/V1OrderLineImpressionsPerDaySet';
import { V1OrderLineImpressionsSet } from '../models/V1OrderLineImpressionsSet';
import { V1OrderLineInvestCampaignSet } from '../models/V1OrderLineInvestCampaignSet';
import { V1OrderLineInvestClickThroughURLSet } from '../models/V1OrderLineInvestClickThroughURLSet';
import { V1OrderLineInvestCreativesSet } from '../models/V1OrderLineInvestCreativesSet';
import { V1OrderLineInvestSegmentsSet } from '../models/V1OrderLineInvestSegmentsSet';
import { V1OrderLineJobIdSet } from '../models/V1OrderLineJobIdSet';
import { V1OrderLineLocked } from '../models/V1OrderLineLocked';
import { V1OrderLineMigrated } from '../models/V1OrderLineMigrated';
import { V1OrderLineMinimumImpressionsSet } from '../models/V1OrderLineMinimumImpressionsSet';
import { V1OrderLineNameSet } from '../models/V1OrderLineNameSet';
import { V1OrderLineNoteAppended } from '../models/V1OrderLineNoteAppended';
import { V1OrderLineNoteDeactivated } from '../models/V1OrderLineNoteDeactivated';
import { V1OrderLineNoteUpdated } from '../models/V1OrderLineNoteUpdated';
import { V1OrderLineOrgIdSet } from '../models/V1OrderLineOrgIdSet';
import { V1OrderLinePaidSet } from '../models/V1OrderLinePaidSet';
import { V1OrderLinePaused } from '../models/V1OrderLinePaused';
import { V1OrderLinePlayed } from '../models/V1OrderLinePlayed';
import { V1OrderLinePoIdSet } from '../models/V1OrderLinePoIdSet';
import { V1OrderLinePoliticalFieldsSet } from '../models/V1OrderLinePoliticalFieldsSet';
import { V1OrderLinePoliticalSet } from '../models/V1OrderLinePoliticalSet';
import { V1OrderLinePrepaySet } from '../models/V1OrderLinePrepaySet';
import { V1OrderLineRefIdSet } from '../models/V1OrderLineRefIdSet';
import { V1OrderLineRejected } from '../models/V1OrderLineRejected';
import { V1OrderLineReviewStarted } from '../models/V1OrderLineReviewStarted';
import { V1OrderLineSegmentConfigSet } from '../models/V1OrderLineSegmentConfigSet';
import { V1OrderLineStartTimeSet } from '../models/V1OrderLineStartTimeSet';
import { V1OrderLineStateSetToActive } from '../models/V1OrderLineStateSetToActive';
import { V1OrderLineStateSetToPaused } from '../models/V1OrderLineStateSetToPaused';
import { V1OrderLineStatusSetToDeploying } from '../models/V1OrderLineStatusSetToDeploying';
import { V1OrderLineStatusSetToDraft } from '../models/V1OrderLineStatusSetToDraft';
import { V1OrderLineStatusSetToPrevious } from '../models/V1OrderLineStatusSetToPrevious';
import { V1OrderLineStepFunctionSet } from '../models/V1OrderLineStepFunctionSet';
import { V1OrderLineSubmittedForReview } from '../models/V1OrderLineSubmittedForReview';
import { V1OrderLineTargetJobAttached } from '../models/V1OrderLineTargetJobAttached';
import { V1OrderLineTargetJobDetached } from '../models/V1OrderLineTargetJobDetached';
import { V1OrderLineTemplateTypeSet } from '../models/V1OrderLineTemplateTypeSet';
import { V1OrderLineUnarchived } from '../models/V1OrderLineUnarchived';
import { V1OrderLineUnlocked } from '../models/V1OrderLineUnlocked';
import { V1OrderLineViantAdelphicConfigurationSet } from '../models/V1OrderLineViantAdelphicConfigurationSet';
import { V1OrderLineViantAdelphicIDsSet } from '../models/V1OrderLineViantAdelphicIDsSet';
import { V1ReviewRequested } from '../models/V1ReviewRequested';
import { HttpFile } from '../http/http';

/**
* Wrapper for the different kinds of events
*/
export class V1OrderLineEvent {
    /**
    * The type of event
    */
    'eventType'?: string;
    'orderLineCreated'?: V1OrderLineCreated;
    'orderLineConfigured'?: V1OrderLineConfigured;
    'orderLineCloned'?: V1OrderLineCloned;
    'orderLineCampaignAttached'?: V1OrderLineCampaignAttached;
    'orderLineCampaignSet'?: V1OrderLineCampaignSet;
    'orderLineCampaignDetached'?: V1OrderLineCampaignDetached;
    'orderLineDeleted'?: V1OrderLineDeleted;
    'orderLinePlayed'?: V1OrderLinePlayed;
    'orderLinePaused'?: V1OrderLinePaused;
    'orderLineFreeImpressionsSet'?: V1OrderLineFreeImpressionsSet;
    'orderLineStatusSetToDraft'?: V1OrderLineStatusSetToDraft;
    'orderLineClickThroughUrlSet'?: V1OrderLineClickThroughURLSet;
    'orderLineSubmittedForReview'?: V1OrderLineSubmittedForReview;
    'orderLineReviewStarted'?: V1OrderLineReviewStarted;
    'orderLineRejected'?: V1OrderLineRejected;
    'orderLineApproved'?: V1OrderLineApproved;
    'orderLineDeploymentCancelled'?: V1OrderLineDeploymentCancelled;
    'orderLineDeployed'?: V1OrderLineDeployed;
    'orderLineImpressionsSet'?: V1OrderLineImpressionsSet;
    'orderLineFlightDatesSet'?: V1OrderLineFlightDatesSet;
    'orderLineDeployRetried'?: V1OrderLineDeployRetried;
    'orderLineCreativeAttached'?: V1OrderLineCreativeAttached;
    'orderLineCreativeSet'?: V1OrderLineCreativeSet;
    'orderLineCreativeDetached'?: V1OrderLineCreativeDetached;
    'orderLineTargetJobAttached'?: V1OrderLineTargetJobAttached;
    'orderLineAudienceSet'?: V1OrderLineAudienceSet;
    'orderLineTargetJobDetached'?: V1OrderLineTargetJobDetached;
    'orderLineInvestCampaignSet'?: V1OrderLineInvestCampaignSet;
    'orderLineInvestCreativesSet'?: V1OrderLineInvestCreativesSet;
    'orderLineInvestSegmentsSet'?: V1OrderLineInvestSegmentsSet;
    'orderLineInvestClickThroughUrlSet'?: V1OrderLineInvestClickThroughURLSet;
    'orderLineDestinationXandrInvestCreated'?: V1OrderLineDestinationXandrInvestCreated;
    'orderLineDeployedToDestinationXandrInvest'?: V1OrderLineDeployedToDestinationXandrInvest;
    'orderLineErroredDeployingToDestinationXandrInvest'?: V1OrderLineErroredDeployingToDestinationXandrInvest;
    'orderLineDestinationXandrInvestSet'?: V1OrderLineDestinationXandrInvestSet;
    'orderLineConsoleCampaignSet'?: V1OrderLineConsoleCampaignSet;
    'orderLineConsoleCreativesSet'?: V1OrderLineConsoleCreativesSet;
    'orderLineConsoleSegmentsSet'?: V1OrderLineConsoleSegmentsSet;
    'orderLineDestinationXandrConsoleCreated'?: V1OrderLineDestinationXandrConsoleCreated;
    'orderLineConsoleClickThroughUrlSet'?: V1OrderLineConsoleClickThroughURLSet;
    'orderLinePaidSet'?: V1OrderLinePaidSet;
    'orderLinePrepaySet'?: V1OrderLinePrepaySet;
    'orderLineCostSet'?: V1OrderLineCostSet;
    'orderLinePoliticalSet'?: V1OrderLinePoliticalSet;
    'orderLineCreating'?: V1OrderLineCreating;
    'reviewRequested'?: V1ReviewRequested;
    'orderLineDeploymentDestinationConfigurationSet'?: V1OrderLineDeploymentDestinationConfigurationSet;
    'deploymentCreated'?: V1DeploymentCreated;
    'creativeAdded'?: V1CreativeAdded;
    'creativeRemoved'?: V1CreativeRemoved;
    'orderLineStartTimeSet'?: V1OrderLineStartTimeSet;
    'orderLineEndTimeSet'?: V1OrderLineEndTimeSet;
    'orderLineImpressionsPerDaySet'?: V1OrderLineImpressionsPerDaySet;
    'orderLineNameSet'?: V1OrderLineNameSet;
    'orderLineRefIdSet'?: V1OrderLineRefIdSet;
    'orderLineAdTypeSet'?: V1OrderLineAdTypeSet;
    'orderLineLocked'?: V1OrderLineLocked;
    'orderLineUnlocked'?: V1OrderLineUnlocked;
    'orderLineDestinationXandrCurateCreated'?: V1OrderLineDestinationXandrCurateCreated;
    'orderLineDestinationXandrCurateSet'?: V1OrderLineDestinationXandrCurateSet;
    'orderLineDeployedToDestinationXandrCurate'?: V1OrderLineDeployedToDestinationXandrCurate;
    'orderLineErroredDeployingToDestinationXandrCurate'?: V1OrderLineErroredDeployingToDestinationXandrCurate;
    'orderLineArchived'?: V1OrderLineArchived;
    'orderLineUnarchived'?: V1OrderLineUnarchived;
    'audienceAdded'?: V1AudienceAdded;
    'audienceRemoved'?: V1AudienceRemoved;
    'orderLineMinimumImpressionsSet'?: V1OrderLineMinimumImpressionsSet;
    'orderLineOrgIdSet'?: V1OrderLineOrgIdSet;
    'orderLinePoliticalFieldsSet'?: V1OrderLinePoliticalFieldsSet;
    'orderLineCompleted'?: V1OrderLineCompleted;
    'orderLineSegmentConfigSet'?: V1OrderLineSegmentConfigSet;
    'orderLineTemplateTypeSet'?: V1OrderLineTemplateTypeSet;
    'orderLineNoteAppended'?: V1OrderLineNoteAppended;
    'orderLineNoteUpdated'?: V1OrderLineNoteUpdated;
    'orderLineStateSetToActive'?: V1OrderLineStateSetToActive;
    'orderLineStateSetToPaused'?: V1OrderLineStateSetToPaused;
    'orderLineAuditConditionsSet'?: V1OrderLineAuditConditionsSet;
    'orderLineDeploymentCancelling'?: V1OrderLineDeploymentCancelling;
    'orderLineSetToPrevious'?: V1OrderLineStatusSetToPrevious;
    'orderLineStatusSetToDeploying'?: V1OrderLineStatusSetToDeploying;
    'orderLineCpmSet'?: V1OrderLineCPMSet;
    'orderLineDeploymentDestinationSet'?: V1OrderLineDeploymentDestinationSet;
    'orderLineStepFunctionSet'?: V1OrderLineStepFunctionSet;
    'orderLineViantAdelphicConfigurationSet'?: V1OrderLineViantAdelphicConfigurationSet;
    'orderLineDeployedWithConfig'?: V1OrderLineDeployedWithConfig;
    'orderLineViantAdelphicIdsSet'?: V1OrderLineViantAdelphicIDsSet;
    'orderLineNoteDeactivated'?: V1OrderLineNoteDeactivated;
    'orderLineJobIdSet'?: V1OrderLineJobIdSet;
    'orderLinePoIdSet'?: V1OrderLinePoIdSet;
    'orderLineCpmOverriden'?: V1OrderLineCPMOverriden;
    'orderLineCpmOverrideRemoved'?: V1OrderLineCPMOverrideRemoved;
    'orderLineMigrated'?: V1OrderLineMigrated;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "eventType",
            "baseName": "event_type",
            "type": "string",
            "format": ""
        },
        {
            "name": "orderLineCreated",
            "baseName": "order_line_created",
            "type": "V1OrderLineCreated",
            "format": ""
        },
        {
            "name": "orderLineConfigured",
            "baseName": "order_line_configured",
            "type": "V1OrderLineConfigured",
            "format": ""
        },
        {
            "name": "orderLineCloned",
            "baseName": "order_line_cloned",
            "type": "V1OrderLineCloned",
            "format": ""
        },
        {
            "name": "orderLineCampaignAttached",
            "baseName": "order_line_campaign_attached",
            "type": "V1OrderLineCampaignAttached",
            "format": ""
        },
        {
            "name": "orderLineCampaignSet",
            "baseName": "order_line_campaign_set",
            "type": "V1OrderLineCampaignSet",
            "format": ""
        },
        {
            "name": "orderLineCampaignDetached",
            "baseName": "order_line_campaign_detached",
            "type": "V1OrderLineCampaignDetached",
            "format": ""
        },
        {
            "name": "orderLineDeleted",
            "baseName": "order_line_deleted",
            "type": "V1OrderLineDeleted",
            "format": ""
        },
        {
            "name": "orderLinePlayed",
            "baseName": "order_line_played",
            "type": "V1OrderLinePlayed",
            "format": ""
        },
        {
            "name": "orderLinePaused",
            "baseName": "order_line_paused",
            "type": "V1OrderLinePaused",
            "format": ""
        },
        {
            "name": "orderLineFreeImpressionsSet",
            "baseName": "order_line_free_impressions_set",
            "type": "V1OrderLineFreeImpressionsSet",
            "format": ""
        },
        {
            "name": "orderLineStatusSetToDraft",
            "baseName": "order_line_status_set_to_draft",
            "type": "V1OrderLineStatusSetToDraft",
            "format": ""
        },
        {
            "name": "orderLineClickThroughUrlSet",
            "baseName": "order_line_click_through_url_set",
            "type": "V1OrderLineClickThroughURLSet",
            "format": ""
        },
        {
            "name": "orderLineSubmittedForReview",
            "baseName": "order_line_submitted_for_review",
            "type": "V1OrderLineSubmittedForReview",
            "format": ""
        },
        {
            "name": "orderLineReviewStarted",
            "baseName": "order_line_review_started",
            "type": "V1OrderLineReviewStarted",
            "format": ""
        },
        {
            "name": "orderLineRejected",
            "baseName": "order_line_rejected",
            "type": "V1OrderLineRejected",
            "format": ""
        },
        {
            "name": "orderLineApproved",
            "baseName": "order_line_approved",
            "type": "V1OrderLineApproved",
            "format": ""
        },
        {
            "name": "orderLineDeploymentCancelled",
            "baseName": "order_line_deployment_cancelled",
            "type": "V1OrderLineDeploymentCancelled",
            "format": ""
        },
        {
            "name": "orderLineDeployed",
            "baseName": "order_line_deployed",
            "type": "V1OrderLineDeployed",
            "format": ""
        },
        {
            "name": "orderLineImpressionsSet",
            "baseName": "order_line_impressions_set",
            "type": "V1OrderLineImpressionsSet",
            "format": ""
        },
        {
            "name": "orderLineFlightDatesSet",
            "baseName": "order_line_flight_dates_set",
            "type": "V1OrderLineFlightDatesSet",
            "format": ""
        },
        {
            "name": "orderLineDeployRetried",
            "baseName": "order_line_deploy_retried",
            "type": "V1OrderLineDeployRetried",
            "format": ""
        },
        {
            "name": "orderLineCreativeAttached",
            "baseName": "order_line_creative_attached",
            "type": "V1OrderLineCreativeAttached",
            "format": ""
        },
        {
            "name": "orderLineCreativeSet",
            "baseName": "order_line_creative_set",
            "type": "V1OrderLineCreativeSet",
            "format": ""
        },
        {
            "name": "orderLineCreativeDetached",
            "baseName": "order_line_creative_detached",
            "type": "V1OrderLineCreativeDetached",
            "format": ""
        },
        {
            "name": "orderLineTargetJobAttached",
            "baseName": "order_line_target_job_attached",
            "type": "V1OrderLineTargetJobAttached",
            "format": ""
        },
        {
            "name": "orderLineAudienceSet",
            "baseName": "order_line_audience_set",
            "type": "V1OrderLineAudienceSet",
            "format": ""
        },
        {
            "name": "orderLineTargetJobDetached",
            "baseName": "order_line_target_job_detached",
            "type": "V1OrderLineTargetJobDetached",
            "format": ""
        },
        {
            "name": "orderLineInvestCampaignSet",
            "baseName": "order_line_invest_campaign_set",
            "type": "V1OrderLineInvestCampaignSet",
            "format": ""
        },
        {
            "name": "orderLineInvestCreativesSet",
            "baseName": "order_line_invest_creatives_set",
            "type": "V1OrderLineInvestCreativesSet",
            "format": ""
        },
        {
            "name": "orderLineInvestSegmentsSet",
            "baseName": "order_line_invest_segments_set",
            "type": "V1OrderLineInvestSegmentsSet",
            "format": ""
        },
        {
            "name": "orderLineInvestClickThroughUrlSet",
            "baseName": "order_line_invest_click_through_url_set",
            "type": "V1OrderLineInvestClickThroughURLSet",
            "format": ""
        },
        {
            "name": "orderLineDestinationXandrInvestCreated",
            "baseName": "order_line_destination_xandr_invest_created",
            "type": "V1OrderLineDestinationXandrInvestCreated",
            "format": ""
        },
        {
            "name": "orderLineDeployedToDestinationXandrInvest",
            "baseName": "order_line_deployed_to_destination_xandr_invest",
            "type": "V1OrderLineDeployedToDestinationXandrInvest",
            "format": ""
        },
        {
            "name": "orderLineErroredDeployingToDestinationXandrInvest",
            "baseName": "order_line_errored_deploying_to_destination_xandr_invest",
            "type": "V1OrderLineErroredDeployingToDestinationXandrInvest",
            "format": ""
        },
        {
            "name": "orderLineDestinationXandrInvestSet",
            "baseName": "order_line_destination_xandr_invest_set",
            "type": "V1OrderLineDestinationXandrInvestSet",
            "format": ""
        },
        {
            "name": "orderLineConsoleCampaignSet",
            "baseName": "order_line_console_campaign_set",
            "type": "V1OrderLineConsoleCampaignSet",
            "format": ""
        },
        {
            "name": "orderLineConsoleCreativesSet",
            "baseName": "order_line_console_creatives_set",
            "type": "V1OrderLineConsoleCreativesSet",
            "format": ""
        },
        {
            "name": "orderLineConsoleSegmentsSet",
            "baseName": "order_line_console_segments_set",
            "type": "V1OrderLineConsoleSegmentsSet",
            "format": ""
        },
        {
            "name": "orderLineDestinationXandrConsoleCreated",
            "baseName": "order_line_destination_xandr_console_created",
            "type": "V1OrderLineDestinationXandrConsoleCreated",
            "format": ""
        },
        {
            "name": "orderLineConsoleClickThroughUrlSet",
            "baseName": "order_line_console_click_through_url_set",
            "type": "V1OrderLineConsoleClickThroughURLSet",
            "format": ""
        },
        {
            "name": "orderLinePaidSet",
            "baseName": "order_line_paid_set",
            "type": "V1OrderLinePaidSet",
            "format": ""
        },
        {
            "name": "orderLinePrepaySet",
            "baseName": "order_line_prepay_set",
            "type": "V1OrderLinePrepaySet",
            "format": ""
        },
        {
            "name": "orderLineCostSet",
            "baseName": "order_line_cost_set",
            "type": "V1OrderLineCostSet",
            "format": ""
        },
        {
            "name": "orderLinePoliticalSet",
            "baseName": "order_line_political_set",
            "type": "V1OrderLinePoliticalSet",
            "format": ""
        },
        {
            "name": "orderLineCreating",
            "baseName": "order_line_creating",
            "type": "V1OrderLineCreating",
            "format": ""
        },
        {
            "name": "reviewRequested",
            "baseName": "review_requested",
            "type": "V1ReviewRequested",
            "format": ""
        },
        {
            "name": "orderLineDeploymentDestinationConfigurationSet",
            "baseName": "order_line_deployment_destination_configuration_set",
            "type": "V1OrderLineDeploymentDestinationConfigurationSet",
            "format": ""
        },
        {
            "name": "deploymentCreated",
            "baseName": "deployment_created",
            "type": "V1DeploymentCreated",
            "format": ""
        },
        {
            "name": "creativeAdded",
            "baseName": "creative_added",
            "type": "V1CreativeAdded",
            "format": ""
        },
        {
            "name": "creativeRemoved",
            "baseName": "creative_removed",
            "type": "V1CreativeRemoved",
            "format": ""
        },
        {
            "name": "orderLineStartTimeSet",
            "baseName": "order_line_start_time_set",
            "type": "V1OrderLineStartTimeSet",
            "format": ""
        },
        {
            "name": "orderLineEndTimeSet",
            "baseName": "order_line_end_time_set",
            "type": "V1OrderLineEndTimeSet",
            "format": ""
        },
        {
            "name": "orderLineImpressionsPerDaySet",
            "baseName": "order_line_impressions_per_day_set",
            "type": "V1OrderLineImpressionsPerDaySet",
            "format": ""
        },
        {
            "name": "orderLineNameSet",
            "baseName": "order_line_name_set",
            "type": "V1OrderLineNameSet",
            "format": ""
        },
        {
            "name": "orderLineRefIdSet",
            "baseName": "order_line_ref_id_set",
            "type": "V1OrderLineRefIdSet",
            "format": ""
        },
        {
            "name": "orderLineAdTypeSet",
            "baseName": "order_line_ad_type_set",
            "type": "V1OrderLineAdTypeSet",
            "format": ""
        },
        {
            "name": "orderLineLocked",
            "baseName": "order_line_locked",
            "type": "V1OrderLineLocked",
            "format": ""
        },
        {
            "name": "orderLineUnlocked",
            "baseName": "order_line_unlocked",
            "type": "V1OrderLineUnlocked",
            "format": ""
        },
        {
            "name": "orderLineDestinationXandrCurateCreated",
            "baseName": "order_line_destination_xandr_curate_created",
            "type": "V1OrderLineDestinationXandrCurateCreated",
            "format": ""
        },
        {
            "name": "orderLineDestinationXandrCurateSet",
            "baseName": "order_line_destination_xandr_curate_set",
            "type": "V1OrderLineDestinationXandrCurateSet",
            "format": ""
        },
        {
            "name": "orderLineDeployedToDestinationXandrCurate",
            "baseName": "order_line_deployed_to_destination_xandr_curate",
            "type": "V1OrderLineDeployedToDestinationXandrCurate",
            "format": ""
        },
        {
            "name": "orderLineErroredDeployingToDestinationXandrCurate",
            "baseName": "order_line_errored_deploying_to_destination_xandr_curate",
            "type": "V1OrderLineErroredDeployingToDestinationXandrCurate",
            "format": ""
        },
        {
            "name": "orderLineArchived",
            "baseName": "order_line_archived",
            "type": "V1OrderLineArchived",
            "format": ""
        },
        {
            "name": "orderLineUnarchived",
            "baseName": "order_line_unarchived",
            "type": "V1OrderLineUnarchived",
            "format": ""
        },
        {
            "name": "audienceAdded",
            "baseName": "audience_added",
            "type": "V1AudienceAdded",
            "format": ""
        },
        {
            "name": "audienceRemoved",
            "baseName": "audience_removed",
            "type": "V1AudienceRemoved",
            "format": ""
        },
        {
            "name": "orderLineMinimumImpressionsSet",
            "baseName": "order_line_minimum_impressions_set",
            "type": "V1OrderLineMinimumImpressionsSet",
            "format": ""
        },
        {
            "name": "orderLineOrgIdSet",
            "baseName": "order_line_org_id_set",
            "type": "V1OrderLineOrgIdSet",
            "format": ""
        },
        {
            "name": "orderLinePoliticalFieldsSet",
            "baseName": "order_line_political_fields_set",
            "type": "V1OrderLinePoliticalFieldsSet",
            "format": ""
        },
        {
            "name": "orderLineCompleted",
            "baseName": "order_line_completed",
            "type": "V1OrderLineCompleted",
            "format": ""
        },
        {
            "name": "orderLineSegmentConfigSet",
            "baseName": "order_line_segment_config_set",
            "type": "V1OrderLineSegmentConfigSet",
            "format": ""
        },
        {
            "name": "orderLineTemplateTypeSet",
            "baseName": "order_line_template_type_set",
            "type": "V1OrderLineTemplateTypeSet",
            "format": ""
        },
        {
            "name": "orderLineNoteAppended",
            "baseName": "order_line_note_appended",
            "type": "V1OrderLineNoteAppended",
            "format": ""
        },
        {
            "name": "orderLineNoteUpdated",
            "baseName": "order_line_note_updated",
            "type": "V1OrderLineNoteUpdated",
            "format": ""
        },
        {
            "name": "orderLineStateSetToActive",
            "baseName": "order_line_state_set_to_active",
            "type": "V1OrderLineStateSetToActive",
            "format": ""
        },
        {
            "name": "orderLineStateSetToPaused",
            "baseName": "order_line_state_set_to_paused",
            "type": "V1OrderLineStateSetToPaused",
            "format": ""
        },
        {
            "name": "orderLineAuditConditionsSet",
            "baseName": "order_line_audit_conditions_set",
            "type": "V1OrderLineAuditConditionsSet",
            "format": ""
        },
        {
            "name": "orderLineDeploymentCancelling",
            "baseName": "order_line_deployment_cancelling",
            "type": "V1OrderLineDeploymentCancelling",
            "format": ""
        },
        {
            "name": "orderLineSetToPrevious",
            "baseName": "order_line_set_to_previous",
            "type": "V1OrderLineStatusSetToPrevious",
            "format": ""
        },
        {
            "name": "orderLineStatusSetToDeploying",
            "baseName": "order_line_status_set_to_deploying",
            "type": "V1OrderLineStatusSetToDeploying",
            "format": ""
        },
        {
            "name": "orderLineCpmSet",
            "baseName": "order_line_cpm_set",
            "type": "V1OrderLineCPMSet",
            "format": ""
        },
        {
            "name": "orderLineDeploymentDestinationSet",
            "baseName": "order_line_deployment_destination_set",
            "type": "V1OrderLineDeploymentDestinationSet",
            "format": ""
        },
        {
            "name": "orderLineStepFunctionSet",
            "baseName": "order_line_step_function_set",
            "type": "V1OrderLineStepFunctionSet",
            "format": ""
        },
        {
            "name": "orderLineViantAdelphicConfigurationSet",
            "baseName": "order_line_viant_adelphic_configuration_set",
            "type": "V1OrderLineViantAdelphicConfigurationSet",
            "format": ""
        },
        {
            "name": "orderLineDeployedWithConfig",
            "baseName": "order_line_deployed_with_config",
            "type": "V1OrderLineDeployedWithConfig",
            "format": ""
        },
        {
            "name": "orderLineViantAdelphicIdsSet",
            "baseName": "order_line_viant_adelphic_ids_set",
            "type": "V1OrderLineViantAdelphicIDsSet",
            "format": ""
        },
        {
            "name": "orderLineNoteDeactivated",
            "baseName": "order_line_note_deactivated",
            "type": "V1OrderLineNoteDeactivated",
            "format": ""
        },
        {
            "name": "orderLineJobIdSet",
            "baseName": "order_line_job_id_set",
            "type": "V1OrderLineJobIdSet",
            "format": ""
        },
        {
            "name": "orderLinePoIdSet",
            "baseName": "order_line_po_id_set",
            "type": "V1OrderLinePoIdSet",
            "format": ""
        },
        {
            "name": "orderLineCpmOverriden",
            "baseName": "order_line_cpm_overriden",
            "type": "V1OrderLineCPMOverriden",
            "format": ""
        },
        {
            "name": "orderLineCpmOverrideRemoved",
            "baseName": "order_line_cpm_override_removed",
            "type": "V1OrderLineCPMOverrideRemoved",
            "format": ""
        },
        {
            "name": "orderLineMigrated",
            "baseName": "order_line_migrated",
            "type": "V1OrderLineMigrated",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1OrderLineEvent.attributeTypeMap;
    }

    public constructor() {
    }
}

