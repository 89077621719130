/*
   This context watches and handles notifications of server side events as they are emitted by the next-gen API.
   The list of all supported events is commented at the bottom of this page.
   If you need to listen to events within a specific component, use the hook useSSEListener.
   If you need to update the entire app, trigger an update here.
 */

import {
  EventSourceMessage,
  fetchEventSource,
} from '@microsoft/fetch-event-source'
import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from 'react'
import {
  Button,
  showErrorMessage,
  showInfoMessage,
  showSuccessMessage,
  showWarningMessage,
} from '@eltoro-ui/components'
import { useAppContext } from 'Contexts'
import { useAuth } from 'react-oidc-context'
import { SSEMessageType } from 'types'
import { getApiUrl } from 'Helpers'

export type SSEContextType = {
  SSEMessageListRef: React.MutableRefObject<SSEMessageType[]>
  SSEMessageList: SSEMessageType[]
  triggerUpdate: () => void
  handleRemoveMessage: (msg: SSEMessageType, update?: boolean) => void
}

export const sseContextBaseState = {
  SSEMessageListRef: { current: [] },
  SSEMessageList: [{ eventType: '', topic: '', message: {} }],
  triggerUpdate: () => {},
  handleRemoveMessage: () => {},
}

export const SSEContext = React.createContext<SSEContextType>(
  sseContextBaseState,
)
export const useSSEContext = () => {
  const ctx = useContext(SSEContext)
  if (!ctx)
    throw new Error('useSSEContext must be used within the SSEContextProvider')
  return ctx
}

const onMessage = (msg: EventSourceMessage, endPoint: 'org' | 'user') => {
  const formatMsg = (str: string) =>
    str ? str.replace(/([A-Z])/g, ' $1').trim() : ''
  const parsedData = JSON.parse(msg.data)
  const parsedMessage = JSON.parse(parsedData.message)
  const isError = formatMsg(parsedData.event_type)
    .split(' ')
    .some((word: string) => word === 'Error')

  // Dispatch event for useSSEListener to catch
  if (
    parsedData.event_type &&
    (endPoint === 'org' ||
      (endPoint === 'user' &&
        (parsedData.event_type === 'OrgUpdated' ||
          parsedData.event_type === 'OrgDeleted')))
  ) {
    window.dispatchEvent(
      new CustomEvent(parsedData.event_type, {
        detail: {
          eventType: parsedData?.event_type,
          topic: parsedData?.topic,
          message: parsedMessage,
        },
      }),
    )
  }

  if (isError) {
    if (parsedData.event_type === 'DeployCampaignToXandrInvestError') {
      showErrorMessage(
        'Deploy Failed',
        'Your order line has failed to deploy to the destination',
      )
    }
    if (parsedData.event_type === 'DeployCampaignToXandrCurateError') {
      showErrorMessage(
        'Deploy Failed',
        'Your audience has failed to deploy to the destination',
      )
    }
    if (parsedData.event_type === 'DeployAdvertiserToXandrInvestError') {
      showErrorMessage(
        'Deploy failed',
        'Your Advertiser has failed to deploy to the destination. Please contact support',
      )
    }
    if (
      parsedData.event_type !== 'DeployCampaignToXandrInvestError' &&
      parsedData.event_type !== 'DeployCampaignToXandrCurateError' &&
      parsedData.event_type !== 'DeployAdvertiserToXandrInvestError'
    ) {
      showErrorMessage(formatMsg(parsedData.event_type), parsedMessage.message)
    }
  }
  return { parsedData, parsedMessage }
}

export const SSEContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { currentOrg, setRebuild } = useAppContext()
  const auth = useAuth()
  const url = getApiUrl()

  const orgEndpoint =
    currentOrg?.id && `${url}/v1/sse:subscribe?org_id=${currentOrg.id}`
  // const userEndpoint =
  //   auth.user?.profile.sub &&
  //   `${url}/v1/sse:subscribe?user_id=${auth.user?.profile.sub}`
  /* const adminEndpoint = `${url}/v1/sse:subscribe` */
  const [SSEMessageList, setSSEMessageList] = useState<SSEMessageType[]>([])
  const SSEMessageListRef = useRef<SSEMessageType[]>([])
  const setSSEMessageListRef = (newMessage: SSEMessageType) => {
    SSEMessageListRef.current = [...SSEMessageListRef.current, newMessage]
  }
  const triggerUpdate = useCallback(() => {
    setSSEMessageList(SSEMessageListRef.current)
  }, [SSEMessageListRef])

  const handleRemoveMessage = (msg: SSEMessageType, update?: boolean) => {
    const filtered = SSEMessageListRef.current.filter(
      (oldMsg) =>
        oldMsg.eventType !== msg.eventType &&
        oldMsg.message.id !== msg.message.id &&
        oldMsg.message.request_id !== msg.message.request_id,
    )
    SSEMessageListRef.current = filtered
    if (update) triggerUpdate()
  }

  const onError = (err: any) => {
    if (typeof err === 'string') {
      showErrorMessage(err, '')
    }
  }

  useEffect(() => {
    const controller = new AbortController()
    const fetchMyEvents = async () => {
      if (auth && auth.user?.access_token && orgEndpoint) {
        await fetchEventSource(orgEndpoint, {
          headers: {
            'Content-Type': 'text/event-stream',
            authorization: `Bearer ${auth?.user?.access_token}`,
          },
          keepalive: true,
          signal: controller.signal,
          onmessage(msg) {
            const { parsedData, parsedMessage } = onMessage(msg, 'org')
            switch (parsedData.topic) {
              case 'orgservice':
                if (parsedData.event_type === 'OrgLogoUploaded') {
                  if (parsedMessage.user_id === auth?.user?.profile.sub) {
                    showSuccessMessage(
                      `Upload Successful!`,
                      "Your org's logo has been updated",
                    )
                  }
                }
                if (parsedData.event_type === 'OrgLogoDeleted') {
                  if (parsedMessage.user_id === auth?.user?.profile.sub) {
                    showSuccessMessage(
                      'Delete Successful!',
                      "Your org's logo has been removed",
                    )
                  }
                }
                break
              case 'deployservice':
                if (parsedData.event_type === 'CreativeCreated') {
                  console.log('deployservice-->CreativeCreated')
                }
                break
              case 'creativeservice':
                if (parsedData.event_type === 'BannerCreativeCreated') {
                  if (parsedMessage.user_id === auth?.user?.profile.sub) {
                    showInfoMessage(
                      `Creating: ${parsedMessage.name}`,
                      'Your creative is being created',
                    )
                  }
                }
                if (parsedData.event_type === 'CreativeDeleted') {
                  if (parsedMessage.user_id === auth?.user?.profile.sub) {
                    showSuccessMessage(
                      'Creative Deleted',
                      'Your creative has been removed from your library',
                    )
                  }
                }
                if (parsedData.event_type === 'CreativeUpdated') {
                  if (
                    parsedMessage.creative.status === 2 &&
                    parsedMessage.update_mask.paths.includes('status')
                  )
                    if (parsedMessage.user_id === auth?.user?.profile.sub) {
                      showSuccessMessage(
                        `Created: ${parsedMessage.creative.name}`,
                        'Your creative was successfully created',
                      )
                    } else {
                      showInfoMessage(
                        `New Creatives Added`,
                        <>
                          <p className="flex flex-col gap-3 text-sm">
                            Creatives has added to your org.
                            <Button
                              onClick={() => {
                                setRebuild(true)
                              }}
                            >
                              Refresh?
                            </Button>
                          </p>
                        </>,
                      )
                    }
                }
                break
              case 'targetservice':
                switch (parsedData.event_type) {
                  case 'TargetUploaded':
                    console.log('targetService-->TargetUploaded')
                    if (
                      parsedMessage.user_id === auth?.user?.profile.sub &&
                      !parsedMessage.job_id // do not show vr sub jobs
                    ) {
                      const fileLocations = parsedMessage.locations || []
                      // if it has more locations, then this is likely a replace file upload
                      if (fileLocations.length === 1)
                        showInfoMessage(
                          // Removing File extension
                          `Creating: ${fileLocations[0].filename
                            .split('.')
                            .slice(0, -1)
                            .join('.')}`,
                          'Your Audience is being created',
                        )
                    }
                    break
                  case 'TargetFileCleaned':
                    console.log('targetService-->TargetFileCleaned')
                    break
                  case 'JobCreated':
                    console.log('targetService-->JobCreated')
                    if (parsedMessage.job_id) return // do not show vr sub jobs
                    if (parsedMessage.user_id === auth?.user?.profile.sub) {
                      showInfoMessage(
                        `Created: ${parsedMessage.name}`,
                        'Your Audience has been created',
                      )
                    } else {
                      showInfoMessage(
                        `New Audience(s) Added`,
                        <div>
                          <p className="text-sm">
                            Someone in your org has added an Audience.
                          </p>
                          <Button
                            onClick={() => {
                              setRebuild(true)
                            }}
                          >
                            Refresh?
                          </Button>
                        </div>,
                      )
                    }
                    break
                  case 'JobSegmentAttached':
                    console.log('targetService-->JobSegmentAttached')
                    break
                  case 'JobFinished':
                    console.log('targetService-->JobFinished')
                    if (
                      parsedMessage.user_id === auth?.user?.profile.sub ||
                      parsedMessage.user_id === 'onspot' // for VR jobs
                    ) {
                      if (
                        parsedMessage.audience.status === 35 ||
                        parsedMessage.audience.status === 30
                      ) {
                        const { audience } = parsedMessage
                        // Skip showing success for VR child jobs and skip retargeting
                        if (audience.audience_id || audience.type === 115)
                          return
                        if (audience.result) {
                          // Check each audience type for results, if none show zero warning
                          const isZipWithNoMatches =
                            audience.type === 17 &&
                            !(
                              audience.result.addresses_matched ||
                              audience.result.returned_ip_count
                            )
                          const isDCWithNoMatches =
                            audience.type === 11 &&
                            !audience.result.neighbors_matched
                          const isVRWithNoMatches =
                            audience.type === 37 && !audience.result.total
                          const isMPWithNoMatches =
                            audience.type === 33 && !audience.result.ips_matched
                          if (
                            isZipWithNoMatches ||
                            isDCWithNoMatches ||
                            isVRWithNoMatches ||
                            isMPWithNoMatches ||
                            (!audience.result.matched && !audience.result.total)
                          ) {
                            showWarningMessage(
                              `Audience: ${audience.name}`,
                              'Your audience returned 0 matches.',
                            )
                            return
                          }
                          // If has results
                          showSuccessMessage(
                            `Audience: ${audience.name}`,
                            'Your audience has finished quoting.',
                          )
                        }
                      }
                    }
                    break
                  case 'JobUpdated':
                    console.log('targetService-->JobUpdated')
                    break
                  case 'JobDeleted':
                    if (parsedMessage.user_id === auth?.user?.profile.sub) {
                      showSuccessMessage(
                        'Audience Deleted',
                        'The audience has been removed from your library.',
                      )
                    }
                    break
                  default:
                }
                break
              default:
            }
          },
          onopen(res) {
            if (res && res.type === 'cors' && res.status === 401) {
              setRebuild(true)
            }
            return Promise.resolve()
          },
          onerror(err) {
            onError(err)
          },
          fetch: fetch,
          // I'm leaving this for future testing
          /* onclose() {
           *   console.log('event listener closed')
           * }, */
        })
      }
    }
    fetchMyEvents()
    return () => controller.abort()
  }, [auth, auth?.user?.profile.sub, setRebuild, orgEndpoint])

  // Opens up user SSE endpoint, which is needed for some orgservice messages (OrgDeleted, OrgCreated)
  // useEffect(() => {
  //   const controller = new AbortController()
  //   const fetchMyEvents = async () => {
  //     if (auth && auth?.user?.access_token && userEndpoint) {
  //       await fetchEventSource(userEndpoint, {
  //         headers: {
  //           'Content-Type': 'text/event-stream',
  //           authorization: `Bearer ${auth?.user?.access_token}`,
  //         },
  //         keepalive: true,
  //         signal: controller.signal,
  //         onmessage(msg) {
  //           onMessage(msg, 'user')
  //         },
  //         onopen(res) {
  //           if (res && res.type === 'cors' && res.status === 401) {
  //             setRebuild(true)
  //           }
  //           return Promise.resolve()
  //         },
  //         onerror(err) {
  //           onError(err)
  //         },
  //         fetch: fetch,
  //         // I'm leaving this for future testing
  //         /* onclose() {
  //          *   console.log('event listener closed')
  //          * }, */
  //       })
  //     }
  //   }

  //   return () => controller.abort()
  // }, [auth, auth?.user?.profile.sub, setRebuild, userEndpoint])

  const contextValues = {
    SSEMessageListRef,
    SSEMessageList,
    triggerUpdate,
    setSSEMessageListRef,
    handleRemoveMessage,
  }
  if (!children) {
    return <></>
  }
  return (
    <SSEContext.Provider value={contextValues}>{children}</SSEContext.Provider>
  )
}

/*
   list of all published topics and there events :

	"targetservice": {
			"TargetUploaded",
			"TargetFileCleaned",
			"TargetUpdated",
			"JsonTargetCreated",
			"UploadTargetError",
			"DownloadFileError",
			"DownloadJobFileError",
			"ConfigureTargetError",
			"CreateTargetFromJsonInputError",
			"SetTargetRefIDError",
			"AddJobToTargetError",
			"DeleteTargetError",
			"AddTargetToFolderError",
			"RemoveTargetFromFolderError",
			"JobCreated",
			"JobUpdated",
			"JobSegmentAttached",
			"JobFinished",
			"OrderLineAdded",
			"OrderLineRemoved",
			"StartJobError",
			"AddJobToQueueError",
			"CreateJobError",
			"CancelJobError",
			"AttachSegmentToJobError",
			"AttachJobToOrderLineError",
			"DetachJobFromOrderLineError",
			"UpdateJobResultError",
			"UpdateJobError",
			"JobDeleted",
			"DeleteJobError",
		},
		"orgservice": {
			"OrgCreated",
			"OrgUpdated",
			"OrgDeleted",
			"OrgCPMCreated",
			"OrgCPMUpdated",
			"OrgCPMDeleted",
			"OrgContactCreated",
			"OrgContactUpdated",
			"OrgContactDeleted",
			"OrgAccountRepAdded",
			"OrgAccountRepRemoved",
			"OrgSalesRepAdded",
			"OrgSalesRepRemoved",
			"OrgUserAdded",
			"OrgUserRemoved",
			"OrgLogoUploaded",
			"OrgLogoDeleted",
			"CreateOrgError",
			"UpdateOrgError",
			"DeleteOrgError",
			"CreateContactError",
			"UpdateContactError",
			"DeleteContactError",
			"AddAccountRepError",
			"RemoveAccountRepError",
			"AddSalesRepError",
			"RemoveSalesRepError",
			"AddUserError",
			"RemoveUserError",
			"UploadOrgLogoError",
			"CreateCPMError",
			"UpdateCPMError",
			"DeleteCPMError",
			"OrgUserCreated",
			"OrgUserDeleted",
			"OrgUserUpdated",
			"OrgUserCreatedError",
			"OrgUserDeletedError",
			"OrgUserUpdatedError",
			"AdvertiserUpdateCompensated",
		},
		"orderlineservice": {
			"OrderLineCreating",
			"OrderLineCreated",
			"OrderLineDeleted",
			"OrderLinePlayed",
			"OrderLinePaused",
			"OrderLineLocked",
			"OrderLineUnlocked",
			"OrderLineStatusSetToDraft",
			"OrderLineUpdated",
			"ReviewRequested",
			"CreativeAdded",
			"CreativeUpdated",
			"CreativeRemoved",
			"AudienceAdded",
			"AudienceUpdated",
			"AudienceRemoved",
			"OrderLineReviewStarted",
			"OrderLineRejected",
			"OrderLineApproved",
			"OrderLineDeploymentCancelled",
			"OrderLineDeployed",
			"OrderLineDeployRetried",
			"OrderLineInvestCampaignSet",
			"OrderLineInvestCreativesSet",
			"OrderLineInvestSegmentsSet",
			"OrderLineInvestClickThroughURLSet",
			"OrderLineDestinationXandrInvestCreated",
			"OrderLineDeployedToDestinationXandrInvest",
			"OrderLineErroredDeployingToDestinationXandrInvest",
			"OrderLineDestinationXandrInvestSet",
			"OrderLineDestinationXandrCurateCreated",
			"OrderLineDeployedToDestinationXandrCurate",
			"OrderLineErroredDeployingToDestinationXandrCurate",
			"OrderLineDestinationXandrCurateSet",
			"OrderLineConsoleCampaignSet",
			"OrderLineConsoleCreativesSet",
			"OrderLineConsoleSegmentsSet",
			"OrderLineDestinationXandrConsoleCreated",
			"OrderLineConsoleClickThroughURLSet",
			"CreateOrderLineError",
			"UpdateOrderLineError",
			"CostCalculated",
			"CostRemoved",
			"DeleteOrderLineError",
			"RetryDeployError",
			"OrderLineStatusSetToDraftError",
			"ConfigureDeploymentError",
			"DeploymentCreated",
			"DeploymentUpdated",
			"OrderLineArchived",
			"OrderLineUnarchived",
		},
		"creativeservice": {
			"BannerCreativeCreated",
			"BannerCreativeStatusProcessing",
			"BannerCreativeStatusErrored",
			"BannerCreativeStatusSuccess",
			"AdTagCreativeCreated",
			"VASTCreativeCreated",
			"VASTCreativeStatusProcessing",
			"VASTCreativeStatusErrored",
			"VASTCreativeStatusSuccess",
			"OTTCreativeCreated",
			"OTTCreativeStatusProcessing",
			"OTTCreativeStatusErrored",
			"OTTCreativeStatusSuccess",
			"VASTAdTagCreativeCreated",
			"NativeCreativeCreated",
			"NativeCreativeStatusProcessing",
			"NativeCreativeStatusErrored",
			"NativeCreativeStatusSuccess",
			"HTML5CreativeCreated",
			"HTML5CreativeStatusProcessing",
			"HTML5CreativeStatusErrored",
			"HTML5CreativeStatusSuccess",
			"DirectMailCreativeCreated",
			"DirectMailCreativeStatusProcessing",
			"DirectMailCreativeStatusErrored",
			"DirectMailCreativeStatusSuccess",
			"CreativeDeleted",
			"CreativeUpdated",
			"OrderLineAdded",
			"OrderLineRemoved",
			"UploadCreativeFileError",
			"CreateCreativeError",
			"DownloadCreativeFileError",
			"AddOrderLineError",
			"RemoveOrderLineError",
			"DeleteCreativeError",
		},
		"deployservice": {
			"AdvertiserCreated",
			"AdvertiserNameSet",
			"CampaignCreated",
			"CreativeCreated",
			"SegmentCreated",
			"SegmentLocationSet",
			"DeploymentCreated",
			"CampaignDeployedToXandrInvest",
			"CampaignDeployedToXandrCurate",
			"CreateAdvertiserError",
			"SetAdvertiserNameError",
			"CreateCreativeError",
			"CreateSegmentError",
			"SetLocationOnSegmentError",
			"CreateCampaignError",
			"CreateDeploymentError",
			"UpdateDeploymentError",
			"SegmentAttachmentCompensated",
			"AdvertiserDeployedToXandrInvest",
			"DeployCampaignToXandrInvestError",
			"DeployCampaignToXandrCurateError",
			"DeployAdvertiserToXandrInvestError",
			"AdvertiserDeleted",
			"CampaignDeleted",
			"CreativeDeleted",
			"SegmentDeleted",
			"DeploymentDeleted",
			"AdvertiserUpdated",
			"DeploymentUpdated",
			"SegmentDeployed",
		},
		"campaignservice": {
			"CampaignCreated",
			"OrderLineAdded",
			"OrderLineUpdated",
			"OrderLineRemoved",
			"CampaignUpdated",
			"CampaignDeleted",
			"CreateCampaignError",
			"AddOrderLineError",
			"RemoveOrderLineError",
			"SetCampaignNameError",
			"SetCampaignRefIDError",
			"DeleteCampaignError",
			"CampaignArchived",
			"CampaignUnarchived",
		},
		"costcalculator": {
			"OrderLineCostCalculated",
			"OrderLineCostRemoved",
			"CreateOrderLineError",
			"CalculateCostError",
			"AddTargetJobError",
			"RemoveTargetJobError",
			"AddCreativeError",
			"RemoveCreativeError",
			"AddCPMError",
			"SetCPMError",
			"RemoveCPMError",
			"SetImpressionsError",
			"RemoveCostError",
			"OrderLineUpdated",
		},
		"onspot-api-gateway": {
			"VenueReplayFilesCreated",
		},
		"userservice": {
			"UserCreated",
			"UserDeleted",
			"UserUpdated",
		},
 */
