import {
  Campaignservicev1OrderLine,
  V2Target,
  Targetjobservicev1Audience,
  V1ProductType,
  Targetorchestratorv2FileType,
} from 'next-gen-sdk'
import { TargetLocationMetadataType } from 'types'
import { simplifyEnum } from './enum-helpers'
import dayjs from 'dayjs'

// Converts meta data on target location, which comes back as a base64 string
export const convertMetadata = (
  meta?: string,
): TargetLocationMetadataType | undefined => {
  if (!meta) return undefined
  const string = atob(meta).replace(/-./g, (letter) => letter.toUpperCase()[1]) // Replace kebab-case object properties
  return JSON.parse(string) || undefined
}
// Get row count from cleaned target location
export const getTargetRows = (target: V2Target) => {
  if (target.locations) {
    const location = target?.locations.find((loc) => loc.type === 'cleaned')
    if (location) {
      const totalRows = convertMetadata(location.metadata)?.bucketReview
        .totalLines
      if (totalRows) {
        return `${
          convertMetadata(location.metadata)?.bucketReview.totalLines
        } rows`
      }
    }
  }
  return 'unknown'
}
// Get icon for audience product type
export const getAudienceProductIcon = (type: V1ProductType) => {
  switch (type) {
    case 'PRODUCT_TYPE_IP_TARGETING':
      return 'ip-targeting'
    case 'PRODUCT_TYPE_DIGITAL_CANVASSING':
      return 'digital-canvassing'
    case 'PRODUCT_TYPE_VENUE_REPLAY':
      return 'venue-replay'
    case 'PRODUCT_TYPE_NEW_MOVERS':
      return 'digital-new-movers'
    case 'PRODUCT_TYPE_MAP_POLYGON':
      return 'map-polygon'
    case 'PRODUCT_TYPE_RETARGETING':
      return 'retargeting'
    default:
      return null
  }
}
// Get the correct ET icon names for target types
export const getTargetIconET = (jobType: string) => {
  switch (jobType) {
    case 'AUDIENCE_TYPE_ADDRESS':
    case 'address':
      return 'sale-doc'
    case 'b2b':
    case 'AUDIENCE_TYPE_B2B':
      return 'b2b'
    case 'AUDIENCE_TYPE_B2C':
    case 'b2c':
      return 'b2c'
    case 'AUDIENCE_TYPE_DC':
    case 'dc':
      return 'digital-canvassing'
    case 'ipListForMailing':
    case 'AUDIENCE_TYPE_ADDRESSES_FOR_IP':
      return 'map-data'
    case 'AUDIENCE_TYPE_DIRECTMAIL':
      return 'direct-mail'
    case 'escrow':
    case 'AUDIENCE_TYPE_ESCROWMOVER':
    case 'pre':
    case 'AUDIENCE_TYPE_PREMOVER':
      return 'digital-new-movers'
    case 'AUDIENCE_TYPE_IP':
    case 'ip':
      return 'ip-targeting'
    case 'AUDIENCE_TYPE_IPSFORGEOJSON':
    case 'AUDIENCE_TYPE_IPSFORWKB':
      return 'map-polygon'
    case 'AUDIENCE_TYPE_DEVICE':
    case 'device':
    case 'AUDIENCE_TYPE_ONSPOTMAID':
    case 'AUDIENCE_TYPE_MAID':
      return 'maid'
    case 'new-mover':
    case 'AUDIENCE_TYPE_POSTMOVER':
    case 'post':
      return 'movers'
    case 'AUDIENCE_TYPE_RETARGETING':
      return 'retargeting'
    case 'AUDIENCE_TYPE_VR':
    case 'vr':
      return 'venue-replay'
    case 'reverseIp':
    case 'AUDIENCE_TYPE_REVERSE':
    case 'AUDIENCE_TYPE_ZIP':
    case 'zip':
      return 'locator'
    case 'AUDIENCE_TYPE_UNKNOWN':
      return 'question'
    default:
      return 'target'
  }
}
// Get a user friendly name for job types (ip -> 'IP Address List')
export const userFriendlyJobStatus = (jobStatus: string) => {
  if (jobStatus === 'AUDIENCE_STATUS_RUNNING') return 'Processing'
  return jobStatus
    ?.replace('AUDIENCE_STATUS_', '')
    .toLowerCase()
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
}
export const userFriendlyAudienceName = (
  jobType: string,
  dashboardDefault?: boolean,
) => {
  switch (jobType) {
    case 'AUDIENCE_TYPE_DEVICES_FOR_HOUSEHOLDS':
      return 'Devices For Households'
    case 'AUDIENCE_TYPE_ADDRESS':
    case 'JOB_TYPE_ADDRESS':
    case 'custom':
      return 'Address'
    case 'AUDIENCE_TYPE_VR':
    case 'JOB_TYPE_VR':
    case 'venue-replay':
    case 'vr':
    case 'PRODUCT_TYPE_VENUE_REPLAY':
      return 'Venue Replay'
    case 'AUDIENCE_TYPE_IPSFORWKB':
    case 'JOB_TYPE_IPSFORWKB':
    case 'AUDIENCE_TYPE_IPSFORGEOJSON':
    case 'JOB_TYPE_IPSFORGEOJSON':
    case 'map-polygon':
    case 'PRODUCT_TYPE_MAP_POLYGON':
      return 'Map Polygon'
    case 'AUDIENCE_TYPE_IP':
    case 'JOB_TYPE_IP':
    case 'ip':
      return 'IP Address List'
    case 'AUDIENCE_TYPE_ZIP':
    case 'JOB_TYPE_ZIP':
    case 'zip':
      return 'Zip Code List'
    case 'AUDIENCE_TYPE_B2C':
    case 'JOB_TYPE_B2C':
    case 'b2c':
      return 'B2C: IP Targeting'
    case 'AUDIENCE_TYPE_B2B':
    case 'JOB_TYPE_B2B':
    case 'b2b':
      return 'B2B: IP Targeting'
    case 'AUDIENCE_TYPE_DC':
    case 'JOB_TYPE_DC':
    case 'PRODUCT_TYPE_DIGITAL_CANVASSING':
    case 'dc':
      return 'Digital Canvassing'
    case 'AUDIENCE_TYPE_RETARGETING':
    case 'JOB_TYPE_RETARGETING':
    case 'PRODUCT_TYPE_RETARGETING':
      return 'Retargeting'
    case 'reverseIp':
    case 'AUDIENCE_TYPE_REVERSE':
    case 'JOB_TYPE_REVERSE':
      return 'Reverse IP'
    case 'AUDIENCE_TYPE_PREMOVER':
    case 'JOB_TYPE_PREMOVER':
    case 'pre':
      return 'Pre Mover'
    case 'AUDIENCE_TYPE_ESCROWMOVER':
    case 'JOB_TYPE_ESCROWMOVER':
    case 'escrow':
      return 'Escrow Mover'
    case 'AUDIENCE_TYPE_POSTMOVER':
    case 'JOB_TYPE_POSTMOVER':
    case 'PRODUCT_TYPE_NEW_MOVERS':
    case 'post':
      return 'New Mover'
    case 'AUDIENCE_TYPE_DEVICE':
    case 'JOB_TYPE_DEVICE':
    case 'device':
      return 'Device ID'
    case 'PRODUCT_TYPE_WEB_TO_HOME':
      return 'Web-To-Home'
    case 'PRODUCT_TYPE_IP_PLUS':
      return 'IP+'
    case 'PRODUCT_TYPE_IP_TARGETING':
      return 'IP Targeting'
    case 'AUDIENCE_TYPE_UNKNOWN':
    case 'PRODUCT_TYPE_UNKNOWN':
      return 'Unknown'
    case 'AUDIENCE_SUB_TYPE_DEVICES_IN_HOMES_NOT_SEEN':
      return 'Other Device IDs in Home'
    case 'AUDIENCE_SUB_TYPE_DEVICES_WITHOUT_HOMES':
      return 'Unmapped Device IDs'
    case 'AUDIENCE_SUB_TYPE_DEVICES_WITH_HOMES':
      return 'Mapped Device IDs'
    case 'AUDIENCE_SUB_TYPE_HOMES':
      return 'Mapped Homes'
    case 'ipListForMailing':
    case 'AUDIENCE_TYPE_ADDRESSES_FOR_IP':
    case 'JOB_TYPE_ADDRESSES_FOR_IP':
    case 'PRODUCT_TYPE_IP_LIST_FOR_MAILING':
      return 'IP List for Mailing'
    default:
      return dashboardDefault
        ? 'No Audiences yet'
        : simplifyEnum(3, jobType, true) // catch new audience types
  }
}
// Gets the audience count / "matches" from a job
export const getJobCounts = (job: Targetjobservicev1Audience) => {
  if (!job.result) return 0
  const jobResult = job.result
  const { type } = job
  // IF ZIP LIST - use addresses_matched
  if (type === 'AUDIENCE_TYPE_ZIP') {
    return jobResult.addressesMatched || 0
  }
  // IF DC - use neighbors_matched
  // https://eltorocorp.atlassian.net/browse/PLAS-117?focusedCommentId=79783
  if (type === 'AUDIENCE_TYPE_DC') {
    return jobResult.neighborsMatched || 0
  }
  // IF VR - use matched device count
  if (type === 'AUDIENCE_TYPE_VR') {
    return (
      job.audiences?.reduce((acc, currentJob: Targetjobservicev1Audience) => {
        if (currentJob.result) {
          const subJobResult = currentJob.result
          return acc + (subJobResult.matched || 0)
        }

        return acc
      }, 0) || 0
    )
  }
  // IF devices for households (out of home) - use totalDeviceCount
  // https://eltorocorp.atlassian.net/browse/NGBR-229?focusedCommentId=121377
  if (type === 'AUDIENCE_TYPE_DEVICES_FOR_HOUSEHOLDS') {
    return jobResult.totalDeviceCount || 0
  }

  return jobResult.matched || jobResult.total || 0
}
// Checks if a job id is currently attached to an order line
export const checkIfJobIsAttached = (
  orderLine: Campaignservicev1OrderLine,
  audienceId: string,
) => {
  const currentJobIds = [
    ...new Set(
      orderLine.audiences?.flatMap((aud) => {
        return aud.id
      }),
    ),
  ]
  return !!currentJobIds.find((id) => id === audienceId)
}
// DO NOT PASS THIS AN ARRAY OF Apqsv1Job- the created date is incorrect
export const getNewestJob = (jobs: Array<Targetjobservicev1Audience>) => {
  const newestJob = jobs.reduce((a, b) => {
    if (a.createTime && b.createTime) {
      return a.createTime > b.createTime ? a : b
    }
    return b
  }, {})
  return newestJob
}

export const getTargetFileType = (
  audienceType: string,
): Targetorchestratorv2FileType => {
  if (
    audienceType === 'reverse' ||
    audienceType === 'reverseIp' ||
    audienceType === 'ipForMailing'
  ) {
    return 'FILE_TYPE_IP'
  }
  if (
    audienceType === 'address' ||
    audienceType === 'dc' ||
    audienceType === 'custom'
  ) {
    return 'FILE_TYPE_ADDRESS'
  }
  if (audienceType === 'zip') {
    return 'FILE_TYPE_ZIP'
  }
  if (audienceType === 'device') {
    return 'FILE_TYPE_DEVICE'
  }
  return 'FILE_TYPE_IP'
}

export const getLatestUTCVRJobStopTime = () => {
  return dayjs().utc().subtract(2, 'day').endOf('day').toDate()
}
