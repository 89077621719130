import { V1RuleSet } from 'next-gen-sdk'
import { ApiFactory } from 'apiFactory'
import { createRuleSet } from './createRuleSet'

export const updateRuleSet = async (
  incomingRuleSet: V1RuleSet,
  pixelId: string,
  subscriptionId: string,
  orgId: string,
) => {
  const token = localStorage.getItem('eltoro_token')
  const apis = ApiFactory(token)
  const { webToHomeAPI } = apis
  if (!webToHomeAPI) return undefined

  const processRuleSet = async (ruleSet: V1RuleSet) => {
    if (ruleSet.rules?.length) {
      await Promise.allSettled(
        ruleSet.rules.map(async (rule) => {
          if (!rule.id) {
            return webToHomeAPI.advertisingPlatformServiceCreateRule(
              pixelId,
              subscriptionId,
              ruleSet.id || '',
              { ...rule, orgId },
              orgId,
            )
          }
          return webToHomeAPI.advertisingPlatformServiceUpdateRule(
            pixelId,
            subscriptionId,
            rule.ruleSetId || '',
            rule.id!,
            { ...rule, orgId },
            'minimum,maximum,term,type',
            orgId,
          )
        }),
      )
    }

    if (ruleSet.id) {
      await webToHomeAPI
        .advertisingPlatformServiceUpdateRuleSet(
          pixelId,
          subscriptionId,
          ruleSet.id,
          {
            ...ruleSet,
            subscriptionId,
          },
          'operator',
          orgId,
        )
        .catch((e) => {
          console.error('Error updating rule set:', e)
        })
    }

    if (ruleSet.ruleSet?.length) {
      await Promise.allSettled(
        ruleSet.ruleSet.map(async (childRuleSet) => {
          if (!childRuleSet.id) {
            return createRuleSet(
              { ...childRuleSet, parentRuleSetId: ruleSet.id },
              pixelId,
              subscriptionId,
              orgId,
            )
          }
          return processRuleSet({
            ...childRuleSet,
            parentRuleSetId: ruleSet.id,
          })
        }),
      )
    }
  }

  await processRuleSet(incomingRuleSet)

  if (!incomingRuleSet.parentRuleSetId) {
    const parentRuleSet = await webToHomeAPI.advertisingPlatformServiceGetRuleSet(
      pixelId,
      subscriptionId,
      incomingRuleSet.id!,
      orgId,
    )
    return parentRuleSet
  }
  return undefined
}
