/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Targetjobservicev1Deployment } from '../models/Targetjobservicev1Deployment';
import { Targetjobservicev1Note } from '../models/Targetjobservicev1Note';
import { Targetjobservicev1Timeframe } from '../models/Targetjobservicev1Timeframe';
import { V1AudienceStatus } from '../models/V1AudienceStatus';
import { V1AudienceSubType } from '../models/V1AudienceSubType';
import { V1AudienceType } from '../models/V1AudienceType';
import { V1Automation } from '../models/V1Automation';
import { V1CpmPlus } from '../models/V1CpmPlus';
import { V1DataProduct } from '../models/V1DataProduct';
import { V1DataSource } from '../models/V1DataSource';
import { V1ProductType } from '../models/V1ProductType';
import { V1Result } from '../models/V1Result';
import { HttpFile } from '../http/http';

/**
* A subresource of a Target, an Audience is what is generated from a data source to determine whom advertiserments are server
*/
export class Targetjobservicev1Audience {
    /**
    * The Audience ID.
    */
    'id'?: string;
    /**
    * The Name that is associated with the Audience.
    */
    'name'?: string;
    /**
    * The Org's ID that is associated with the Audience.
    */
    'orgId'?: string;
    /**
    * The ID of the Target this audience is associated with.
    */
    'targetId'?: string;
    /**
    * This ID only exists if the audience is a child audience. This is the ID of the parent audience.
    */
    'audienceId'?: string;
    /**
    * The time the Audience was created.
    */
    'createTime'?: Date;
    /**
    * The time the Audience was updated.
    */
    'updateTime'?: Date;
    /**
    * The time the Audience was deleted.
    */
    'deleteTime'?: Date;
    /**
    * The time the Audience was archived.
    */
    'archiveTime'?: Date;
    /**
    * The time the Audience was expired.
    */
    'expireTime'?: Date;
    /**
    * The time the Audience was purged.
    */
    'purgeTime'?: Date;
    'status'?: V1AudienceStatus;
    'type'?: V1AudienceType;
    'subType'?: V1AudienceSubType;
    'productType'?: V1ProductType;
    /**
    * An array of child audiences belonging to this parent.  **Key Details**  AUDIENCE_TYPE_VR and AUDIENCE_TYPE_GROW_YOUR_SPHERE create their child audiences automatically, you do not pass these through the create request.  Audiences that are of a PROSPECTING product type are allowed to have child audiences. You can create these on creation of the PROSPECTING audience by passing the audience in this array. You can create them later by setting the audience_id on the audience object in the request. If you requote the parent audience, the child audience also gets requoted. Attach child audiences to an order line, not the parent audience. If the parent prospecting audience has an automation associated with it, the parent and child are both effected by the automation. Any child audiences created on a PROSPECTING audiences will automatically be assigned the data_product DATA_PRODUCT_CUSTOM_AUDIENCE. All DATA_PRODUCT_CUSTOM_AUDIENCE rules will apply to this audience. No other audience types allow the creation of child audiences at this time. 
    */
    'audiences'?: Array<Targetjobservicev1Audience>;
    'orderLineIds'?: Array<string>;
    /**
    * An ID that is generated when a Audience is ran.
    */
    'instanceId'?: string;
    'result'?: V1Result;
    /**
    * The limit you want to set on the number of matches.
    */
    'matchCap'?: number;
    /**
    * The Code associated with a Segment.
    */
    'code'?: number;
    /**
    * The start and end times for a venue replay audience.
    */
    'timeframes'?: Array<Targetjobservicev1Timeframe>;
    'dataSource'?: V1DataSource;
    /**
    * The the folder where the audience is stored.
    */
    'folder'?: string;
    'hidden'?: boolean;
    'locked'?: boolean;
    /**
    * The deployments for the audience.
    */
    'deployments'?: Array<Targetjobservicev1Deployment>;
    /**
    * The notes for the audience.
    */
    'notes'?: Array<Targetjobservicev1Note>;
    'initialMatches'?: number;
    'dataProduct'?: V1DataProduct;
    'cpmPlus'?: V1CpmPlus;
    /**
    * The data sources of the uploaded file. This should be used if the file was created from multiple data sets and then the results were combined. This is not a required field and if both the data_source field and the data_sources fields exist, they will be combined.
    */
    'dataSources'?: Array<V1DataSource>;
    /**
    * ### Automations for Audiences  Automations are used to automate recurring actions related to an Audience. They are currently only associated with audiences of the `PRODUCT_TYPE_PROSPECTING` type and provide a streamlined way to manage routine tasks.  ---  #### Key Features of Automations - **Persistence**: Automations are designed to persist without deletion.    - To disable an automation, set `enabled` to `false` using the `UpdateAudienceAutomation` endpoint. - **Execution Rules for `AUTOMATION_TYPE_REFRESH`**:   - These automations execute only if the Audience has been generated at least once and is deployed on an active order line.   - If all associated order lines are completed, the automation is automatically deactivated. - **Queuing Behavior**:   - Automations for prospecting audiences are queued rather than executed immediately, allowing for necessary quoting of child audiences.  ---  #### **Key Fields to Configure** 1. **`enabled`**:      - Determines whether the automation will run if it is active.     - Default: `false`.    - This is set by client     2. **`interval_value` and `interval_unit`**:      - Specify the time interval between automation runs.  3. **`type`**:      - Defines the action performed by the automation.      - Example: `AUTOMATION_TYPE_REFRESH` to regenerate and refresh the audience.  ---  #### **Important Notes** - Automations requiring queuing will adhere to the system's default queue scheduling after the specified interval. - Automations have dedicated create and update endpoints for managing their configuration. - Automations on a prospecting audience will automatically retrieve prospects and then requote and refresh any associated child audiences.  ---  This structure ensures easy management of automations and provides clear guidelines for configuring and maintaining them effectively.  
    */
    'automations'?: Array<V1Automation>;
    /**
    * An option field to be used to tag your audience with something meaningful. A way to group audiences together so you can search for everything with that specific tag.
    */
    'tag'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "targetId",
            "baseName": "target_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "audienceId",
            "baseName": "audience_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updateTime",
            "baseName": "update_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "deleteTime",
            "baseName": "delete_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "archiveTime",
            "baseName": "archive_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "expireTime",
            "baseName": "expire_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "purgeTime",
            "baseName": "purge_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "V1AudienceStatus",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "V1AudienceType",
            "format": ""
        },
        {
            "name": "subType",
            "baseName": "sub_type",
            "type": "V1AudienceSubType",
            "format": ""
        },
        {
            "name": "productType",
            "baseName": "product_type",
            "type": "V1ProductType",
            "format": ""
        },
        {
            "name": "audiences",
            "baseName": "audiences",
            "type": "Array<Targetjobservicev1Audience>",
            "format": ""
        },
        {
            "name": "orderLineIds",
            "baseName": "order_line_ids",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "instanceId",
            "baseName": "instance_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "result",
            "baseName": "result",
            "type": "V1Result",
            "format": ""
        },
        {
            "name": "matchCap",
            "baseName": "match_cap",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "code",
            "baseName": "code",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "timeframes",
            "baseName": "timeframes",
            "type": "Array<Targetjobservicev1Timeframe>",
            "format": ""
        },
        {
            "name": "dataSource",
            "baseName": "data_source",
            "type": "V1DataSource",
            "format": ""
        },
        {
            "name": "folder",
            "baseName": "folder",
            "type": "string",
            "format": ""
        },
        {
            "name": "hidden",
            "baseName": "hidden",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "locked",
            "baseName": "locked",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "deployments",
            "baseName": "deployments",
            "type": "Array<Targetjobservicev1Deployment>",
            "format": ""
        },
        {
            "name": "notes",
            "baseName": "notes",
            "type": "Array<Targetjobservicev1Note>",
            "format": ""
        },
        {
            "name": "initialMatches",
            "baseName": "initial_matches",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "dataProduct",
            "baseName": "data_product",
            "type": "V1DataProduct",
            "format": ""
        },
        {
            "name": "cpmPlus",
            "baseName": "cpm_plus",
            "type": "V1CpmPlus",
            "format": ""
        },
        {
            "name": "dataSources",
            "baseName": "data_sources",
            "type": "Array<V1DataSource>",
            "format": ""
        },
        {
            "name": "automations",
            "baseName": "automations",
            "type": "Array<V1Automation>",
            "format": ""
        },
        {
            "name": "tag",
            "baseName": "tag",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return Targetjobservicev1Audience.attributeTypeMap;
    }

    public constructor() {
    }
}

