import React from 'react'
import { Fa } from '@eltoro-ui/components'
import classNames from 'classnames'
import { V1CreativeFile } from 'next-gen-sdk'
import { isVideo, isImage, isAudio, cleanedMime, isHtml } from 'Helpers'
import './CreativeThumbnail.scss'
import { ETIcon } from 'Assets'

export const CreativeThumbnail: React.FC<{
  file: V1CreativeFile
  onClick?: (file: V1CreativeFile) => void
  // Includes the border, shadow, and pointer when hovering
  hoverStyles?: boolean
  // Adds object-fit: cover
  cover?: boolean
  // If you do not give this a size, it will fit to container
  size?: 'xs' | 's' | 'm' | 'l' | 'f'
}> = ({ file, onClick, cover = false, size, hoverStyles }) => {
  const { bucket, height, key, mime, width, extension } = file
  const url = `https://s3.amazonaws.com/${bucket}/${key}`
  const cleanMime = cleanedMime(mime || '')

  const thumbnailClassNames = classNames('CreativeThumbnail__thumbnail', {
    'CreativeThumbnail__thumbnail--cover': cover,
    'CreativeThumbnail--size-xs': size === 'xs',
    'CreativeThumbnail--size-s': size === 's',
    'CreativeThumbnail--size-m': size === 'm',
    'CreativeThumbnail--size-l': size === 'l',
    'CreativeThumbnail--size-full': size === 'f',
    'hover:border-primary hover:cursor-pointer hover:shadow-[var(--box-shadow-300)]':
      !!onClick || hoverStyles,
  })

  const Element = onClick ? 'button' : 'div'
  return (
    <div
      className={`CreativeThumbnail ${
        Element === 'button' ? '' : 'before:!hidden after:!hidden'
      }`}
      data-tooltip="right"
      aria-label={Element === 'button' ? 'Click to view full resolution' : ''}
    >
      <Element
        className={`CreativeThumbnail__thumbnail-${Element}`}
        type="button"
        onClick={() => {
          if (onClick && !isAudio(cleanMime)) onClick(file)
        }}
      >
        {isHtml(cleanMime) && (
          <div className="border-secondary-900 border-2">
            <ETIcon icon="html5" className="m-auto" />
          </div>
        )}
        {(isImage(
          cleanMime === 'application/octet-stream' || cleanMime === ''
            ? extension || ''
            : cleanMime,
        ) ||
          file.id?.includes('_resized')) && (
          <img
            src={url}
            alt="thumbnail"
            className={thumbnailClassNames}
            height={height}
            width={width}
          />
        )}
        {isVideo(cleanMime) && (
          <div className="Creative__video--wrap relative flex items-center justify-center">
            <Fa
              className="!text-tint-light-600 bg-tint-dark-400 absolute !h-11 !w-11 shrink-0 grow-0 rounded-full pl-1"
              icon="play"
              size={1}
            />
            <video className={thumbnailClassNames}>
              <source src={url} type={cleanMime} />
              <track default kind="captions" />
              Your browser does not support the video element.
            </video>
          </div>
        )}
        {isAudio(cleanMime) && (
          <audio controls>
            <source src={url} type={cleanMime} />
            <track default kind="captions" />
            Your browser does not support the audio element.
          </audio>
        )}
      </Element>
    </div>
  )
}
