import { useState } from 'react'
import {
  Button,
  Fa,
  Form,
  Modal,
  showErrorMessage,
  TagInput,
  TextInput,
  Toggle,
} from '@eltoro-ui/components'
import {
  Hagridv1Subscription,
  V1Pixel,
  V1WebToHomeNoteType,
} from 'next-gen-sdk'
import { v4 as uuid } from 'uuid'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useAppContext } from 'Contexts'
import { createSubscription } from 'Requests'
import { PixelGeoLocationFilters } from './GeoLocationFilters'
import { PixelNotes } from './PixelNotes'
import { RuleSets } from './RuleSets'
import { PixelSelect } from './PixelSelect'
import { PixelFormItem } from './shared'
import { RuleSetWithTempId } from './types'
import './PixelSubscriptionCreate.scss'

export const PixelSubscriptionCreate = ({
  offClick,
  setRefresh,
  incomingPixel,
}: {
  offClick: () => void
  setRefresh?: React.Dispatch<React.SetStateAction<string>>
  incomingPixel?: Partial<V1Pixel>
}) => {
  const [pixel, setPixel] = useState<Partial<V1Pixel | undefined>>(
    incomingPixel,
  )
  const [subscription, setSubscription] = useState<
    Partial<Hagridv1Subscription>
  >()
  const [parentRuleSet, setParentRuleSet] = useState<
    Partial<RuleSetWithTempId>
  >({
    operator: 'and',
    rules: [{ term: '', tempId: uuid(), type: 'term' }],
    tempId: uuid(),
  })
  const [saving, setSaving] = useState(false)
  const [subscriptionTypes, setSubscriptionTypes] = useState<string[]>([
    'Web-to-Home',
  ])
  const [enableGeo, setEnableGeo] = useState(false)
  const [notes, setNotes] = useState<{
    content: string
    type: V1WebToHomeNoteType
  }>()
  const { currentOrg, webToHomeApi } = useAppContext()
  const nav = useNavigate()
  const query = currentOrg?.id ? `?org_id=${currentOrg.id}` : ''

  const setters = {
    setPixel,
    setParentRuleSet,
    setSubscription,
  }

  const disableSave =
    saving ||
    (!pixel?.id && !pixel?.name) ||
    !subscription?.name ||
    !subscriptionTypes.length

  const handleSave = async () => {
    if (!currentOrg?.id || !currentOrg?.name || !pixel || !subscription) return
    setSaving(true)
    try {
      await createSubscription(
        currentOrg.id,
        currentOrg.name,
        pixel,
        subscription,
        parentRuleSet,
      ).then(async (res) => {
        if (
          webToHomeApi &&
          res?.pixel.id &&
          res?.subscription.id &&
          res.subscription.orgId &&
          notes?.content
        ) {
          await webToHomeApi.advertisingPlatformServiceCreateSubscriptionNote(
            res.pixel.id,
            res?.subscription?.id,
            notes,
            res.subscription.orgId,
          )
        }
        offClick()
        if (res?.subscription?.id && res.subscription.pixelId)
          nav(
            `/pixel-library/pixel/${res.subscription.pixelId}/subscription/${res.subscription.id}${query}`,
          )
        if (setRefresh) setRefresh(uuid())
      })
    } catch (e) {
      showErrorMessage('Error saving pixel and subscription', '')
    } finally {
      setSaving(false)
    }
  }

  const isNameValid = (subscription?.name || '').length <= 100

  return (
    <Modal
      className="PixelSubscriptionCreate tablet:min-w-[100%] min-w-[50%]"
      header="Create New Subscription"
      offClick={offClick}
    >
      <Form className="PixelCreate__form flex flex-col gap-4 p-4">
        <PixelFormItem
          htmlFor="subscription-name"
          label="Subscription Name"
          required
          valid={isNameValid}
          counter
        >
          <TextInput
            value={subscription?.name || ''}
            id="subscription-name"
            onChange={(e) =>
              setSubscription((prev) => ({ ...prev, name: e.target.value }))
            }
            maxLength={101}
            minLength={1}
            valid={isNameValid}
            hideValidIcon
          />
        </PixelFormItem>
        <PixelFormItem htmlFor="pixel-tags" label="Tags">
          <TagInput
            disabled={saving}
            value={subscription?.tags || []}
            addTagsInBulk
            onAddTag={(tagArray: string[]) => {
              setSubscription((prev) => ({
                ...prev,
                tags: tagArray,
              }))
            }}
            onRemoveTag={(tagToRemove) => {
              setSubscription((prev) => ({
                ...prev,
                tags: subscription?.tags?.filter((tag) => tag !== tagToRemove),
              }))
            }}
            limit={1000}
          />
        </PixelFormItem>
        <PixelFormItem
          label="Subscription Types"
          htmlFor="subscription-types"
          required
        >
          <div className="flex gap-2">
            {['Web-to-Home', 'Digital Targeting'].map((type) => {
              const disabled = type === 'Digital Targeting'
              return (
                <Button
                  key={type}
                  iconRight={
                    <Fa
                      icon={
                        subscriptionTypes.includes(type)
                          ? 'square-check'
                          : 'square'
                      }
                      size={1}
                      type={
                        !subscriptionTypes.includes(type) ? 'light' : undefined
                      }
                    />
                  }
                  className={classNames('rounded-s', {
                    'cursor-not-allowed': disabled,
                  })}
                  // TODO: when we have different types of pixel subscriptions
                  // onClick={() =>
                  //   setSubscriptionTypes((prev) =>
                  //     prev.includes(type)
                  //       ? prev.filter((t) => t !== type)
                  //       : [...new Set([...prev, type])],
                  //   )
                  // }
                  kind={
                    subscriptionTypes.includes(type) ? 'primary' : undefined
                  }
                  disabled={disabled}
                >
                  {type}
                </Button>
              )
            })}
          </div>
        </PixelFormItem>
        <PixelNotes
          type="subscription"
          onChange={(notes, type) => setNotes({ content: notes, type })}
        />
        <PixelSelect
          pixel={pixel}
          disabled={incomingPixel !== undefined}
          {...setters}
        />
        <RuleSets parentRuleSet={parentRuleSet} {...setters} />
        <div className="flex flex-col gap-4">
          <Toggle
            label="Geographic Filters"
            onChange={setEnableGeo}
            className="max-w-max"
          />
          {enableGeo && (
            <PixelGeoLocationFilters
              zipsFiltered={subscription?.zipsFiltered || []}
              statesFiltered={subscription?.statesFiltered || []}
              handleZipsFiltered={(zips) =>
                setSubscription((prev) => ({ ...prev, zipsFiltered: zips }))
              }
              handleStatesFiltered={(states) =>
                setSubscription((prev) => ({ ...prev, statesFiltered: states }))
              }
            />
          )}
        </div>
        <Toggle
          label="Enable Daily Processing"
          isChecked={subscription?.enabled || false}
          onChange={(enabled) =>
            setSubscription((prev) => ({ ...prev, enabled }))
          }
          className="max-w-max"
        />
        <div className="PixelCreate__form-buttons flex justify-end gap-2">
          <Button onClick={() => offClick()} disabled={saving}>
            Cancel
          </Button>
          <Button
            iconLeft={
              saving ? <Fa icon="spinner" size={1} animate="spin" /> : undefined
            }
            kind="primary"
            disabled={disableSave}
            onClick={handleSave}
          >
            Create Subscription
          </Button>
        </div>
      </Form>
    </Modal>
  )
}
