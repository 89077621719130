import { useEffect, useState } from 'react'
import { Button, Fa, TextHeader } from '@eltoro-ui/components'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'
import { PageContent, OrgNotes, PixelSubscriptionCreate } from 'Components'
import { useAppContext } from 'Contexts'
import { PixelCreate } from 'Components'
import { SubscriptionTable } from './components/SubscriptionTable'
import { PixelTable } from './components/PixelTable'
import { CreatePixelOrSubscriptionModal } from './components/CreatePixelOrSubscriptionModal'
import { WebToHomeDisabled } from './components/WebToHomeDisabled'

export type ModalView = 'pixel' | 'subscription' | 'create'

export const PixelLibraryPage = () => {
  const { view } = useParams<{ view: string }>()
  const [selectedTab, setSelectedTab] = useState(view || 'pixels')
  const [modalView, setModalView] = useState<ModalView>()
  // trigger for refreshing tables- when a different id is set from new item, the table refetches
  const [refresh, setRefresh] = useState<string>('')

  const { isAdmin, currentOrg, webToHomeEnabled, isReadOnly } = useAppContext()
  const nav = useNavigate()

  const query = currentOrg?.id ? `?org_id=${currentOrg.id}` : ''

  const closeModal = () => setModalView(undefined)

  useEffect(() => {
    if (view) {
      setSelectedTab(view)
    }
  }, [view])

  const tabs = [
    {
      id: 'pixels',
      label: 'Pixels',
      isActive: selectedTab === 'pixels',
      onClick: () => {
        setSelectedTab('pixels')
        nav(`pixels${query}`)
      },
      component: <PixelTable refresh={refresh} />,
    },
    {
      id: 'subscriptions',
      label: 'Subscriptions',
      isActive: selectedTab === 'subscriptions',
      onClick: () => {
        setSelectedTab('subscriptions')
        nav(`subscriptions${query}`)
      },
      component: <SubscriptionTable refresh={refresh} />,
    },
    {
      id: 'partner-subscriptions',
      label: 'Partner Subscriptions',
      isActive: selectedTab === 'partner-subscriptions',
      onClick: () => {
        setSelectedTab('partner-subscriptions')
        nav(`partner-subscriptions${query}`)
      },
      component: <SubscriptionTable partner refresh={refresh} />,
    },
  ]

  if (!isAdmin && !webToHomeEnabled) return <WebToHomeDisabled />

  return (
    <PageContent className="PixelLibraryPage pt-0">
      <div className="PixelLibraryHeader mb-4 flex items-end justify-between gap-x-4 pb-2">
        <div className="flex items-center gap-2">
          <TextHeader className="PixelLibraryHeader__text" type={1}>
            Pixel Library
          </TextHeader>
          {isAdmin && (
            <div
              className={classNames(
                'PixelLibraryPage__status-tag mt-[2rem] rounded-sm border px-2 py-1 font-bold',
                {
                  'border-danger text-danger': !webToHomeEnabled,
                  'border-success text-success': webToHomeEnabled,
                },
              )}
            >
              {webToHomeEnabled ? 'Enabled' : 'Disabled'}
            </div>
          )}
        </div>
        <Button
          iconLeft={<Fa icon="plus" size={1} />}
          onClick={() => setModalView('create')}
          disabled={isReadOnly}
        >
          New
        </Button>
      </div>

      {isAdmin && <OrgNotes />}

      <div className="PixelLibraryPage__tabs border-b-thin border-tint-gray-300 mb-4 flex gap-2">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={classNames(
              'hover:bg-grey-200 border-b-4 border-b-transparent p-4 text-lg',
              {
                'border-b-primary': tab.isActive,
              },
            )}
            onClick={tab.onClick}
          >
            {tab.label}
          </button>
        ))}
      </div>
      {tabs.find((tab) => tab.isActive)?.component}
      {(() => {
        const props = { offClick: closeModal, setRefresh, setModalView }
        if (modalView === 'create')
          return <CreatePixelOrSubscriptionModal {...props} />
        if (modalView === 'pixel') return <PixelCreate {...props} />
        if (modalView === 'subscription')
          return <PixelSubscriptionCreate {...props} />
      })()}
    </PageContent>
  )
}
