import { useEffect, useRef, useState } from 'react'
import {
  Button,
  Fa,
  Loader,
  Table,
  TextHeader,
  showErrorMessage,
} from '@eltoro-ui/components'
import { useAppContext } from 'Contexts'
import { downloadTos } from 'Requests'
import { useAuth } from 'react-oidc-context'

type TOSTableColumn = {
  user: string
  userId: string
  version: string
  created: string
  fileName: string
}

export const TOSList = () => {
  const [tosList, setTosList] = useState<TOSTableColumn[]>([])
  const [downloading, setDownloading] = useState('')
  const [loading, setLoading] = useState(false)
  const { tosApi, currentOrg, isAdmin, isUser, isReadOnly } = useAppContext()
  const auth = useAuth()
  const userId = auth?.user?.profile?.sub
  const hasFetchedTOS = useRef('')

  useEffect(() => {
    if (
      !tosApi ||
      !currentOrg ||
      hasFetchedTOS.current === currentOrg.id ||
      currentOrg.msaSigned
    )
      return
    setLoading(true)
    setTosList([])
    tosApi
      .advertisingPlatformServiceListTOSAgreements(
        currentOrg.id,
        isAdmin || isUser ? undefined : userId, // only show the user's TOS if they are read only
      )
      .then(({ tosAgreements }) => {
        const convertedTosList = tosAgreements?.reduce(
          (acc: TOSTableColumn[], curr) => {
            if (!curr.userId || !curr.filename) return acc
            const splitFileName = curr.filename.split('-')
            const arrLength = splitFileName.length - 1
            const created = `${splitFileName[arrLength - 1]}/${splitFileName[
              arrLength
            ].replace('.pdf', '')}/${splitFileName[arrLength - 2]}`
            const version = splitFileName[arrLength - 3]
            const user = splitFileName[arrLength - 4]
              .replace('_', ' ')
              .replace('*', '')
            return [
              ...acc,
              {
                userId: curr.userId,
                fileName: curr.filename,
                created,
                version,
                user,
              },
            ]
          },
          [],
        )
        setTosList(convertedTosList || [])
      })
      .catch((err) => {
        if (err.body?.message !== 'could not find tos agreements')
          showErrorMessage('Error fetching terms of service list', '')
      })
      .finally(() => {
        setLoading(false)
        hasFetchedTOS.current = currentOrg?.id || ''
      })
  }, [currentOrg, isAdmin, isUser, tosApi, userId])
  return (
    <div className="TOSList">
      <TextHeader type={3} className="text-primary">
        Terms of service
      </TextHeader>
      {(() => {
        if (currentOrg?.msaSigned)
          return (
            <div className="mt-2 py-2 text-center">
              This org has an Outside TOS.
            </div>
          )
        if (loading) return <Loader />
        if (tosList.length > 0)
          return (
            <Table
              className="TOSList__table"
              columns={[
                { path: 'user', label: 'Name' },
                { path: 'version', label: 'Version' },
                { path: 'created', label: 'Created' },
                {
                  path: '',
                  RowCell: ({ fileName, userId }) => {
                    // catches fileNames that occured on orgs with slashes in their name
                    const hasInvalidFileName = currentOrg?.name
                      ? !fileName.includes(currentOrg.name)
                      : true
                    return (
                      <span
                        data-tooltip="left"
                        aria-label={(() => {
                          if (isReadOnly) {
                            return 'You are not able to perform this action'
                          }
                          if (hasInvalidFileName) {
                            return 'This TOS cannot be downloaded. Please contact support.'
                          }
                          if (!fileName) {
                            return 'This TOS cannot be downloaded. Please contact support.'
                          }
                          return 'Download terms of service'
                        })()}
                        className="justify-center"
                      >
                        <Button
                          iconOnly={<Fa icon="file-arrow-down" size={1} />}
                          disabled={
                            downloading === fileName ||
                            hasInvalidFileName ||
                            !fileName ||
                            isReadOnly
                          }
                          onClick={() => {
                            if (!currentOrg?.id) return
                            setDownloading('fileName')
                            downloadTos(
                              fileName,
                              currentOrg.id,
                              userId,
                            ).then(() => setDownloading(''))
                          }}
                        />
                      </span>
                    )
                  },
                },
              ]}
              rows={tosList}
            />
          )

        return (
          <div className="mt-2 py-2 text-center">
            No terms of service signatures found.
          </div>
        )
      })()}
    </div>
  )
}
