import { TextInput, Button, Fa } from '@eltoro-ui/components'
import { useAppContext } from 'Contexts'
import { TableSearchType } from './useTableSearch'

export const SearchBar = (props: { type: string } & TableSearchType) => {
  const { currentOrg } = useAppContext()

  const {
    searchInput,
    setSearchInput,
    childOrgsOnly,
    setChildOrgsOnly,
    // showArchived,
    // setShowArchived,
    type,
  } = props

  return (
    <div className="PixelTable__search-bar tablet:flex-wrap flex items-center justify-between gap-4">
      <TextInput
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        // placeholder={`Search ${type}...`}
        placeholder={`Search ${type}... (coming soon)`}
        iconRight={
          searchInput ? (
            <button
              onClick={() => {
                setSearchInput('')
              }}
            >
              <Fa icon="xmark" size={1} className="text-secondary" />
            </button>
          ) : (
            <Fa icon="search" size={1} className="text-primary" />
          )
        }
        disabled
      />
      <div className="tablet:flex-wrap flex items-center gap-2">
        {/* TODO: we may not be able to support this if we cannot filter pixel/subscriptions by mutliple org ids without a BFF */}
        <span
          // aria-label={(() => {
          //   if (!currentOrg?.childOrgs?.length)
          //     return 'This organization does not have child orgs.'
          //   if (childOrgsOnly) return 'Click to hide pixels from child orgs'
          //   return 'Click to include pixels from child orgs'
          // })()}
          aria-label="coming soon?"
          data-tooltip="left" // tooltip appears under back button on some instance
        >
          <Button
            iconRight={
              <Fa
                icon={childOrgsOnly ? 'square-check' : 'square'}
                size={1}
                type={!childOrgsOnly ? 'light' : undefined}
              />
            }
            className="rounded-s"
            onClick={() => setChildOrgsOnly(!childOrgsOnly)}
            // disabled={!currentOrg?.childOrgs?.length}
            disabled
            kind={childOrgsOnly ? 'primary' : undefined}
          >
            Show child orgs
          </Button>
        </span>
        {/* <span
          aria-label={(() => {
            // if (archiveDisabled)
            //   return 'Please clear the status filter before using archive toggle.'
            if (showArchived) return 'Click to hide archived subscriptions'
            return 'Click to include archived subscriptions'
          })()}
          data-tooltip="left"
        >
          <Button
            className="rounded-s"
            iconRight={
              <Fa
                icon={showArchived ? 'square-check' : 'square'}
                size={1}
                type={!showArchived ? 'light' : undefined}
              />
            }
            onClick={() => setShowArchived(!showArchived)}
            // disabled={archiveDisabled}
            // kind={archiveDisabled ? 'primary' : undefined}
          >
            Show archive
          </Button>
        </span> */}
      </div>
    </div>
  )
}
