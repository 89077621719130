import {
  Targetjobservicev1Audience,
  Targetorchestratorv2Column,
  V1DataSource,
  V2Target,
} from 'next-gen-sdk'
import { createAudience, getApiConfig, uploadTargetFile } from 'Requests'
import { ApiFactory } from 'apiFactory'
import { getTargetFileType, retry } from 'Helpers'
import { TargetPreviewType } from '../AudienceColumnSelector'

type TargetsWithColumnsType = Omit<TargetPreviewType, 'columns'> & {
  columns: Targetorchestratorv2Column[]
}

// TODO: Refactor this and avoid this step later
export const getConvertedColumnTarget = (
  targetFilePreview: TargetPreviewType,
) => {
  const newColumns = targetFilePreview.columns.reduce(
    (array: { index: number; value: string; type: string }[], col) => {
      if (col.src_key !== 'other_ignore' && col.payload_key !== 'ig')
        array.push({
          index: col.src_index,
          value: col.src_field,
          type: col.src_key,
        })
      return array
    },
    [],
  )
  return { ...targetFilePreview, columns: newColumns }
}

export const handleUploadTarget = async (
  uploadingFile: TargetsWithColumnsType,
  audienceType: string,
  jobType: string,
  orgId: string,
  onUpload: (
    uploadedTarget: V2Target,
    uploadedJob: Targetjobservicev1Audience,
  ) => void,
  onError: (err?: any) => void,
  onProgress: (percent: number, currentStatus?: string) => void,
  token: string,
  locked?: boolean,
  hidden?: boolean,
  customAudienceTag?: string,
) => {
  const apis = await ApiFactory(token)
  const { audienceServiceAPI } = apis
  if (!audienceServiceAPI) return

  const getJobTypeEnum = () => {
    if (jobType === 'b2b') return 'AUDIENCE_TYPE_B2B'
    if (jobType === 'b2c') return 'AUDIENCE_TYPE_B2C'
    if (jobType === 'ipForMailing') return 'AUDIENCE_TYPE_ADDRESSES_FOR_IP'
    if (jobType === 'reverseIp') return 'AUDIENCE_TYPE_REVERSE'
    if (jobType === 'dc') return 'AUDIENCE_TYPE_DC'
    if (jobType === 'zip') return 'AUDIENCE_TYPE_ZIP'
    if (jobType === 'ip') return 'AUDIENCE_TYPE_IP'
    if (jobType === 'device') return 'AUDIENCE_TYPE_DEVICE'
    if (audienceType === 'custom') return 'AUDIENCE_TYPE_ADDRESS'
    return 'AUDIENCE_TYPE_UNKNOWN'
  }

  let uploadedTarget: V2Target | undefined
  let createdAudience: Targetjobservicev1Audience | undefined

  // Step 1: Upload target, get its id
  onProgress(0, 'upload') // Initiate progress
  try {
    uploadedTarget = await retry(
      () => {
        const latestToken = localStorage.getItem('eltoro_token') || token
        return uploadTargetFile(
          uploadingFile.fileData.name,
          uploadingFile.fileData,
          latestToken,
          orgId,
          getTargetFileType(audienceType),
          uploadingFile.hasHeader || true,
          uploadingFile.columns,
          (prog) => {
            onProgress(prog)
          },
          audienceType !== 'custom' ? 'DATA_SOURCE_CLIENT' : jobType,
          undefined,
          audienceType === 'custom'
            ? 'DATA_PRODUCT_CUSTOM_AUDIENCE'
            : undefined,
        )
      },
      undefined,
      6,
    )
  } catch (error) {
    onError({ error, step: 'upload' })
    throw new Error('upload')
  }
  // Step 2: If uploaded target, create job
  try {
    if (uploadedTarget?.id) {
      onProgress(100, 'job')
      createdAudience = await retry(
        () =>
          createAudience(
            orgId,
            getJobTypeEnum(),
            uploadedTarget!.id!,
            uploadingFile.fileData.name || '',
            hidden,
            audienceType !== 'custom'
              ? 'DATA_SOURCE_CLIENT'
              : (jobType as V1DataSource),
            undefined,
            audienceType === 'custom'
              ? 'DATA_PRODUCT_CUSTOM_AUDIENCE'
              : undefined,
            customAudienceTag,
          ),
        undefined,
        6,
      )
    }
  } catch (error) {
    onError({ error, step: 'job' })
    throw new Error('job')
  }
  // Step 4: If job created, add it to the queue
  if (
    createdAudience?.id &&
    createdAudience.status === 'AUDIENCE_STATUS_CREATED'
  ) {
    try {
      onProgress(100, 'queue')
      await retry(
        async () => {
          const latestToken = localStorage.getItem('eltoro_token') || token
          await audienceServiceAPI?.advertisingPlatformServiceGenerateAudience(
            createdAudience?.id,
            orgId,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            token ? getApiConfig(latestToken) : undefined,
          )
          if (!locked) {
            // Step 4a: Success! Unlocked audience is in the queue
            onProgress(100, 'queue_success')
            onUpload(uploadedTarget || {}, createdAudience || {})
          }
        },
        undefined,
        6,
      )
    } catch (error) {
      onError({ error, step: 'queue' })
      throw new Error('queue')
    }
  }
  // Step 5: if locked, update audience to be locked (cannot be done before generating audience)
  if (locked && createdAudience && createdAudience.id) {
    try {
      await retry(
        async () => {
          const latestToken = localStorage.getItem('eltoro_token') || token
          await audienceServiceAPI.advertisingPlatformServiceUpdateAudience(
            createdAudience?.id!,
            orgId,
            {
              locked: locked,
            },
            'locked',
            token ? getApiConfig(latestToken) : undefined,
          )
          // Step 5a: Success! Audience is locked
          onProgress(100, 'queue_success')
          onUpload(uploadedTarget || {}, createdAudience || {})
        },
        undefined,
        6,
      )
    } catch (error) {
      onError({ error, step: 'lock' })
      throw new Error('lock')
    }
  }
}
