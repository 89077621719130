// TODO: better import syntax?
import {BaseAPIRequestFactory, RequiredError, COLLECTION_FORMATS} from './baseapi';
import {Configuration} from '../configuration';
import {RequestContext, HttpMethod, ResponseContext, HttpFile} from '../http/http';
import {ObjectSerializer} from '../models/ObjectSerializer';
import {ApiException} from './exception';
import {canConsumeForm, isCodeInRange} from '../util';
import {SecurityAuthentication} from '../auth/auth';


import { AdvertisingPlatformServiceUpdatePublisherSubscriptionRequest } from '../models/AdvertisingPlatformServiceUpdatePublisherSubscriptionRequest';
import { GooglerpcStatus } from '../models/GooglerpcStatus';
import { SubscriptionNote } from '../models/SubscriptionNote';
import { V1ListPublisherSubscriptionsResponse } from '../models/V1ListPublisherSubscriptionsResponse';
import { V1ListStatsResponse } from '../models/V1ListStatsResponse';
import { V1PublisherSubscription } from '../models/V1PublisherSubscription';
import { V1SubscriptionNote } from '../models/V1SubscriptionNote';

/**
 * no description
 */
export class WebToHomePrintPartnerOnlyApiRequestFactory extends BaseAPIRequestFactory {

    /**
     * Subscriptions - Notes - Create
     * @param publisherId 
     * @param subscriptionId 
     * @param note 
     * @param orgId 
     */
    public async advertisingPlatformServiceCreatePublisherSubscriptionNote(publisherId: string, subscriptionId: string, note: V1SubscriptionNote, orgId?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'publisherId' is not null or undefined
        if (publisherId === null || publisherId === undefined) {
            throw new RequiredError("WebToHomePrintPartnerOnlyApi", "advertisingPlatformServiceCreatePublisherSubscriptionNote", "publisherId");
        }


        // verify required parameter 'subscriptionId' is not null or undefined
        if (subscriptionId === null || subscriptionId === undefined) {
            throw new RequiredError("WebToHomePrintPartnerOnlyApi", "advertisingPlatformServiceCreatePublisherSubscriptionNote", "subscriptionId");
        }


        // verify required parameter 'note' is not null or undefined
        if (note === null || note === undefined) {
            throw new RequiredError("WebToHomePrintPartnerOnlyApi", "advertisingPlatformServiceCreatePublisherSubscriptionNote", "note");
        }



        // Path Params
        const localVarPath = '/v1/publishers/{publisher_id}/subscriptions/{subscription_id}/notes'
            .replace('{' + 'publisher_id' + '}', encodeURIComponent(String(publisherId)))
            .replace('{' + 'subscription_id' + '}', encodeURIComponent(String(subscriptionId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.POST);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (orgId !== undefined) {
            requestContext.setQueryParam("org_id", ObjectSerializer.serialize(orgId, "string", ""));
        }


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(note, "V1SubscriptionNote", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["OAuth2"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Delete a Subscription Note
     * Subscriptions - Notes - Delete
     * @param publisherId 
     * @param subscriptionId 
     * @param noteId 
     * @param orgId 
     */
    public async advertisingPlatformServiceDeletePublisherSubscriptionNote(publisherId: string, subscriptionId: string, noteId: string, orgId?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'publisherId' is not null or undefined
        if (publisherId === null || publisherId === undefined) {
            throw new RequiredError("WebToHomePrintPartnerOnlyApi", "advertisingPlatformServiceDeletePublisherSubscriptionNote", "publisherId");
        }


        // verify required parameter 'subscriptionId' is not null or undefined
        if (subscriptionId === null || subscriptionId === undefined) {
            throw new RequiredError("WebToHomePrintPartnerOnlyApi", "advertisingPlatformServiceDeletePublisherSubscriptionNote", "subscriptionId");
        }


        // verify required parameter 'noteId' is not null or undefined
        if (noteId === null || noteId === undefined) {
            throw new RequiredError("WebToHomePrintPartnerOnlyApi", "advertisingPlatformServiceDeletePublisherSubscriptionNote", "noteId");
        }



        // Path Params
        const localVarPath = '/v1/publishers/{publisher_id}/subscriptions/{subscription_id}/notes/{note_id}'
            .replace('{' + 'publisher_id' + '}', encodeURIComponent(String(publisherId)))
            .replace('{' + 'subscription_id' + '}', encodeURIComponent(String(subscriptionId)))
            .replace('{' + 'note_id' + '}', encodeURIComponent(String(noteId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.DELETE);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (orgId !== undefined) {
            requestContext.setQueryParam("org_id", ObjectSerializer.serialize(orgId, "string", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["OAuth2"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Get a subscription in which the user/org is the print partner. This is a restricted endpoint and only available to print partners Only the fields the print partner has access to view are returned. 
     * Subscriptions - Get
     * @param publisherId 
     * @param subscriptionId 
     * @param orgId 
     */
    public async advertisingPlatformServiceGetPublisherSubscription(publisherId: string, subscriptionId: string, orgId?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'publisherId' is not null or undefined
        if (publisherId === null || publisherId === undefined) {
            throw new RequiredError("WebToHomePrintPartnerOnlyApi", "advertisingPlatformServiceGetPublisherSubscription", "publisherId");
        }


        // verify required parameter 'subscriptionId' is not null or undefined
        if (subscriptionId === null || subscriptionId === undefined) {
            throw new RequiredError("WebToHomePrintPartnerOnlyApi", "advertisingPlatformServiceGetPublisherSubscription", "subscriptionId");
        }



        // Path Params
        const localVarPath = '/v1/publishers/{publisher_id}/subscriptions/{subscription_id}'
            .replace('{' + 'publisher_id' + '}', encodeURIComponent(String(publisherId)))
            .replace('{' + 'subscription_id' + '}', encodeURIComponent(String(subscriptionId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (orgId !== undefined) {
            requestContext.setQueryParam("org_id", ObjectSerializer.serialize(orgId, "string", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["OAuth2"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Get a note made on the subscription. This is a restricted endpoint and only available to print partners 
     * Subscriptions - Notes - Get
     * @param publisherId 
     * @param subscriptionId 
     * @param noteId 
     * @param orgId 
     */
    public async advertisingPlatformServiceGetPublisherSubscriptionNote(publisherId: string, subscriptionId: string, noteId: string, orgId?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'publisherId' is not null or undefined
        if (publisherId === null || publisherId === undefined) {
            throw new RequiredError("WebToHomePrintPartnerOnlyApi", "advertisingPlatformServiceGetPublisherSubscriptionNote", "publisherId");
        }


        // verify required parameter 'subscriptionId' is not null or undefined
        if (subscriptionId === null || subscriptionId === undefined) {
            throw new RequiredError("WebToHomePrintPartnerOnlyApi", "advertisingPlatformServiceGetPublisherSubscriptionNote", "subscriptionId");
        }


        // verify required parameter 'noteId' is not null or undefined
        if (noteId === null || noteId === undefined) {
            throw new RequiredError("WebToHomePrintPartnerOnlyApi", "advertisingPlatformServiceGetPublisherSubscriptionNote", "noteId");
        }



        // Path Params
        const localVarPath = '/v1/publishers/{publisher_id}/subscriptions/{subscription_id}/notes/{note_id}'
            .replace('{' + 'publisher_id' + '}', encodeURIComponent(String(publisherId)))
            .replace('{' + 'subscription_id' + '}', encodeURIComponent(String(subscriptionId)))
            .replace('{' + 'note_id' + '}', encodeURIComponent(String(noteId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (orgId !== undefined) {
            requestContext.setQueryParam("org_id", ObjectSerializer.serialize(orgId, "string", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["OAuth2"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Get all stats on a subscription or filter by the run date. returns in most recently ran order This is a restricted endpoint and only available to print partners Only the stats for subscriptions the user/org is the print partner can be accessed. The only valid filters to use fight now are start_report_date and end_report_date. These are formatted as strings. If you pass start_report_date but not end_report_date, you will get all stats for runs after start_report_date. If you pass start_report_date and end_report_date, you will get all stats for runs after start_report_date and before the end_report_date. If you pass only the end_report_date, you will get all stats for runs that occurred before the end_report_date. 
     * Subscriptions - Stats - List
     * @param publisherId 
     * @param subscriptionId 
     * @param pageSize The maximum number of items to return.
     * @param pageToken 
     * @param orgId 
     * @param startReportDate 
     * @param endReportDate 
     * @param orderBy 
     */
    public async advertisingPlatformServiceListPublisherSubscriptionStats(publisherId: string, subscriptionId: string, pageSize?: number, pageToken?: string, orgId?: string, startReportDate?: string, endReportDate?: string, orderBy?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'publisherId' is not null or undefined
        if (publisherId === null || publisherId === undefined) {
            throw new RequiredError("WebToHomePrintPartnerOnlyApi", "advertisingPlatformServiceListPublisherSubscriptionStats", "publisherId");
        }


        // verify required parameter 'subscriptionId' is not null or undefined
        if (subscriptionId === null || subscriptionId === undefined) {
            throw new RequiredError("WebToHomePrintPartnerOnlyApi", "advertisingPlatformServiceListPublisherSubscriptionStats", "subscriptionId");
        }








        // Path Params
        const localVarPath = '/v1/publishers/{publisher_id}/subscriptions/{subscription_id}/stats'
            .replace('{' + 'publisher_id' + '}', encodeURIComponent(String(publisherId)))
            .replace('{' + 'subscription_id' + '}', encodeURIComponent(String(subscriptionId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (pageSize !== undefined) {
            requestContext.setQueryParam("page_size", ObjectSerializer.serialize(pageSize, "number", "int32"));
        }

        // Query Params
        if (pageToken !== undefined) {
            requestContext.setQueryParam("page_token", ObjectSerializer.serialize(pageToken, "string", ""));
        }

        // Query Params
        if (orgId !== undefined) {
            requestContext.setQueryParam("org_id", ObjectSerializer.serialize(orgId, "string", ""));
        }

        // Query Params
        if (startReportDate !== undefined) {
            requestContext.setQueryParam("start_report_date", ObjectSerializer.serialize(startReportDate, "string", ""));
        }

        // Query Params
        if (endReportDate !== undefined) {
            requestContext.setQueryParam("end_report_date", ObjectSerializer.serialize(endReportDate, "string", ""));
        }

        // Query Params
        if (orderBy !== undefined) {
            requestContext.setQueryParam("order_by", ObjectSerializer.serialize(orderBy, "string", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["OAuth2"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * List all the subscriptions in which the user/org is the print partner  This is a restricted endpoint and only available to print partners Only the fields the print partner has access to view are returned. 
     * Subscriptions - List
     * @param publisherId 
     * @param pageSize The maximum number of items to return.
     * @param pageToken 
     * @param orgId 
     * @param orderBy 
     */
    public async advertisingPlatformServiceListPublisherSubscriptions(publisherId: string, pageSize?: number, pageToken?: string, orgId?: string, orderBy?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'publisherId' is not null or undefined
        if (publisherId === null || publisherId === undefined) {
            throw new RequiredError("WebToHomePrintPartnerOnlyApi", "advertisingPlatformServiceListPublisherSubscriptions", "publisherId");
        }






        // Path Params
        const localVarPath = '/v1/publishers/{publisher_id}/subscriptions'
            .replace('{' + 'publisher_id' + '}', encodeURIComponent(String(publisherId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.GET);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (pageSize !== undefined) {
            requestContext.setQueryParam("page_size", ObjectSerializer.serialize(pageSize, "number", "int32"));
        }

        // Query Params
        if (pageToken !== undefined) {
            requestContext.setQueryParam("page_token", ObjectSerializer.serialize(pageToken, "string", ""));
        }

        // Query Params
        if (orgId !== undefined) {
            requestContext.setQueryParam("org_id", ObjectSerializer.serialize(orgId, "string", ""));
        }

        // Query Params
        if (orderBy !== undefined) {
            requestContext.setQueryParam("order_by", ObjectSerializer.serialize(orderBy, "string", ""));
        }


        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["OAuth2"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Update a subscription in pixel which the user/org is the print partner  This is a restricted endpoint and only available to print partners Only the fields the print partner can update are: enabled and ftp_path. notes can be managed via the notes endpoint.  
     * Subscriptions - Update
     * @param publisherId 
     * @param subscriptionId 
     * @param subscription 
     * @param updateMask 
     * @param orgId 
     */
    public async advertisingPlatformServiceUpdatePublisherSubscription(publisherId: string, subscriptionId: string, subscription: AdvertisingPlatformServiceUpdatePublisherSubscriptionRequest, updateMask?: string, orgId?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'publisherId' is not null or undefined
        if (publisherId === null || publisherId === undefined) {
            throw new RequiredError("WebToHomePrintPartnerOnlyApi", "advertisingPlatformServiceUpdatePublisherSubscription", "publisherId");
        }


        // verify required parameter 'subscriptionId' is not null or undefined
        if (subscriptionId === null || subscriptionId === undefined) {
            throw new RequiredError("WebToHomePrintPartnerOnlyApi", "advertisingPlatformServiceUpdatePublisherSubscription", "subscriptionId");
        }


        // verify required parameter 'subscription' is not null or undefined
        if (subscription === null || subscription === undefined) {
            throw new RequiredError("WebToHomePrintPartnerOnlyApi", "advertisingPlatformServiceUpdatePublisherSubscription", "subscription");
        }




        // Path Params
        const localVarPath = '/v1/publishers/{publisher_id}/subscriptions/{subscription.id}'
            .replace('{' + 'publisher_id' + '}', encodeURIComponent(String(publisherId)))
            .replace('{' + 'subscription.id' + '}', encodeURIComponent(String(subscriptionId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.PATCH);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (updateMask !== undefined) {
            requestContext.setQueryParam("update_mask", ObjectSerializer.serialize(updateMask, "string", ""));
        }

        // Query Params
        if (orgId !== undefined) {
            requestContext.setQueryParam("org_id", ObjectSerializer.serialize(orgId, "string", ""));
        }


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(subscription, "AdvertisingPlatformServiceUpdatePublisherSubscriptionRequest", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["OAuth2"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

    /**
     * Subscriptions - Notes - Update
     * @param publisherId 
     * @param subscriptionId 
     * @param noteId The Note ID.
     * @param note SubscriptionNote
     * @param updateMask 
     * @param orgId 
     */
    public async advertisingPlatformServiceUpdatePublisherSubscriptionNote(publisherId: string, subscriptionId: string, noteId: string, note: SubscriptionNote, updateMask?: string, orgId?: string, _options?: Configuration): Promise<RequestContext> {
        let _config = _options || this.configuration;

        // verify required parameter 'publisherId' is not null or undefined
        if (publisherId === null || publisherId === undefined) {
            throw new RequiredError("WebToHomePrintPartnerOnlyApi", "advertisingPlatformServiceUpdatePublisherSubscriptionNote", "publisherId");
        }


        // verify required parameter 'subscriptionId' is not null or undefined
        if (subscriptionId === null || subscriptionId === undefined) {
            throw new RequiredError("WebToHomePrintPartnerOnlyApi", "advertisingPlatformServiceUpdatePublisherSubscriptionNote", "subscriptionId");
        }


        // verify required parameter 'noteId' is not null or undefined
        if (noteId === null || noteId === undefined) {
            throw new RequiredError("WebToHomePrintPartnerOnlyApi", "advertisingPlatformServiceUpdatePublisherSubscriptionNote", "noteId");
        }


        // verify required parameter 'note' is not null or undefined
        if (note === null || note === undefined) {
            throw new RequiredError("WebToHomePrintPartnerOnlyApi", "advertisingPlatformServiceUpdatePublisherSubscriptionNote", "note");
        }




        // Path Params
        const localVarPath = '/v1/publishers/{publisher_id}/subscriptions/{subscription_id}/notes/{note.id}'
            .replace('{' + 'publisher_id' + '}', encodeURIComponent(String(publisherId)))
            .replace('{' + 'subscription_id' + '}', encodeURIComponent(String(subscriptionId)))
            .replace('{' + 'note.id' + '}', encodeURIComponent(String(noteId)));

        // Make Request Context
        const requestContext = _config.baseServer.makeRequestContext(localVarPath, HttpMethod.PATCH);
        requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

        // Query Params
        if (updateMask !== undefined) {
            requestContext.setQueryParam("update_mask", ObjectSerializer.serialize(updateMask, "string", ""));
        }

        // Query Params
        if (orgId !== undefined) {
            requestContext.setQueryParam("org_id", ObjectSerializer.serialize(orgId, "string", ""));
        }


        // Body Params
        const contentType = ObjectSerializer.getPreferredMediaType([
            "application/json"
        ]);
        requestContext.setHeaderParam("Content-Type", contentType);
        const serializedBody = ObjectSerializer.stringify(
            ObjectSerializer.serialize(note, "SubscriptionNote", ""),
            contentType
        );
        requestContext.setBody(serializedBody);

        let authMethod: SecurityAuthentication | undefined;
        // Apply auth methods
        authMethod = _config.authMethods["OAuth2"]
        if (authMethod?.applySecurityAuthentication) {
            await authMethod?.applySecurityAuthentication(requestContext);
        }
        
        const defaultAuth: SecurityAuthentication | undefined = _options?.authMethods?.default || this.configuration?.authMethods?.default
        if (defaultAuth?.applySecurityAuthentication) {
            await defaultAuth?.applySecurityAuthentication(requestContext);
        }

        return requestContext;
    }

}

export class WebToHomePrintPartnerOnlyApiResponseProcessor {

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to advertisingPlatformServiceCreatePublisherSubscriptionNote
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async advertisingPlatformServiceCreatePublisherSubscriptionNote(response: ResponseContext): Promise<V1SubscriptionNote > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: V1SubscriptionNote = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "V1SubscriptionNote", ""
            ) as V1SubscriptionNote;
            return body;
        }
        if (isCodeInRange("403", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Returned when the user does not have permission to access the resource.", body, response.headers);
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: string = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "string", ""
            ) as string;
            throw new ApiException<string>(response.httpStatusCode, "Returned when the resource does not exist.", body, response.headers);
        }
        if (isCodeInRange("500", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Server error", body, response.headers);
        }
        if (isCodeInRange("0", response.httpStatusCode)) {
            const body: GooglerpcStatus = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "GooglerpcStatus", ""
            ) as GooglerpcStatus;
            throw new ApiException<GooglerpcStatus>(response.httpStatusCode, "An unexpected error response.", body, response.headers);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: V1SubscriptionNote = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "V1SubscriptionNote", ""
            ) as V1SubscriptionNote;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to advertisingPlatformServiceDeletePublisherSubscriptionNote
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async advertisingPlatformServiceDeletePublisherSubscriptionNote(response: ResponseContext): Promise<any > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            return body;
        }
        if (isCodeInRange("403", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Returned when the user does not have permission to access the resource.", body, response.headers);
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: string = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "string", ""
            ) as string;
            throw new ApiException<string>(response.httpStatusCode, "Returned when the resource does not exist.", body, response.headers);
        }
        if (isCodeInRange("500", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Server error", body, response.headers);
        }
        if (isCodeInRange("0", response.httpStatusCode)) {
            const body: GooglerpcStatus = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "GooglerpcStatus", ""
            ) as GooglerpcStatus;
            throw new ApiException<GooglerpcStatus>(response.httpStatusCode, "An unexpected error response.", body, response.headers);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to advertisingPlatformServiceGetPublisherSubscription
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async advertisingPlatformServiceGetPublisherSubscription(response: ResponseContext): Promise<V1PublisherSubscription > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: V1PublisherSubscription = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "V1PublisherSubscription", ""
            ) as V1PublisherSubscription;
            return body;
        }
        if (isCodeInRange("403", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Returned when the user does not have permission to access the resource.", body, response.headers);
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: string = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "string", ""
            ) as string;
            throw new ApiException<string>(response.httpStatusCode, "Returned when the resource does not exist.", body, response.headers);
        }
        if (isCodeInRange("500", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Server error", body, response.headers);
        }
        if (isCodeInRange("0", response.httpStatusCode)) {
            const body: GooglerpcStatus = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "GooglerpcStatus", ""
            ) as GooglerpcStatus;
            throw new ApiException<GooglerpcStatus>(response.httpStatusCode, "An unexpected error response.", body, response.headers);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: V1PublisherSubscription = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "V1PublisherSubscription", ""
            ) as V1PublisherSubscription;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to advertisingPlatformServiceGetPublisherSubscriptionNote
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async advertisingPlatformServiceGetPublisherSubscriptionNote(response: ResponseContext): Promise<V1SubscriptionNote > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: V1SubscriptionNote = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "V1SubscriptionNote", ""
            ) as V1SubscriptionNote;
            return body;
        }
        if (isCodeInRange("403", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Returned when the user does not have permission to access the resource.", body, response.headers);
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: string = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "string", ""
            ) as string;
            throw new ApiException<string>(response.httpStatusCode, "Returned when the resource does not exist.", body, response.headers);
        }
        if (isCodeInRange("500", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Server error", body, response.headers);
        }
        if (isCodeInRange("0", response.httpStatusCode)) {
            const body: GooglerpcStatus = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "GooglerpcStatus", ""
            ) as GooglerpcStatus;
            throw new ApiException<GooglerpcStatus>(response.httpStatusCode, "An unexpected error response.", body, response.headers);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: V1SubscriptionNote = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "V1SubscriptionNote", ""
            ) as V1SubscriptionNote;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to advertisingPlatformServiceListPublisherSubscriptionStats
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async advertisingPlatformServiceListPublisherSubscriptionStats(response: ResponseContext): Promise<V1ListStatsResponse > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: V1ListStatsResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "V1ListStatsResponse", ""
            ) as V1ListStatsResponse;
            return body;
        }
        if (isCodeInRange("403", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Returned when the user does not have permission to access the resource.", body, response.headers);
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: string = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "string", ""
            ) as string;
            throw new ApiException<string>(response.httpStatusCode, "Returned when the resource does not exist.", body, response.headers);
        }
        if (isCodeInRange("500", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Server error", body, response.headers);
        }
        if (isCodeInRange("0", response.httpStatusCode)) {
            const body: GooglerpcStatus = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "GooglerpcStatus", ""
            ) as GooglerpcStatus;
            throw new ApiException<GooglerpcStatus>(response.httpStatusCode, "An unexpected error response.", body, response.headers);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: V1ListStatsResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "V1ListStatsResponse", ""
            ) as V1ListStatsResponse;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to advertisingPlatformServiceListPublisherSubscriptions
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async advertisingPlatformServiceListPublisherSubscriptions(response: ResponseContext): Promise<V1ListPublisherSubscriptionsResponse > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: V1ListPublisherSubscriptionsResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "V1ListPublisherSubscriptionsResponse", ""
            ) as V1ListPublisherSubscriptionsResponse;
            return body;
        }
        if (isCodeInRange("403", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Returned when the user does not have permission to access the resource.", body, response.headers);
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: string = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "string", ""
            ) as string;
            throw new ApiException<string>(response.httpStatusCode, "Returned when the resource does not exist.", body, response.headers);
        }
        if (isCodeInRange("500", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Server error", body, response.headers);
        }
        if (isCodeInRange("0", response.httpStatusCode)) {
            const body: GooglerpcStatus = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "GooglerpcStatus", ""
            ) as GooglerpcStatus;
            throw new ApiException<GooglerpcStatus>(response.httpStatusCode, "An unexpected error response.", body, response.headers);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: V1ListPublisherSubscriptionsResponse = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "V1ListPublisherSubscriptionsResponse", ""
            ) as V1ListPublisherSubscriptionsResponse;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to advertisingPlatformServiceUpdatePublisherSubscription
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async advertisingPlatformServiceUpdatePublisherSubscription(response: ResponseContext): Promise<V1PublisherSubscription > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: V1PublisherSubscription = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "V1PublisherSubscription", ""
            ) as V1PublisherSubscription;
            return body;
        }
        if (isCodeInRange("403", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Returned when the user does not have permission to access the resource.", body, response.headers);
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: string = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "string", ""
            ) as string;
            throw new ApiException<string>(response.httpStatusCode, "Returned when the resource does not exist.", body, response.headers);
        }
        if (isCodeInRange("500", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Server error", body, response.headers);
        }
        if (isCodeInRange("0", response.httpStatusCode)) {
            const body: GooglerpcStatus = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "GooglerpcStatus", ""
            ) as GooglerpcStatus;
            throw new ApiException<GooglerpcStatus>(response.httpStatusCode, "An unexpected error response.", body, response.headers);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: V1PublisherSubscription = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "V1PublisherSubscription", ""
            ) as V1PublisherSubscription;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

    /**
     * Unwraps the actual response sent by the server from the response context and deserializes the response content
     * to the expected objects
     *
     * @params response Response returned by the server for a request to advertisingPlatformServiceUpdatePublisherSubscriptionNote
     * @throws ApiException if the response code was not in [200, 299]
     */
     public async advertisingPlatformServiceUpdatePublisherSubscriptionNote(response: ResponseContext): Promise<V1SubscriptionNote > {
        const contentType = ObjectSerializer.normalizeMediaType(response.headers["content-type"]);
        if (isCodeInRange("200", response.httpStatusCode)) {
            const body: V1SubscriptionNote = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "V1SubscriptionNote", ""
            ) as V1SubscriptionNote;
            return body;
        }
        if (isCodeInRange("403", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Returned when the user does not have permission to access the resource.", body, response.headers);
        }
        if (isCodeInRange("404", response.httpStatusCode)) {
            const body: string = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "string", ""
            ) as string;
            throw new ApiException<string>(response.httpStatusCode, "Returned when the resource does not exist.", body, response.headers);
        }
        if (isCodeInRange("500", response.httpStatusCode)) {
            const body: any = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "any", ""
            ) as any;
            throw new ApiException<any>(response.httpStatusCode, "Server error", body, response.headers);
        }
        if (isCodeInRange("0", response.httpStatusCode)) {
            const body: GooglerpcStatus = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "GooglerpcStatus", ""
            ) as GooglerpcStatus;
            throw new ApiException<GooglerpcStatus>(response.httpStatusCode, "An unexpected error response.", body, response.headers);
        }

        // Work around for missing responses in specification, e.g. for petstore.yaml
        if (response.httpStatusCode >= 200 && response.httpStatusCode <= 299) {
            const body: V1SubscriptionNote = ObjectSerializer.deserialize(
                ObjectSerializer.parse(await response.body.text(), contentType),
                "V1SubscriptionNote", ""
            ) as V1SubscriptionNote;
            return body;
        }

        throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers);
    }

}
