import { useEffect, useState } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router'
import {
  Fa,
  Modal,
  Text,
  TextHeader,
  showErrorMessage,
} from '@eltoro-ui/components'
import { AudienceUploadNavigation, PoliticalTransparencyPage } from 'Pages'
import {
  MainWithSide,
  CreateCampaignHeader,
  CreateCampaignSplash,
  CreateTypeSelector,
  CampaignSettingsForm,
  ReviewShoppingCart,
  PageContent,
  PrePayMsg,
  OrgNotes,
} from 'Components'
import { useAppContext, useCampaignContext } from 'Contexts'
import { styleTailwind } from 'Helpers'
import { submitPrePayment } from 'Requests'
import './CreateCampaignPage.scss'
import { Campaignservicev1OrderLine } from 'next-gen-sdk'

const StepButton = styleTailwind(
  'button',
  'CreateCampaignPage__stepper-button flex items-center text-center justify-start relative',
)

const NavButton = styleTailwind(
  'button',
  'Button border-primary font-semibold text-l flex items-center rounded-full py-2 px-8 border-thin flex-col text-center justify-center relative z-10 w-auto hover:bg-primary-700 transition-all hover:border-primary-700 hover:text-white',
)

const StepButtonText = styleTailwind(
  Text,
  'StepButtonText text-xs font-semibold uppercase',
)

const FaCircle = styleTailwind('div', 'FaCircle circle')

type ParamType = {
  campaignId: string
}

export const CreateCampaignPage = () => {
  const stepSetter = () => {
    const url = window.location.href
    if (/audiences/.test(url)) return 1
    if (/ads/.test(url)) return 2
    if (/political-transparency/.test(url)) return 3
    if (/settings/.test(url)) return 4

    return 1
  }
  const [currentStep, setCurrentStep] = useState(stepSetter())
  const [sideClose, setSideClose] = useState(true)
  const [showTip, setShowTip] = useState(true)
  const loc = useLocation()
  const params = useParams<ParamType>()
  const navigate = useNavigate()
  const {
    campaign,
    campaign_name,
    attachedAudiences,
    attachedCreatives,
    orderLines,
    fetchDraftCampaign,
    isPolitical,
    submissionRemovals,
    setRefresh,
  } = useCampaignContext()
  const {
    currentOrg,
    campaignServiceApi,
    isAdmin,
    overrideCreditCardRequirement,
  } = useAppContext()

  if (params.campaignId && !campaign?.id && !loc.pathname.includes('edit')) {
    /* console.log('copy and pasted url from creation but empty context') */
    fetchDraftCampaign(params.campaignId)
  }

  if (loc.pathname.includes('edit') && !campaign?.id && params.campaignId) {
    /* console.log('Add Campaign Context Filler Here', loc) */
    fetchDraftCampaign(params.campaignId)
  }

  const stepNotReady = () => {
    if (!campaign_name || !campaign?.name) return true
    if (currentStep === 1) {
      return (
        !(attachedAudiences && attachedAudiences?.length >= 1) &&
        !(orderLines?.length && orderLines.some((ol) => ol.audiences?.length))
      )
    }
    if (currentStep === 2) {
      return (
        !(attachedCreatives && attachedCreatives?.length >= 1) &&
        !(orderLines?.length && orderLines.some((ol) => ol.creatives?.length))
      )
    }
    if (currentStep === 3 && isPolitical) {
      const firstOl = orderLines?.[0]
      return (
        !firstOl ||
        !campaign.politicalTransparency?.politicalObjective ||
        !campaign.politicalTransparency?.paymentMethodType ||
        !campaign.politicalTransparency?.orgAddress1 ||
        !campaign.politicalTransparency?.orgCity ||
        !campaign.politicalTransparency?.orgName ||
        !campaign.politicalTransparency?.orgRegion ||
        !campaign.politicalTransparency?.orgPostalCode ||
        !campaign.politicalTransparency?.orgPhone ||
        !campaign.politicalTransparency?.treasurerName ||
        !firstOl.politicalFields?.orgZip ||
        !firstOl.politicalFields?.paidForBy ||
        !firstOl.politicalFields?.audienceDescription ||
        !firstOl.politicalFields?.audienceIdsTargeted ||
        !firstOl.politicalFields?.submitterAddress ||
        !firstOl.politicalFields?.submitterCity ||
        !firstOl.politicalFields?.submitterName ||
        !firstOl.politicalFields?.submitterState ||
        !firstOl.politicalFields?.submitterZip
      )
    }
    return true
  }

  const query = currentOrg?.id ? `?org_id=${currentOrg.id}` : ''

  const navToStep = (step: 1 | 2 | 3 | 4 | 5) => {
    // audiences
    if (step === 1) {
      setCurrentStep(1)
      navigate(
        `../${loc.pathname.includes('edit') ? 'edit' : 'create'}/${
          campaign?.id
        }/audiences${query}`,
      )
    }
    // Creatives
    if (step === 2) {
      navigate(
        `../${loc.pathname.includes('edit') ? 'edit' : 'create'}/${
          campaign?.id
        }/ads${query}`,
      )
      setCurrentStep(2)
    }
    // order summary
    if (step === 3) {
      navigate(
        `../${loc.pathname.includes('edit') ? 'edit' : 'create'}/${
          campaign?.id
        }/political-transparency${query}`,
      )
      setCurrentStep(3)
    }
    // order summary
    if (step === 4) {
      navigate(
        `../${loc.pathname.includes('edit') ? 'edit' : 'create'}/${
          campaign?.id
        }/settings${query}`,
      )
      setCurrentStep(4)
    }
    // save draft and return to dashboard
    if (step === 5) {
      navigate(`/campaigns/dashboard${query}`)
    }
  }

  const navButtonText = () => {
    if (currentStep === 4) return 'Save'
    if (stepNotReady()) return 'Skip'
    return 'Next'
  }

  const submitForReview = async (selectedCard?: string) => {
    if (!orderLines) return

    const submissionOrderlines = orderLines.reduce(
      (acc: Campaignservicev1OrderLine[], ol) => {
        if (!ol.clickThroughUrl) {
          showErrorMessage(`${ol.name} is missing a clickthru url`, '')
          return acc
        }
        if (
          ol.costRange &&
          ol.costRange.estimate &&
          ol.costRange.estimate.units &&
          ol.audiences &&
          ol.audiences.length > 0 &&
          ol.creatives &&
          ol.creatives.length > 0 &&
          !ol.locked &&
          ol.id &&
          !submissionRemovals.includes(ol.id) &&
          ol.status === 'ORDERLINE_STATUS_DRAFT'
        ) {
          return [...acc, ol]
        }
        return acc
      },
      [],
    )
    // pre pay for order lines
    if (
      currentOrg?.id &&
      !overrideCreditCardRequirement &&
      (isPolitical || !!orderLines.find((ol) => ol.prepay))
    ) {
      const olIds = submissionOrderlines.reduce((acc: string[], ol) => {
        if (!ol.id) return acc
        return [...acc, ol.id]
      }, [])
      try {
        await submitPrePayment(currentOrg.id, olIds, selectedCard)
      } catch (err) {
        showErrorMessage(
          'Error submitting pre-payment',
          (err as { body?: { message?: string } })?.body?.message || '',
        )
        setRefresh(true)
        return
      }
    }
    // submit order lines for review
    const submissions = submissionOrderlines.map((currentOL) => {
      if (!currentOL?.id || !campaignServiceApi || !currentOrg?.id) {
        return null
      }
      return campaignServiceApi.advertisingPlatformServiceRequestReview(
        currentOL.id,
        {
          orgId: currentOrg.id,
        },
      )
    })
    if (submissions && submissions.length === 0) {
      showErrorMessage('No submittable order lines', '')
      return
    }
    return Promise.all(submissions)
      .then(() => {
        navigate(`../${campaign?.id}/success${query}`)
      })
      .catch((err) => {
        showErrorMessage(
          'Error submitting order lines for review',
          (err as { body?: { message?: string } })?.body?.message || '',
        )
        setRefresh(true)
      })
  }

  useEffect(() => {
    if (
      attachedAudiences &&
      attachedAudiences.length === 0 &&
      loc.pathname.includes('/audiences')
    )
      setCurrentStep(1)
  }, [attachedAudiences, loc.pathname])

  useEffect(() => {
    const tipTime = setTimeout(() => {
      setShowTip(false)
    }, 1500)

    return () => clearTimeout(tipTime)
  }, [])

  return (
    <>
      <PageContent className="CreateCampaignPage">
        <MainWithSide
          onSideClose={() => {
            setSideClose((sideClosed) => !sideClosed)
          }}
          side={
            currentStep === 4 &&
            campaign?.orgId &&
            sideClose && <ReviewShoppingCart onSubmit={submitForReview} />
          }
        >
          {isAdmin && <OrgNotes />}

          {campaign && !campaign.name && (
            <div
              className={`CreateCampaignPage__splash-container ${
                campaign && !campaign.name ? 'visible' : 'invisible hidden'
              }`}
            >
              <CreateCampaignSplash />
            </div>
          )}

          {campaign && campaign.name && (
            <>
              <div
                className={`CreateCampaignPage__content flex-col items-start pt-4 ${
                  campaign && campaign.name ? 'flex' : 'hidden'
                }`}
              >
                <TextHeader className="pb-2" type={1}>
                  Create campaign
                </TextHeader>
                <div className="CreateCampaignPage__stepper flex flex-1 items-center justify-between">
                  <div
                    className={`CreateCampaignHeader__tooltip Stepper-button z-20 ${
                      currentStep === 1 ? 'is-hidden' : ''
                    }`}
                    aria-label="Click to edit Campaign details"
                    data-tooltip="top"
                  >
                    <CreateCampaignHeader />
                  </div>
                  {/* Stepper */}
                  {/* Step 1 */}
                  <div
                    className={`Stepper-button audience ${
                      currentStep === 4 || currentStep === 3 ? '' : 'is-hidden'
                    } ${
                      (showTip && currentStep === 4) ||
                      (showTip && currentStep === 3)
                        ? 'is-shown'
                        : ''
                    }`}
                    data-tooltip="top"
                    aria-label="Go to Review to edit audience"
                  >
                    <StepButton
                      disabled={currentStep === 4 || currentStep === 3}
                      role="button"
                      className={`Step-1 ${
                        currentStep === 1
                          ? 'bg-base is-selected rounded-tl-xl rounded-tr-xl'
                          : 'bg-gray'
                      }`}
                      onClick={() => navToStep(1)}
                      onKeyDown={() => navToStep(1)}
                      tabIndex={0}
                    >
                      {!(attachedAudiences && attachedAudiences?.length >= 1) &&
                      !(
                        orderLines?.length &&
                        orderLines?.some((ol) => ol.audiences?.length)
                      ) ? (
                        <>
                          <FaCircle className="bg-tint-khaki-200 text-primary">
                            <Fa icon="users" size={1} />
                          </FaCircle>
                          <StepButtonText
                            className="desktopsmall:hidden"
                            tag="p"
                          >
                            audience
                          </StepButtonText>
                        </>
                      ) : (
                        <>
                          <FaCircle className="bg-success-400 text-white">
                            <Fa icon="check" size={1} />
                          </FaCircle>
                          <StepButtonText
                            className="text-success-500 desktopsmall:hidden"
                            tag="p"
                          >
                            Audience
                          </StepButtonText>
                        </>
                      )}
                    </StepButton>
                  </div>
                  {/* Step 2 */}
                  <div
                    className={`Stepper-button creatives ${
                      currentStep === 4 || currentStep === 3 ? '' : 'is-hidden'
                    } ${
                      (showTip && currentStep === 4) ||
                      (showTip && currentStep === 3)
                        ? 'is-shown'
                        : ''
                    }`}
                    data-tooltip="top"
                    aria-label="Go to Review to edit creatives"
                  >
                    <StepButton
                      disabled={currentStep === 4 || currentStep === 3}
                      role="button"
                      className={`step2 ${
                        !attachedAudiences?.length && !orderLines?.length
                          ? 'cursor-default'
                          : ''
                      } ${
                        currentStep === 2
                          ? 'bg-base is-selected rounded-tr-xl before:rounded-tl-xl'
                          : 'bg-gray'
                      }`}
                      onClick={() => navToStep(2)}
                      onKeyDown={() => navToStep(2)}
                      tabIndex={0}
                    >
                      {!attachedAudiences?.length && !orderLines?.length ? (
                        <>
                          <FaCircle className="bg-tint-gray-200 text-white">
                            <Fa icon="image" size={1} />
                          </FaCircle>
                          <StepButtonText
                            className="desktopsmall:hidden"
                            tag="p"
                          >
                            creative
                          </StepButtonText>
                        </>
                      ) : (
                        <>
                          {orderLines?.some((ol) => ol.creatives?.length) ? (
                            <>
                              <FaCircle className="bg-success-400 text-white">
                                <Fa icon="check" size={1} />
                              </FaCircle>
                              <StepButtonText
                                className="text-success-500 desktopsmall:hidden"
                                tag="p"
                              >
                                creative
                              </StepButtonText>
                            </>
                          ) : (
                            <>
                              <FaCircle className="bg-tint-khaki-200 text-primary">
                                <Fa icon="photo-video" size={1} />
                              </FaCircle>
                              <StepButtonText
                                className="text-primary-600 desktopsmall:hidden"
                                tag="p"
                              >
                                creative
                              </StepButtonText>
                            </>
                          )}
                        </>
                      )}
                    </StepButton>
                  </div>
                  {isPolitical && (
                    <div
                      className={`Stepper-button tooltip-primary ${
                        isPolitical && !(currentStep === 3)
                          ? ''
                          : 'is-hidden cursor-default'
                      } ${showTip && isPolitical ? 'is-shown' : ''}`}
                      data-tooltip="top"
                      aria-label="Please fill out form"
                    >
                      <StepButton
                        role="button"
                        className={`PoliticalTransparencyStep ${
                          !orderLines?.some((ol) => ol.creatives?.length) ||
                          !orderLines?.some((ol) => ol.audiences?.length)
                            ? 'cursor-default'
                            : ''
                        } ${
                          currentStep === 3
                            ? 'bg-base is-selected rounded-tl-xl rounded-tr-xl'
                            : 'bg-gray'
                        }`}
                        onClick={() => navToStep(3)}
                        onKeyDown={() => navToStep(3)}
                      >
                        {!(currentStep === 3) ? (
                          <>
                            <FaCircle className="bg-tint-gray-200 text-white">
                              <Fa icon="file-check" size={1} />
                            </FaCircle>
                            <StepButtonText
                              className="desktopsmall:hidden"
                              tag="p"
                            >
                              political transparency
                            </StepButtonText>
                          </>
                        ) : (
                          <>
                            <FaCircle className="bg-primary-100 text-primary">
                              <Fa icon="file-check" size={1} />
                            </FaCircle>
                            <StepButtonText
                              className="text-primary-600 desktopsmall:hidden"
                              tag="p"
                            >
                              political transparency
                            </StepButtonText>
                          </>
                        )}
                      </StepButton>
                    </div>
                  )}

                  {/* Review step */}
                  <div
                    className={`ReviewStep-tooltip-wrap Stepper-button z-30 `}
                    aria-label={
                      !sideClose ? 'Click to see payment summary' : undefined
                    }
                    data-tooltip="top"
                  >
                    <StepButton
                      role="button"
                      className={`ReviewStep relative ${
                        !orderLines?.some((ol) => ol.creatives?.length) ||
                        !orderLines?.some((ol) => ol.audiences?.length)
                          ? '!cursor-default'
                          : ''
                      } ${
                        currentStep === 4
                          ? 'bg-base is-selected rounded-tl-xl rounded-tr-xl'
                          : 'bg-gray'
                      } ${!sideClose ? '!cursor-pointer' : ''}`}
                      onClick={() => {
                        navToStep(4)
                        if (!sideClose) setSideClose(true)
                      }}
                      onKeyDown={() => navToStep(4)}
                      tabIndex={0}
                    >
                      {!(currentStep === 4) ? (
                        <>
                          <FaCircle className="bg-tint-gray-200 text-white">
                            <Fa icon="shopping-cart" size={1} />
                          </FaCircle>
                          <StepButtonText tag="p">Review</StepButtonText>
                        </>
                      ) : (
                        <>
                          {currentStep === 4 &&
                          orderLines?.some((ol) => ol.creatives?.length) &&
                          orderLines?.length &&
                          orderLines?.length >= 0 ? (
                            <>
                              <FaCircle className="bg-success-400 text-white">
                                <Fa icon="check" size={1} />
                              </FaCircle>
                              <StepButtonText
                                className="text-success-500"
                                tag="p"
                              >
                                Review
                              </StepButtonText>
                            </>
                          ) : (
                            <>
                              <FaCircle className="bg-primary-100 text-primary">
                                <Fa icon="shopping-cart" size={1} />
                              </FaCircle>
                              <StepButtonText
                                className="text-primary-600"
                                tag="p"
                              >
                                Review
                              </StepButtonText>
                            </>
                          )}
                        </>
                      )}
                    </StepButton>
                  </div>
                </div>
                {/* steps content */}
                <div className="CreateCampaignPage__steps bg-base flex flex-1 flex-col rounded-lg px-6 pt-6 pb-7">
                  {currentStep === 1 && (
                    <AudienceUploadNavigation audienceTitle="Select the audiences you'd like to target" />
                  )}
                  {currentStep === 2 && <CreateTypeSelector />}
                  {currentStep === 3 && (
                    <PoliticalTransparencyPage navFunc={navToStep} />
                  )}
                  {currentStep === 4 && <CampaignSettingsForm />}
                </div>
              </div>
            </>
          )}
        </MainWithSide>
        {campaign?.id && (
          <Modal
            classNameWrap="CreateCampaignPage__buttons-modal shadow-6xl pointer-events-none top-auto bottom-0 margin-t-auto"
            className="CreateCampaignPage__footer_buttons animate-slideup pointer-events-auto bottom-0 left-0 right-0 z-40 ml-auto w-4/5 justify-end gap-4 rounded-none"
            classNameContent="CreateCampaignPage__buttons-modal-content flex justify-end gap-4 pr-4 mr-2"
            noBackground
            noShade
            noStyles
          >
            {isPolitical ? (
              <div className="CreateCampaignPage__footer-msg-container animate-slidedown flex flex-col justify-center">
                <PrePayMsg />
              </div>
            ) : null}

            {currentStep === 2 && (
              <NavButton
                className="PrevButton bg-base-700 text-primary-600"
                type="button"
                onClick={() => {
                  navigate(-1)
                  navToStep(1)
                }}
              >
                Back
              </NavButton>
            )}

            <NavButton
              className={`NextButton bg-primary text-base ${
                navButtonText() === 'Next' ? 'ripples' : ''
              }`}
              type="button"
              onClick={() => {
                if (currentStep === 1) {
                  navToStep(2)
                }
                if (currentStep === 2) {
                  if (isPolitical) {
                    navToStep(3)
                  } else {
                    navToStep(4)
                  }
                }
                if (currentStep === 3) {
                  navToStep(4)
                }
                if (currentStep === 4) {
                  navToStep(5)
                }
              }}
            >
              {navButtonText()}
            </NavButton>
          </Modal>
        )}
      </PageContent>
    </>
  )
}
