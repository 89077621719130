/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

export class AdvertisingPlatformServiceUpdateRuleRequest {
    /**
    * RefId of the ruleset
    */
    'refId'?: string;
    /**
    * minimum values considered a hit
    */
    'minimum'?: number;
    /**
    * maximum values considered a hit
    */
    'maximum'?: number;
    /**
    * type of rule it is
    */
    'type'?: string;
    /**
    * the term of the rule
    */
    'term'?: string;
    /**
    * The ID of the rule set this rule belongs to
    */
    'ruleSetId'?: string;
    /**
    * The Pixels OrgID
    */
    'orgId'?: string;
    /**
    * The time the rule was created.
    */
    'created'?: Date;
    /**
    * The last time the rule was updated.
    */
    'updated'?: Date;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "refId",
            "baseName": "ref_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "minimum",
            "baseName": "minimum",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "maximum",
            "baseName": "maximum",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "string",
            "format": ""
        },
        {
            "name": "term",
            "baseName": "term",
            "type": "string",
            "format": ""
        },
        {
            "name": "ruleSetId",
            "baseName": "rule_set_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "created",
            "baseName": "created",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updated",
            "baseName": "updated",
            "type": "Date",
            "format": "date-time"
        }    ];

    static getAttributeTypeMap() {
        return AdvertisingPlatformServiceUpdateRuleRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

