import { useRef, useState, FC, useEffect, PropsWithChildren } from 'react'
import { useOnClickOutside } from '@eltoro-ui/hooks'
import { Fa } from '../FontAwesome'
import { Popover } from '../Popover'
import { FilterOptionType, TableFilterDropdownType } from '../Table/types'
import { Button } from '../Button'
import { Text } from '../Text'
import { TextInput } from '../TextInput'
import { Checkbox } from '../Checkbox'
import { styleTailwind } from '../../helpers'
import './TableFilterDropdown.scss'
import '../../Assets/css/all.css'

const DropdownMenuHeader = styleTailwind(
  Text,
  'DropdownMenuHeader text-tint-gray-600 pb-1 font-bold flex justify-between items-center',
)
const SortButton = styleTailwind(
  Button,
  'TableFilterDropdown__sort-button flex-1 focus:ring-2',
)
const StatusCloseBtn = styleTailwind(Button, 'StatusCloseBtn flex-1')

const KeywordFilters: FC<{
  onChange: (value: string) => void
  onRemove: () => void
  currentValue: string
}> = ({ onChange, onRemove, currentValue }) => {
  return (
    <div className="KeywordFilters flex items-center gap-2">
      <Button
        iconOnly={<Fa icon="minus" size={1} />}
        onClick={() => onRemove()}
      />
      <TextInput
        value={currentValue}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  )
}

export const TableFilterDropdown = <T extends { [key: string]: any }>({
  column,
  columnIndex,
  onFilter,
  handleSort,
  sorted,
  setFiltered,
  clearSort,
  children,
}: PropsWithChildren<TableFilterDropdownType<T>>) => {
  const [isOpen, setIsOpen] = useState(false)
  const [filterValue, setFilterValue] = useState<string[]>()
  const {
    filterOn,
    removeFilter = false,
    removeSort = false,
    filterPath,
    allowSearchFilter,
  } = column

  const contentRef = useRef<HTMLDivElement>(null)
  const filterButtonRef = useRef<HTMLDivElement>(null)
  useOnClickOutside([contentRef, filterButtonRef], () => {
    setIsOpen(false)
  })

  useEffect(() => {
    if (onFilter && column.path) {
      const nonEmpty = filterValue?.filter((n) => n !== '') || []
      const removeEmpty = Array.from(new Set(nonEmpty))
      const path = filterPath || column.path
      onFilter(columnIndex, path, removeEmpty)

      if (removeEmpty.length) {
        setFiltered((prev) => [...new Set([...prev, columnIndex])])
      } else {
        setFiltered((prev) => prev.filter((i) => i !== columnIndex))
      }
    }
  }, [filterValue])

  const handleOnChangeCheckboxes = (
    option: FilterOptionType,
    checked: boolean,
  ) => {
    if (checked) {
      setFilterValue((prev) => [...(prev || []), option.value] as string[])
    } else {
      setFilterValue((prev) =>
        (prev || []).filter((val) => val !== option.value),
      )
    }
  }

  const handleOnChange = (value: string, index: number) =>
    setFilterValue((prev) => {
      if (!prev) return [value]
      return [
        ...prev.slice(0, index),
        value,
        ...prev.slice(index + 1),
      ] as string[]
    })

  const handleOnRemove = (index: number) => {
    setFilterValue((prev) => {
      if (!prev) return [] as string[]
      return [...prev.slice(0, index), ...prev.slice(index + 1)] as string[]
    })
  }

  const isAsc = sorted && sorted[0] === columnIndex && sorted[1] === 'asc'
  const isDesc = sorted && sorted[0] === columnIndex && sorted[1] === 'desc'

  return !removeFilter || !removeSort ? (
    <Popover
      position={['bottom']}
      align="start"
      content={
        <div ref={contentRef} className="TableFilterDropdown__content-wrapper">
          <>
            {!removeSort && (
              <>
                <DropdownMenuHeader>
                  <span>Sort</span>
                  <span
                    className="animate-slideleft"
                    data-tooltip="left"
                    aria-label="Close dropdown"
                  >
                    <StatusCloseBtn
                      iconOnly={<Fa icon="xmark" size={1} />}
                      onClick={() => setIsOpen(false)}
                      className="StatusCloseButton"
                    />
                  </span>
                </DropdownMenuHeader>
                <div className="ButtonSort__wrap flex gap-1">
                  <span
                    className="flex-1 flex"
                    data-tooltip="top"
                    aria-label="Sort asc"
                  >
                    <SortButton
                      onClick={
                        !isAsc ? () => handleSort(columnIndex, 'asc') : () => {}
                      }
                      iconLeft={<Fa icon="sort-up" size={2} />}
                      className={
                        isAsc
                          ? 'TableFilterDropdown__sort-button--is-active'
                          : ''
                      }
                    />
                  </span>
                  <span
                    className="flex-1 flex"
                    data-tooltip="top"
                    aria-label="Sort desc"
                  >
                    <SortButton
                      onClick={
                        !isDesc
                          ? () => handleSort(columnIndex, 'desc')
                          : () => {}
                      }
                      iconLeft={<Fa icon="sort-down" size={2} />}
                      className={
                        isDesc
                          ? 'TableFilterDropdown__sort-button--is-active'
                          : ''
                      }
                    />
                  </span>
                  {(isDesc || isAsc) && !removeSort && (
                    <span
                      className="TableFilterDropdown__clear-sort animate-slideleft"
                      data-tooltip="left"
                      aria-label={isDesc ? 'Clear desc sort' : 'Clear asc sort'}
                    >
                      <Button
                        className="TableFilterDropdown__clear-sort-button"
                        onClick={() => clearSort(columnIndex)}
                        iconOnly={<Fa icon="xmark" size={1} />}
                      />
                    </span>
                  )}
                </div>
              </>
            )}
            {!removeFilter && (
              <>
                <DropdownMenuHeader className="pt-4 mt-4 border-t-thin border-tint-gray-200">
                  Filter
                </DropdownMenuHeader>
                {typeof filterOn === 'object' && (
                  <div className="FilterList__checkbox flex flex-col gap-1 relative">
                    {filterValue && filterValue.length > 0 && (
                      <span
                        className="ButtonFilter__remove ml-auto"
                        data-tooltip="left"
                        aria-label="Clear filter"
                      >
                        <StatusCloseBtn
                          className="animate-slideleft"
                          iconOnly={<Fa icon="xmark" size={1} />}
                          onClick={() => setFilterValue(undefined)}
                        />
                      </span>
                    )}
                    {/* TODO: AdOps would like a search on filtering, Testrail ref: C20274 - Alphabetize the statuses available in the order line status filter...add a “search” button for the filtering. */}
                    {filterOn
                      .sort((a, b) => {
                        const A =
                          typeof a.label === 'string'
                            ? a.label
                            : String(a.label)
                        const B =
                          typeof b.label === 'string'
                            ? b.label
                            : String(b.label)
                        return A.localeCompare(B)
                      })
                      .map(({ label, value }) => {
                        const matching = filterValue?.find(
                          (opt) => opt === value,
                        )
                        return (
                          <Checkbox
                            size="l"
                            key={`checkbox-${label}-${value}`}
                            checked={matching !== undefined}
                            label={label}
                            onChange={(checked) =>
                              handleOnChangeCheckboxes(
                                { label, value },
                                checked,
                              )
                            }
                          />
                        )
                      })}
                  </div>
                )}
                {(filterOn === 'string' || allowSearchFilter) && (
                  <div className="FilterAdd flex flex-col gap-2">
                    {filterValue?.map((value, index) => {
                      return (
                        <KeywordFilters
                          key={`${column.path}-keyword-filter-${index}`}
                          currentValue={value.toString()}
                          onChange={(value) => handleOnChange(value, index)}
                          onRemove={() => handleOnRemove(index)}
                        />
                      )
                    })}
                    <Button
                      iconLeft={<Fa icon="plus" size={1} />}
                      onClick={() =>
                        setFilterValue((prev) => {
                          if (!prev) return ['']
                          return [...prev, ''] as string[]
                        })
                      }
                    >
                      Add Filter
                    </Button>
                  </div>
                )}
                {/*  FUTURE STATE: I think we could easily support number and date inputs (see v2 tables), 
                   but since the NextGen wireframes do not have filtering on them, I'm going to hold off. */}
              </>
            )}
          </>
        </div>
      }
      isOpen={isOpen}
      // Quick fix for tables in modals- there's likely a better way to do this
      className="TableFilterDropdown"
    >
      <div className="TableFilterDropdown__filter-icon" ref={filterButtonRef}>
        <div
          role="button"
          className={`TableFilterDropdown__filter-button flex w-full items-center focus:ring-1 ${
            isOpen || ((isDesc || isAsc) && !removeSort) ? 'active' : ''
          }`}
          onClick={() => setIsOpen(true)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') setIsOpen(!isOpen)
          }}
          tabIndex={0}
        >
          {children}
        </div>
      </div>
    </Popover>
  ) : null
}
