import { V1RuleSet } from 'next-gen-sdk'
import { ApiFactory } from 'apiFactory'

export const createRuleSet = async (
  incomingRuleSet: V1RuleSet,
  pixelId: string,
  subscriptionId: string,
  orgId: string,
) => {
  const token = localStorage.getItem('eltoro_token')
  const apis = ApiFactory(token)
  const { webToHomeAPI } = apis
  if (!webToHomeAPI) return undefined

  const ruleSet = await webToHomeAPI.advertisingPlatformServiceCreateRuleSet(
    pixelId,
    subscriptionId,
    {
      operator: incomingRuleSet.operator,
      parentRuleSetId: incomingRuleSet.parentRuleSetId,
      orgId: incomingRuleSet.orgId,
      subscriptionId,
    },
    orgId,
  )

  if (incomingRuleSet?.rules?.length && ruleSet?.id) {
    // create rules
    await Promise.all(
      incomingRuleSet?.rules?.map(async (rule) => {
        return webToHomeAPI.advertisingPlatformServiceCreateRule(
          pixelId,
          subscriptionId,
          ruleSet?.id || '',
          { ...rule, orgId },
          orgId,
        )
      }),
    )
  }

  if (incomingRuleSet?.ruleSet?.length && ruleSet?.id) {
    // create child rule sets
    await Promise.all(
      incomingRuleSet?.ruleSet?.map(async (rs) => {
        return createRuleSet(
          { ...rs, parentRuleSetId: ruleSet.id },
          pixelId,
          subscriptionId,
          orgId,
        )
      }),
    )
  }

  // for testing, will log entire rule set
  if (!incomingRuleSet.parentRuleSetId) {
    const parentRuleSet = await webToHomeAPI.advertisingPlatformServiceGetRuleSet(
      pixelId,
      subscriptionId,
      ruleSet.id!,
      orgId,
    )

    return parentRuleSet
  }
  return undefined
}
