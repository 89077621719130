/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1BatchOrderLineRequest } from '../models/V1BatchOrderLineRequest';
import { HttpFile } from '../http/http';

export class V1BatchOrderLinesRequest {
    /**
    * The request message specifying the resources to update. A maximum of 50 Order Lines can be created in a batch.
    */
    'orderLines'?: Array<V1BatchOrderLineRequest>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "orderLines",
            "baseName": "order_lines",
            "type": "Array<V1BatchOrderLineRequest>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1BatchOrderLinesRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

