import { useEffect, useState } from 'react'
import { V1Pixel } from 'next-gen-sdk'
import { useAppContext } from 'Contexts'
import { PixelFormItem } from './shared'

export const PixelSelect = ({
  pixel,
  setPixel,
  disabled,
}: {
  pixel: Partial<V1Pixel> | undefined
  setPixel: React.Dispatch<React.SetStateAction<Partial<V1Pixel> | undefined>>
  disabled?: boolean
}) => {
  const [existingPixels, setExistingPixels] = useState<V1Pixel[]>([])
  const [fetchStatus, setFetchStatus] = useState<
    'loading' | 'fail' | 'success'
  >('loading')

  const { currentOrg, webToHomeApi } = useAppContext()

  useEffect(() => {
    if (!webToHomeApi || !currentOrg?.id) return

    const handleFetchPixels = async () => {
      setFetchStatus('loading')
      let nextPageToken = ''
      let pix: V1Pixel[] = []
      do {
        await webToHomeApi
          .advertisingPlatformServiceListPixels(
            100, // pageSize
            nextPageToken, // pageToken
            currentOrg?.id,
            'name', // orderBy
          )
          .then((res) => {
            pix = [...pix, ...(res.pixels || [])]
            nextPageToken = res.nextPageToken || ''
          })
        setFetchStatus('success')
        setExistingPixels(pix)
      } while (nextPageToken !== '')
    }

    handleFetchPixels().catch((err) => {
      setFetchStatus('fail')
    })
  }, [currentOrg?.id, webToHomeApi])

  return (
    <div className="PixelSelectOrCreateNew flex flex-col gap-3">
      <PixelFormItem htmlFor="existing-pixel-name" label="Pixel" required>
        <select
          required
          value={pixel?.id || ''}
          onChange={(e) =>
            setPixel((prev) => ({ ...prev, id: e.target.value }))
          }
          data-testid="existing-pixel-select"
          className="text-grey-500 border-tint-gray-200 w-full rounded-md border p-2 pr-3 focus:!ring"
          disabled={fetchStatus !== 'success' || disabled}
        >
          <option value="" disabled>
            {(() => {
              if (fetchStatus === 'loading') {
                return 'Loading...'
              }
              if (fetchStatus === 'fail') {
                return 'Error loading pixels'
              }
              return 'Select an existing pixel'
            })()}
          </option>
          {existingPixels.map((pixel) => (
            <option
              key={pixel.id}
              value={pixel.id}
              data-testid={`option-${pixel.id}`}
            >
              {pixel.name}
            </option>
          ))}
        </select>
      </PixelFormItem>
    </div>
  )
}
