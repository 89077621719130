import { useContext, useEffect, useState, useRef } from 'react'
import { useLocation, useRoutes } from 'react-router-dom'
import {
  ScreenSize,
  useBrowser,
  useLocalStorage,
  useMediaQuery,
  useScreenSize,
} from '@eltoro-ui/hooks'
import {
  Button,
  MaxHeightContainer,
  SideMenu,
  SideMenuHeader,
  Fa,
} from '@eltoro-ui/components'
import {
  MainFooter,
  MainHeader,
  SideMenuItem,
  SideMainContent,
  Loading,
} from 'Components'
import { ETIcon } from 'Assets'
import { authorizedRoutes } from './routes'
import { useMouseHandlers } from 'Hooks'
import { SideMenuOrgDetails } from 'Components/SideMenuOrgDetails'
import { useAppContext, ThemeContext } from 'Contexts'
import { GlobalAlert } from 'Components/GlobalAlert'
import { TermsOfServicePage } from 'Pages'
const Routes = () => {
  const routes = useRoutes(authorizedRoutes)
  return routes
}
type DeviceType =
  | 'mobile'
  | 'mobileL'
  | 'tablet'
  | 'tabletP'
  | 'tabletL'
  | 'landscape'
  | 'desktop'

export const AuthorizedRoutes = () => {
  const {
    currentOrg,
    errorRebuildCount,
    isOnHold,
    isAdmin,
    isReadOnly,
    tosStatus,
    setTosStatus,
    webToHomeEnabled,
  } = useAppContext()
  const [hoverExpand, setHoverExpand] = useState(false)
  const { pathname } = useLocation()
  const [isExpanded, setIsExpanded] = useLocalStorage(
    false,
    'sidebar-collapsed',
  )
  const [showOrgSelect, setShowOrgSelect] = useState(false)
  const { name } = useContext(ThemeContext)

  const timeOutId = useRef<number | null>(1000)
  const { handleMouseEnter, handleMouseLeave } = useMouseHandlers({
    onMouseEnter: () => setHoverExpand(true),
    onMouseLeave: () => setHoverExpand(false),
    delay: timeOutId,
  })

  // adding media queries here so we could use it later for other components for specific overrides targeting devices
  const screenSize: ScreenSize = useScreenSize()
  const browser = useBrowser()
  const [mobile] = useMediaQuery('(max-width: 540px)')
  const [mobileLandscape] = useMediaQuery(
    '(orientation: landscape) and (max-width: 844px)',
  )
  const [landscape] = useMediaQuery(
    '(orientation: landscape) and (max-height:540px)',
  )
  const [tablet] = useMediaQuery('(max-width: 1024px)')
  const [tabletPortrait] = useMediaQuery(
    '(orientation: portrait) and (max-height: 1024px) and (max-width: 840px)',
  )
  const [tabletLandscape] = useMediaQuery(
    '(orientation: landscape) and (max-height: 840px)',
  )
  const [desktop] = useMediaQuery('(min-width: 1025px)')
  const orientation =
    screenSize.width > screenSize.height ? 'Landscape' : 'Portrait'
  const [darkMode] = useMediaQuery('(prefers-color-scheme: dark)')

  const Devices: Record<DeviceType, boolean> = {
    mobile: mobile,
    mobileL: mobileLandscape,
    landscape: landscape,
    tablet: tablet,
    tabletP: tabletPortrait,
    tabletL: tabletLandscape,
    desktop: desktop,
  }

  const Device = Object.entries(Devices).find(([key, value]) => value)?.[0] as
    | DeviceType
    | ''

  const handleToggler = () => {
    if (isExpanded) {
      setIsExpanded(!isExpanded)
    } else {
      setIsExpanded(true)
    }
  }

  useEffect(() => {
    let timeout: NodeJS.Timeout

    // Remove hover expansion class after 2 seconds if sidemenu doesn't collapse
    if (hoverExpand && !showOrgSelect) {
      timeout = setTimeout(() => {
        setHoverExpand(false)
      }, 2000)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [showOrgSelect, hoverExpand])

  // Reset error rebuild count when changing routes (used by LibraryContext + DashboardPage)
  useEffect(() => {
    errorRebuildCount.current = 0
  }, [errorRebuildCount, pathname])

  const query = `?org_id=${currentOrg?.id || ''}`

  return (
    <MaxHeightContainer
      id="MainAppContainer"
      className={`MainAppContainer MainAppContainer__${
        Device === '' ? 'Other' : Device
      } ${
        browser === undefined ? '' : `Browser__${browser} `
      } MainAppContainer__${orientation} Theme__${
        darkMode && name === 'default' ? 'dark' : name
      }`}
      classNameContent="MainAppContent"
      idContent="MainAppContent"
      fullHeight
      header={<MainHeader disableSearch={tosStatus !== 'signed'} />}
      footer={<MainFooter />}
    >
      {currentOrg && (
        <SideMainContent
          className={`AuthorizedRoutes relative z-[1] flex ${
            isExpanded ? 'SideMenu__expanded' : 'SideMenu__collapsed'
          } ${hoverExpand || showOrgSelect ? 'Hover__expanded' : ''}`}
        >
          {pathname !== '/documentation' && pathname !== '/internal-api-docs' && (
            <div
              id="SideMenu__dropdown"
              className="SideMenu__left-menu-wrap"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <SideMenu>
                {!showOrgSelect && (
                  <Button
                    className="SideMenuToggle bg-base border-thin border-tint-gray-400 group -mt-2 transition-all"
                    iconOnly={
                      <Fa
                        className="SideMenuToggle__icon"
                        icon="arrow-from-right"
                        size={1}
                      />
                    }
                    onClick={handleToggler}
                    dataTooltip="left"
                    ariaLabel={
                      isExpanded ? 'Collapse sidemenu' : 'Expand sidemenu'
                    }
                  />
                )}

                <div className="SideMenu__left-menu">
                  <SideMenuOrgDetails
                    disableNav={tosStatus !== 'signed'}
                    setShowOrgSelect={setShowOrgSelect}
                    showOrgSelect={showOrgSelect}
                  />

                  {tosStatus === 'signed' && (
                    <>
                      <SideMenuHeader
                        className="CampaignSideMenuHeader"
                        title="CAMPAIGNS"
                      >
                        <Fa
                          className="IconOnCollapsed__icon"
                          icon="ad"
                          size={3}
                          type="duotone"
                        />
                      </SideMenuHeader>
                      <SideMenuItem
                        icon={<Fa icon="tachometer-alt" size={1} />}
                        label="Dashboard"
                        to={`/campaigns/dashboard${query}`}
                        isActive={
                          pathname.includes('campaigns/dashboard') ||
                          pathname === '/'
                        }
                      />
                      {!isReadOnly && (
                        <SideMenuItem
                          icon={<Fa icon="plus-square" size={1} />}
                          label="Create"
                          disabled={isOnHold}
                          to={isOnHold ? '' : `/campaigns/create${query}`}
                          isActive={
                            pathname.includes('/campaigns/create') ||
                            pathname.includes('/campaigns/edit')
                          }
                        />
                      )}
                      <SideMenuItem
                        icon={<Fa icon="photo-video" size={1} />}
                        label="Creative Library"
                        to={`/campaigns/creative-library${query}`}
                        isActive={pathname === '/campaigns/creative-library'}
                      />
                      <SideMenuHeader
                        className="AudienceSideMenuHeader mt-6"
                        title="AUDIENCES"
                      >
                        <ETIcon
                          className="IconOnCollapsed__icon"
                          icon="target"
                        />
                      </SideMenuHeader>
                      {!isReadOnly && (
                        <SideMenuItem
                          icon={<Fa icon="user-plus" size={1} />}
                          label="Create"
                          disabled={isOnHold}
                          to={isOnHold ? '' : `/audiences/create${query}`}
                          isActive={pathname === '/audiences/create'}
                        />
                      )}
                      <SideMenuItem
                        icon={
                          <Fa className="scale-105" icon="file-user" size={1} />
                        }
                        label="Audience Library"
                        to={`/audiences/audience-library${query}`}
                        isActive={pathname === '/audiences/audience-library'}
                      />
                      {isAdmin &&
                        (/dev/.test(window.location.hostname) ||
                          /localhost/.test(window.location.hostname)) && (
                          <SideMenuItem
                            icon={
                              <Fa
                                className="scale-105"
                                icon="code-simple"
                                size={1}
                              />
                            }
                            label="Pixel Library"
                            to={`/pixel-library${query}`}
                            isActive={pathname === '/pixel-library'}
                          />
                        )}
                      {/* <SideMenuItem
                icon={<Fa icon="store" size={1} />}
                label="Marketplace"
                to="/audiences/marketplace"
                isActive={pathname === '/audiences/marketplace'}
                /> */}
                      {/* <SideMenuHeader title="Advanced Analytics"/> */}
                      {isAdmin && (
                        <>
                          <SideMenuHeader
                            className="AdOpsSideMenuHeader mt-6"
                            title="AD OPS"
                          >
                            <Fa
                              className="IconOnCollapsed__icon"
                              icon="user-secret"
                              size={3}
                              type="duotone"
                            />
                          </SideMenuHeader>
                          <SideMenuItem
                            icon={
                              <Fa className="scale-105" icon="bell" size={1} />
                            }
                            label="Alerts"
                            to={`/alerts${query}`}
                            isActive={pathname === '/alerts'}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </SideMenu>
            </div>
          )}
          <main className="AuthorizedRoutes__main flex-1">
            {tosStatus === 'signed' && <GlobalAlert />}
            <MaxHeightContainer
              className="AuthorizedRoutesContainer"
              classNameContent="AuthorizedRoutesContent"
            >
              {(() => {
                if (tosStatus === 'loading') return <Loading />
                if (tosStatus === 'not-signed')
                  return (
                    <TermsOfServicePage onSign={() => setTosStatus('signed')} />
                  )
                return <Routes />
              })()}
            </MaxHeightContainer>
          </main>
        </SideMainContent>
      )}
    </MaxHeightContainer>
  )
}
