import { Button, CharacterCounter, Fa, TextInput } from '@eltoro-ui/components'
import classNames from 'classnames'
import { useAppContext } from 'Contexts'
import { RuleWithTempId } from './types'
import { RuleRowSelect } from './shared'

export const RuleRow = ({
  rule,
  handleDeleteRule,
  handleUpdateRule,
  debugMode,
}: {
  rule: RuleWithTempId
  handleDeleteRule: (ruleToDelete: RuleWithTempId) => void
  handleUpdateRule: (ruleToUpdate: RuleWithTempId) => void
  debugMode: boolean
}) => {
  const { isReadOnly } = useAppContext()
  const isTermValid = (rule.term || '')?.length <= 2000

  return (
    <div
      className={classNames('RuleRow w-full', {
        'm-1 border border-[cornflowerblue] p-1': debugMode,
      })}
    >
      {debugMode && (
        <span className={debugMode ? 'text-[cornflowerblue]' : ''}>
          rule id: {rule.id}
        </span>
      )}
      <div className="RuleSetRow border-tint-gray-200 flex w-full flex-col gap-2 rounded-md border p-3">
        <div className="mobile:flex-wrap flex gap-2">
          <RuleRowSelect
            value={rule.type}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              handleUpdateRule({
                ...rule,
                type: e.target.value,
              })
            }}
            disabled={isReadOnly}
          >
            <option value="term">Term</option>
            <option value="regex">Regex</option>
          </RuleRowSelect>
          <div className="flex w-full flex-col">
            <TextInput
              value={rule.term || ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleUpdateRule({ ...rule, term: e.target.value })
              }
              placeholder="Enter value"
              className="min-w-[7rem]"
              classNameWrap={classNames({ '!border-warning': !isTermValid })}
              maxLength={2001}
              valid={isTermValid}
              hideValidIcon
              disabled={isReadOnly}
            />
            {(rule.term || '').length > 1995 && (
              <CharacterCounter
                fieldName="rule-term"
                max={2000}
                text={rule.term || ''}
              />
            )}
          </div>
          <TextInput
            value={rule.minimum?.toString() || ''}
            onChange={(e) => {
              handleUpdateRule({ ...rule, minimum: +e.target.value })
            }}
            type="number"
            placeholder="Min"
            classNameWrap="max-w-[5rem]"
            disabled={isReadOnly}
          />
          <TextInput
            value={rule.maximum?.toString() || ''}
            onChange={(e) => {
              handleUpdateRule({
                ...rule,
                maximum: +e.target.value,
              })
            }}
            type="number"
            placeholder="Max"
            classNameWrap="max-w-[5rem]"
            disabled={isReadOnly}
          />
          <Button
            iconOnly={<Fa icon="xmark" size={1} />}
            onClick={() => handleDeleteRule(rule)}
            disabled={isReadOnly}
          />
        </div>
      </div>
    </div>
  )
}
