import { useEffect, useState } from 'react'
import { useValidation } from '@eltoro-ui/hooks'
import { Button, Fa, TextHeader, Toggle } from '@eltoro-ui/components'
import { styleTailwind, v2SyncPrompt } from 'Helpers'
import { Orgmanagerv1Contact } from 'next-gen-sdk'
import { ContactForm } from 'Components'
import { useAppContext } from 'Contexts'

const FormFields = styleTailwind('div', 'FormFields')
const ContactLabel = styleTailwind('span', 'ContactBlock__label')
const ContactValue = styleTailwind('span', 'ContactBlock__value')

export const BillingDetails = () => {
  const { orgServiceApi, setCurrentOrg, currentOrg, roles } = useAppContext()
  const opContact = currentOrg?.contacts?.find(
    (contact) => contact.type === 'operations',
  )
  const billingContact = currentOrg?.contacts?.find(
    (contact) => contact.type === 'billing',
  )
  const [editing, setEditing] = useState(true)
  const [showWarning, setShowWarning] = useState(true)
  const [inputContact, setInputContact] = useState<
    Pick<
      Orgmanagerv1Contact,
      | 'address'
      | 'city'
      | 'email'
      | 'firstName'
      | 'lastName'
      | 'phone'
      | 'state'
      | 'zip'
      | 'country'
      | 'secondaryEmails'
    >
  >({})
  const isSameAsOpsContact =
    opContact?.firstName === inputContact.firstName &&
    opContact?.lastName === inputContact.lastName &&
    opContact?.phone === inputContact.phone &&
    opContact?.email === inputContact.email &&
    opContact?.address === inputContact.address &&
    opContact?.city === inputContact.city &&
    opContact?.state === inputContact.state &&
    opContact?.zip === inputContact.zip &&
    opContact?.country === inputContact.country
  const [useOpContact, setUseOpContact] = useState(isSameAsOpsContact)

  const {
    phone,
    email,
    zip,
    address,
    city,
    firstName,
    lastName,
    state,
    country,
    secondaryEmails,
  } = inputContact

  const isValidEmail = useValidation('email')
  const isValidPhone = useValidation('phone-number')
  const isValidZip = useValidation('zip-code')
  const isValidCanadianZip = useValidation('canadian-zip')

  const isReadOnly = roles && roles.includes('nextgen_read_only')

  // open editing automatically if there is no contact
  useEffect(() => {
    if (!billingContact && !isReadOnly) {
      setEditing(true)
      setShowWarning(true)
    } else {
      setShowWarning(false)
      setEditing(false)
    }
  }, [billingContact, isReadOnly])

  useEffect(() => {
    setUseOpContact(isSameAsOpsContact)
  }, [isSameAsOpsContact])

  // if current org changes, clear the input
  useEffect(() => {
    setInputContact({})
  }, [currentOrg])

  const handleUpdateCurrentOrg = (newContact: Orgmanagerv1Contact) => {
    if (!currentOrg) return
    let newContacts: Orgmanagerv1Contact[] = []
    // if new contact already exists
    if (currentOrg.contacts?.find((con) => con.id === newContact.id)) {
      newContacts = (currentOrg.contacts || []).reduce(
        (acc: Orgmanagerv1Contact[], contact) => {
          if (contact.id === newContact.id) return [...acc, newContact]
          return [...acc, contact]
        },
        [],
      )
    } else {
      // if it does not already exist
      newContacts = [...(currentOrg.contacts || []), newContact]
    }
    setCurrentOrg({ ...currentOrg, contacts: newContacts })
    setEditing(false)
    setShowWarning(false)
  }

  const handleSaveBillingDetails = () => {
    // TODO: Might have to update type 'primary' as well, see thread:
    // https://eltoroteam.slack.com/archives/C029B5WPMU3/p1680708216028389?thread_ts=1680535105.358669&cid=C029B5WPMU3
    if (currentOrg?.id && orgServiceApi) {
      // update current
      if (billingContact?.id) {
        orgServiceApi
          .advertisingPlatformServiceUpdateContact(
            currentOrg.id,
            billingContact.id,
            {
              ...inputContact,
              secondaryEmails: inputContact.secondaryEmails?.filter(
                (email) => email,
              ),
            },
          )
          .then(handleUpdateCurrentOrg)
      }
      // create new
      else {
        orgServiceApi
          .advertisingPlatformServiceCreateContact(currentOrg.id, {
            ...inputContact,
            type: 'billing',
          })
          .then(handleUpdateCurrentOrg)
      }
    }
  }

  const handleOnChange = (newContact: Orgmanagerv1Contact) => {
    setInputContact((prev) => ({ ...prev, ...newContact }))
  }

  const canSubmitForm =
    isValidEmail(email || '') &&
    isValidPhone(phone || '') &&
    (isValidZip(zip || '') || isValidCanadianZip(zip || '')) &&
    (address || '').length > 0 &&
    (city || '').length > 0 &&
    (firstName || '').length > 0 &&
    (lastName || '').length > 0 &&
    (state || '').length > 1 &&
    (email || '').length > 0 &&
    (zip || '').length > 0 &&
    (phone || '').length > 0 &&
    (country || '').length > 0 &&
    (secondaryEmails && secondaryEmails.length > 0
      ? secondaryEmails.every((email) => email && isValidEmail(email))
      : true)

  return (
    <>
      {showWarning && (
        <div className="BillingDetails__warning text-warning bg-warning-50 border-warning rounded-s flex items-center justify-between gap-2 border px-2 py-3">
          <div className="flex items-center gap-2">
            <Fa icon="triangle-exclamation" size={1} className="text-warning" />
            <span className="leading-none">
              Please update with your company&apos;s information
            </span>
          </div>
          <Fa
            icon="xmark"
            size={1}
            className="text-warning font-light"
            onClick={() => setShowWarning(false)}
          />
        </div>
      )}
      <div className="BillingDetails BlockWithBorder group flex flex-col gap-2">
        <div className="flex items-center justify-between">
          <TextHeader className="BillingDetails__header" type={3}>
            Billing details
          </TextHeader>
        </div>
        {editing ? (
          <>
            <div
              className={`BillingDetails__editable-section laptop:flex-row desktop:flex-col mobile:flex-col flex w-full gap-4 ${
                editing ? 'animate-slidedown' : ''
              }`}
            >
              <div className="BillingsDetails__contact EditableSection my-3 flex w-full flex-col gap-4">
                <ContactForm
                  contact={inputContact}
                  onChange={handleOnChange}
                  isValidEmail={isValidEmail(email || '')}
                  isValidPhone={isValidPhone(phone || '')}
                  isValidZip={
                    isValidZip(zip || '') || isValidCanadianZip(zip || '')
                  }
                  contactHeader={
                    <div className="Billing__contact-header mb-2 flex justify-between">
                      <TextHeader className="!font-medium" type={5}>
                        Billing contact
                      </TextHeader>
                    </div>
                  }
                  addressHeader={
                    <div className="Billing__address-header mb-2 mt-4">
                      <TextHeader className="!font-medium" type={5}>
                        Billing address
                      </TextHeader>
                    </div>
                  }
                  showSecondaryEmails
                />
              </div>
            </div>
            <div
              className={`OperationsContact__toggle flex items-center${
                !opContact ? ' hidden' : ''
              }`}
            >
              <Toggle
                size="m"
                isChecked={useOpContact}
                isDisabled={!opContact}
                onChange={(bool) => {
                  setUseOpContact(!useOpContact)
                  if (bool) {
                    setInputContact({
                      address: opContact?.address,
                      city: opContact?.city,
                      email: opContact?.email,
                      firstName: opContact?.firstName,
                      lastName: opContact?.lastName,
                      phone: opContact?.phone,
                      state: opContact?.state,
                      zip: opContact?.zip,
                      country: opContact?.country,
                    })
                  } else {
                    setInputContact({})
                  }
                }}
                label={
                  !useOpContact
                    ? 'Same as operations contact'
                    : 'Set different contact'
                }
              />
            </div>
            <div className="BillingDetails__buttons flex justify-end gap-4 py-4">
              <Button
                onClick={() => {
                  setEditing(false)
                  setInputContact({})
                }}
              >
                Cancel
              </Button>
              <Button
                kind="primary"
                disabled={!canSubmitForm}
                onClick={() =>
                  v2SyncPrompt(() => handleSaveBillingDetails(), currentOrg)
                }
              >
                Save
              </Button>
            </div>
          </>
        ) : (
          <>
            {(() => {
              if (billingContact) {
                const {
                  firstName,
                  lastName,
                  email,
                  secondaryEmails,
                  phone,
                  address,
                  city,
                  state,
                  zip,
                  country,
                } = billingContact
                return (
                  <>
                    <div className="BillingDetails__current-info EditableSection__body pt-2">
                      {firstName && (
                        <FormFields>
                          <ContactLabel>First Name:</ContactLabel>
                          <ContactValue>{firstName}</ContactValue>
                        </FormFields>
                      )}
                      {lastName && (
                        <FormFields>
                          <ContactLabel>Last Name:</ContactLabel>
                          <ContactValue>{lastName}</ContactValue>
                        </FormFields>
                      )}
                      {email && (
                        <FormFields>
                          <ContactLabel>Email:</ContactLabel>
                          <ContactValue>{email}</ContactValue>
                        </FormFields>
                      )}
                      {secondaryEmails && secondaryEmails.length > 0
                        ? secondaryEmails.map((email, index) => (
                            <FormFields key={index}>
                              <ContactLabel>Secondary Email:</ContactLabel>
                              <ContactValue>{email}</ContactValue>
                            </FormFields>
                          ))
                        : null}
                      {phone && (
                        <FormFields>
                          <ContactLabel>Phone:</ContactLabel>
                          <ContactValue>{phone}</ContactValue>
                        </FormFields>
                      )}
                      {address && (
                        <FormFields>
                          <ContactLabel>Address:</ContactLabel>
                          <ContactValue>{address}</ContactValue>
                        </FormFields>
                      )}
                      {city && (
                        <FormFields>
                          <ContactLabel>City:</ContactLabel>
                          <ContactValue>{city}</ContactValue>
                        </FormFields>
                      )}
                      {state && (
                        <FormFields>
                          <ContactLabel>
                            {country === 'Canada' ? 'Province:' : 'State:'}
                          </ContactLabel>
                          <ContactValue>{state}</ContactValue>
                        </FormFields>
                      )}
                      {zip && (
                        <FormFields>
                          <ContactLabel>
                            {country === 'Canada'
                              ? 'Postal Code:'
                              : 'ZIP Code:'}
                          </ContactLabel>
                          <ContactValue>{zip}</ContactValue>
                        </FormFields>
                      )}
                      {country && (
                        <FormFields>
                          <ContactLabel>Country:</ContactLabel>
                          <ContactValue>{country}</ContactValue>
                        </FormFields>
                      )}
                    </div>
                    {!editing && !isReadOnly && (
                      <Button
                        className="ml-auto mt-4"
                        onClick={() => {
                          setEditing(true)
                          setInputContact(billingContact || {})
                        }}
                        iconOnly={<Fa icon="pencil" size={1} />}
                      />
                    )}
                  </>
                )
              }
            })()}
          </>
        )}
      </div>
    </>
  )
}
