import { useState } from 'react'
import { V1Pixel, V1WebToHomeNoteType } from 'next-gen-sdk'
import {
  Button,
  Fa,
  Form,
  Modal,
  RadioButton,
  RadioButtonGroup,
  showErrorMessage,
  TagInput,
  Text,
  TextInput,
} from '@eltoro-ui/components'
import { useValidation } from '@eltoro-ui/hooks'
import classNames from 'classnames'
import { v4 as uuid } from 'uuid'
import { useNavigate } from 'react-router-dom'
import { useAppContext } from 'Contexts'
import { createPixel } from 'Requests'
import { TextInputWithErrorText } from 'Components/TextInputWithErrorText'
import { PixelNotes } from './PixelNotes'
import { PixelFormItem } from './shared'
import './PixelCreate.scss'

export const PixelCreate = ({
  offClick,
  setRefresh,
}: {
  offClick: () => void
  setRefresh: React.Dispatch<React.SetStateAction<string>>
}) => {
  const [pixel, setPixel] = useState<Partial<V1Pixel>>()
  const [saving, setSaving] = useState(false)
  const [notes, setNotes] = useState<{
    content: string
    type: V1WebToHomeNoteType
  }>()
  const { currentOrg, webToHomeApi } = useAppContext()
  const nav = useNavigate()
  const query = currentOrg?.id ? `?org_id=${currentOrg.id}` : ''
  const isValidUrl = useValidation('url')

  const disableSave =
    saving ||
    (!pixel?.id && !pixel?.name) ||
    !isValidUrl(pixel?.topLevelUrl || '') ||
    !pixel.topLevelUrl

  const handleSave = async () => {
    if (!currentOrg?.id || !pixel) return
    setSaving(true)
    try {
      await createPixel(currentOrg?.id, pixel)
        .then(async (createdPixel) => {
          if (
            !webToHomeApi ||
            !createdPixel?.id ||
            !createdPixel?.orgId ||
            !notes?.content
          )
            return
          await webToHomeApi.advertisingPlatformServiceCreatePixelNote(
            createdPixel.id,
            notes,
            createdPixel.orgId,
          )
        })
        .then(() => {
          offClick()
          nav(`pixels${query}`)
          setRefresh(uuid())
        })
    } catch (e) {
      showErrorMessage('Error saving pixel', '')
    } finally {
      setSaving(false)
    }
  }

  const isNameValid = (pixel?.name || '').length <= 100

  return (
    <Modal
      className="PixelCreate min-w-[50%]"
      header="Create New Pixel"
      offClick={offClick}
    >
      <Form className="PixelCreate__form flex max-w-3xl flex-col gap-4 p-4">
        <PixelFormItem
          htmlFor="pixel-name"
          label="Pixel Name"
          required
          valid={isNameValid}
          counter
        >
          <TextInput
            classNameWrap={classNames({ '!border-warning': !isNameValid })}
            value={pixel?.name || ''}
            id="pixel-name"
            onChange={(e) =>
              setPixel((prev) => ({ ...prev, name: e.target.value }))
            }
            maxLength={101}
            minLength={1}
            valid={isNameValid}
            hideValidIcon
          />
        </PixelFormItem>
        <PixelFormItem htmlFor="pixel-tags" label="Tags">
          <TagInput
            disabled={saving}
            value={pixel?.tags || []}
            addTagsInBulk
            onAddTag={(tagArray: string[]) => {
              setPixel((prev) => ({
                ...prev,
                tags: tagArray,
              }))
            }}
            onRemoveTag={(tagToRemove) => {
              setPixel((prev) => ({
                ...prev,
                tags: pixel?.tags?.filter((tag) => tag !== tagToRemove),
              }))
            }}
            limit={1000}
          />
        </PixelFormItem>
        <PixelFormItem htmlFor="pixel-url" label="Placement URL" required>
          <TextInputWithErrorText
            value={pixel?.topLevelUrl || ''}
            id="pixel-url"
            onChange={(e) => {
              setPixel((prev) => ({ ...prev, topLevelUrl: e.target.value }))
            }}
            isValid={
              pixel?.topLevelUrl
                ? isValidUrl(pixel.topLevelUrl) &&
                  pixel.topLevelUrl.length <= 1000
                : true
            }
            baseValues={['http://', 'https://']}
            maxLength={1000}
            popoverContent={(() => {
              return (
                <div className="text-warning">
                  Please enter a valid url under 1,000 characters:
                  &quot;https://www.websitename.com&quot;
                </div>
              )
            })()}
          />
        </PixelFormItem>
        <PixelNotes
          type="pixel"
          onChange={(notes, type) => setNotes({ content: notes, type })}
        />
        <PixelFormItem
          htmlFor="is-sensitive-industry"
          label="Sensitive Industry"
          required
        >
          <Text size="s" className="mt-2">
            Please indicate if this pixel is associated with a sensitive
            industry (e.g., healthcare, finance, gambling, etc). This will
            enable additional compliance checks.
          </Text>
          <RadioButtonGroup
            valueSelected={pixel?.sensitive ? 'true' : 'false'}
            name="Is a political campaign"
            onChange={(value) =>
              setPixel((prev) => ({ ...prev, sensitive: value === 'true' }))
            }
            layout="horizontal"
          >
            <RadioButton value="true" label="Yes" />
            <RadioButton value="false" label="No" />
          </RadioButtonGroup>
        </PixelFormItem>
        <div className="PixelCreate__form-buttons flex justify-end gap-2">
          <Button onClick={() => offClick()} disabled={saving}>
            Cancel
          </Button>
          <Button
            iconLeft={
              saving ? <Fa icon="spinner" size={1} animate="spin" /> : undefined
            }
            kind="primary"
            disabled={disableSave}
            onClick={handleSave}
          >
            Create Pixel
          </Button>
        </div>
      </Form>
    </Modal>
  )
}
