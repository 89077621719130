import { V1Stats } from 'next-gen-sdk'
import { formatDate } from 'Helpers'

export const exportAndDownloadStatsCsv = (
  stats: V1Stats[],
  subscriptionName: string,
  start: Date,
  end: Date,
) => {
  const data = [
    [
      'Date',
      'Total Hits',
      'Filtered Audience',
      'Matched Audience',
      'Previously Matched',
      'Targetable Audience',
      'Geo-Filtered',
    ],
    ...stats.map((stat) => [
      stat.runDate ? stat.runDate.toString() : '',
      stat.hits ? stat.hits.toString() : '',
      stat.matcherTotal ? stat.matcherTotal.toString() : '',
      stat.matcherMatches ? stat.matcherMatches.toString() : '',
      '',
      stat.matches ? stat.matches.toString() : '',
      stat.zipFilterReductions !== undefined &&
      stat.stateFilterReductions !== undefined
        ? stat.zipFilterReductions + stat.stateFilterReductions
        : '',
    ]),
  ]

  const csvString = data.map((row) => row.join(',')).join('\n')
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })

  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = `${subscriptionName}_${formatDate(start).replaceAll(
    '/',
    '-',
  )}-${formatDate(end).replaceAll('/', '-')}.csv`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
