/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Campaignservicev1JobStatus } from '../models/Campaignservicev1JobStatus';
import { Campaignservicev1OrderLine } from '../models/Campaignservicev1OrderLine';
import { HttpFile } from '../http/http';

export class V1OrderLinesBatchJob {
    /**
    * The ID of the batch upsert job.
    */
    'jobId'?: string;
    'status'?: Campaignservicev1JobStatus;
    /**
    * Successfully upserted Order Lines.
    */
    'orderLines'?: Array<Campaignservicev1OrderLine>;
    /**
    * Errors returned while upserting order lines.
    */
    'errors'?: Array<string>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "jobId",
            "baseName": "job_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "Campaignservicev1JobStatus",
            "format": ""
        },
        {
            "name": "orderLines",
            "baseName": "order_lines",
            "type": "Array<Campaignservicev1OrderLine>",
            "format": ""
        },
        {
            "name": "errors",
            "baseName": "errors",
            "type": "Array<string>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1OrderLinesBatchJob.attributeTypeMap;
    }

    public constructor() {
    }
}

