import { ReactNode } from 'react'
import { Button, FormItem } from '@eltoro-ui/components'
import classNames from 'classnames'
import { styleTailwind } from 'Helpers'

export const PixelFormItem = (props: {
  htmlFor: string
  children?: ReactNode
  className?: string
  counter?: boolean
  errorMessage?: string
  label?: string | ReactNode
  required?: boolean
  UNSAFE_style?: { [key: string]: any }
  valid?: boolean
  wrapperClassname?: string
}) => (
  <FormItem
    {...props}
    wrapperClassname={classNames(
      'PixelFormItem flex-col',
      props.wrapperClassname,
    )}
  >
    {props.children}
  </FormItem>
)

export const RuleSetButton = styleTailwind(Button, 'max-w-min')
export const RuleRowSelect = styleTailwind(
  'select',
  'text-grey-500 border-tint-gray-200 w-full rounded-md border p-2 pr-3 max-w-[12ch] max-h-[2.6rem]',
)
