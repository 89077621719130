/* eslint-disable */
import { useEffect, useState } from 'react'
import { V1MoverMatchCount } from 'next-gen-sdk'
import { CounterButton } from 'Components'
import {
  AutoScroller,
  TagInput,
  TextInput,
  Button,
  Modal,
  Fa,
  Checkbox,
} from '@eltoro-ui/components'
import { useAppContext } from 'Contexts'
import { createAudience, uploadTargetFile } from 'Requests'
import zips from './ZipCodes.json'
import './NewMover.scss'
import { Targetjobservicev1Audience } from 'next-gen-sdk'

export type NewMoverType = {
  setCurrentStep: (id: string) => void
  handleCompleteStep: (id: string, remove?: boolean) => void
  setNewMoverName: (name: string) => void
  newMoverName?: string
  onAddAudienceToAttach?: (audiences: Targetjobservicev1Audience[]) => void
}
export const NewMover = ({
  setCurrentStep,
  handleCompleteStep,
  setNewMoverName,
  newMoverName,
  onAddAudienceToAttach,
}: NewMoverType) => {
  const [zipCodes, setZipCodes] = useState<string[]>([])
  const [typeSelections, setTypeSelections] = useState<string[]>([])
  const [preCount, setPreCount] = useState<string>('0')
  const [csv, setCsv] = useState<string>('')
  const [escrowCount, setEscrowCount] = useState<string>('0')
  const [newCount, setNewCount] = useState<string>('0')
  const [loading, setLoading] = useState(false)
  const [locked, setLocked] = useState(false)
  const {
    newMoverApi,
    currentOrg,
    tok,
    rebuild,
    audienceServiceApi,
    isAdmin,
  } = useAppContext()

  const handleAudienceCreation = async () => {
    const orgId = currentOrg?.id

    if (!tok || !orgId || !audienceServiceApi) return
    if (rebuild) return
    setLoading(true)
    const blob = new Blob([csv], { type: 'text/csv' })
    const file: File = new File([blob], `${newMoverName}.csv`, {
      type: 'text/csv',
    })
    const uploadedFile = await uploadTargetFile(
      `${newMoverName}.csv`,
      file,
      tok,
      orgId,
      'FILE_TYPE_ZIP',
      true,
      [{ index: 0, value: 'zip', type: 'zip' }],
      undefined,
      'DATA_SOURCE_CLIENT',
    )

    if (!uploadedFile?.id) return

    const getType = (val: string) => {
      switch (val) {
        case 'pre':
          return 'AUDIENCE_TYPE_PREMOVER'

        case 'post':
          return 'AUDIENCE_TYPE_POSTMOVER'

        default:
          return 'AUDIENCE_TYPE_ESCROWMOVER'
      }
    }

    const addMoverJob = async (targetId: string, jobType: string) => {
      if (!orgId || !targetId) return
      const createdAudience = await createAudience(
        orgId,
        getType(jobType),
        targetId,
        newMoverName || '',
      )

      if (createdAudience?.id) {
        return audienceServiceApi
          ?.advertisingPlatformServiceGenerateAudience(
            createdAudience.id,
            orgId,
          )
          .then(() => {
            if (onAddAudienceToAttach && createdAudience.id)
              onAddAudienceToAttach([createdAudience])
          })
          .then(() => {
            if (locked && createdAudience.id) {
              audienceServiceApi.advertisingPlatformServiceUpdateAudience(
                createdAudience.id,
                orgId,
                {
                  locked: locked,
                },
                'locked',
              )
            }
          })
      }
    }

    Promise.all(
      typeSelections.map((jobType: string) =>
        addMoverJob(uploadedFile?.id || '', jobType),
      ),
    )
  }

  useEffect(() => {
    if (zipCodes) {
      if (zipCodes.length > 0) {
        const zipCsv = ['zip', zipCodes.join(',').replace(/,/g, '\n')].join(
          '\r\n',
        )
        setCsv(zipCsv)
      }
    }
  }, [newMoverName])

  useEffect(() => {
    setCurrentStep('identify')
  }, [])

  useEffect(() => {
    if (zipCodes.length) {
      newMoverApi
        ?.advertisingPlatformServiceGetNewMoverMatchCount(
          ['MOVER_TYPE_CNM', 'MOVER_TYPE_PRE', 'MOVER_TYPE_PENDING'],
          undefined,
          zipCodes,
          currentOrg?.id,
        )
        .then((data: any) => {
          const parsedData = data?.moverMatchCounts?.reduce(
            (acc: { [key: string]: number }, val: V1MoverMatchCount) => {
              return { ...acc, [val.moverType as string]: val.count }
            },
            {},
          )
          setPreCount(String(parsedData.MOVER_TYPE_PRE))
          setEscrowCount(String(parsedData.MOVER_TYPE_PENDING))
          setNewCount(String(parsedData.MOVER_TYPE_CNM))
        })
        .catch((error) => console.error(error))
      handleCompleteStep('identify')
      setCurrentStep('select')
    } else {
      handleCompleteStep('identify', true)
      setCurrentStep('identify')
    }
  }, [zipCodes])

  useEffect(() => {
    if (typeSelections.length) {
      handleCompleteStep('select')
      setCurrentStep('name')
    } else {
      handleCompleteStep('select', true)
      setCurrentStep('select')
    }
  }, [typeSelections])

  const listed = (idArg: string) =>
    typeSelections.some((counterId) => counterId === idArg)

  const handleTypeSelect = (
    id: string,
    e?: React.MouseEvent<HTMLButtonElement>,
  ) => {
    if (listed(id)) {
      typeSelections.filter((counterId) => counterId !== id)
      setTypeSelections(typeSelections.filter((counterId) => counterId !== id))
    } else {
      setTypeSelections([...typeSelections, id])
    }
  }
  /*
   The positive look-behind of the  wildcard validation regex pattern for the tag input below is an ES9 feature, and is not currently support in safari browsers.
   This should be refactored, but there doesn't seem to be another way to do wildcard validation without destroying component modularity
  */
  return (
    <>
      <div className="NewMover">
        <div className="NewMover__identifyStep mb-10">
          <div className="NewMover__step-info-container">
            <div className="NewMover__step-header">
              Identify areas you&#39;d like to track
            </div>
            <div className="NewMover__step-text">
              Enter a single ZIP code, or multiple ZIP codes, separated by a
              commas or spaces. We also support wildcard matching, so 4020* will
              work. Limit 1000 ZIP codes.
            </div>
          </div>
          <div className="NewMover__tagInputWrapper mt-6 mb-4">
            <TagInput
              value={zipCodes}
              addTagsInBulk
              validateFromSuggestions
              autoCompleteSuggestionsList={zips}
              maxAutoCompleteSuggestions={5}
              onAddTag={(tagArray: string[]) => setZipCodes(tagArray)}
              onRemoveTag={(tag: string) =>
                setZipCodes(zipCodes.filter((zip) => zip !== tag))
              }
              validationPattern="^\d{5}$"
              wildCardValidationPattern="^(\S{5})(?<=^\d{5}$|\d{1,3}\*{1,2})$"
              tagErrorLabel="zip code"
              limit={1000}
            />
          </div>
        </div>
        {!!zipCodes.length && (
          <div className="NewMover__selectStep mb-4">
            <div className="NewMover__step-info-container">
              <div className="NewMover__step-header">
                Select your mover-type
              </div>
            </div>
            <div className="NewMover__buttons my-2 flex justify-between">
              <CounterButton
                handleClick={handleTypeSelect}
                isSelected={listed('pre')}
                id="pre"
                title="Pre Mover"
                subTitle="Homes listed on Market"
                count={preCount}
                footerText="Identifed"
                disabled={!+preCount}
              />
              <CounterButton
                handleClick={handleTypeSelect}
                isSelected={listed('escrow')}
                id="escrow"
                title="Escrow Mover"
                subTitle="Homes purchased under contract to sell"
                count={escrowCount}
                footerText="Identifed"
                disabled={!+escrowCount}
              />
              <CounterButton
                handleClick={handleTypeSelect}
                isSelected={listed('post')}
                id="post"
                title="New Mover"
                subTitle="Just Moved into new Home"
                count={newCount}
                footerText="Identifed"
                disabled={!+newCount}
              />
            </div>
          </div>
        )}
        {!!typeSelections.length && !!zipCodes.length && (
          <AutoScroller
            className="pb-12"
            shouldScroll={!!typeSelections}
            scrollToTop
          >
            <div className="NewMover__nameStep">
              <div className="NewMover__step-info-container audience-name my-2">
                <div
                  className="NewMover__step-header text-l"
                  data-tooltip="right"
                  aria-label="Name that will be displayed in your library"
                >
                  Name your audience
                </div>
              </div>
              <TextInput
                classNameWrap={`AudienceName--textinput ${
                  newMoverName!.length > 2 &&
                  newMoverName!.length <= 255 &&
                  'border-success animate-glowsuccess'
                }`}
                value={newMoverName || ''}
                onChange={(e) => setNewMoverName(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    handleCompleteStep('name')
                  }
                }}
                placeholder="Enter name here"
                size="m"
              />
              {isAdmin && (
                <Checkbox
                  label="Lock audience"
                  checked={locked}
                  onChange={(val) => setLocked(val)}
                />
              )}

              <div className="NewMover__ButtonWrapper my-3 flex justify-end">
                <Button
                  size="l"
                  rounded={false}
                  kind="primary"
                  onClick={() => {
                    handleAudienceCreation()
                      .then(() => {
                        handleCompleteStep('name')
                      })
                      .finally(() => setLoading(false))
                  }}
                  disabled={!newMoverName || newMoverName.length < 3}
                >
                  Save
                </Button>
              </div>
            </div>
          </AutoScroller>
        )}
      </div>
      {loading && (
        <Modal>
          <Fa icon="spinner" size={2} className="text-grey-200 animate-spin" />
          <p>Loading</p>
        </Modal>
      )}
    </>
  )
}
