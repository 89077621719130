/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V3AuctionEvent } from '../models/V3AuctionEvent';
import { V3BudgetInterval } from '../models/V3BudgetInterval';
import { V3BudgetSchedulingSettings } from '../models/V3BudgetSchedulingSettings';
import { V3CampaignCreative } from '../models/V3CampaignCreative';
import { V3LineItemDeal } from '../models/V3LineItemDeal';
import { V3LineItemPixel } from '../models/V3LineItemPixel';
import { V3PacingStrategies } from '../models/V3PacingStrategies';
import { V3SupplyStrategies } from '../models/V3SupplyStrategies';
import { V3UserInfo } from '../models/V3UserInfo';
import { V3Valuation } from '../models/V3Valuation';
import { HttpFile } from '../http/http';

/**
* A specific type of Line Item. See [Line Item Service](https://docs.xandr.com/bundle/xandr-api/page/line-item-service---ali.html) in Xandr's documentation.
*/
export class V3AugmentedLineItem {
    /**
    * The state of the line item. Possible values: 'active' or 'inactive'.
    */
    'state'?: string;
    /**
    * The name of the line item.
    */
    'name'?: string;
    /**
    * The reference code of the line item.
    */
    'code'?: string;
    /**
    * The click URL to apply at the line item level.
    */
    'clickUrl'?: string;
    /**
    * When multiple creatives of the same size are trafficked via a line item, this field's setting is used to determine the creative rotation strategy that will be used.
    */
    'creativeDistributionType'?: string;
    /**
    * Deprecated.
    */
    'discrepancyPct'?: number;
    /**
    * Deprecated. Use the values of the supply_strategies array to set the supply types (e.g., RTB/Open Exchange, Deals, Managed).
    */
    'publishersAllowed'?: string;
    /**
    * Indicates whether or not you want to serve only to users who use cookies for conversion-tracking purposes (because Xandr conversion attribution is cookie-based). If set to true, this indicates that you won't serve to non-cookie users because they have no potential for conversion attribution. If set to false, this indicates that you'll serve to non-cookie users and accept no conversion attribution for those users. This field's setting is only relevant when a conversion pixel has been applied, so setting this to true won't require cookies for a line item that has no conversion pixels.
    */
    'requireCookieForTracking'?: boolean;
    /**
    * The type of line item.
    */
    'lineItemType'?: string;
    /**
    * Budget intervals enable multiple date intervals to be attached to a line item, each with corresponding budget values.
    */
    'budgetIntervals'?: Array<V3BudgetInterval>;
    'valuation'?: V3Valuation;
    /**
    * The way the advertiser has agreed to pay you (also called Booked Revenue).
    */
    'revenueType'?: string;
    /**
    * The amount paid to the network by the advertiser.
    */
    'revenueValue'?: number;
    /**
    * For a line item targeting managed inventory (inventory_type is 'direct'), since you have already paid for inventory, there is no need to input a buying strategy. However, you can set the line item's priority to weight the line item against other direct line items within your account. The line item with the highest priority will always win, even if a lower priority line item bids more.
    */
    'priority'?: number;
    /**
    * The type of creative used for this line item.
    */
    'adTypes'?: Array<string>;
    /**
    * This field is used to indicate your goal priority (whether you wish to give greater priority to delivery, performance, or margin).
    */
    'preferDeliveryOverPerformance'?: boolean;
    'supplyStrategies'?: V3SupplyStrategies;
    /**
    * When you apply a template to a creative that determines how often it's served, how it rotates through the inventory, and more.
    */
    'manageCreative'?: boolean;
    /**
    * The currency used for this line item.
    */
    'currency'?: string;
    'goalType'?: string;
    'timezone'?: string;
    'pixels'?: Array<V3LineItemPixel>;
    /**
    * ID of the line item.
    */
    'id'?: number;
    'userInfo'?: V3UserInfo;
    'deals'?: Array<V3LineItemDeal>;
    'creatives'?: Array<V3CampaignCreative>;
    'budgetSchedulingSettings'?: V3BudgetSchedulingSettings;
    'pacingStrategies'?: Array<V3PacingStrategies>;
    'auctionEvent'?: V3AuctionEvent;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "state",
            "baseName": "state",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "code",
            "baseName": "code",
            "type": "string",
            "format": ""
        },
        {
            "name": "clickUrl",
            "baseName": "click_url",
            "type": "string",
            "format": ""
        },
        {
            "name": "creativeDistributionType",
            "baseName": "creative_distribution_type",
            "type": "string",
            "format": ""
        },
        {
            "name": "discrepancyPct",
            "baseName": "discrepancy_pct",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "publishersAllowed",
            "baseName": "publishers_allowed",
            "type": "string",
            "format": ""
        },
        {
            "name": "requireCookieForTracking",
            "baseName": "require_cookie_for_tracking",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "lineItemType",
            "baseName": "line_item_type",
            "type": "string",
            "format": ""
        },
        {
            "name": "budgetIntervals",
            "baseName": "budget_intervals",
            "type": "Array<V3BudgetInterval>",
            "format": ""
        },
        {
            "name": "valuation",
            "baseName": "valuation",
            "type": "V3Valuation",
            "format": ""
        },
        {
            "name": "revenueType",
            "baseName": "revenue_type",
            "type": "string",
            "format": ""
        },
        {
            "name": "revenueValue",
            "baseName": "revenue_value",
            "type": "number",
            "format": "float"
        },
        {
            "name": "priority",
            "baseName": "priority",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "adTypes",
            "baseName": "ad_types",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "preferDeliveryOverPerformance",
            "baseName": "prefer_delivery_over_performance",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "supplyStrategies",
            "baseName": "supply_strategies",
            "type": "V3SupplyStrategies",
            "format": ""
        },
        {
            "name": "manageCreative",
            "baseName": "manage_creative",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "currency",
            "baseName": "currency",
            "type": "string",
            "format": ""
        },
        {
            "name": "goalType",
            "baseName": "goal_type",
            "type": "string",
            "format": ""
        },
        {
            "name": "timezone",
            "baseName": "timezone",
            "type": "string",
            "format": ""
        },
        {
            "name": "pixels",
            "baseName": "pixels",
            "type": "Array<V3LineItemPixel>",
            "format": ""
        },
        {
            "name": "id",
            "baseName": "id",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "userInfo",
            "baseName": "user_info",
            "type": "V3UserInfo",
            "format": ""
        },
        {
            "name": "deals",
            "baseName": "deals",
            "type": "Array<V3LineItemDeal>",
            "format": ""
        },
        {
            "name": "creatives",
            "baseName": "creatives",
            "type": "Array<V3CampaignCreative>",
            "format": ""
        },
        {
            "name": "budgetSchedulingSettings",
            "baseName": "budget_scheduling_settings",
            "type": "V3BudgetSchedulingSettings",
            "format": ""
        },
        {
            "name": "pacingStrategies",
            "baseName": "pacing_strategies",
            "type": "Array<V3PacingStrategies>",
            "format": ""
        },
        {
            "name": "auctionEvent",
            "baseName": "auction_event",
            "type": "V3AuctionEvent",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3AugmentedLineItem.attributeTypeMap;
    }

    public constructor() {
    }
}

