import { useCallback, useEffect, useState, useRef } from 'react'
import { Fa, Pagination, Popover, Text, TextInput } from '@eltoro-ui/components'
import { ETIcon } from 'Assets'
import {
  checkIfCreative,
  getTargetIconET,
  readableCreativeType,
  userFriendlyAudienceName,
} from 'Helpers'
import { useAppContext } from 'Contexts'
import {
  useDebounce,
  useOnClickOutside,
  useTokenPagination,
} from '@eltoro-ui/hooks'
import {
  Creativeservicev1Creative,
  Creativeservicev1ListCreativesResponse,
  Targetjobservicev1Audience,
  V1ListAudiencesResponse,
} from 'next-gen-sdk'
import {
  AssetEditHeaderAudiences,
  AssetEditHeaderCreatives,
} from './AssetEditHeader'
import { getThumbnail } from './CreativeEdit/getThumbnail'

export const AssetEditSearch = ({
  modifiedOrderLines,
  handleAddAudience,
  handleAddCreative,
}: AssetEditHeaderAudiences | AssetEditHeaderCreatives) => {
  const [searchInput, setSearchInput] = useState('')
  const debouncedSearch = useDebounce(searchInput, 200)
  const [dropdownWidth, setDropdownWidth] = useState(274)

  const { creativeServiceApi, audienceServiceApi, currentOrg } = useAppContext()

  const inputWrapperRef = useRef<HTMLInputElement>(null)
  const dropdownResultsRef = useRef<HTMLDivElement>(null)
  useOnClickOutside([inputWrapperRef, dropdownResultsRef], () =>
    setSearchInput(''),
  )

  useEffect(() => {
    if (!inputWrapperRef.current?.clientWidth) return
    setDropdownWidth(inputWrapperRef.current.clientWidth)
  }, [inputWrapperRef.current?.clientWidth])

  const handleFetchAssets = useCallback(
    async ({
      pageSize,
      nextPageToken,
    }: {
      pageSize: number
      nextPageToken?: string
    }) => {
      const empty = {
        data: [],
        totalItems: 0,
      }
      if (!creativeServiceApi || !debouncedSearch) return empty
      let res:
        | Creativeservicev1ListCreativesResponse
        | V1ListAudiencesResponse
        | undefined
      if (handleAddCreative) {
        res = await creativeServiceApi.advertisingPlatformServiceListCreatives(
          pageSize, // pageSize
          nextPageToken, // pageToken
          undefined, // orderBy
          `org_id = "${currentOrg?.id}" AND name = "*${debouncedSearch}*" OR id = "*${debouncedSearch}*"`,
        )
      }

      if (handleAddAudience) {
        res = await audienceServiceApi?.advertisingPlatformServiceListAudiences(
          pageSize, // pageSize
          nextPageToken, // pageToken
          undefined, // orderBy
          `org_id = "${currentOrg?.id}" AND name = "*${debouncedSearch}*" OR id = "*${debouncedSearch}*"`,
        )
      }

      if (res) {
        return {
          data:
            (handleAddCreative
              ? (res as Creativeservicev1ListCreativesResponse).creatives
              : (res as V1ListAudiencesResponse).audiences) || [],
          totalItems: res.totalSize || 0,
          nextPageToken: res.nextPageToken,
        }
      }
      return empty
    },
    [
      audienceServiceApi,
      creativeServiceApi,
      currentOrg?.id,
      debouncedSearch,
      handleAddAudience,
      handleAddCreative,
    ],
  )

  const pagination = useTokenPagination<
    Creativeservicev1Creative | Targetjobservicev1Audience
  >(
    {
      pageSize: 10,
      fetchData: handleFetchAssets,
    },
    [debouncedSearch, creativeServiceApi, audienceServiceApi],
  )

  return (
    <Popover
      position={['bottom']}
      align="start"
      content={
        <div
          className="AssetEditSearch__result-dropdown bg-base max-h-60 overflow-auto"
          style={{ minWidth: dropdownWidth }}
          ref={dropdownResultsRef}
        >
          {pagination.currentPageData?.map((asset, i) => (
            <button
              className="AssetEditSearch__asset-result hover:bg-tint-gray-100 flex w-full items-center p-2"
              onClick={() => {
                if (handleAddAudience) {
                  handleAddAudience([asset] as Targetjobservicev1Audience[])
                  return
                }
                if (handleAddCreative)
                  handleAddCreative(asset as Creativeservicev1Creative)
              }}
              key={`audience-result-${asset.id}_${i}`}
            >
              <div className="AssetEditSearch__asset-result-icon-wrapper w-[2.1rem]">
                {(() => {
                  if (
                    modifiedOrderLines.length > 0 &&
                    modifiedOrderLines.every((ol) => {
                      return !!ol.audiences?.find(
                        (modAud) => asset.id === modAud.id,
                      )
                    })
                  )
                    return (
                      <Fa
                        icon="circle-check"
                        size={2}
                        className="text-success"
                      />
                    )
                  return checkIfCreative(asset) ? (
                    getThumbnail(asset, 'xs')
                  ) : (
                    <ETIcon
                      icon={asset.type ? getTargetIconET(asset.type) : 'target'}
                      className="h-8"
                    />
                  )
                })()}
              </div>
              <div className="AsssetEditSearch__name-type-label text-left">
                <Text size="m" tag="p">
                  {asset.name || asset.id || 'No name'}
                </Text>
                {asset.type && (
                  <Text size="xs" tag="p" className="text-tint-gray-600">
                    {checkIfCreative(asset)
                      ? readableCreativeType(asset.type)
                      : userFriendlyAudienceName(asset.type)}
                  </Text>
                )}
              </div>
            </button>
          ))}
          {pagination.currentPageData.length > 0 && (
            <Pagination
              {...pagination}
              onChange={(pageInfo) => {
                pagination.setPage(pageInfo.page)
              }}
              className="p-2"
            />
          )}
        </div>
      }
      isOpen={!!debouncedSearch}
    >
      <div className="laptopsmall:w-full">
        <TextInput
          className="laptopsmall:w-full min-w-[20.5rem]"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder="Search name/ID"
          inputRef={inputWrapperRef}
        />
      </div>
    </Popover>
  )
}
