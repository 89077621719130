import { TagInput } from '@eltoro-ui/components'
import { stateOptions } from 'Components'
import { useAppContext } from 'Contexts'
import { PixelFormItem } from './shared'
import zips from 'Components/NewMover/ZipCodes.json'

export const PixelGeoLocationFilters = ({
  zipsFiltered,
  statesFiltered,
  handleStatesFiltered,
  handleZipsFiltered,
}: {
  zipsFiltered: string[]
  statesFiltered: string[]
  handleZipsFiltered: (zips: string[]) => void
  handleStatesFiltered: (states: string[]) => void
}) => {
  const statesValues = stateOptions.map((state) => state.value)
  const { isReadOnly } = useAppContext()

  return (
    <div className="PixelGeoLocationFilters animate-slidedown">
      <PixelFormItem label="ZIP Filters" htmlFor="zipcodes">
        <TagInput
          value={zipsFiltered}
          addTagsInBulk
          validateFromSuggestions
          autoCompleteSuggestionsList={zips}
          maxAutoCompleteSuggestions={5}
          onAddTag={(tagArray: string[]) => handleZipsFiltered(tagArray)}
          onRemoveTag={(tag) =>
            handleZipsFiltered(zipsFiltered.filter((zip) => zip !== tag))
          }
          validationPattern="^\d{5}$"
          tagErrorLabel="zip code"
          disabled={isReadOnly}
        />
      </PixelFormItem>
      <p className="mb-2 text-sm">
        Enter a single ZIP code, or multiple ZIP codes, separated by a commas or
        spaces
      </p>

      <PixelFormItem label="State Filters" htmlFor="states">
        {/* this need some work- validation stuff was based on zipcode only */}
        <TagInput
          value={statesFiltered}
          addTagsInBulk
          validateFromSuggestions
          autoCompleteSuggestionsList={statesValues}
          maxAutoCompleteSuggestions={5}
          onAddTag={(tagArray: string[]) =>
            handleStatesFiltered(tagArray.map((state) => state.toUpperCase()))
          }
          onRemoveTag={(tag) =>
            handleStatesFiltered(
              statesFiltered.filter((state) => state !== tag),
            )
          }
          validationPattern="^[A-Za-z]{2}$"
          tagErrorLabel="state"
          disabled={isReadOnly}
        />
      </PixelFormItem>
      <p className="text-sm">
        Enter a single state, or multiple states, separated by a commas or
        spaces
      </p>
    </div>
  )
}
