/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1AccountingJobKind } from '../models/V1AccountingJobKind';
import { HttpFile } from '../http/http';

export class V1AccountingJob {
    /**
    * ID of the Job
    */
    'id'?: string;
    'kind'?: V1AccountingJobKind;
    /**
    * The start timestamp of the Job.
    */
    'start'?: Date;
    /**
    * The end timestamp of the Job.
    */
    'end'?: Date;
    /**
    * Boolean that defines whether Job was a dry run
    */
    'dryRun'?: boolean;
    /**
    * The timestamp of when the Job ran.
    */
    'runDate'?: Date;
    /**
    * Boolean if the Job was successful
    */
    'success'?: boolean;
    /**
    * Status of the job
    */
    'status'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": "uuid"
        },
        {
            "name": "kind",
            "baseName": "kind",
            "type": "V1AccountingJobKind",
            "format": ""
        },
        {
            "name": "start",
            "baseName": "start",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "end",
            "baseName": "end",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "dryRun",
            "baseName": "dry_run",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "runDate",
            "baseName": "run_date",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "success",
            "baseName": "success",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1AccountingJob.attributeTypeMap;
    }

    public constructor() {
    }
}

