/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Orgmanagerv1Org } from '../models/Orgmanagerv1Org';
import { HttpFile } from '../http/http';

export class V1UpdateOrgRequest {
    'org'?: Orgmanagerv1Org;
    /**
    * The list of fields to update.
    */
    'updateMask'?: string;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "org",
            "baseName": "org",
            "type": "Orgmanagerv1Org",
            "format": ""
        },
        {
            "name": "updateMask",
            "baseName": "update_mask",
            "type": "string",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1UpdateOrgRequest.attributeTypeMap;
    }

    public constructor() {
    }
}

