// Target upload limits
const maxGB = 1024 * 1024 * 1024
export const MAX_UPLOAD_TARGET_FILE_SIZE = maxGB

// Audience row limits
export const ADDRESS_LIMIT = 500000
export const REVERSE_LIMIT = 500000
export const DEVICE_LIMIT = 500000
export const IP_LIMIT = 500000
export const ZIP_LIMIT = 500
export const DIGITAL_CANVASSING_LIMIT = 50000

// Audience settings limits
export const AUDIENCE_NOTE_LIMIT = 255
export const AUDIENCE_TAG_LIMIT = 30
