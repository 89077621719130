/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { HttpFile } from '../http/http';

/**
* Deploy Ingress Segment Config
*/
export class V3ViantSegmentConfig {
    'internalAudienceID'?: string;
    'segmentorJobType'?: string;
    'segmentCode'?: string;
    'segmentLocationBucket'?: string;
    'segmentLocationKey'?: string;
    'segmentLocationFilename'?: string;
    'segmentName'?: string;
    'adelphicTaxonomyID'?: string;
    'segmentorJobID'?: string;
    'segmentorExclude'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "internalAudienceID",
            "baseName": "InternalAudienceID",
            "type": "string",
            "format": ""
        },
        {
            "name": "segmentorJobType",
            "baseName": "SegmentorJobType",
            "type": "string",
            "format": ""
        },
        {
            "name": "segmentCode",
            "baseName": "SegmentCode",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "segmentLocationBucket",
            "baseName": "SegmentLocationBucket",
            "type": "string",
            "format": ""
        },
        {
            "name": "segmentLocationKey",
            "baseName": "SegmentLocationKey",
            "type": "string",
            "format": ""
        },
        {
            "name": "segmentLocationFilename",
            "baseName": "SegmentLocationFilename",
            "type": "string",
            "format": ""
        },
        {
            "name": "segmentName",
            "baseName": "SegmentName",
            "type": "string",
            "format": ""
        },
        {
            "name": "adelphicTaxonomyID",
            "baseName": "AdelphicTaxonomyID",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "segmentorJobID",
            "baseName": "SegmentorJobID",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "segmentorExclude",
            "baseName": "SegmentorExclude",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V3ViantSegmentConfig.attributeTypeMap;
    }

    public constructor() {
    }
}

