/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1WebToHomeNoteType } from '../models/V1WebToHomeNoteType';
import { HttpFile } from '../http/http';

export class V1PixelNote {
    /**
    * The Note ID.
    */
    'id'?: string;
    /**
    * The time the Note was created.
    */
    'createTime'?: Date;
    /**
    * The last time the Note was updated.
    */
    'updateTime'?: Date;
    /**
    * The Pixel's ID that this note is associated with.
    */
    'pixelId'?: string;
    'content'?: string;
    /**
    * User name comes from the authentication  
    */
    'userId'?: string;
    /**
    * User name comes from the authentication  
    */
    'userName'?: string;
    'type'?: V1WebToHomeNoteType;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updateTime",
            "baseName": "update_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "pixelId",
            "baseName": "pixel_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "content",
            "baseName": "content",
            "type": "string",
            "format": ""
        },
        {
            "name": "userId",
            "baseName": "user_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "userName",
            "baseName": "user_name",
            "type": "string",
            "format": ""
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "V1WebToHomeNoteType",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1PixelNote.attributeTypeMap;
    }

    public constructor() {
    }
}

