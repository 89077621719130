import { useState, useEffect } from 'react'
import {
  Button,
  Fa,
  Text,
  Toggle,
  showErrorMessage,
  showSuccessMessage,
  triggerPrompt,
} from '@eltoro-ui/components'
import classNames from 'classnames'
import {
  Targetjobservicev1Audience,
  Targetjobservicev1Note,
  Targetjobservicev1NoteType,
  V1CpmPlus,
  V2Target,
} from 'next-gen-sdk'
import { useAppContext, useLibraryContext } from 'Contexts'
import { AUDIENCE_NOTE_LIMIT, styleTailwind } from 'Helpers'
import { getApiConfig, uploadBusinessLocation } from 'Requests'
import { MatchCap } from '../MatchCap'
import { PriceTextInput } from 'Components/PriceTextInput'
import { CustomAudienceTagDropdown } from 'Components/CustomAudienceTagDropdown'

const DividerRule = styleTailwind(
  'hr',
  'divider-rule border-t-thin border-tint-gray-500 my-3',
)
const NAText = styleTailwind('p', 'text-grey-200')

const CPMPrice: React.FC<{
  units?: number
  nanos?: number
  onChange?: (newCpm: V1CpmPlus) => void
  dataSource?: string
}> = ({ units, nanos, onChange, dataSource }) => {
  const value = +(units || 0) + (nanos || 0) / 10 ** 9
  if (!onChange)
    return value ? <Text>${value.toFixed(2)}</Text> : <NAText>n/a</NAText>
  return (
    <>
      <PriceTextInput
        min={0}
        max={999}
        value={value}
        onChange={(input) => {
          const numberInput = +input
          const newUnits = numberInput - (numberInput % 1)
          const newNanos = Math.round(+(numberInput % 1) * 10 ** 9)
          onChange({
            units: newUnits.toString(),
            nanos: newNanos,
          })
        }}
        step={0.01}
        removeFocusOnMouseOut
      />
      {dataSource && (
        <span
          data-tooltip="top"
          aria-label={`${dataSource.replace('DATA_SOURCE_', '')} CPM`}
        >
          <Fa
            icon="tag"
            size={1}
            className="text-grey-400 hover:cursor-pointer focus:ring"
          />
        </span>
      )}
    </>
  )
}

export const AdOpsSettings: React.FC<{
  previewItem: Targetjobservicev1Audience
  target: V2Target
  notes: Targetjobservicev1Note[] | undefined
  handleSaveNote: (
    type: Targetjobservicev1NoteType,
    content: string,
  ) => Promise<void>
  openNoteModal: () => void
  hasDeployments?: boolean
}> = ({
  previewItem,
  target,
  notes,
  handleSaveNote,
  openNoteModal,
  hasDeployments,
}) => {
  const isHidden = previewItem.hidden
  const isLocked = previewItem.locked
  const [toggleHide, setToggleHide] = useState(isHidden)
  const [toggleLocked, setToggleLocked] = useState(isLocked)
  const [dataRetention, setDataRetention] = useState<string>('1')
  // const [showDataHelp, setShowDataHelp] = useState(false)
  const [adOpsNotes, setAdOpsNotes] = useState<string>('')
  const [upCharge, setUpcharge] = useState<V1CpmPlus | undefined>(undefined)
  const [loading, setLoading] = useState('')
  const [tagInput, setTagInput] = useState(previewItem.tag || '')
  const [openCustomTagDropdown, setOpenCustomTagDropdown] = useState(false)

  const {
    audienceServiceApi,
    isLeadershipOrDev,
    campaignServiceApi,
  } = useAppContext()
  const {
    pagination: { updateRow, findCachedRow },
    handleSetSelectedItem,
  } = useLibraryContext()

  const handleRequote = async () => {
    if (previewItem.id && previewItem.orgId && audienceServiceApi) {
      await audienceServiceApi
        .advertisingPlatformServiceGenerateAudience(
          previewItem.id,
          previewItem.orgId,
        )
        .then(() => {
          const existingAudience = findCachedRow(
            (aud) => aud?.id === previewItem.id,
          )?.existingItem
          if (existingAudience) {
            const updatedAudience = {
              ...existingAudience,
              status: 'AUDIENCE_STATUS_RUNNING',
            } as Targetjobservicev1Audience
            updateRow((aud) => aud?.id === previewItem.id, updatedAudience)
          }
          handleSetSelectedItem({
            ...previewItem,
            status: 'AUDIENCE_STATUS_RUNNING',
          })
          showSuccessMessage(
            `${previewItem.name || 'Your audience'} is re-quoting`,
            '',
          )
        })
        .catch(() =>
          showErrorMessage(
            `Error re-quoting audience${
              previewItem.name ? `: ${previewItem.name}` : ''
            } `,
            '',
          ),
        )
    }
  }
  const handleLockToggle = async () => {
    if (previewItem.id && previewItem.orgId) {
      await audienceServiceApi
        ?.advertisingPlatformServiceUpdateAudience(
          previewItem.id,
          previewItem.orgId,
          {
            locked: !toggleLocked,
            audienceId: previewItem.id,
          },
          'locked',
        )
        .then((res) => {
          setToggleLocked(res?.locked)
          showSuccessMessage(
            'Audience Updated',
            `The audience has been ${res.locked ? 'locked' : 'unlocked'}`,
          )
        })
        .catch(() =>
          showErrorMessage('Error updating audience locked field', ''),
        )
    }
  }
  const handleHiddenToggle = async () => {
    if (previewItem.id && previewItem.orgId) {
      await audienceServiceApi
        ?.advertisingPlatformServiceUpdateAudience(
          previewItem.id,
          previewItem.orgId,
          {
            hidden: !toggleHide,
          },
          undefined,
        )
        .then((res) => {
          setToggleHide(res?.hidden)
          showSuccessMessage(
            'Audience Updated',
            `The audience has been ${res.hidden ? 'hidden' : 'unhidden'}`,
          )
        })
        .catch(() =>
          showErrorMessage('Error updating audience hidden field', ''),
        )
    }
  }
  const handleSaveAdOpsNotes = () => {
    setLoading('notes')
    handleSaveNote('NOTE_TYPE_ADMIN', adOpsNotes)
      .then(() => setAdOpsNotes(''))
      .finally(() => setLoading(''))
  }
  const canChangeUpcharge = async () => {
    // checks orderlines for deployed ols
    const { orderLineIds } = previewItem
    if (orderLineIds && orderLineIds?.length > 0) {
      const olArray = orderLineIds.map(async (currentId) => {
        if (!previewItem.orgId) return
        const ol = await campaignServiceApi?.advertisingPlatformServiceGetOrderLine(
          currentId,
          previewItem.orgId,
        )
        return ol
      })
      const arrayResolved = await Promise.all(olArray).then((res) => res)
      // filters by if a firstDeployedTime is set, as this is set on every deploy
      return arrayResolved.filter((c) => c?.firstDeployTime !== null)
    }
  }
  const handleUpcharge = async () => {
    if (previewItem.id && previewItem.orgId) {
      setLoading('upcharge')
      const token = localStorage.getItem('eltoro_token')

      await audienceServiceApi
        ?.advertisingPlatformServiceUpdateAudience(
          previewItem.id,
          previewItem.orgId,
          {
            cpmPlus: {
              units: upCharge?.units,
              nanos: upCharge?.nanos,
              upcharges: [
                {
                  dataSource: previewItem.dataSource,
                  units: upCharge?.units,
                  nanos: upCharge?.nanos,
                },
              ],
            },
          },
          'cpm_plus',
          token ? getApiConfig(token) : undefined,
        )
        .then((res) => {
          setUpcharge(undefined)
          const existingAudience = findCachedRow(
            (aud) => aud?.id === previewItem.id,
          )?.existingItem
          if (existingAudience) {
            const updatedAudience = {
              ...existingAudience,
              cpmPlus: {
                units: upCharge?.units,
                nanos: upCharge?.nanos,
                upcharges: [
                  {
                    dataSource: previewItem.dataSource,
                    units: upCharge?.units,
                    nanos: upCharge?.nanos,
                  },
                ],
              },
            } as Targetjobservicev1Audience
            updateRow((aud) => aud?.id === previewItem.id, updatedAudience)
          }
          handleSetSelectedItem({
            ...previewItem,
            cpmPlus: {
              nanos: upCharge?.nanos,
              units: upCharge?.units,
            },
          })
          showSuccessMessage(
            'Audience Updated',
            "The audience's upcharged CPM has been updated.",
          )
          setLoading('')
        })
        .catch(() => {
          showErrorMessage(
            'Audience Not Updated',
            "The audience's upcharged CPM change has failed.",
          )
        })
        .finally(() => setLoading(''))
    }
  }
  const handleAudienceTag = async () => {
    if (previewItem.id && previewItem.orgId) {
      setLoading('audience-tag')
      const token = localStorage.getItem('eltoro_token')
      await audienceServiceApi
        ?.advertisingPlatformServiceUpdateAudience(
          previewItem.id,
          previewItem.orgId,
          {
            tag: tagInput,
          },
          'tag',
          token ? getApiConfig(token) : undefined,
        )
        .then((res) => {
          const existingAudience = findCachedRow(
            (aud) => aud?.id === previewItem.id,
          )?.existingItem
          if (existingAudience) {
            const updatedAudience = {
              ...existingAudience,
              tag: res.tag,
            } as Targetjobservicev1Audience
            updateRow((aud) => aud?.id === previewItem.id, updatedAudience)
          }
          handleSetSelectedItem({
            ...previewItem,
            tag: res.tag,
          })
        })
        .finally(() => setLoading(''))
    }
  }
  const handleBuisnessLocationSend = async () => {
    if (!previewItem.orgId) return
    uploadBusinessLocation(target, previewItem, previewItem.orgId)
      .then(() => showSuccessMessage('Business Location Uploaded', ''))
      .catch(() => showErrorMessage('Error uploading business location', ''))
  }
  // const handleBuisnessLocationIPs = async () => {
  //   if (!previewItem.orgId) return
  //   // TODO: when endpoint is available
  // }
  useEffect(() => {
    setToggleHide(isHidden)
    setToggleLocked(isLocked)
    setTagInput(previewItem.tag || '')
    setLoading('')
    setUpcharge(undefined)
  }, [previewItem, isHidden, isLocked])

  const requoteDisabled =
    previewItem.status !== 'AUDIENCE_STATUS_COMPLETED' &&
    previewItem.status !== 'AUDIENCE_STATUS_READY' &&
    previewItem.status !== 'AUDIENCE_STATUS_CANCELLED' &&
    previewItem.status !== 'AUDIENCE_STATUS_ERRORED'

  return (
    <div className="AdOpsSettings mb-8 flex flex-col">
      <DividerRule />
      <Text className="Section__header">
        <span className="AdOpsSettings__section-header">AdOps Settings</span>
      </Text>
      <div className="AdOpsSettings__btn-container flex flex-col gap-2">
        {isLeadershipOrDev && previewItem.type !== 'AUDIENCE_TYPE_RETARGETING' && (
          <span
            data-tooltip="top"
            aria-label={
              requoteDisabled ? 'Audience cannot be re-quoted' : undefined
            }
          >
            <Button
              disabled={requoteDisabled}
              iconLeft={<Fa icon="rotate-right" size={1} />}
              className="RequoteButton mt-2 w-full"
              onClick={() => {
                if (previewItem.locked) {
                  triggerPrompt(
                    'Re-quote Audience',
                    <p>
                      This audience is currently locked. Are you sure you want
                      to re-quote <strong>{previewItem.name}</strong>?
                    </p>,
                    handleRequote,
                  )
                } else handleRequote()
              }}
            >
              Re-quote Audience
            </Button>
          </span>
        )}
        {previewItem.type === 'AUDIENCE_TYPE_B2B' && (
          <>
            {/* feature flagging for now */}
            {/* <Button
              iconLeft={<Fa icon="paper-plane" size={1} />}
              onClick={handleBuisnessLocationSend}
            >
              Send to Business Locations
            </Button> */}
            {/* TODO: hook this up when available */}
            {/* <Button
              iconLeft={<Fa icon="location-dot" size={1} />}
              onClick={handleBuisnessLocationIPs}
            >
              IPs from Business Locations
            </Button> */}
          </>
        )}
      </div>
      <div className="AdOpsSettings__adOpsControls--settings">
        <div className="HideAudience my-4 flex items-center gap-2">
          <Text className="text-xs">Hide Audience</Text>
          <Toggle
            className="ml-auto"
            size="xs"
            isChecked={toggleHide}
            onChange={() => {
              if (isLocked) {
                triggerPrompt(
                  `${previewItem.hidden ? 'Unhide' : 'Hide'} Audience`,
                  <p>
                    This audience is currently locked. Are you sure you want to
                    change the hidden status to:{' '}
                    <strong>
                      {previewItem.hidden ? 'not hidden' : 'hidden'}
                    </strong>
                  </p>,
                  handleHiddenToggle,
                )
              } else handleHiddenToggle()
            }}
          />
        </div>
        <div className="LockAudience my-4 flex items-center gap-2">
          <Text className="text-xs">Lock Audience</Text>
          <Toggle
            className="ml-auto"
            size="xs"
            isChecked={toggleLocked}
            onChange={() => {
              if (
                isLocked &&
                (previewItem.type === 'AUDIENCE_TYPE_DEVICE' ||
                  previewItem.type === 'AUDIENCE_TYPE_REVERSE' ||
                  previewItem.type === 'AUDIENCE_TYPE_ADDRESSES_FOR_IP')
              ) {
                triggerPrompt(
                  'Unlock Audience',
                  <p>
                    This audience is an <strong>admin-only</strong> audience.
                    Are you sure you want to unlock it and allow org admins to
                    edit this audience?
                  </p>,
                  handleLockToggle,
                )
              } else handleLockToggle()
            }}
          />
        </div>
        {previewItem.type !== 'AUDIENCE_TYPE_RETARGETING' &&
          previewItem.type !== 'AUDIENCE_TYPE_IPSFORGEOJSON' &&
          previewItem.type !== 'AUDIENCE_TYPE_IPSFORWKB' &&
          previewItem.type !== 'AUDIENCE_TYPE_POSTMOVER' &&
          previewItem.type !== 'AUDIENCE_TYPE_ESCROWMOVER' &&
          previewItem.type !== 'AUDIENCE_TYPE_PREMOVER' &&
          previewItem.type !== 'AUDIENCE_TYPE_VR' && (
            <MatchCap
              audience={previewItem}
              hasDeployments={hasDeployments}
              requoteDisabled={requoteDisabled}
              className="AdOpSettings__match-cap border-b-thin border-tint-gray-300 pb-4"
            />
          )}
        {previewItem.dataProduct === 'DATA_PRODUCT_CUSTOM_AUDIENCE' &&
          previewItem.cpmPlus &&
          (previewItem.cpmPlus.nanos || previewItem.cpmPlus.nanos === 0) &&
          previewItem.cpmPlus.units && (
            <div className="Stats__bottom-border pb-2">
              <span className="flex flex-1 items-center justify-between gap-2 pt-2 pb-1">
                <span>
                  <Fa icon="fa-gem" type="duotone" size="lg" />
                  <Text className="text-xs">Audience Upcharge</Text>
                </span>
                <CPMPrice
                  units={
                    upCharge?.units
                      ? Number(upCharge.units)
                      : Number(previewItem.cpmPlus.units)
                  }
                  nanos={
                    upCharge?.units ? upCharge.nanos : previewItem.cpmPlus.nanos
                  }
                  dataSource={previewItem?.dataSource}
                  onChange={(change) => setUpcharge(change)}
                />
                <Button
                  iconOnly={
                    loading === 'upcharge' ? (
                      <Fa icon="fa-spinner" size={1} animate="spin" />
                    ) : (
                      <Fa icon="save" size={1} />
                    )
                  }
                  disabled={!upCharge || !!loading}
                  onClick={async (e) => {
                    e?.preventDefault()
                    setLoading('upcharge')
                    const deployedOls = await canChangeUpcharge()
                    if (deployedOls && deployedOls.length > 0) {
                      return showErrorMessage(
                        'Change cannot be made',
                        'The audience is attached to a deployed order line, and the upcharge cannot be changed',
                      )
                    }
                    await handleUpcharge()
                  }}
                />
              </span>
            </div>
          )}
        {previewItem.dataProduct === 'DATA_PRODUCT_CUSTOM_AUDIENCE' && (
          <div className="AudienceTag border-b-thin border-tint-gray-300 mb-2 pt-2 pb-2">
            <Text className="text-xs">Audience Tag</Text>
            <div className="mt-1 flex items-center justify-between gap-2">
              <CustomAudienceTagDropdown
                value={tagInput}
                onChange={setTagInput}
                disabled={hasDeployments || loading === 'audience-tag'}
              />
              <Button
                iconOnly={
                  loading === 'audience-tag' ? (
                    <Fa icon="fa-spinner" size={1} animate="spin" />
                  ) : (
                    <Fa icon="save" size={1} />
                  )
                }
                disabled={!tagInput || !!loading || hasDeployments}
                onClick={async (e) => {
                  e?.preventDefault()
                  await handleAudienceTag()
                }}
              />
            </div>
          </div>
        )}
        <div className="border-b-thin border-tint-gray-300 dataRetention my-2 items-center gap-2">
          <span className="flex flex-1 justify-between gap-2 pt-2 pb-1">
            <Text className="text-xs">Data Retention Policy</Text>
            <span
              className="ml-auto before:!min-w-[12rem]"
              data-tooltip="left"
              aria-label="EL TORO will retain Information on our systems for as long as required to engage in the uses described in the Privacy Policy, unless a longer retention period is required by applicable law or other legal obligation."
            >
              <Fa
                color="grey"
                type="light"
                className="PreviewPanel__helpButton-icon"
                icon="circle-question"
                size={1}
              />
            </span>
          </span>
          <select
            disabled={true || isLocked}
            className="dataRention-select border-thin border-tint-gray-300 col-span-2 mb-4 !min-h-[1rem] w-full !p-1 text-sm "
            name="dataRetention"
            onChange={(e) => setDataRetention(e.target.value)}
          >
            <option value={1}>3 Months</option>
            <option value={2}>6 Months</option>
          </select>
        </div>
      </div>
      <div className="adopsNotes mb-2 flex flex-col gap-1">
        <div className="flex items-end justify-between">
          <Text className="mb-2 text-xs">AdOps Notes</Text>
          <Button
            iconOnly={
              <span
                data-tooltip="left"
                aria-label={(() => {
                  if (adOpsNotes.length > AUDIENCE_NOTE_LIMIT)
                    return `Note is over the character limit of ${AUDIENCE_NOTE_LIMIT}`
                  return 'Click icon to save'
                })()}
              >
                <Fa icon="save" size={1} />
              </span>
            }
            disabled={!adOpsNotes || adOpsNotes.length > AUDIENCE_NOTE_LIMIT}
            onClick={() => handleSaveAdOpsNotes()}
          />
        </div>
        <textarea
          className={classNames(
            'AdOpsSettings__text-area border-grey-400 my-1 resize-none rounded border p-2',
            {
              'border-warning animate-glowwarning text-warning-500':
                adOpsNotes.length > AUDIENCE_NOTE_LIMIT,
            },
          )}
          value={adOpsNotes}
          onChange={(e) => setAdOpsNotes(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              handleSaveAdOpsNotes()
            }
          }}
        />
        <div
          className={classNames('flex justify-between text-right text-xs', {
            'text-warning-500': adOpsNotes.length > AUDIENCE_NOTE_LIMIT,
          })}
        >
          <p>Limit {AUDIENCE_NOTE_LIMIT} characters</p>
          <p className="font-semibold">
            Remaining: {AUDIENCE_NOTE_LIMIT - adOpsNotes.length}
          </p>
        </div>
        {(notes || []).length > 0 ? (
          <Button
            className="mt-2 w-full"
            iconLeft={<Fa icon="note-sticky" size={1} />}
            onClick={() => openNoteModal()}
          >{`View Notes (${(notes || []).length})`}</Button>
        ) : null}
      </div>
    </div>
  )
}
