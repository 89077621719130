import React, { ReactNode, useRef, useState } from 'react'
import { useOnClickOutside } from '@eltoro-ui/hooks'
import { Fa, Text, Button } from '@eltoro-ui/components'
import { userFriendlyAudienceName, styleTailwind } from 'Helpers'
import { uploaderContainerHeaderText } from '../AudienceUploadContainer/configs'
import device from '../../Assets/aud_deviceIDs.jpg'
import ip from '../../Assets/aud_ip.jpg'
import addressZip from '../../Assets/aud_add_zip.jpg'
import newmover from '../../Assets/aud_newmover.jpg'
import retarg from '../../Assets/aud_retargeting1.jpg'
import zip from '../../Assets/aud_zip.jpg'
import './FileRequirements.scss'

type ContentType = {
  title?: string
  baseContent?: ReactNode
  content?: ReactNode
  image?: ReactNode
  footnote?: ReactNode
}

const Bullet = styleTailwind('i', 'Bullet fas fa-check-square')
const BulletPoint = styleTailwind('span', 'BulletPoint fa-li')
const CodeTag = styleTailwind(
  'code',
  'CodeTag px-1 !text-xs text-success-500 font-bold',
)

const getFileContent = (
  id: string,
  audienceType: string,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const AudType = userFriendlyAudienceName(audienceType)
    .toLowerCase()
    .replace(/ /g, '')
  const AudTypeText = uploaderContainerHeaderText.find((item) => item.id === id)

  const fileContent: ContentType = {
    title: `${AudTypeText && AudTypeText.id === AudType ? AudType : id}`,
    baseContent: (
      <>
        <li
          className={`FileContent ListItem ${
            AudTypeText && AudTypeText.id
          }-${AudType}-${id}`}
        >
          <BulletPoint>
            <Bullet />
          </BulletPoint>
          Must be a <strong>CSV</strong> file, save as
          <strong> CSV UTF-8</strong>, <strong>comma-delimited</strong>, with
          <CodeTag>.csv</CodeTag>
          extension
        </li>
        <li className="ListItem">
          <BulletPoint>
            <Bullet />
          </BulletPoint>
          No commas or special characters allowed
        </li>
        <li className="ListItem">
          <BulletPoint>
            <Bullet />
          </BulletPoint>
          Be sure to upload the correct audience list for your campaign to avoid
          <strong className="text-danger-600"> delays</strong>. Data needs to be
          complete without<strong> missing information </strong>such as partial
          street addresses or
          <strong> missing leading '0' </strong>and/or
          <strong> blank fields </strong>in the ZIP codes column
        </li>
      </>
    ),
    footnote: (
      <>
        You may have additional columns such as <em>First Name</em>,
        <em> Last Name</em>,<em> Address 2 </em>or<em> Apt.</em>,<em> City</em>,
        <em> State</em>, etc., but they are<strong> not required </strong>for
        our quoting process. Each component of the address must have its own
        column and column header; if you decide not to remove those columns from
        the file before loading
      </>
    ),
  }

  const FileReqs = ({
    fileContent,
    onRequestClose,
  }: {
    fileContent: ContentType
    onRequestClose: () => void
  }): JSX.Element => {
    const { title, baseContent, content, image, footnote } = fileContent

    return (
      <div className="FileRequirements FileRequirements__content">
        <div className="FileRequirements__header">
          <Text
            tag="h4"
            className="FileRequirements__header-text text-l flex gap-2"
          >
            <span className={`Title ${title === 'device' ? 'capitalize' : ''}`}>
              {title === 'address' || title === 'ip' || title === 'custom'
                ? 'IP Address'
                : title === 'dc'
                ? 'Digital Canvassing'
                : title === 'new-mover'
                ? 'Digital New Mover'
                : title === 'ipForMailing'
                ? 'IP List for Mailing'
                : title === 'retargeting'
                ? 'Retargeting'
                : title === 'reverseIp'
                ? 'Reverse IP'
                : title === 'zip'
                ? 'ZIP Code List'
                : title}
            </span>
            file formatting
          </Text>
          <span
            className="FileRequirements__close-btn--wrap block"
            aria-label="Close info box"
            data-tooltip="left"
          >
            <Button
              iconOnly={<Fa className="text-lg" icon="times" size={1} />}
              className="text-primary-600 hover:bg-primary-500 h-7 w-7 rounded-full hover:text-white"
              onClick={onRequestClose}
            />
          </span>
        </div>
        <div className="FileRequirements__text flex flex-col gap-2">
          <ul className="FileRequirements__text-list Text--list fa-ul flex flex-col gap-1 py-1">
            {content}
            {title === 'new-mover'
              ? null
              : title === 'retargeting'
              ? null
              : baseContent}
          </ul>
          {image && (
            <figure className="FileRequirements__image">
              <Text
                className="FileRequirements__image-header flex gap-2 py-2 font-light"
                tag="h5"
              >
                <span
                  className={`Title ${title === 'device' ? 'capitalize' : ''}`}
                >
                  {title === 'address' || title === 'ip'
                    ? 'IP Address'
                    : title === 'dc'
                    ? 'Digital Canvassing'
                    : title === 'ipForMailing'
                    ? 'IP List for Mailing'
                    : title === 'new-mover'
                    ? 'Digital New Mover'
                    : title === 'retargeting'
                    ? 'Retargeting'
                    : title === 'reverseIp'
                    ? 'Reverse IP'
                    : title === 'zip'
                    ? 'ZIP Code List'
                    : title}
                </span>
                example
              </Text>
              {image}
            </figure>
          )}
          {title === 'new-mover' ? null : title === 'retargeting' ? null : (
            <Text
              className="FileRequirements__footnote py-2 py-2 text-sm"
              tag="p"
            >
              {footnote}
            </Text>
          )}
        </div>
      </div>
    )
  }

  // Content based on audience type
  const fileContentTypes: Record<string, ContentType> = {
    device: {
      content: (
        <>
          <li className={`ListItem ${audienceType}_Item`}>
            <BulletPoint>
              <Bullet />
            </BulletPoint>
            Device IDs column with <strong>Device IDs</strong> as column header
          </li>
        </>
      ),
      ...fileContent,
      image: (
        <img
          className={`${audienceType}_img`}
          src={device}
          alt={`${audienceType} example`}
        />
      ),
    },
    address: {
      content: (
        <>
          <li className={`ListItem ${audienceType}_item`}>
            <BulletPoint>
              <Bullet />
            </BulletPoint>
            Address column needs to have<strong> Address 1 </strong>as column
            header
          </li>
          <li className="ListItem">
            <BulletPoint>
              <Bullet />
            </BulletPoint>
            ZIP Code column must have<strong> ZIP </strong>as column header. ZIP
            needs to be broken down into a<strong> 5-digit ZIP </strong>and
            <strong> preserve 0 </strong>in ZIP Codes beginning with the number
            0
          </li>
        </>
      ),
      ...fileContent,
      image: (
        <img
          className={`${audienceType}_img`}
          src={addressZip}
          alt={`${AudType} example`}
        />
      ),
    },
    custom: {
      content: (
        <>
          <li className={`ListItem ${audienceType}_item`}>
            <BulletPoint>
              <Bullet />
            </BulletPoint>
            Address column needs to have<strong> Address 1 </strong>as column
            header
          </li>
          <li className="ListItem">
            <BulletPoint>
              <Bullet />
            </BulletPoint>
            ZIP Code column must have<strong> ZIP </strong>as column header. ZIP
            needs to be broken down into a<strong> 5-digit ZIP </strong>and
            <strong> preserve 0 </strong>in ZIP Codes beginning with the number
            0
          </li>
        </>
      ),
      ...fileContent,
      image: (
        <img
          className={`${audienceType}_img`}
          src={addressZip}
          alt={`${AudType} example`}
        />
      ),
    },
    ip: {
      content: (
        <>
          <li className={`ListItem ${audienceType}_Item`}>
            <BulletPoint>
              <Bullet />
            </BulletPoint>
            A list of IP addresses to serve digital ads to the devices connected
            to each IP address. IP address column needs to have
            <strong> IP Address </strong>as column header
          </li>
        </>
      ),
      ...fileContent,
      image: (
        <img
          className={`${audienceType}_img`}
          src={ip}
          alt={`${AudType} example`}
        />
      ),
    },
    ipForMailing: {
      content: (
        <>
          <li className={`ListItem ${audienceType}_item`}>
            <BulletPoint>
              <Bullet />
            </BulletPoint>
            Upload a list of IPs addresses and allow the list to quote. IP
            address column needs to have
            <strong> IP Address </strong>as column header
          </li>
        </>
      ),
      ...fileContent,
      image: (
        <img
          className={`${audienceType}_img`}
          src={ip}
          alt={`${AudType} example`}
        />
      ),
    },
    dc: {
      content: (
        <>
          <li className={`ListItem ${audienceType}_item`}>
            <BulletPoint>
              <Bullet />
            </BulletPoint>
            Address column needs to have<strong> Address 1 </strong>as column
            header
          </li>
          <li className={`ListItem ${audienceType}_item`}>
            <BulletPoint>
              <Bullet />
            </BulletPoint>
            Submitted addresses will be removed from the audience list, and
            replaced with neighbors' addresses
          </li>
          <li className={`ListItem ${audienceType}_item`}>
            <BulletPoint>
              <Bullet />
            </BulletPoint>
            ZIP Code column must have<strong> ZIP </strong>as column header. ZIP
            needs to be broken down into a<strong> 5-digit ZIP </strong>and
            <strong> preserve 0 </strong>in ZIP Codes beginning with the number
            0
          </li>
        </>
      ),
      ...fileContent,
      image: (
        <img
          className={`${audienceType}_img`}
          src={addressZip}
          alt={`${AudType} example`}
        />
      ),
    },
    'new-mover': {
      content: (
        <>
          <li className={`ListItem ${audienceType}_item`}>
            <BulletPoint>
              <Bullet />
            </BulletPoint>
            Enter ZIP codes of areas you'd like to track
          </li>
          <li className={`ListItem ${audienceType}_item`}>
            <BulletPoint>
              <Bullet />
            </BulletPoint>
            You can type in a single ZIP code, or copy-and-paste multiple ZIP
            codes, separated by a commas or spaces
          </li>
          <li className={`ListItem ${audienceType}_item`}>
            <BulletPoint>
              <Bullet />
            </BulletPoint>
            We also support<strong> wildcard </strong>matching, so
            <CodeTag>4020*</CodeTag> will work.<em> Limit 1000 ZIP codes</em>.
          </li>
        </>
      ),
      ...fileContent,
      image: (
        <img
          className={`${audienceType}_img`}
          src={newmover}
          alt={`${AudType} example`}
        />
      ),
    },
    retargeting: {
      content: (
        <>
          <li className={`ListItem ${audienceType}_item`}>
            <BulletPoint>
              <Bullet />
            </BulletPoint>
            Create a retargeting pixel audience to show website visitors your
            ads
          </li>
          <li className={`ListItem ${audienceType}_item`}>
            <BulletPoint>
              <Bullet />
            </BulletPoint>
            Type a name for your retargeting audience
          </li>
          <li className={`ListItem ${audienceType}_item`}>
            <BulletPoint>
              <Bullet />
            </BulletPoint>
            Copy either of the tracking codes -- JavaScript or Image -- and
            paste the code right before your website's closing body tag. An
            example of JavaScript Code:
            <CodeTag className="block pt-2 leading-snug">
              &lt;!-- Segment Pixel - Retargeting audience Retargeting Pixel -
              DO NOT MODIFY --&gt;&lt;script
              src='https://secure.adnxs.com/seg?add=12345678&amp;t=1'
              type='text/javascript'&gt;&lt;/script&gt;&lt;!-- End of Segment
              Pixel --&gt;
            </CodeTag>
          </li>
        </>
      ),
      ...fileContent,
      image: (
        <img
          className={`${audienceType}_img`}
          src={retarg}
          alt={`${AudType} example`}
        />
      ),
    },
    reverseIp: {
      content: (
        <>
          <li className={`ListItem ${audienceType}_item`}>
            <BulletPoint>
              <Bullet />
            </BulletPoint>
            Upload a list of IPs addresses and allow the list to quote.
            <strong className="px-1"> IP address </strong>as column header
          </li>
        </>
      ),
      ...fileContent,
      image: (
        <img
          className={`${audienceType}_img`}
          src={ip}
          alt={`${AudType} example`}
        />
      ),
    },
    zip: {
      content: (
        <>
          <li className={`ListItem ${audienceType}_item`}>
            <BulletPoint>
              <Bullet />
            </BulletPoint>
            A single zip code column with
            <strong className="px-1">Zip Codes</strong>as column header
          </li>
          <li className={`ListItem ${audienceType}_item`}>
            <BulletPoint>
              <Bullet />
            </BulletPoint>
            ZIP needs to be broken down into a 5-digit ZIP and preserve 0 in ZIP
            Codes beginning with the number 0
          </li>
        </>
      ),
      ...fileContent,
      image: (
        <img
          className={`${audienceType}_img`}
          src={zip}
          alt={`${AudType} example`}
        />
      ),
    },
  }

  return (
    <FileReqs
      fileContent={fileContentTypes[id]}
      onRequestClose={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
    />
  )
}

export const FileRequirements = ({
  audienceType,
}: {
  audienceType: string
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const fileReqsRef = useRef<HTMLDivElement>(null)
  const closeRef = useRef<HTMLButtonElement>(null)

  useOnClickOutside([fileReqsRef, closeRef], () => {
    setIsOpen(false)
  })

  return (
    <div className="FileRequirements__outer ml-1 flex items-center">
      <button
        aria-label="Click for list formatting info"
        data-tooltip="right"
        type="button"
        className="QuestionInfo h-full"
        tabIndex={0}
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={() => setIsOpen(!isOpen)}
        ref={closeRef}
      >
        <Fa className="QuestionInfoIcon" icon="question-circle" size={1} />
      </button>
      {isOpen && (
        <div
          className={`FileRequirements__card cursor-auto rounded-md p-5 text-left ${
            isOpen ? 'is-open' : ''
          }`}
          ref={fileReqsRef}
        >
          {audienceType === 'address' ||
          audienceType === 'dc' ||
          audienceType === 'device' ||
          audienceType === 'ip' ||
          audienceType === 'ipForMailing' ||
          audienceType === 'new-mover' ||
          audienceType === 'retargeting' ||
          audienceType === 'reverseIp' ||
          audienceType === 'zip' ||
          audienceType === 'custom' ? (
            <div className={`FileRequirements__card-content--${audienceType}`}>
              {getFileContent(audienceType, audienceType, setIsOpen)}
            </div>
          ) : (
            <div
              className={`FileRequirements__card-default-content--${audienceType}`}
            >
              {getFileContent('address', audienceType, setIsOpen)}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
