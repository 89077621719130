import {
  useState,
  useContext,
  useEffect,
  createContext,
  useRef,
  useCallback,
} from 'react'
import { showErrorMessage, showSuccessMessage } from '@eltoro-ui/components'
import {
  Campaignservicev1Campaign,
  Creativeservicev1Creative,
  Creativeservicev1Type,
  Campaignservicev1Audience,
  Campaignservicev1OrderLine,
  Targetjobservicev1Audience,
  V1PoliticalFields,
} from 'next-gen-sdk'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  canIAttach,
  checkIfJobIsAttached,
  olNameParser,
  olTypesFinder,
} from 'Helpers'
import { useAppContext } from 'Contexts'
import { CampaignContextType } from 'types'
import { useSSEEventReactor } from 'Hooks'
import { IMPRESSION_MIN } from 'Components/OrderLineEditWrapper/components/shared'
import { dayjs } from 'Tools/dateUtils'

export type CreativeAddType = {
  attachToOl?: Campaignservicev1OrderLine
  olCreativeType: string
  creativeToAttach: Creativeservicev1Creative
  needsNewOl: boolean
  index?: number
}

export type AudienceAddType = {
  attachToOl?: Campaignservicev1OrderLine
  olAudienceType: string
  audienceToAttach: Targetjobservicev1Audience
  needsNewOl: boolean
}

export type attachedCreative = {
  id?: string
  creative?: Creativeservicev1Creative
  attached?: boolean
  orderLineID?: string
}
const now = new Date().toUTCString()
const nowPlusAMonth = dayjs()
  .tz()
  .add(1, 'month')
  .endOf('day')
  .toDate()
  .toUTCString()

export const campaignContextBaseState = {
  campaign_name: '',
  end_date: nowPlusAMonth,
  start_date: now,
  refId: '',
  setCampaignName: () => {},
  setCampaignStartDate: () => {},
  setCampaignEndDate: () => {},
  setCampaign: () => {},
  campaign: {},
  orderLines: [],
  setOrderLines: () => [],
  setAttachedAudiences: () => [],
  setAttachedCreatives: () => {},
  attachedCreatives: [],
  setRefresh: () => false,
  refresh: false,
  launchFreshCampaign: () => {},
  temporaryCreatives: [],
  setTemporaryCreatives: () => {},
  fetchDraftCampaign: () => {},
  handleCampaignCreation: async () => {},
  NewhandleAttachAudiences: async () => {},
  NewhandleAttachCreatives: async () => {},
  handleRemoveCreativeFromCampaign: async () => {},
  handleRemoveAudienceFromCampaign: async () => {},
  newCartChange: false,
  isPolitical: false,
  setIsPolitical: () => {},
  setNewCartChange: () => {},
  creatingInitOrderLine: false,
  handlePolitical: async () => {},
  setSubmissionRemovals: () => {},
  submissionRemovals: [],
}

export const CampaignContext = createContext<CampaignContextType>(
  campaignContextBaseState,
)
// Export a hook to use context (avoids importing useContext+AppContext every time)
export const useCampaignContext = () => {
  const ctx = useContext(CampaignContext)
  if (!ctx)
    throw new Error(
      'useCampaignContext must be used within the CampaignContextProvider',
    )
  return ctx
}

export const CampaignContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [campaign_name, setCampaignName] = useState<string>('')
  // prettier-ignore
  const [
    orderLinePoliticalFields,
    setOrderLinePoliticalFields,
  ] = useState<V1PoliticalFields>()
  const [start_date, setCampaignStartDate] = useState<string>(
    campaignContextBaseState.start_date,
  )
  const [end_date, setCampaignEndDate] = useState<string>(
    campaignContextBaseState.end_date,
  )
  const [campaign, setCampaign] = useState<Campaignservicev1Campaign>({})
  const [orderLines, setOrderLines] = useState<Campaignservicev1OrderLine[]>([])
  const [attachedAudiences, setAttachedAudiences] = useState<
    Campaignservicev1Audience[]
  >([])
  const [attachedCreatives, setAttachedCreatives] = useState<
    Creativeservicev1Creative[]
  >()
  const [refresh, setRefresh] = useState<boolean>(false)
  const [isPolitical, setIsPolitical] = useState(false)
  const [newCartChange, setNewCartChange] = useState(false)
  // for pre-loading a creative from creative library
  const [temporaryCreatives, setTemporaryCreatives] = useState<
    Creativeservicev1Creative[]
  >([])
  const [temporaryAudiences, setTemporaryAudiences] = useState<
    Targetjobservicev1Audience[]
  >([])
  const [submissionRemovals, setSubmissionRemovals] = useState<string[]>([])
  const {
    creativeServiceApi,
    campaignServiceApi,
    orgServiceApi,
    currentOrg,
    setCurrentOrg,
  } = useAppContext()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isMounted = useRef(false)

  const launchFreshCampaign = ({
    audiences,
    creative,
  }: {
    audiences?: Targetjobservicev1Audience[]
    creative?: Creativeservicev1Creative
  }) => {
    setCampaignName('')
    setCampaignStartDate(campaignContextBaseState.start_date)
    setCampaignEndDate(campaignContextBaseState.end_date)
    setCampaign({})
    setOrderLines([])
    setAttachedAudiences([])
    setAttachedCreatives([])
    setSubmissionRemovals([])
    if (audiences) {
      setTemporaryAudiences(audiences)
    } else {
      setTemporaryAudiences([])
    }
    if (creative) {
      setTemporaryCreatives([creative])
    } else {
      setTemporaryCreatives([])
    }
  }

  const fetchCampaignOrderLines = async (campaignId: string, orgId: string) => {
    if (!campaignServiceApi) return {}
    // Get the updated campaign
    const currentCampaign = await campaignServiceApi?.advertisingPlatformServiceGetCampaign(
      campaignId,
      orgId,
    )
    // For each campaign.orderLines, fetch
    if (!currentCampaign.orderLines) {
      setCampaign(currentCampaign)
      return {}
    }
    const currentOrderLines = await Promise.all(
      currentCampaign.orderLines.map((ol) => {
        if (!ol.id) return Promise.resolve({} as Campaignservicev1OrderLine)
        return campaignServiceApi
          .advertisingPlatformServiceGetOrderLine(ol.id, orgId)
          .catch(() => {
            return Promise.resolve({} as Campaignservicev1OrderLine)
          })
      }),
    )
    return { orderLines: currentOrderLines.filter((ol) => !!ol.id) }
  }
  const query = currentOrg?.id ? `?org_id=${currentOrg.id}` : ''
  const fetchDraftCampaign = useCallback(
    (id: string) => {
      // Temporary fix- getCampaign now requires the campaign's org id,
      // which could be the wrong one here and cannot be derived from the url params.
      // we likely need to add the org id to the urls/routes
      // https://eltoroteam.slack.com/archives/C029B5WPMU3/p1673389831152259?thread_ts=1673386457.228479&cid=C029B5WPMU3
      campaignServiceApi
        ?.advertisingPlatformServiceListCampaigns(
          undefined, // pageSize
          undefined, // pageToken
          undefined, // orderBy,
          `_id = ${id}`, // filter
        )
        .then(async (result) => {
          const { campaigns } = result
          const draftCampaign = campaigns?.[0]
          if (!draftCampaign) {
            window.history.replaceState(
              '',
              '',
              `${window.location.origin}/campaigns/create${query}`,
            )
          }
          let org = currentOrg
          // Set the current org that this campaign belongs to
          if (draftCampaign?.orgId && currentOrg?.id !== draftCampaign.orgId) {
            const newOrg = await orgServiceApi?.advertisingPlatformServiceGetOrg(
              draftCampaign?.orgId,
            )
            org = newOrg
          }
          return { draftCampaign, org }
        })
        .then(({ draftCampaign, org }) => {
          if (!draftCampaign || !draftCampaign.name) return null
          setCampaignName(draftCampaign.name)
          setCampaign(draftCampaign)
          if (draftCampaign.startTime)
            setCampaignStartDate(draftCampaign.startTime.toISOString())
          if (draftCampaign.endTime)
            setCampaignEndDate(draftCampaign.endTime.toISOString())
          if (org) setCurrentOrg(org)
          setIsPolitical(!!draftCampaign.politicalTransparency)
          // set refresh so the order lines, creatives, audiences are populated
          setRefresh(true)
        })
    },
    [campaignServiceApi, currentOrg, orgServiceApi],
  )

  /// New AddCreatives
  const NewaddCreative = (
    creatives: Creativeservicev1Creative[],
    clickThru?: string,
    orderline?: Campaignservicev1OrderLine,
    brandURL?: string,
  ) => {
    if (
      !currentOrg?.id ||
      !campaignServiceApi ||
      !campaignServiceApi ||
      !creatives
    )
      return
    if (orderline) {
      const creativeIdsToAttach = creatives
        .map((c) => (c.id ? c.id : undefined))
        .filter((c) => c !== undefined) as string[]
      if (!orderline.id || !currentOrg.id) return
      const creativeType = creatives[0].type
      campaignServiceApi
        .advertisingPlatformServiceBatchAddCreatives(orderline.id, {
          orgId: currentOrg.id,
          creativeIds: creativeIdsToAttach,
        })
        .then((res) => {
          if (res && brandURL && currentOrg.id) {
            creatives.forEach((selectedCreative) => {
              if (!selectedCreative.id || !currentOrg.id) return
              creativeServiceApi?.advertisingPlatformServiceUpdateCreative(
                selectedCreative.id,
                currentOrg.id,
                {
                  nativeMetadata: {
                    alternativeMobileLandingPageUrl: brandURL,
                  },
                },
                selectedCreative.nativeMetadata !== null
                  ? 'native_metadata.alternative_mobile_landing_page_url'
                  : '',
              )
            })
          }
          if (res) {
            showSuccessMessage(
              `${creatives.length > 1 ? 'Creatives' : 'Creative'} Attached`,
              `Your ${
                creatives.length > 1 ? 'creatives were' : 'creative was'
              } attached to the order line`,
            )
          }
        })
        .catch(() => {
          showErrorMessage(
            'Error attaching creative',
            'There was an error attaching the selected creative to the order line',
          )
        })
        .then(() => {
          if (orderline?.name && currentOrg.id && creativeType) {
            campaignServiceApi
              ?.advertisingPlatformServiceUpdateOrderLine(
                orderline?.id || '',
                currentOrg?.id,
                {
                  name: olNameParser(orderline?.name, {
                    creativeType: creativeType,
                  }).updatedName,
                  clickThroughUrl:
                    clickThru || orderline?.clickThroughUrl || '',
                  orgId: currentOrg.id,
                },
              )
              .then((res: Campaignservicev1OrderLine) => {
                setRefresh(true)
                setNewCartChange(true)
              })
          }
        })
    }
  }
  /// New AddAudiences
  const newAddAudience = (
    audiences: Targetjobservicev1Audience[] | Campaignservicev1Audience[],
    orderline: Campaignservicev1OrderLine,
  ) => {
    if (!audiences || !orderline?.id || !campaignServiceApi || !currentOrg?.id)
      return

    let audiencesToAttach: {
      id: string
      exclude: boolean
    }[] = []
    // typescript would not let me use reduce because of the different types here, despite them both having "id"
    // i think related to this: https://github.com/microsoft/TypeScript/issues/44373
    audiences.forEach(
      (audience: Targetjobservicev1Audience | Campaignservicev1Audience) => {
        if (
          audience.type === 'AUDIENCE_TYPE_VR' &&
          (audience as Targetjobservicev1Audience).audiences &&
          (audience as Targetjobservicev1Audience).audiences?.length
        ) {
          ;(audience as Targetjobservicev1Audience).audiences?.forEach(
            (aud) => {
              if (aud.id) {
                audiencesToAttach = [
                  ...audiencesToAttach,
                  { id: aud.id, exclude: false },
                ]
              }
            },
          )
          return
        }
        if (audience.id) {
          audiencesToAttach = [
            ...audiencesToAttach,
            { id: audience.id, exclude: false },
          ]
        }
      },
    )
    campaignServiceApi
      .advertisingPlatformServiceBatchAddAudiences(orderline.id, {
        orgId: currentOrg.id,
        audiences: audiencesToAttach,
      })
      .then((res) => {
        if (res) {
          showSuccessMessage(
            `${audiences.length > 1 ? 'Audiences' : 'Audience'} Attached`,
            `Your ${
              audiences.length > 1 ? 'audiences were' : 'audience was'
            } attached to the order line`,
          )
        }
      })
      .catch((e) =>
        showErrorMessage(
          'Error attaching audience(s)',
          `There was an error attaching the selected audience(s) to the order line: ${
            e?.message || e?.body?.message || ''
          }`,
        ),
      )
      .then(() => {
        const [aud] = audiences
        if (orderline?.name && aud?.productType && currentOrg.id) {
          campaignServiceApi
            ?.advertisingPlatformServiceUpdateOrderLine(
              orderline?.id || '',
              currentOrg?.id,
              {
                name: olNameParser(orderline?.name, {
                  audienceType: aud.productType,
                }).updatedName,
                orgId: currentOrg.id,
              },
            )
            .then(() => {
              setRefresh(true)
              setNewCartChange(true)
            })
            .catch(() => {
              showErrorMessage(
                'Error updating order line',
                'There was an error updating the name of your order line',
              )
            })
        }
      })
  }

  /// New HandleAudience
  const NewhandleAttachAudiences = async (
    audiences: Targetjobservicev1Audience[],
    orderline?: Campaignservicev1OrderLine,
  ) => {
    if (!currentOrg?.id || audiences === undefined || !campaignServiceApi) {
      return
    }
    const orderlinesList = orderline ? [orderline] : orderLines
    // Audience Product Type. All same type to ol
    const typeName = audiences[0]?.productType
    // No OL create one
    if (orderlinesList.length === 0) {
      campaignServiceApi
        .advertisingPlatformServiceCreateOrderLine(
          currentOrg.id,
          {
            name: `${campaign.name}_Draft_1`,
            startTime: new Date(start_date),
            endTime: new Date(end_date),
            impressions: currentOrg?.minimumImpressions || IMPRESSION_MIN,
            political: isPolitical,
            politicalFields: isPolitical
              ? {
                  audienceDescription: '',
                  audienceIdsTargeted: '',
                  orgZip: '',
                  paidForBy: '',
                  submitterName: '',
                  submitterAddress: '',
                  submitterCity: '',
                  submitterState: '',
                  submitterZip: '',
                  submitterCountry: '',
                  submitterPhone: '',
                }
              : undefined,
          },
          campaign.id,
        )
        .then((res) => {
          newAddAudience(audiences, res)
        })
    }
    // Find the type of Audiences that do not have OLs.
    const audsTypesWithoutOLs = orderlinesList
      .map((ol) => {
        // Check if passed in audiences are attahcable to OL
        if (typeName && canIAttach(ol, typeName)) {
          const audiencesToAttach = audiences
            .filter((aud) => {
              // Is it already attached? No --> Add it to audiencesToAttach
              if (aud.id && !checkIfJobIsAttached(ol, aud.id)) {
                return aud
              }
              return undefined
            })
            .filter((x) => x !== undefined)
          // add audiences to current ol.
          newAddAudience(audiencesToAttach, ol)
          return undefined
        } else {
          // returns type name of aud types without compatiable OL
          return typeName
        }
      })
      .filter((x) => x !== undefined)
    if (audsTypesWithoutOLs && audsTypesWithoutOLs.length > 0) {
      if (currentOrg.id) {
        // Create OLs based on audsTypesWithoutOLs
        campaignServiceApi
          .advertisingPlatformServiceCreateOrderLine(
            currentOrg.id,
            {
              name: `${campaign.name}_Draft_1`,
              startTime: new Date(start_date),
              endTime: new Date(end_date),
              impressions: currentOrg?.minimumImpressions || IMPRESSION_MIN,
              political: isPolitical,
              politicalFields: isPolitical
                ? {
                    audienceDescription: '',
                    audienceIdsTargeted: '',
                    orgZip: '',
                    paidForBy: '',
                    submitterName: '',
                    submitterAddress: '',
                    submitterCity: '',
                    submitterState: '',
                    submitterZip: '',
                    submitterCountry: '',
                    submitterPhone: '',
                  }
                : undefined,
            },
            campaign.id,
          )
          .then((res) => {
            newAddAudience(audiences, res)
          })
      }
    }
  }
  /// New HandleCreatives
  const NewhandleAttachCreatives = async (
    creatives: Creativeservicev1Creative[],
    clickThru?: string,
    orderline?: Campaignservicev1OrderLine,
    brandURL?: string,
  ) => {
    if (!currentOrg?.id || !campaignServiceApi || !campaignServiceApi) return
    const orderlinesList = orderline ? [orderline] : orderLines
    // No OLs create one and attach
    if (orderlinesList.length === 0) {
      campaignServiceApi
        .advertisingPlatformServiceCreateOrderLine(
          currentOrg.id,
          {
            name: `${campaign.name}_Draft_1`,
            startTime: new Date(start_date),
            endTime: new Date(end_date),
            clickThroughUrl: clickThru,
            impressions: currentOrg?.minimumImpressions || IMPRESSION_MIN,
            orgId: currentOrg.id,
            political: isPolitical,
            politicalFields: isPolitical
              ? {
                  audienceDescription: '',
                  audienceIdsTargeted: '',
                  orgZip: '',
                  paidForBy: '',
                  submitterName: '',
                  submitterAddress: '',
                  submitterCity: '',
                  submitterState: '',
                  submitterZip: '',
                  submitterCountry: '',
                  submitterPhone: '',
                }
              : undefined,
          },
          campaign.id,
        )
        .then((res) => {
          NewaddCreative(creatives, clickThru, res, brandURL)
        })
        .catch(() => {
          showErrorMessage(
            'Error creating order line',
            'There was an error creating a new order line for one of the selected audiences',
          )
        })
    }
    // Get Type of the array of creatives that has been passed into the func
    const creativeType = creatives.map((c) => c.type)
    const typeName = (x?: Creativeservicev1Type) => {
      const rawName = x?.split('CREATIVE_TYPE_')[1] || ''
      switch (rawName) {
        case 'NATIVE_BANNER':
          return 'NATIVE-BANNER'
        case 'NATIVE_VIDEO':
          return 'NATIVE-VIDEO'
        case 'AD_TAG_VIDEO':
          return 'ADTAG-VIDEO'
        case 'AD_TAG_BANNER':
          return 'ADTAG-BANNER'
        case 'VAST_AUDIO':
          return 'VAST-AUDIO'
        case 'VAST_VIDEO':
          return 'VAST-VIDEO'
        default:
          return rawName
      }
    }
    // Get type of audiences on the current orderlines
    const audienceType = orderlinesList
      .map((cOL) => {
        const { audiences } = cOL
        if (!audiences || audiences.length === 0) return undefined
        return audiences[0].productType
      })
      .filter((c) => c !== undefined)
    // Create a unique Set of audience types
    const uniqueAuds = new Set(audienceType)
    // Check if audiences are attached
    // OR if this is the original OL created upon creation
    if (
      (attachedAudiences && attachedAudiences.length > 0) ||
      orderlinesList.length === 1
    ) {
      // Map over current OLs
      orderlinesList.map((ol, i) => {
        const { acceptedCreativeTypes, hasCreatives } = olTypesFinder(ol) || {}
        // OL Has creatives and matches the type passed
        // OR OL has no creatives --> attach creatives
        if (
          (hasCreatives &&
            acceptedCreativeTypes.some(
              (tp: string) => tp === typeName(creativeType[0]),
            )) ||
          !hasCreatives
        ) {
          NewaddCreative(creatives, clickThru, ol, brandURL)
        }
        // OL has creatives and DOES NOT match type passed in
        // OL has no audiences attached
        if (
          hasCreatives &&
          !acceptedCreativeTypes.some(
            (tp: string) => tp === typeName(creativeType[0]),
          ) &&
          uniqueAuds.size === 0
        ) {
          if (!currentOrg.id) return false
          campaignServiceApi
            .advertisingPlatformServiceCreateOrderLine(
              currentOrg.id,
              {
                name: `${campaign.name}_Draft_1`,
                startTime: new Date(start_date),
                endTime: new Date(end_date),
                clickThroughUrl: clickThru,
                impressions: currentOrg?.minimumImpressions || IMPRESSION_MIN,
                orgId: currentOrg.id,
                political: isPolitical,
                politicalFields: isPolitical
                  ? {
                      audienceDescription: '',
                      audienceIdsTargeted: '',
                      orgZip: '',
                      paidForBy: '',
                      submitterName: '',
                      submitterAddress: '',
                      submitterCity: '',
                      submitterState: '',
                      submitterZip: '',
                      submitterCountry: '',
                      submitterPhone: '',
                    }
                  : undefined,
              },
              campaign.id,
            )
            .then((res) => {
              NewaddCreative(creatives, clickThru, res, brandURL)
              return res
            })
        }
        if (
          !acceptedCreativeTypes.some(
            (tp: string) => tp === typeName(creativeType[0]),
          ) &&
          hasCreatives &&
          i < uniqueAuds.size
        ) {
          const { audiences } = ol
          if (!currentOrg.id) return false
          campaignServiceApi
            .advertisingPlatformServiceCreateOrderLine(
              currentOrg.id,
              {
                name: `${campaign.name}_Draft_1`,
                startTime: new Date(start_date),
                endTime: new Date(end_date),
                clickThroughUrl: clickThru,
                impressions: currentOrg?.minimumImpressions || IMPRESSION_MIN,
                orgId: currentOrg.id,
                political: isPolitical,
                politicalFields: isPolitical
                  ? {
                      audienceDescription: '',
                      audienceIdsTargeted: '',
                      orgZip: '',
                      paidForBy: '',
                      submitterName: '',
                      submitterAddress: '',
                      submitterCity: '',
                      submitterState: '',
                      submitterZip: '',
                      submitterCountry: '',
                      submitterPhone: '',
                    }
                  : undefined,
              },
              campaign.id,
            )
            .then((res) => {
              NewaddCreative(creatives, clickThru, res, brandURL)
              return res
            })
            .then((res) => {
              if (audiences) {
                newAddAudience(audiences, res)
              }
            })
        }
      })
    }
    // Skipped Audiences and already added creatives to base OL
    if (
      attachedAudiences &&
      attachedAudiences.length === 0 &&
      orderLines.length > 1
    ) {
      if (currentOrg.id) {
        campaignServiceApi
          .advertisingPlatformServiceCreateOrderLine(
            currentOrg.id,
            {
              name: `${campaign.name}_Draft_1`,
              startTime: new Date(start_date),
              endTime: new Date(end_date),
              clickThroughUrl: clickThru,
              impressions: currentOrg?.minimumImpressions || IMPRESSION_MIN,
              orgId: currentOrg.id,
              political: isPolitical,
              politicalFields: isPolitical
                ? {
                    audienceDescription: '',
                    audienceIdsTargeted: '',
                    orgZip: '',
                    paidForBy: '',
                    submitterName: '',
                    submitterAddress: '',
                    submitterCity: '',
                    submitterState: '',
                    submitterZip: '',
                    submitterCountry: '',
                    submitterPhone: '',
                  }
                : undefined,
            },
            campaign.id,
          )
          .then((res) => {
            NewaddCreative(creatives, clickThru, res)
          })
      }
    }
  }

  // removes the audience from the campaign (all orderlines)
  const handleRemoveAudienceFromCampaign = async (audienceId: string) => {
    if (orderLines && currentOrg?.id) {
      Promise.all(
        orderLines.map((ol) => {
          if (
            ol.id &&
            currentOrg?.id &&
            (ol.audiences || []).find((aud) => aud.id === audienceId)
          ) {
            return campaignServiceApi?.advertisingPlatformServiceRemoveAudience(
              ol.id,
              { audienceId, orgId: currentOrg.id },
            )
          }
          return Promise.resolve(null)
        }),
      )
        .then(() => {
          showSuccessMessage(
            `Audience Detached`,
            `Your audience was detached from the order line`,
          )
        })
        .finally(() => {
          setRefresh(true)
        })
    }
  }
  // remove a single creative from the campaign (all order lines)
  const handleRemoveCreativeFromCampaign = async (creativeId: string) => {
    if (orderLines && currentOrg?.id) {
      Promise.all(
        orderLines.map((ol) => {
          if (
            ol.id &&
            currentOrg?.id &&
            (ol.creatives || []).find((creat) => creat.id === creativeId)
          ) {
            return campaignServiceApi
              ?.advertisingPlatformServiceRemoveCreative(ol.id, {
                creativeId,
                orgId: currentOrg.id,
              })
              .then((res) => {
                if (res) {
                  showSuccessMessage(
                    'Creative Detached',
                    `Your selected creative(s) were detached from the order line.`,
                  )
                }
                return res
              })
          }
          return Promise.resolve(null)
        }),
      ).finally(() => {
        setRefresh(true)
      })
    }
  }
  // triggered when the "continue" button is clicked, this function will create a campaign and empty order line
  // for audiences and creatives to be added to in the following steps.
  const handleCampaignCreation = async (
    name: string,
    org: string,
    isPolitical: boolean,
    jobId?: string,
    poId?: string,
  ) => {
    setCampaignName(name)
    // Create new campaign with name and route
    const politicalTransparency = {
      isAccuracyAcknowledged: false,
      isExpenditureCommittee: false,
    }
    const newCampaignBase = {
      name: name.replace(/\t/g, ''), //this is filtering out any "tabs" from the name string do to errors with viants naming conventions
      orgId: org,
      jobId,
      poId,
    }
    campaignServiceApi
      ?.advertisingPlatformServiceCreateCampaign(
        org,
        isPolitical
          ? { ...newCampaignBase, politicalTransparency }
          : newCampaignBase,
      )
      .then((campaignRes: Campaignservicev1Campaign) => {
        if (
          !campaignRes ||
          !campaignRes.id ||
          !campaignServiceApi ||
          !campaignServiceApi
        ) {
          showErrorMessage(
            'Error',
            'Sorry, we are having a problem creating your new campaign right now',
          )
        }
        setCampaign(campaignRes)
        return campaignRes
      })
      .then((campaignRes: Campaignservicev1Campaign) => {
        if (temporaryAudiences.length > 0 || temporaryCreatives.length > 0) {
          campaignServiceApi
            ?.advertisingPlatformServiceCreateOrderLine(
              org,
              {
                name: `${campaignRes.name}_Draft_1`,
                startTime: new Date(start_date),
                endTime: new Date(end_date),
                impressions: currentOrg?.minimumImpressions || IMPRESSION_MIN,
                political: isPolitical,
                politicalFields: isPolitical
                  ? {
                      audienceDescription: '',
                      audienceIdsTargeted: '',
                      orgZip: '',
                      paidForBy: '',
                      submitterName: '',
                      submitterAddress: '',
                      submitterCity: '',
                      submitterState: '',
                      submitterZip: '',
                      submitterCountry: '',
                      submitterPhone: '',
                    }
                  : undefined,
              },
              campaignRes.id,
            )
            .then((olRes: Campaignservicev1OrderLine) => {
              if (temporaryAudiences)
                NewhandleAttachAudiences(temporaryAudiences, olRes)
              if (temporaryCreatives)
                NewhandleAttachCreatives(temporaryCreatives, '', olRes)
              return olRes
            })
        }
        const query = currentOrg?.id ? `?org_id=${currentOrg.id}` : ''
        navigate(`/campaigns/create/${campaignRes.id}/audiences${query}`)
      })
  }

  // To be used AFTER initial campaign creation
  const handlePolitical = async (setPoliticalToTrue: boolean) => {
    const errorHandler = () =>
      showErrorMessage(
        'Error updating campaign',
        `Cannot change campaign political status to ${setPoliticalToTrue.toString()}`,
      )

    const successHandler = () => {
      setIsPolitical(setPoliticalToTrue)
      // wiggle room for cascading order line changes
      setTimeout(() => setRefresh(true), 5000)
    }
    // this updates the order lines attached to it automatically
    if (!campaign?.id || !currentOrg?.id) return
    if (setPoliticalToTrue && !isPolitical) {
      // if true, add the empty politicalTransparency to campaign if it is not here
      await campaignServiceApi
        ?.advertisingPlatformServiceUpdateCampaign(
          campaign.id,
          currentOrg?.id,
          {
            politicalTransparency: {
              isAccuracyAcknowledged: false,
              isExpenditureCommittee: false,
            },
          },
          'political_transparency',
        )
        .then(successHandler)
        .catch(errorHandler)
    }
    if (!setPoliticalToTrue && isPolitical) {
      // if false, set politicalTransparency to null
      await campaignServiceApi
        ?.advertisingPlatformServiceUpdateCampaign(
          campaign.id,
          currentOrg?.id,
          {
            // it is unclear if undefined is going to work in future sdk iterations, best to submit null for now
            // see convo: https://eltoroteam.slack.com/archives/C029B5WPMU3/p1678981292654039
            // politicalTransparency: undefined,
            politicalTransparency: null as any,
          },
          'political_transparency',
        )
        .then(successHandler)
        .catch(errorHandler)
    }
  }

  // triggers when something is added to cart- for animating the cart
  useEffect(() => {
    if (newCartChange) {
      setTimeout(() => {
        setNewCartChange(false)
      }, 1500)
    }
  }, [newCartChange])

  // clears campaign when user leaves campaign creation
  // moved this out of AuthorizedRoutes to prevent multiple refreshes on context change
  useEffect(() => {
    if (!campaign?.id) return
    const splitPath = pathname.split('/')
    if (!splitPath.includes(campaign?.id)) {
      launchFreshCampaign({})
    }
  }, [pathname])

  // listens for attached audiences that have finished quoting and refreshes
  useSSEEventReactor('JobFinished', (e) => {
    const { message } = e
    if (
      message.user_id === 'onspot' &&
      (message.audience.sub_type === 1 ||
        message.audience.sub_type === 3 ||
        message.audience.sub_type === 5 ||
        message.audience.sub_type === 7)
    ) {
      // ignore child VR finished events, we want the parent to finish then update
      return
    }
    if (
      attachedAudiences.some(
        (aud) =>
          aud.audienceId === message.audience.id || // VR
          aud.id === message.audience.id,
      )
    ) {
      setRefresh(true)
    }
  })

  // Listening for order line creating -> draft updates
  useSSEEventReactor('OrderLineStatusSetToDraft', (e) => {
    if (!e.message?.id) return
    // find the ol in the list
    const olIndex = orderLines.findIndex((ol) => ol.id === e.message.id)
    // replace it in place and update the state
    if (olIndex > -1) {
      const newOrderLines = [...orderLines]
      newOrderLines[olIndex] = {
        ...newOrderLines[olIndex],
        status: 'ORDERLINE_STATUS_DRAFT',
      }
      setOrderLines(newOrderLines)
    }
  })

  // updates campaign start and end dates, updates lists of audiences and creatives
  // on the current campaign whenever the "refresh" state is updated
  useEffect(() => {
    if (
      campaign.id &&
      campaignServiceApi &&
      refresh &&
      campaign.orgId &&
      isMounted.current
    ) {
      fetchCampaignOrderLines(campaign.id, campaign.orgId)
        .then((res) => {
          // on every refesh of campaign data, this updates the campaign start and end dates to be the earliest
          // OL start date and the latest OL end date
          // this shouldn't need to update state on every refresh, only when these values change
          if (res.orderLines && res.orderLines.length > 0) {
            const startDates = res.orderLines.map((cOL) => {
              return cOL.startTime
            })
            if (startDates && startDates.length > 1) {
              const sortedStartDates = startDates?.sort((a, b) => {
                if (!a || !b) return 0
                return Date.parse(a.toISOString()) - Date.parse(b.toISOString())
              })
              setCampaignStartDate(sortedStartDates[0]?.toISOString() as string)
            }
            const endDates = res?.orderLines?.map((cOL) => {
              return cOL.endTime
            })
            if (endDates && endDates.length > 1) {
              const sortedEndDates = endDates?.sort((a, b) => {
                if (!a || !b) return 0
                return Date.parse(b.toISOString()) - Date.parse(a.toISOString())
              })
              setCampaignEndDate(sortedEndDates[0]?.toISOString() as string)
            }
          }
          return res.orderLines
        })
        .then((fetchedOrderLines) => {
          // refetches the audiences on every OL on the campaign, and updates the context state.
          // this should be memoized to prevent un-needed re-renders
          // making this many calls every refresh seems unnessessary, and might create problems at scale
          // we should look for alternate ways to handle this
          if (fetchedOrderLines) {
            setOrderLines(fetchedOrderLines)
            const uniqueSharedAudiences = fetchedOrderLines.reduce(
              (acc: Campaignservicev1Audience[], currentOL) => {
                currentOL.audiences?.forEach((audience) => {
                  if (!acc.find((aud) => aud.id === audience.id)) {
                    acc = [...acc, audience]
                  }
                })
                return acc
              },
              [],
            )
            setAttachedAudiences(uniqueSharedAudiences)
            return fetchedOrderLines
          }
        })
        .then((fetchedOrderLines) => {
          // this refetches the creatives on every OL in the campaign. and updates them in the context state.
          // this should be memoized to prevent un-needed re-renders
          // making this many calls every refresh seems unnessessary, and might create problems at scale
          // we should look for alternate ways to handle this
          if (fetchedOrderLines) {
            const uniqueCreativeIds = [
              ...new Set(
                fetchedOrderLines.flatMap((currentOl) =>
                  currentOl.creatives?.map(({ id }) => id),
                ),
              ),
            ]
            const filter = uniqueCreativeIds
              .map((id) => `id="${id}"`)
              .join(' OR ')
            if (filter && uniqueCreativeIds.length > 0) {
              creativeServiceApi
                ?.advertisingPlatformServiceListCreatives(
                  uniqueCreativeIds.length,
                  undefined, // pageToken
                  undefined, // orderBy
                  filter,
                )
                .then(({ creatives }) => setAttachedCreatives(creatives || []))
            }
          }
        })
        .finally(() => setRefresh(false))
    } else {
      isMounted.current = true
    }
  }, [refresh])
  // get political fields if any for OL
  useEffect(() => {
    if (isPolitical && orderLines) {
      const politicalObject: V1PoliticalFields = {}
      orderLines.map((currentOL) => {
        if (currentOL.politicalFields) {
          const { politicalFields } = currentOL
          if (politicalFields.audienceDescription) {
            politicalObject.audienceDescription =
              politicalFields.audienceDescription
          }
          if (politicalFields.audienceIdsTargeted) {
            politicalObject.audienceIdsTargeted =
              politicalFields.audienceIdsTargeted
          }
          if (politicalFields.orgZip) {
            politicalObject.orgZip = politicalFields.orgZip
          }
          if (politicalFields.submitterAddress) {
            politicalObject.submitterAddress = politicalFields.submitterAddress
          }
          if (politicalFields.submitterCity) {
            politicalObject.submitterCity = politicalFields.submitterCity
          }
          if (politicalFields.submitterName) {
            politicalObject.submitterName = politicalFields.submitterName
          }
          if (politicalFields.submitterState) {
            politicalObject.submitterState = politicalFields.submitterState
          }
          if (politicalFields.submitterZip) {
            politicalObject.submitterZip = politicalFields.submitterZip
          }
          if (politicalFields.paidForBy) {
            politicalObject.paidForBy = politicalFields.paidForBy
          }
        }
        return politicalObject
      })
      setOrderLinePoliticalFields(politicalObject)
    }
  }, [orderLines, isPolitical])

  const contextValues = {
    setCampaignName,
    campaign_name,
    end_date,
    setCampaignEndDate,
    start_date,
    setCampaignStartDate,
    campaign,
    setCampaign,
    orderLines,
    setOrderLines,
    attachedAudiences,
    attachedCreatives,
    setAttachedCreatives,
    setAttachedAudiences,
    setRefresh,
    refresh,
    launchFreshCampaign,
    temporaryCreatives,
    setTemporaryCreatives,
    fetchDraftCampaign,
    handleCampaignCreation,
    NewhandleAttachAudiences,
    NewhandleAttachCreatives,
    handleRemoveAudienceFromCampaign,
    handleRemoveCreativeFromCampaign,
    newCartChange,
    setNewCartChange,
    isPolitical,
    setIsPolitical,
    handlePolitical,
    setSubmissionRemovals,
    submissionRemovals,
    orderLinePoliticalFields,
  }

  if (!children) {
    return <></>
  }
  return (
    <CampaignContext.Provider value={contextValues}>
      {children}
    </CampaignContext.Provider>
  )
}
