import { useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { useSearchParams } from 'react-router-dom'
import {
  Button,
  Fa,
  FilterValueType,
  FormItem,
  Modal,
  Table,
  TextHeader,
  TextInput,
  showErrorMessage,
} from '@eltoro-ui/components'
import { useCopy, usePagination, useTable } from '@eltoro-ui/hooks'
import {
  Targetjobservicev1Note,
  Targetjobservicev1NoteType,
} from 'next-gen-sdk'
import { dayjs } from 'Tools/dateUtils'
import { TablePagination } from 'Components/TablePagination'
import { useAppContext } from 'Contexts'
import {
  ErrorMessage,
  isValidCharLength,
  simplifyEnum,
  styleTailwind,
  validCheck,
  AUDIENCE_NOTE_LIMIT,
} from 'Helpers'
import classNames from 'classnames'

const StackBtn = styleTailwind('div', 'StackedButtonIcon group')
const DetailLabel = styleTailwind(
  'label',
  'flex gap-1 text-l items-start odd:bg-gray px-1 py-2',
)

const EditingRow = ({
  note,
  handleUpdateNote,
}: {
  note: Targetjobservicev1Note
  handleUpdateNote: (noteId: string, content: string) => Promise<void>
}) => {
  const [editing, setEditing] = useState(false)
  const [input, setInput] = useState('')
  const { isAdmin, isReadOnly } = useAppContext()
  const auth = useAuth()
  const userId = auth?.user?.profile?.sub

  return (
    <div className="flex flex-col">
      <div className="NotesModal__editing-row flex w-full items-center justify-between gap-2">
        {editing ? (
          <input
            className={classNames('TextInput px-2 outline-none', {
              'border-warning animate-glowwarning text-warning-500':
                input !== '' && input.length > AUDIENCE_NOTE_LIMIT,
            })}
            onChange={(e) => setInput(e.target.value)}
            value={input}
          />
        ) : (
          <p className="break-all">{note.content}</p>
        )}
        <div className="flex gap-2">
          {(isAdmin || note.userId === userId) && !isReadOnly && (
            <Button
              onClick={() => {
                if (editing) {
                  setEditing(false)
                  if (note.id) {
                    handleUpdateNote(note.id, input)
                      .then(() => setInput(''))
                      .catch(() =>
                        showErrorMessage(
                          'There was an error updating your note',
                          '',
                        ),
                      )
                  }
                } else {
                  setEditing(true)
                  setInput(note.content || '')
                }
              }}
              iconOnly={
                <span
                  data-tooltip="left"
                  aria-label={
                    input.length > AUDIENCE_NOTE_LIMIT
                      ? `Note is over the character limit of ${AUDIENCE_NOTE_LIMIT}`
                      : undefined
                  }
                >
                  <Fa icon={editing ? 'save' : 'pencil'} size={1} />
                </span>
              }
              disabled={
                editing && (!input || input.length > AUDIENCE_NOTE_LIMIT)
              }
            />
          )}
          {editing && (
            <Button
              onClick={() => {
                setEditing(false)
                setInput('')
              }}
              iconOnly={<Fa icon="xmark" size={1} />}
            />
          )}
        </div>
      </div>
      {editing && (
        <div
          className={classNames('flex w-full gap-2', {
            'text-warning-500': input.length > AUDIENCE_NOTE_LIMIT,
          })}
        >
          <p>Limit {AUDIENCE_NOTE_LIMIT} characters</p>
          <p className="font-semibold">
            Remaining: {AUDIENCE_NOTE_LIMIT - input.length}
          </p>
        </div>
      )}
    </div>
  )
}

const AddNoteInput = ({
  type,
  handleSaveNote,
}: {
  type: Targetjobservicev1NoteType
  handleSaveNote: (
    type: Targetjobservicev1NoteType,
    content: string,
  ) => Promise<void>
}) => {
  const [note, setNote] = useState('')
  return (
    <div className="NotesModal__add-note-input flex items-center justify-between gap-4">
      <FormItem
        wrapperClassname="flex-col"
        htmlFor="newNote"
        label="New Note"
        errorMessage={ErrorMessage({
          fieldName: note,
          max: AUDIENCE_NOTE_LIMIT,
          label: 'Note',
          isValid:
            validCheck(note) && isValidCharLength(note, AUDIENCE_NOTE_LIMIT),
        })}
        valid={validCheck(note) && isValidCharLength(note, AUDIENCE_NOTE_LIMIT)}
        counter={note.length > 0}
      >
        <TextInput
          maxLength={AUDIENCE_NOTE_LIMIT + 1}
          value={note}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setNote(e.target.value)
          }
          className={classNames('TextInput px-2 outline-none', {
            'border-warning animate-glowwarning text-warning-500':
              note !== '' && note.length > AUDIENCE_NOTE_LIMIT,
          })}
        />
      </FormItem>
      <div
        data-tooltip="left"
        aria-label={
          note.length > AUDIENCE_NOTE_LIMIT
            ? `Note is over the character limit of ${AUDIENCE_NOTE_LIMIT}`
            : undefined
        }
      >
        <Button
          onClick={() => handleSaveNote(type, note).then(() => setNote(''))}
          className="mb-3"
          disabled={!note || note.length > AUDIENCE_NOTE_LIMIT}
        >
          Add note
        </Button>
      </div>
    </div>
  )
}

const NoteView = ({
  currentNote,
  setCurrentNote,
  handleUpdateNote,
}: {
  currentNote: Targetjobservicev1Note
  setCurrentNote: React.Dispatch<
    React.SetStateAction<Targetjobservicev1Note | undefined>
  >
  handleUpdateNote: (noteId: string, content: string) => Promise<void>
}) => {
  const [editedNoteText, setEditedNoteText] = useState(currentNote?.content)
  const [editingCurrentNote, setEditingCurrentNote] = useState<boolean>()
  const [search, setSearch] = useSearchParams()

  useEffect(() => {
    if (currentNote?.content) setEditedNoteText(currentNote.content)
  }, [currentNote?.content])

  return (
    <div className="NoteModal__currently-viewed animate-slidedown">
      <div className="NoteModal__currently-viewed-header flex justify-between pb-2">
        <TextHeader type={4}>Note Details</TextHeader>
        <span data-tooltip="left" aria-label="Back to audience notes.">
          <Button
            iconOnly={<Fa icon="turn-down-left" size={1} />}
            onClick={() => {
              setCurrentNote(undefined)
              search.delete('note_id')
              setSearch(search)
            }}
          />
        </span>
      </div>

      <div className="flex flex-col gap-1">
        <DetailLabel>
          <strong>ID:</strong> {currentNote.id || 'Not found'}
        </DetailLabel>
        <div className="text-l odd:bg-gray flex items-start gap-1 px-1 py-2">
          <div className="flex w-full items-start gap-2">
            <strong>Note:</strong>
            {editingCurrentNote ? (
              <FormItem
                htmlFor="editedNoteText"
                className="animate-fadein w-full"
                errorMessage={ErrorMessage({
                  fieldName: editedNoteText || '',
                  max: AUDIENCE_NOTE_LIMIT,
                  label: 'Editing note',
                  isValid:
                    validCheck(editedNoteText || '') &&
                    isValidCharLength(
                      editedNoteText || '',
                      AUDIENCE_NOTE_LIMIT,
                    ),
                })}
                valid={
                  validCheck(editedNoteText || '') &&
                  isValidCharLength(editedNoteText || '', AUDIENCE_NOTE_LIMIT)
                }
                counter={(editedNoteText || '').length > 0}
              >
                <textarea
                  className={classNames(
                    'border-thin border-grey-300 w-full bg-transparent p-1 outline-0 focus:ring',
                    {
                      'border-warning animate-glowwarning text-warning-500':
                        editedNoteText &&
                        editedNoteText.length > AUDIENCE_NOTE_LIMIT,
                    },
                  )}
                  maxLength={AUDIENCE_NOTE_LIMIT + 1}
                  onChange={(e) => setEditedNoteText(e.target.value)}
                  value={editedNoteText}
                />
              </FormItem>
            ) : (
              <p>{currentNote.content || 'Not found'}</p>
            )}
          </div>
          {editingCurrentNote && (
            <Button
              iconOnly={<Fa icon="save" size={1} />}
              disabled={
                !editedNoteText || editedNoteText.length > AUDIENCE_NOTE_LIMIT
              }
              onClick={() => {
                if (!currentNote?.id || !editedNoteText) return
                handleUpdateNote(currentNote.id, editedNoteText).then(() => {
                  setEditingCurrentNote(false)
                  setCurrentNote((prev) => ({
                    ...prev,
                    content: editedNoteText,
                  }))
                })
              }}
            />
          )}
          <Button
            iconOnly={
              <Fa icon={editingCurrentNote ? 'xmark' : 'pencil'} size={1} />
            }
            onClick={() => setEditingCurrentNote(!editingCurrentNote)}
          />
        </div>
        <DetailLabel>
          <strong>Type:</strong> {currentNote.type || 'Not found'}
        </DetailLabel>
        <DetailLabel>
          <strong>User:</strong> {currentNote.userName || 'Not found'}
        </DetailLabel>
        <DetailLabel>
          <strong>User ID:</strong> {currentNote.id || 'Not found'}
        </DetailLabel>
        <DetailLabel>
          <strong>Created:</strong>
          {currentNote.createTime?.toISOString() || 'Not found'}
        </DetailLabel>
        <DetailLabel>
          <strong>Updated:</strong>
          {currentNote.createTime?.toISOString() || 'Not found'}
        </DetailLabel>
      </div>
    </div>
  )
}

export const NotesModal = ({
  offClick,
  notes,
  type,
  handleSaveNote,
  handleDeleteNote,
  handleUpdateNote,
  orgId,
  audienceName,
  currentNote,
  setCurrentNote,
}: {
  offClick: () => void
  notes: Targetjobservicev1Note[] | undefined
  type: Targetjobservicev1NoteType
  handleSaveNote: (
    type: Targetjobservicev1NoteType,
    content: string,
  ) => Promise<void>
  handleDeleteNote: (noteId: string) => Promise<void>
  handleUpdateNote: (noteId: string, content: string) => Promise<void>
  orgId?: string
  audienceName?: string
  currentNote?: Targetjobservicev1Note
  setCurrentNote: React.Dispatch<
    React.SetStateAction<Targetjobservicev1Note | undefined>
  >
}) => {
  const [search, setSearch] = useSearchParams()
  const [copyText, copied] = useCopy()
  const { filter, sort, setSort, addFilter, clearSort } = useTable()

  const sortNotes = (notes?: Targetjobservicev1Note[]) => {
    const newestFirst = notes?.sort(({ createTime: a }, { createTime: b }) => {
      if (!a || !b) return 0
      return Date.parse(b.toISOString()) - Date.parse(a.toISOString())
    })
    if (notes) {
      if (sort?.field === 'date') {
        if (sort.direction === 'desc') {
          return [...(newestFirst || [])].reverse()
        }
      }
      if (sort?.field === 'userName') {
        return notes?.sort(({ userName: a }, { userName: b }) => {
          if (!a || !b) return 0
          if (sort?.direction === 'asc') return a.localeCompare(b)
          return b.localeCompare(a)
        })
      }
    }

    // default return by newest -> oldest
    return newestFirst || []
  }

  const filterNotes = (notes?: Targetjobservicev1Note[]) => {
    let filtered = notes
    if (filter?.content && filter.content.length) {
      filtered = filtered?.filter(
        (n) =>
          !!filter.content.find((val) =>
            n.content?.toLowerCase().includes(val.toString().toLowerCase()),
          ),
      )
    }
    if (filter?.userName && filter.userName.length) {
      filtered = filtered?.filter(
        (n) =>
          !!filter.userName.find((val) =>
            n.userName?.toLowerCase().includes(val.toString().toLowerCase()),
          ),
      )
    }
    return filtered
  }

  const paginationProps = usePagination<
    Targetjobservicev1Note & { tableRowId?: number }
  >(
    {
      pageSize: 10,
      sourceData: sortNotes(filterNotes(notes)),
    },
    [notes, sort, filter],
  )
  const { data: pagedNotes } = paginationProps

  // Eventually this will be handled by the useTable hook when the table is refactored and all the table state lives in that hook
  const handleSort = (
    _: number,
    direction?: 'asc' | 'desc',
    field?: string | string[],
  ) => {
    if (direction && field) {
      setSort(field, direction)
    } else {
      clearSort()
    }
  }

  const handleFilter = (
    _: number,
    path: string | string[],
    filterOn: FilterValueType,
  ) => {
    const key = typeof path === 'object' ? path.join('.') : path
    addFilter(key, filterOn)
  }

  return (
    <Modal
      offClick={() => {
        offClick()
        setCurrentNote(undefined)
        search.delete('note_id')
        setSearch(search)
      }}
      header={
        <TextHeader className="py-1">
          View {simplifyEnum(2, type)} notes{' '}
          <span className="font-light">
            {audienceName ? ` - ${audienceName}` : null}
          </span>
        </TextHeader>
      }
      className="m-2 w-full"
    >
      {currentNote ? (
        <NoteView
          currentNote={currentNote}
          handleUpdateNote={handleDeleteNote}
          setCurrentNote={setCurrentNote}
        />
      ) : (
        <>
          <AddNoteInput type={type} handleSaveNote={handleSaveNote} />
          {pagedNotes && (
            <div>
              <Table
                rows={pagedNotes}
                columns={[
                  {
                    path: 'date',
                    label: 'Date',
                    removeFilter: true,
                    RowCell: (row) => {
                      if (!row.createTime) return null
                      return dayjs
                        .utc(row.createTime.toISOString().substring(0, 23))
                        .tz()
                        .format('MM/DD/YY h:mm A')
                    },
                  },
                  {
                    path: 'userName',
                    label: 'User',
                    filterOn: 'string',
                    RowCell: (row) => (
                      <span className="break-normal">{row.userName}</span>
                    ),
                  },
                  {
                    path: 'content',
                    label: 'Note',
                    filterOn: 'string',
                    removeSort: true,
                    RowCell: (note) => (
                      <EditingRow
                        note={note}
                        handleUpdateNote={handleUpdateNote}
                      />
                    ),
                  },
                  {
                    path: 'type',
                    label: 'Type',
                    removeSort: true,
                    removeFilter: true,
                    RowCell: (row) =>
                      row.type ? simplifyEnum(2, row.type, true) : null,
                  },
                  {
                    path: '',
                    label: 'Options',
                    removeSort: true,
                    removeFilter: true,
                    RowCell: (row) => {
                      return (
                        <div className="flex justify-end gap-2 p-4">
                          <StackBtn
                            className="group-hover:text-primary"
                            data-tooltip="left"
                            aria-label="Delete the note from this audience."
                          >
                            <Button
                              iconCenter={
                                <Fa
                                  icon="trash-xmark"
                                  size={1}
                                  className="StackedButtonIcon__icon"
                                />
                              }
                              onClick={() => row.id && handleDeleteNote(row.id)}
                            >
                              <span className="IconLabel text-s">Remove</span>
                            </Button>
                          </StackBtn>

                          <StackBtn
                            className="group-hover:text-primary"
                            data-tooltip="left"
                            aria-label="View audience details."
                          >
                            <Button
                              iconCenter={
                                <Fa
                                  icon="eye"
                                  size={1}
                                  className="StackedButtonIcon__icon"
                                />
                              }
                              onClick={() => {
                                if (!row.id) return
                                search.delete('note_id')
                                search.append('note_id', row.id)
                                setSearch(search)
                                setCurrentNote(row)
                              }}
                            >
                              <span className="IconLabel text-s">View</span>
                            </Button>
                          </StackBtn>
                          {row.audienceId && orgId && row.id && (
                            <StackBtn
                              className="group-hover:text-primary"
                              data-tooltip="left"
                              aria-label={copied ? 'Copied' : `Copy permalink`}
                            >
                              <Button
                                iconCenter={
                                  <Fa
                                    icon="link"
                                    size={1}
                                    className="StackedButtonIcon__icon"
                                  />
                                }
                                onClick={() =>
                                  copyText(
                                    `${window.location.origin}/audiences/audience-library/${row.audienceId}?org_id=${orgId}&note_id=${row.id}`,
                                  )
                                }
                              >
                                <span className="IconLabel text-s">
                                  Copy Link
                                </span>
                              </Button>
                            </StackBtn>
                          )}
                        </div>
                      )
                    },
                  },
                ]}
                onSort={handleSort}
                onFilter={handleFilter}
              />
              <TablePagination pagination={paginationProps} />
            </div>
          )}
        </>
      )}
    </Modal>
  )
}
