import { showErrorMessage } from '@eltoro-ui/components'
import { ApiFactory } from 'apiFactory'
import { Hagridv1Subscription, V1Pixel, V1RuleSet } from 'next-gen-sdk'

export const createSubscription = async (
  orgId: string,
  orgName: string,
  pixel: V1Pixel,
  incomingSubscription: Partial<Hagridv1Subscription>,
  incomingRuleSet?: Partial<V1RuleSet>,
) => {
  const token = localStorage.getItem('eltoro_token')
  const apis = ApiFactory(token)
  const { webToHomeAPI } = apis
  if (!webToHomeAPI || !pixel?.id) return

  let cleanedRuleSet = incomingRuleSet

  if (incomingRuleSet) {
    const cleanRuleSet = (ruleSet: Partial<V1RuleSet>): Partial<V1RuleSet> => {
      return {
        ...ruleSet,
        rules: ruleSet.rules?.filter((rule) => rule.term?.trim() !== ''),
        ruleSet: ruleSet.ruleSet?.map(cleanRuleSet),
      }
    }

    cleanedRuleSet = cleanRuleSet(incomingRuleSet)
  }
  try {
    // create subscription
    const subscription = await webToHomeAPI.advertisingPlatformServiceCreateSubscription(
      pixel?.id,
      {
        orgId,
        orgName,
        ...incomingSubscription,
        pixelId: pixel?.id,
        ruleSet: cleanedRuleSet,
      },
      orgId,
    )

    return { pixel, subscription }
  } catch (error) {
    // @ts-ignore
    showErrorMessage('Error creating pixel', error?.message || '')
  }
}
