import { useState } from 'react'
import {
  TextInput,
  Fa,
  Button,
  showErrorMessage,
  Pagination,
} from '@eltoro-ui/components'
import { useDebounce, useTokenPagination } from '@eltoro-ui/hooks'
import classNames from 'classnames'
import { Campaignservicev1Campaign } from 'next-gen-sdk'
import { useAppContext } from 'Contexts'
import './CampaignSelect.scss'

export const CampaignSelect = ({
  onCampaignSelect,
  currentCampaignId,
  isPrepay,
}: {
  onCampaignSelect: (CampId: string) => void
  currentCampaignId?: string
  isPrepay?: boolean
}) => {
  const [searchText, setSearchText] = useState('')
  const debouncedSearch = useDebounce(searchText, 200)

  const { currentOrg, campaignServiceApi } = useAppContext()

  const handleFetchOrgs = async ({
    pageSize,
    nextPageToken,
  }: {
    pageSize: number
    nextPageToken?: string
  }) => {
    const empty = {
      data: [],
      totalItems: 0,
    }
    if (!campaignServiceApi) return empty

    const res = await campaignServiceApi
      ?.advertisingPlatformServiceListCampaigns(
        pageSize, // pageSize
        nextPageToken, // pageToken
        'create_time desc', // orderBy
        `org_id = ${currentOrg?.id || '*'} AND name = "*${debouncedSearch}*"`, // filter
      )
      .catch(() => {
        showErrorMessage('Error fetching campaigns', '')
        return undefined
      })

    // put filter lopgic for ols here
    let filterCampaignsArr: Campaignservicev1Campaign[] = []

    const filteredCampaigns = async () => {
      for (const campaign of res?.campaigns || []) {
        const campaignToInclude = await Promise.all(
          campaign?.orderLines?.map(async (ol) => {
            const res = await campaignServiceApi.advertisingPlatformServiceGetOrderLine(
              ol?.id!,
              currentOrg?.id!,
            )
            return res.prepay === isPrepay
          }) || [],
        )
        if (!campaignToInclude.includes(false)) {
          filterCampaignsArr.push(campaign)
        }
      }
      return filterCampaignsArr
    }

    await filteredCampaigns()

    if (res && filterCampaignsArr) {
      return {
        data: filterCampaignsArr || [],
        totalItems: res.totalSize || 0,
        nextPageToken: res.nextPageToken,
      }
    }
    return empty
  }

  const pagination = useTokenPagination<Campaignservicev1Campaign>(
    {
      pageSize: 10,
      fetchData: handleFetchOrgs,
    },
    [debouncedSearch, campaignServiceApi],
  )

  const { currentPageData: campaigns, loading, setPage } = pagination

  return (
    <div className="CampaignSelect">
      <div className="CampaignSelect__search flex items-center px-2">
        <TextInput
          iconLeft={<Fa icon="search" size={1} />}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        {searchText && (
          <>
            <Button
              iconOnly={<Fa icon="times" size={1} />}
              onClick={() => {
                setSearchText('')
              }}
              kind="text"
              size="s"
            />
          </>
        )}
      </div>
      <div
        className={classNames(
          'CampaignSelect__camp-list relative flex flex-col overflow-x-hidden pt-2',
          { 'pointer-events-none': loading },
        )}
      >
        {loading && (
          <div className="CampaignSelect__loading absolute top-0 bottom-0 right-0 left-0 z-40 overflow-clip p-2">
            <Fa
              className="text-tint-khaki"
              icon="spinner"
              size={2}
              animate="spin"
            />
          </div>
        )}
        {campaigns.map((camp, index) => (
          <button
            type="button"
            onClick={() => {
              if (camp.id) onCampaignSelect(camp.id)
            }}
            className="CampaignSelect__camp-button flex-1 focus:ring-2"
            key={`${camp.id}_${index}`}
          >
            <span className="CampaignSelect__campaign-button-name text-s text-tint-gray-700 inline-block truncate">
              {currentCampaignId === camp.id ? (
                <strong>{camp.name}</strong>
              ) : (
                camp.name
              )}
            </span>
          </button>
        ))}
        {campaigns.length > 0 && (
          <Pagination
            {...pagination}
            onChange={(pageInfo) => {
              setPage(pageInfo.page)
            }}
          />
        )}
      </div>
    </div>
  )
}
