/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { DeployMetadataDeployedCPM } from '../models/DeployMetadataDeployedCPM';
import { GoogletypeMoney } from '../models/GoogletypeMoney';
import { V1CPMOverride } from '../models/V1CPMOverride';
import { HttpFile } from '../http/http';

export class V1DeployMetadata {
    'firstDeployTime'?: Date;
    'lastDeployTime'?: Date;
    'firstDeployUserId'?: string;
    'lastDeployUserId'?: string;
    'deployedCpm'?: DeployMetadataDeployedCPM;
    'cpm'?: GoogletypeMoney;
    'cpmOverride'?: V1CPMOverride;
    'audienceUpcharges'?: GoogletypeMoney;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "firstDeployTime",
            "baseName": "first_deploy_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "lastDeployTime",
            "baseName": "last_deploy_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "firstDeployUserId",
            "baseName": "first_deploy_user_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "lastDeployUserId",
            "baseName": "last_deploy_user_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "deployedCpm",
            "baseName": "deployed_cpm",
            "type": "DeployMetadataDeployedCPM",
            "format": ""
        },
        {
            "name": "cpm",
            "baseName": "cpm",
            "type": "GoogletypeMoney",
            "format": ""
        },
        {
            "name": "cpmOverride",
            "baseName": "cpm_override",
            "type": "V1CPMOverride",
            "format": ""
        },
        {
            "name": "audienceUpcharges",
            "baseName": "audience_upcharges",
            "type": "GoogletypeMoney",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1DeployMetadata.attributeTypeMap;
    }

    public constructor() {
    }
}

