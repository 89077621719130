import React, { Ref, MutableRefObject } from 'react'
import { Fa, TextInput } from '@eltoro-ui/components'
import classNames from 'classnames'
import { InfoPopover } from 'Components'

export const TextInputWithErrorText = ({
  className = '',
  classNameWrap = '',
  value,
  onChange,
  isValid,
  popoverContent,
  iconR,
  id,
  placeholder,
  maxLength = 1000,
  minLength = 8,
  baseValues = [''],
  icon,
  onFocus,
  onBlur,
  inputRef,
  textInputWrapperRef,
  errorMsg = true,
}: {
  className?: string
  classNameWrap?: string
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  isValid: boolean
  // popoverContent - the content shown when the user clicks the error icon.
  popoverContent: React.ReactNode
  id?: string
  placeholder?: string
  // baseValues - an array of strings that you do not want to show the validity icon for.
  // For example: if the input is empty (''), or if the input is pre-populated with a particular value
  // (like 'https://' for URL validation). These values represent the 'empty state' of the input.
  maxLength?: number
  minLength?: number
  baseValues?: string[]
  icon?: JSX.Element
  iconR?: JSX.Element
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  inputRef?: Ref<HTMLInputElement> | MutableRefObject<HTMLInputElement>
  textInputWrapperRef?: Ref<HTMLDivElement> | MutableRefObject<HTMLDivElement>
  errorMsg?: boolean
}) => {
  const lengthLimit = value.length > maxLength

  return (
    <>
      <TextInput
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        classNameWrap={`TextInputWithError-input-wrap flex flex-col ${classNameWrap}`}
        className={classNames(
          'TextInputWithErrorText',
          {
            'TextInputWithErrorText--clear-icon':
              [...baseValues, ''].includes(value) && !iconR,
          },
          className,
        )}
        iconLeft={icon}
        iconRight={(() => {
          if (iconR) {
            return (
              <div className="flex flex-row">
                <div>{iconR}</div>
                {isValid && !lengthLimit && value ? (
                  <div>
                    <Fa icon="check" size={1} />
                  </div>
                ) : (
                  <div>
                    <InfoPopover
                      icon="exclamation-triangle"
                      content={popoverContent}
                    />
                  </div>
                )}
              </div>
            )
          } else {
            if (isValid && !lengthLimit) return <Fa icon="check" size={1} />
            return (
              <InfoPopover
                icon="exclamation-triangle"
                content={popoverContent}
              />
            )
          }
        })()}
        maxLength={maxLength}
        minLength={minLength}
        onBlur={onBlur}
        onFocus={onFocus}
        inputRef={inputRef}
        wrapperRef={textInputWrapperRef}
        valid={!isValid && lengthLimit}
      />
      {lengthLimit && errorMsg && (
        <div className="TextInputWithError-input-length-limit text-warning animate-slidedown text-m py-1 font-bold">
          {id === 'clickthru' ? 'Click-through' : 'Text'} must be under&nbsp;
          {maxLength} characters
        </div>
      )}
    </>
  )
}
