import React from 'react'
import { Button, Fa, Text } from '@eltoro-ui/components'
import { Link, useLocation } from 'react-router-dom'
import {
  getTargetIconET,
  userFriendlyAudienceName,
  styleTailwind,
} from 'Helpers'
import { Notification } from 'Components'
import { ETIcon } from 'Assets'

const SuccessGraf = styleTailwind(Text, 'SuccessGraf break-word pb-2')

const URLAudienceLibrary = (
  <Link to="/audiences/audience-library"> Audience Library</Link>
)

const UploadSuccessMessage = ({
  jobName,
  files,
  addText,
  audienceLibGraf = false,
}: {
  jobName: string
  files: string[]
  addText?: string | JSX.Element
  audienceLibGraf?: boolean
}) => {
  return (
    <div
      className={`UploadSuccessMessage flex-col flex-wrap items-start justify-start gap-4 ${jobName}`}
    >
      <SuccessGraf className="-mt-1 text-xl" tag="h4">
        You&apos;ve set up a new &#39;{jobName}&#39; audience!
      </SuccessGraf>
      <SuccessGraf
        className="UploadSuccessMessage--body-append-text text-l font-normal"
        tag="div"
      >
        {(() => {
          if (files.length === 1) {
            return (
              <>
                Your &#39;{jobName}&#39; audience{files[0] ? ', ' : ''}
                <strong>{files[0]}</strong>
                {files[0] ? ',' : ''} has been submitted
                {addText ? ` ${addText}` : '.'}
                {!!addText && !audienceLibGraf && URLAudienceLibrary}
                {!!addText && !audienceLibGraf && '.'}
              </>
            )
          }
          if (files.length > 1) {
            return (
              <>
                Your &#39;{jobName}&#39; audiences have been submitted
                {addText ? ` ${addText}` : '.'}
                {!!addText && !audienceLibGraf && URLAudienceLibrary}
                {!!addText && !audienceLibGraf && '.'}
                <ul className="list-outside list-disc">
                  {files.map((file) => (
                    <li key={file}>
                      <strong>{file}</strong>
                    </li>
                  ))}
                </ul>
              </>
            )
          }
        })()}
      </SuccessGraf>
      {!!audienceLibGraf && (
        <SuccessGraf
          className="UploadSuccessMessage--body-audience-liblink text-l font-normal"
          tag="p"
        >
          You may see the results in your {URLAudienceLibrary}. Please allow a
          few minutes to finalize.
        </SuccessGraf>
      )}
    </div>
  )
}

export const UploadSuccess = ({
  jobType,
  fileNames,
  onUseInCampaign,
  onUploadAnother,
}: {
  jobType: string
  fileNames: string[]
  onUseInCampaign?: () => void
  onUploadAnother: () => void
}) => {
  const { pathname } = useLocation()
  const isInCampaignCreation =
    pathname.includes('/campaigns/create') ||
    pathname.includes('/campaigns/edit')

  return (
    <div className="UploadSuccess">
      <Notification
        header="Success!"
        layOut="row"
        icon={jobType !== 'map-polygon' && jobType !== 'venue-replay'}
        iconType={getTargetIconET(jobType)}
        comp={
          <div className="flex flex-col items-center gap-2">
            <h3 className="text-tint-gray-700 text-l -mb-5">
              {userFriendlyAudienceName(jobType)}
            </h3>
            <ETIcon className="ETIcon--full" icon="locator" />
            <Fa
              className={`text-grey-300 w-4/6 rotate-90 ${
                jobType === `venue-replay` ? '' : 'ml-2'
              }`}
              icon="arrow-right"
              size={jobType !== 'venue-replay' ? 3 : 4}
            />
            {jobType !== 'venue-replay' && (
              <div className="flex w-1/2 flex-col gap-4">
                <h3 className="text-grey-600 text-center">IP Addresses</h3>
                <div className="items-start text-center">
                  <ETIcon className="ETIcon--full" icon="ip-targeting" />
                </div>
              </div>
            )}
            {jobType === 'venue-replay' && (
              <div className="results flex flex-col items-start gap-2 self-end text-center">
                <h3 className="text-tint-gray-500 w-full font-semibold">
                  Devices &amp; Households
                </h3>
                <div className="flex items-center gap-8">
                  <ETIcon className="ETIcon--full" icon="mobile" />
                  <ETIcon className="ETIcon--full" icon="ip-targeting" />
                </div>
              </div>
            )}
          </div>
        }
      >
        {(() => {
          if (jobType === 'address') {
            return (
              <UploadSuccessMessage
                jobName="Address List"
                files={fileNames}
                addText="and added to your"
              />
            )
          }
          if (jobType === 'b2b') {
            return (
              <UploadSuccessMessage
                jobName="B2B: IP Targeting"
                files={fileNames}
                audienceLibGraf
              />
            )
          }
          if (jobType === 'b2c') {
            return (
              <UploadSuccessMessage
                jobName="B2C: IP Targeting"
                files={fileNames}
                addText="and added to your"
              />
            )
          }
          if (jobType === 'dc') {
            return (
              <UploadSuccessMessage
                jobName="Digital Canvassing"
                files={fileNames}
                addText="and added to your"
              />
            )
          }
          if (jobType === 'device') {
            return (
              <UploadSuccessMessage
                jobName="Mobile Ad ID"
                files={fileNames}
                addText="and added to your"
              />
            )
          }
          if (jobType === 'ip') {
            return (
              <UploadSuccessMessage
                jobName="IP Address List"
                files={fileNames}
                audienceLibGraf
              />
            )
          }
          if (jobType === 'map-polygon') {
            return (
              <UploadSuccessMessage
                jobName="Map Polygon"
                files={fileNames}
                addText="and can be used for targeting!"
                audienceLibGraf
              />
            )
          }
          if (
            jobType === 'new-mover' ||
            jobType === 'pre' ||
            jobType === 'escrow' ||
            jobType === 'post'
          ) {
            return (
              <UploadSuccessMessage
                jobName="New Mover"
                files={fileNames}
                addText="and we're working hard to find the best matches for you!"
                audienceLibGraf
              />
            )
          }
          if (jobType === 'retargeting') {
            return (
              <UploadSuccessMessage
                jobName="Retargeting"
                files={fileNames}
                audienceLibGraf
              />
            )
          }
          if (jobType === 'reverseIp' || jobType === 'reverse') {
            return (
              <UploadSuccessMessage
                jobName="Reverse IP"
                files={fileNames}
                addText="and added to your"
              />
            )
          }
          if (jobType === 'venue-replay') {
            return (
              <UploadSuccessMessage
                jobName="Venue Replay"
                files={fileNames}
                addText="and we're working hard to find the best matches for you!"
                audienceLibGraf
              />
            )
          }
          if (jobType === 'zip') {
            return (
              <UploadSuccessMessage
                jobName="Zip Code List"
                files={fileNames}
                addText="and added to your"
              />
            )
          }

          // return a generic message even if jobtype is not known
          return (
            <UploadSuccessMessage
              jobName={jobType || 'Target'}
              files={fileNames}
              audienceLibGraf
            />
          )
        })()}

        <div className="Notification__buttons mt-2 gap-4">
          {!isInCampaignCreation && onUseInCampaign && (
            <Button
              size="l"
              rounded={false}
              kind="default-grey"
              onClick={onUseInCampaign}
            >
              Use in New Campaign
            </Button>
          )}
          <Button
            size="l"
            rounded={false}
            kind="primary"
            onClick={onUploadAnother}
          >
            {jobType === 'new-mover' ||
            jobType === 'venue-replay' ||
            jobType === 'map-polygon'
              ? 'Create Another'
              : 'Upload Another'}
          </Button>
        </div>
      </Notification>
    </div>
  )
}
