/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Campaignservicev1AdType } from '../models/Campaignservicev1AdType';
import { Campaignservicev1Audience } from '../models/Campaignservicev1Audience';
import { Campaignservicev1Creative } from '../models/Campaignservicev1Creative';
import { GoogletypeMoney } from '../models/GoogletypeMoney';
import { V1CPMOverride } from '../models/V1CPMOverride';
import { V1CostRange } from '../models/V1CostRange';
import { V1DataSourcesHighestUpcharge } from '../models/V1DataSourcesHighestUpcharge';
import { V1OrderLineCampaign } from '../models/V1OrderLineCampaign';
import { V1OrderLineStatus } from '../models/V1OrderLineStatus';
import { V1PoliticalFields } from '../models/V1PoliticalFields';
import { V1State } from '../models/V1State';
import { V1TemplateType } from '../models/V1TemplateType';
import { V3SegmentConfig } from '../models/V3SegmentConfig';
import { HttpFile } from '../http/http';

/**
* The event emitted when an OrderLine is duplicated
*/
export class V1OrderLineCloned {
    'id'?: string;
    'refId'?: string;
    'originalOrderlineId'?: string;
    'orgId'?: string;
    'name'?: string;
    'startDate'?: Date;
    'endDate'?: Date;
    'campaignId'?: string;
    'clickThroughUrl'?: string;
    'impressions'?: number;
    /**
    * The amount of impressions the Order Line will serve per day.
    */
    'impressionsPerDay'?: number;
    /**
    * The amount of impressions adops is giving to the orderline for free.
    */
    'freeImpressions'?: number;
    'status'?: V1OrderLineStatus;
    'userId'?: string;
    'timestamp'?: Date;
    'requestId'?: string;
    'prepay'?: boolean;
    'poitical'?: boolean;
    'updated'?: Date;
    /**
    * The minimum impressions of the OrderLine.
    */
    'minimumImpressions'?: number;
    'politicalFields'?: V1PoliticalFields;
    'locked'?: boolean;
    'campaign'?: V1OrderLineCampaign;
    'creatives'?: Array<Campaignservicev1Creative>;
    'audiences'?: Array<Campaignservicev1Audience>;
    'costRange'?: V1CostRange;
    'adType'?: Campaignservicev1AdType;
    'state'?: V1State;
    'segmentConfig'?: V3SegmentConfig;
    'templateType'?: V1TemplateType;
    'cpms'?: { [key: string]: GoogletypeMoney; };
    'cpmOverride'?: V1CPMOverride;
    'cpm'?: GoogletypeMoney;
    'audienceUpcharges'?: GoogletypeMoney;
    'dataSourcesHighestUpcharge'?: Array<V1DataSourcesHighestUpcharge>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "refId",
            "baseName": "ref_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "originalOrderlineId",
            "baseName": "original_orderline_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "startDate",
            "baseName": "start_date",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "endDate",
            "baseName": "end_date",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "campaignId",
            "baseName": "campaign_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "clickThroughUrl",
            "baseName": "click_through_url",
            "type": "string",
            "format": ""
        },
        {
            "name": "impressions",
            "baseName": "impressions",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "impressionsPerDay",
            "baseName": "impressions_per_day",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "freeImpressions",
            "baseName": "free_impressions",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "V1OrderLineStatus",
            "format": ""
        },
        {
            "name": "userId",
            "baseName": "user_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "timestamp",
            "baseName": "timestamp",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "requestId",
            "baseName": "request_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "prepay",
            "baseName": "prepay",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "poitical",
            "baseName": "poitical",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "updated",
            "baseName": "updated",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "minimumImpressions",
            "baseName": "minimum_impressions",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "politicalFields",
            "baseName": "political_fields",
            "type": "V1PoliticalFields",
            "format": ""
        },
        {
            "name": "locked",
            "baseName": "locked",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "campaign",
            "baseName": "campaign",
            "type": "V1OrderLineCampaign",
            "format": ""
        },
        {
            "name": "creatives",
            "baseName": "creatives",
            "type": "Array<Campaignservicev1Creative>",
            "format": ""
        },
        {
            "name": "audiences",
            "baseName": "audiences",
            "type": "Array<Campaignservicev1Audience>",
            "format": ""
        },
        {
            "name": "costRange",
            "baseName": "cost_range",
            "type": "V1CostRange",
            "format": ""
        },
        {
            "name": "adType",
            "baseName": "ad_type",
            "type": "Campaignservicev1AdType",
            "format": ""
        },
        {
            "name": "state",
            "baseName": "state",
            "type": "V1State",
            "format": ""
        },
        {
            "name": "segmentConfig",
            "baseName": "segment_config",
            "type": "V3SegmentConfig",
            "format": ""
        },
        {
            "name": "templateType",
            "baseName": "template_type",
            "type": "V1TemplateType",
            "format": ""
        },
        {
            "name": "cpms",
            "baseName": "cpms",
            "type": "{ [key: string]: GoogletypeMoney; }",
            "format": ""
        },
        {
            "name": "cpmOverride",
            "baseName": "cpm_override",
            "type": "V1CPMOverride",
            "format": ""
        },
        {
            "name": "cpm",
            "baseName": "cpm",
            "type": "GoogletypeMoney",
            "format": ""
        },
        {
            "name": "audienceUpcharges",
            "baseName": "audience_upcharges",
            "type": "GoogletypeMoney",
            "format": ""
        },
        {
            "name": "dataSourcesHighestUpcharge",
            "baseName": "data_sources_highest_upcharge",
            "type": "Array<V1DataSourcesHighestUpcharge>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1OrderLineCloned.attributeTypeMap;
    }

    public constructor() {
    }
}

