import { Fa, Modal, Text } from '@eltoro-ui/components'

const SelectButton = ({
  title,
  subtitle,
  onClick,
}: {
  title: string
  subtitle: string
  onClick?: () => void
}) => (
  <button
    onClick={onClick}
    className="CreatePixelOrSubscriptionModal__select-button hover:bg-tint-khaki-200 flex items-center gap-2 rounded p-4"
  >
    <Fa icon="circle-plus" size={3} type="duotone" />
    <div className="flex flex-col items-start gap-2">
      <Text size="l">{title}</Text>
      <Text on="white" kind="subdued">
        {subtitle}
      </Text>
    </div>
  </button>
)

export const CreatePixelOrSubscriptionModal = ({
  offClick,
  setModalView,
}: {
  offClick: () => void
  setModalView: React.Dispatch<
    React.SetStateAction<'pixel' | 'subscription' | 'create' | undefined>
  >
}) => {
  return (
    <Modal
      header="Create New"
      offClick={offClick}
      className="CreatePixelOrSubscriptionModal"
      classNameContent="flex flex-col gap-2"
    >
      <SelectButton
        title="New Pixel"
        subtitle="Create a new tracking pixel"
        onClick={() => setModalView('pixel')}
      />
      <SelectButton
        title="New Subscription"
        subtitle="Create a new pixel subscription"
        onClick={() => setModalView('subscription')}
      />
    </Modal>
  )
}
