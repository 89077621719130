import { useRef } from 'react'
import { Fa } from '@eltoro-ui/components'
import ReactSelect from 'react-select/creatable'
import {
  ClearIndicatorProps,
  components,
  DropdownIndicatorProps,
  InputProps,
  StylesConfig,
} from 'react-select'
import classNames from 'classnames'
import { AUDIENCE_TAG_LIMIT } from 'Helpers'
import './CustomAudienceTagDropdown.scss'

const getHighlightedText = (text: string, highlight: string) => {
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
  return (
    <span>
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === highlight.toLowerCase()
              ? { fontWeight: 'bold', backgroundColor: 'var(--tint-khaki-100)' }
              : {}
          }
        >
          {part}
        </span>
      ))}
    </span>
  )
}

export const CustomAudienceTagDropdown = ({
  value,
  onChange,
  disabled,
}: {
  value: string
  onChange: (value: string) => void
  disabled?: boolean
}) => {
  const ref = useRef(null)

  return (
    <div className="CustomAudienceTagDropdown relative w-full">
      <ReactSelect
        ref={ref}
        components={{
          DropdownIndicator: (props: DropdownIndicatorProps) => (
            <components.DropdownIndicator {...props}>
              <Fa icon="chevron-down" size={1} />
            </components.DropdownIndicator>
          ),
          ClearIndicator: (props: ClearIndicatorProps) => (
            <components.ClearIndicator {...props}>
              <Fa icon="xmark" size={1} />
            </components.ClearIndicator>
          ),
          Option: (props: any) => {
            return (
              <>
                <components.Option {...props}>
                  {props.data?.value
                    ? getHighlightedText(
                        props.children,
                        props.selectProps?.inputValue,
                      )
                    : props.children}
                  {value === props.data.value && <Fa icon="check" size={1} />}
                </components.Option>
                {props.data?.__isNew__ && props.data?.value?.length && (
                  <div
                    className={classNames(
                      'flex justify-between pt-1 text-right text-xs',
                      {
                        'text-warning-500':
                          props.data.value.length >= AUDIENCE_TAG_LIMIT,
                      },
                    )}
                  >
                    <p>Limit {AUDIENCE_TAG_LIMIT} characters</p>
                    <p className="font-semibold">
                      Remaining: {AUDIENCE_TAG_LIMIT - props.data.value.length}
                    </p>
                  </div>
                )}
              </>
            )
          },
          Input: (props: InputProps) => {
            return (
              <components.Input {...props} maxLength={AUDIENCE_TAG_LIMIT + 1} />
            )
          },
        }}
        styles={
          {
            control: (provided, props) => {
              return {
                ...provided,
                backgroundColor: 'var(--bg-base)',
                color: 'var(--base-text)',
                cursor: 'text',
                borderRadius: 'var(--border-radius-m)',
                border: 'var(--border-width-thin) solid var(--tint-gray-500)',
                '&:hover': {
                  borderColor: 'var(--color-primary)',
                },
                borderColor: props.isFocused
                  ? 'var(--color-primary)'
                  : 'var(--tint-gray-500)',
                boxShadow: props.isFocused
                  ? '0 0 0 1px var(--color-primary)'
                  : provided.boxShadow,
              }
            },
            input: (provided) => ({
              ...provided,
              color: 'var(--base-text)',
            }),
            placeholder: (baseStyles) => ({
              ...baseStyles,
              color: 'var(--base-text)',
              fontSize: 'var(--text-size-m)',
              fontWeight: 'bold',
            }),
            singleValue: (baseStyles) => ({
              ...baseStyles,
              color: 'var(--tint-gray-800)',
              fontSize: 'var(--text-size-m)',
              fontWeight: 'bold',
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              display: 'none',
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              paddingLeft: '0px',
              color: 'var(--base-text)',
            }),
            clearIndicator: (provided) => ({
              ...provided,
              paddingRight: '0px',
              color: 'var(--base-text)',
            }),
            menu: (provided) => ({
              ...provided,
              color: 'var(--tint-gray-700)',
              backgroundColor: 'var(--base-bg)',
              borderRadius: 'var(--border-radius-m)',
              padding: '0 0.25rem',
              border: 'var(--border-width-thin) solid var(--color-grey-700)',
            }),
            option: (provided, { isFocused, isSelected }) => ({
              ...provided,
              color: `var(--tint-gray-${isSelected ? '900' : '700'})`,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0.25rem',
              cursor: 'pointer',
              borderRadius: 'var(--border-radius-s)',
              wordBreak: 'break-all',
              backgroundColor:
                isSelected || isFocused
                  ? 'var(--color-grey-050)'
                  : 'var(--base-bg)',
              '&:hover': {
                backgroundColor: 'var(--color-grey-050)',
                color: 'var(--tint-gray-900)',
              },
            }),
          } as StylesConfig<{ label: string; value: string }, false>
        }
        className="CustomAudienceTagSelect"
        classNamePrefix="CustomAudienceTagSelect"
        value={!value ? null : { value: value, label: value }}
        onChange={(newTag: { value: string }) => {
          onChange(newTag?.value.slice(0, AUDIENCE_TAG_LIMIT))
        }}
        isClearable
        isSearchable
        options={[
          'IP+',
          'L2',
          'Aristotle',
          'KnowWho',
          'Intent-to-Home',
          'Venue Replay+',
          'CoreLogic',
        ].map((tag) => ({
          label: tag,
          value: tag,
        }))}
        placeholder="Select / Add Audience Tag"
        isDisabled={disabled}
        // preventing the border to stay in focus style
        onMenuClose={() => (ref.current as any)?.blur()}
      />
    </div>
  )
}
