/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1PixelNote } from '../models/V1PixelNote';
import { HttpFile } from '../http/http';

export class V1Pixel {
    /**
    * Id of the pixel
    */
    'id'?: string;
    /**
    * RefId of the pixel
    */
    'refId'?: string;
    /**
    * Name of the pixel
    */
    'name'?: string;
    /**
    * The Pixels Image
    */
    'image'?: string;
    /**
    * The Pixels HTML
    */
    'html'?: string;
    /**
    * The Pixels OrgID
    */
    'orgId'?: string;
    /**
    * The time the Pixel was created.
    */
    'created'?: Date;
    /**
    * The last time the Pixel was updated.
    */
    'updated'?: Date;
    /**
    * a list of strings to tag the pixel
    */
    'tags'?: Array<string>;
    /**
    * The Pixels Top Level URL
    */
    'topLevelUrl'?: string;
    /**
    * Whether this pixel is associated with a sensitive industry (e.g., healthcare, finance, gambling, etc..). This will enable additional compliance checks.
    */
    'sensitive'?: boolean;
    /**
    * The Pixels Status
    */
    'status'?: string;
    /**
    * a list of notes associated with the pixel. 
    */
    'notes'?: Array<V1PixelNote>;
    /**
    * Whether this pixel is locked. This can only be set or updated by admin.
    */
    'locked'?: boolean;
    /**
    * Whether this pixel is hidden. This means non admin will not see this pixel. This can only be set or updated by admin.
    */
    'hidden'?: boolean;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string",
            "format": ""
        },
        {
            "name": "refId",
            "baseName": "ref_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string",
            "format": ""
        },
        {
            "name": "image",
            "baseName": "image",
            "type": "string",
            "format": ""
        },
        {
            "name": "html",
            "baseName": "html",
            "type": "string",
            "format": ""
        },
        {
            "name": "orgId",
            "baseName": "org_id",
            "type": "string",
            "format": ""
        },
        {
            "name": "created",
            "baseName": "created",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updated",
            "baseName": "updated",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "tags",
            "baseName": "tags",
            "type": "Array<string>",
            "format": ""
        },
        {
            "name": "topLevelUrl",
            "baseName": "top_level_url",
            "type": "string",
            "format": ""
        },
        {
            "name": "sensitive",
            "baseName": "sensitive",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "string",
            "format": ""
        },
        {
            "name": "notes",
            "baseName": "notes",
            "type": "Array<V1PixelNote>",
            "format": ""
        },
        {
            "name": "locked",
            "baseName": "locked",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "hidden",
            "baseName": "hidden",
            "type": "boolean",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1Pixel.attributeTypeMap;
    }

    public constructor() {
    }
}

