import { useEffect, useState } from 'react'
import {
  Button,
  DateRangePicker,
  Fa,
  showErrorMessage,
  TagInput,
  TextInput,
  Toggle,
} from '@eltoro-ui/components'
import { v4 as uuid } from 'uuid'
import classNames from 'classnames'
import { Link, useParams } from 'react-router-dom'
import {
  Hagridv1Subscription,
  V1Publisher,
  V1PublisherSubscription,
  V1Rule,
  V1RuleSet,
  V1WebToHomeNoteType,
} from 'next-gen-sdk'
import dayjs from 'dayjs'
import { useAppContext } from 'Contexts'
import { formatDate } from 'Helpers'
import { PixelFormItem, PixelNotes, SupportRequestModal } from 'Components'
import { updateRuleSet } from 'Requests/updateRuleSet'
import { createRuleSet } from 'Requests/createRuleSet'
import { WebToHomeDisabled } from 'Pages/PixelLibraryPage'
import { Loading, RuleSetWithTempId, RuleSets } from 'Components'
import { PixelGeoLocationFilters } from '../../Components/Pixel/GeoLocationFilters'
import { SubscriptionMetricsCard } from './components/SubscriptionMetricsCard'
import './SubscriptionPage.scss'

export const PixelSubscriptionsPage = () => {
  const [subscription, setSubscription] = useState<
    Partial<Hagridv1Subscription>
  >()
  const [showMatchCap, setShowMatchCap] = useState(false)
  const [publishers, setPublishers] = useState<V1Publisher[]>([])
  const {
    webToHomeApi,
    webToHomePrintPartnerApi,
    currentOrg,
    isAdmin,
    isReadOnly,
    webToHomeEnabled,
  } = useAppContext()

  // form pieces
  const [name, setName] = useState('')
  const [enabled, setEnabled] = useState(false)
  const [parentRuleSet, setParentRuleSet] = useState<
    Partial<RuleSetWithTempId>
  >({
    operator: 'and',
    rules: [{ term: '', tempId: uuid(), type: 'term' }],
    tempId: uuid(),
  })
  const [trialPeriodDates, setTrialPeriodDates] = useState({
    start: new Date(),
    end: new Date(),
  })
  const [selectedPublisher, setSelectedPublisher] = useState<V1Publisher>()
  const [matchCap, setMatchCap] = useState(0)
  const [locked, setLocked] = useState(false)
  const [hidden, setHidden] = useState(false)
  const [autoEnableAfterTrial, setAutoEnableAfterTrial] = useState(false)
  const [tags, setTags] = useState<string[]>()
  const [showNoteAdd, setShowNoteAdd] = useState(false)
  const [notes, setNotes] = useState<{
    content: string
    type: V1WebToHomeNoteType
  }>()
  const [saving, setSaving] = useState(false)
  const [ftpPath, setFtpPath] = useState('')
  const [excludeDuplicates, setExcludeDuplicates] = useState(false)
  const [excludedTime, setExcludedTime] = useState<number>()
  const [supportModal, setSupportModal] = useState(false)

  let { pixelId, subscriptionId, publisherId } = useParams<{
    pixelId?: string
    subscriptionId: string
    publisherId?: string
  }>()
  const isPartner = !!publisherId
  const query = currentOrg?.id ? `?org_id=${currentOrg.id}` : ''

  // list publishers dropdown
  const assignTempIds = (ruleSet: RuleSetWithTempId): RuleSetWithTempId => {
    return {
      ...ruleSet,
      tempId: uuid(),
      rules: ruleSet.rules?.map((rule) => ({
        ...rule,
        tempId: uuid(),
      })),
      ruleSet: ruleSet.ruleSet?.map((rs) => assignTempIds(rs)),
    }
  }

  const excludeTimeOptions = [
    { value: 86400, label: '24 Hours' },
    { value: 172800, label: '48 Hours' },
    { value: 259200, label: '3 Days' },
    { value: 345600, label: '4 Days' },
    { value: 604800, label: '7 Days' },
    { value: 1209600, label: '14 Days' },
    { value: 1209600, label: '2 Weeks' },
    { value: 2419200, label: '4 Weeks' },
    { value: 4838400, label: '8 Weeks' },
  ]

  useEffect(() => {
    if (!webToHomeApi || !currentOrg?.id) return

    const handleFetchPublishers = async () => {
      let nextPageToken = ''
      let pub: V1Publisher[] = []
      do {
        await webToHomeApi
          .advertisingPlatformServiceListPublishers(
            100, // pageSize
            nextPageToken, // pageToken
            'name', // orderBy
          )
          .then((res) => {
            pub = [...pub, ...(res.publishers || [])]
            nextPageToken = res.nextPageToken || ''
          })
        setPublishers(pub)
      } while (nextPageToken !== '')
    }

    if (isAdmin)
      handleFetchPublishers().catch((err) => {
        showErrorMessage('Error fetching publishers', '')
      })
  }, [currentOrg?.id, webToHomeApi])

  // fetch subscription
  useEffect(() => {
    if (
      !subscriptionId ||
      !currentOrg?.id ||
      !webToHomeApi ||
      (!pixelId && !subscriptionId)
    )
      return

    if (pixelId) {
      webToHomeApi
        ?.advertisingPlatformServiceGetSubscription(
          pixelId,
          subscriptionId,
          currentOrg.id,
        )
        .then((sub) => {
          setSubscription(sub)
          setName(sub?.name || '')
          setEnabled(sub?.enabled || false)
          setMatchCap(sub?.matchCap || 0)
          if (sub?.matchCap) setShowMatchCap(true)
          setLocked(sub?.locked || false)
          setHidden(sub?.hidden || false)
          setAutoEnableAfterTrial(sub?.autoEnableAfterTrial || false)
          setTrialPeriodDates({
            start: new Date(sub?.trialPeriodStartDate || new Date()),
            end: new Date(sub?.trialPeriodEndDate || new Date()),
          })
          if (sub.publisherId) setSelectedPublisher({ id: sub.publisherId })
          if (sub.tags) setTags(sub.tags)
          if (sub.ftpPath) setFtpPath(sub.ftpPath)
          if (sub.excludedTime) {
            setExcludedTime(sub.excludedTime)
            setExcludeDuplicates(true)
          }
          return sub
        })
        .then((sub) => {
          if (!webToHomeApi || !sub?.ruleSet?.id || !pixelId || !subscriptionId)
            return

          const resWithTempIds = assignTempIds(sub.ruleSet)
          setParentRuleSet(resWithTempIds)
        })
        .catch(() => {
          showErrorMessage('Error fetching subscription', '')
        })
    }

    if (isPartner && publisherId && webToHomePrintPartnerApi) {
      webToHomePrintPartnerApi
        .advertisingPlatformServiceGetPublisherSubscription(
          publisherId,
          subscriptionId,
          publisherId, // currentOrg.id,
        )
        .then((sub) => {
          setSubscription(sub)
          setName(sub?.name || '')
          setEnabled(sub?.enabled || false)
          if (sub.publisherId) setSelectedPublisher({ id: sub.publisherId })
          if (sub.ftpPath) setFtpPath(sub.ftpPath)
          return sub
        })
        .catch(() => {
          showErrorMessage('Error fetching subscription', '')
        })
    }
  }, [currentOrg?.id, pixelId, subscriptionId, webToHomeApi])

  const setters = {
    setParentRuleSet,
    setSubscription,
  }
  const handleNameChange = (e: any) => {
    const { value } = e.target
    if (value) {
      setName(value)
    }
  }

  const handleUpdateSubscription = async () => {
    if (
      isPartner &&
      publisherId &&
      subscriptionId &&
      webToHomePrintPartnerApi
    ) {
      const updatedPublisherSubscription: V1PublisherSubscription = {
        enabled: enabled,
        ftpPath: ftpPath,
      }
      setSaving(true)
      await webToHomePrintPartnerApi
        ?.advertisingPlatformServiceUpdatePublisherSubscription(
          publisherId,
          subscriptionId,
          updatedPublisherSubscription,
          undefined,
          currentOrg!.id!,
        )
        .then(async (res) => setSubscription(res))
        .catch(() =>
          showErrorMessage('Error updating publisher subscription', ''),
        )
        .finally(() => setSaving(false))
    }

    const updatedSubscription = {
      name: name,
      enabled: enabled,
      statesFiltered: subscription?.statesFiltered || [],
      zipsFiltered: subscription?.zipsFiltered || [],
      matchCap,
      locked,
      hidden,
      autoEnableAfterTrial,
      trialPeriodStartDate: trialPeriodDates.start,
      trialPeriodEndDate: trialPeriodDates.end,
      publisherId: selectedPublisher?.id,
      tags,
      ftpPath,
      excludedTime: excludedTime || 0, // zero to unset
    }
    if (!pixelId || !subscriptionId || !currentOrg?.id) return
    setSaving(true)
    await webToHomeApi
      ?.advertisingPlatformServiceUpdateSubscription(
        pixelId,
        subscriptionId,
        updatedSubscription,
        undefined,
        currentOrg!.id!,
      )
      .then(async (res) => {
        if (!pixelId || !subscriptionId || !currentOrg?.id) return
        let updatedRuleSet: V1RuleSet | undefined

        if (subscription?.ruleSet?.id) {
          updatedRuleSet = await updateRuleSet(
            parentRuleSet,
            pixelId,
            subscriptionId,
            currentOrg.id,
          )
        } else {
          updatedRuleSet = await createRuleSet(
            parentRuleSet,
            pixelId,
            subscriptionId,
            currentOrg.id,
          )
        }
        setSubscription({ ...res, ruleSet: updatedRuleSet })
        if (updatedRuleSet) {
          const resWithTempIds = assignTempIds(updatedRuleSet)
          setParentRuleSet(resWithTempIds)
        }
      })
      .catch(() => showErrorMessage('Error updating subscription', ''))
      .finally(() => setSaving(false))
  }
  const handleSaveNote = () => {
    if (
      isPartner &&
      publisherId &&
      subscriptionId &&
      webToHomePrintPartnerApi
    ) {
      setSaving(true)
      webToHomePrintPartnerApi
        .advertisingPlatformServiceCreatePublisherSubscriptionNote(
          publisherId,
          subscriptionId,
          {
            content: notes?.content || '',
            type: 'NOTE_TYPE_PUBLISHER',
          },
          currentOrg!.id!,
        )
        .then((res) => {
          setSubscription((prev) => ({
            ...prev,
            notes: [...(prev?.notes || []), res],
          }))
          setShowNoteAdd(false)
        })
        .catch(() =>
          showErrorMessage('Error saving publisher subscription note', ''),
        )
        .finally(() => setSaving(false))

      return
    }
    if (
      !pixelId ||
      !subscriptionId ||
      !currentOrg?.id ||
      !notes?.content ||
      !webToHomeApi
    )
      return
    setSaving(true)
    webToHomeApi
      .advertisingPlatformServiceCreateSubscriptionNote(
        pixelId,
        subscriptionId,
        {
          content: notes?.content || '',
          type: notes?.type || 'NOTE_TYPE_USER',
        },
        currentOrg!.id!,
      )
      .then((res) => {
        setSubscription((prev) => ({
          ...prev,
          notes: [...(prev?.notes || []), res],
        }))
        setShowNoteAdd(false)
      })
      .catch(() => showErrorMessage('Error saving subscription note', ''))
      .finally(() => setSaving(false))
  }

  const handleDeleteNote = (noteId: string) => {
    if (
      isPartner &&
      publisherId &&
      subscriptionId &&
      webToHomePrintPartnerApi
    ) {
      setSaving(true)
      webToHomePrintPartnerApi
        .advertisingPlatformServiceDeletePublisherSubscriptionNote(
          publisherId,
          subscriptionId,
          noteId,
          publisherId,
        )
        .then(() => {
          setSubscription((prev) => ({
            ...prev,
            notes: prev?.notes?.filter((note) => note.id !== noteId),
          }))
          setShowNoteAdd(false)
        })
        .catch(() => showErrorMessage('Error deleting subscription note', ''))
        .finally(() => setSaving(false))
    }
    if (!pixelId || !subscriptionId || !currentOrg?.id || !webToHomeApi) return
    setSaving(true)

    webToHomeApi
      .advertisingPlatformServiceDeleteSubscriptionNote(
        pixelId,
        subscriptionId,
        noteId,
        currentOrg!.id!,
      )
      .then(() => {
        setSubscription((prev) => ({
          ...prev,
          notes: prev?.notes?.filter((note) => note.id !== noteId),
        }))
        setShowNoteAdd(false)
      })
      .catch(() => showErrorMessage('Error deleting subscription note', ''))
      .finally(() => setSaving(false))
  }

  const handleDeleteRule = async (ruleToDelete: V1Rule) => {
    if (
      !pixelId ||
      !subscriptionId ||
      !currentOrg?.id ||
      !ruleToDelete.id ||
      !ruleToDelete.ruleSetId
    )
      return
    await webToHomeApi
      ?.advertisingPlatformServiceDeleteRule(
        pixelId,
        subscriptionId,
        ruleToDelete.ruleSetId,
        ruleToDelete.id,
        currentOrg.id,
      )
      .catch(() => showErrorMessage('Error deleting rule', ''))
  }

  const handleDeleteRuleSet = async (ruleSetToDelete: V1RuleSet) => {
    if (!pixelId || !subscriptionId || !currentOrg?.id || !ruleSetToDelete.id)
      return
    await webToHomeApi
      ?.advertisingPlatformServiceDeleteRuleSet(
        pixelId,
        subscriptionId,
        ruleSetToDelete.id,
        currentOrg.id,
      )
      .catch(() => showErrorMessage('Error deleting rule set', ''))
  }

  if (!isAdmin && !webToHomeEnabled) return <WebToHomeDisabled />

  if (!subscription) return <Loading />

  return (
    <div className="PixelSubscriptionPage">
      <div className="PixelSubscriptionContainer">
        <div className="min-h-screen bg-[color:var(--base-gray)]">
          <div className="mx-auto max-w-7xl px-4 py-8 text-[color:var(--base-text)] sm:px-6 lg:px-8">
            {/* Header */}
            <div className="mb-8">
              <div className="mb-4 flex items-center justify-between">
                <div className="flex w-full items-center justify-between">
                  <Link
                    to={`/pixel-library/${
                      isPartner ? 'partner-' : ''
                    }subscriptions${query}`}
                    className="flex items-center"
                  >
                    <Fa icon="arrow-left" size={1} className="mr-2 h-5 w-5" />
                    Back to{isPartner ? ' Partner' : ''} Subscription Library
                  </Link>
                  {isPartner && (
                    <Button onClick={() => setSupportModal(true)}>
                      Support Request
                    </Button>
                  )}
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div>
                  <h1 className="text-2xl font-semibold text-[color:var(--base-text)]">
                    {subscription?.name || 'Subscription Name'}
                  </h1>
                  <div className="mt-2 flex items-center text-sm text-[color:var(--color-grey-500)]">
                    {!isPartner && <span className="mr-2">web-to-home</span>}
                    {(() => {
                      if (!subscription) return null
                      const status = (() => {
                        if (subscription?.failId) return 'Failed'
                        return 'Ready'
                      })()
                      return (
                        <div
                          className={classNames(
                            'max-w-min rounded-full py-1 px-3 font-bold',
                            {
                              'bg-danger-50 text-current': status === 'Failed',
                              'text-success-500 bg-success-50':
                                status === 'Ready',
                            },
                          )}
                        >
                          {status}
                        </div>
                      )
                    })()}
                  </div>
                  <div className="mt-1 text-sm text-[color:var(--color-grey-500)]">
                    Created on:{' '}
                    {subscription?.created
                      ? formatDate(subscription?.created)
                      : 'N/A'}
                  </div>
                </div>
              </div>
            </div>

            {/* Metrics Card */}
            <SubscriptionMetricsCard
              subscription={subscription}
              isPartner={isPartner}
            />

            {/* Subscription Name */}
            {isPartner ? (
              <div className="mb-6 rounded-lg bg-[color:var(--base-bg)] shadow-lg">
                <div className="flex flex-col gap-2 p-6">
                  <h2 className="mb-4 text-lg font-semibold text-[color:var(--base-text)]">
                    Basic Information
                  </h2>
                  <PixelFormItem
                    htmlFor="subscription-name"
                    label="Subscription Name"
                  >
                    <input
                      type="text"
                      value={name}
                      className="w-full rounded-md border border-[color:var(--color-grey-200)] bg-[color:var(--base-bg)] px-3 py-2 text-[color:var(--base-text)]"
                      onChange={(e) => handleNameChange(e)}
                      disabled
                    />
                  </PixelFormItem>
                  <PixelFormItem htmlFor="pixel-name" label="Pixel Name">
                    <input
                      type="text"
                      value={
                        (subscription as V1PublisherSubscription).pixelName ||
                        ''
                      }
                      className="w-full rounded-md border border-[color:var(--color-grey-200)] bg-[color:var(--base-bg)] px-3 py-2 text-[color:var(--base-text)]"
                      onChange={(e) => handleNameChange(e)}
                      disabled
                    />
                  </PixelFormItem>
                </div>
              </div>
            ) : (
              <div className="mb-6 rounded-lg bg-[color:var(--base-bg)] shadow-lg">
                <div className="p-6">
                  <h2 className="mb-4 text-lg font-semibold text-[color:var(--base-text)]">
                    Subscription Name
                  </h2>
                  <input
                    type="text"
                    value={name}
                    className="w-full rounded-md border border-[color:var(--color-grey-200)] bg-[color:var(--base-bg)] px-3 py-2 text-[color:var(--base-text)]"
                    onChange={(e) => handleNameChange(e)}
                    disabled={isReadOnly}
                  />
                </div>
              </div>
            )}

            {/* Tags */}
            {!isPartner && (
              <div className="mb-6 rounded-lg bg-[color:var(--base-bg)] shadow-lg">
                <div className="p-6">
                  <h2 className="text-lg font-semibold text-[color:var(--base-text)]">
                    Tags
                  </h2>
                  <TagInput
                    disabled={isReadOnly}
                    value={tags || []}
                    addTagsInBulk
                    onAddTag={(tagArray: string[]) => {
                      setTags(tagArray)
                    }}
                    onRemoveTag={(tagToRemove) => {
                      setTags(tags?.filter((tag) => tag !== tagToRemove) || [])
                    }}
                    limit={1000}
                  />
                </div>
              </div>
            )}

            {/* Notes */}
            <div className="mb-6 rounded-lg bg-[color:var(--base-bg)] shadow">
              <div className="p-6">
                <div className="mb-4 flex items-center justify-between">
                  <h2 className="text-lg font-semibold text-[color:var(--base-text)]">
                    Notes
                  </h2>
                  <Button
                    kind="text"
                    onClick={() => setShowNoteAdd(!showNoteAdd)}
                  >
                    {showNoteAdd ? 'Close' : 'Add Note'}
                  </Button>
                </div>
                {showNoteAdd && (
                  <div className="animate-slidedown mb-2 flex flex-col py-1">
                    <PixelNotes
                      type="subscription"
                      onChange={(content, type) => setNotes({ content, type })}
                      showLabel={false}
                      onSaveNote={handleSaveNote}
                      saving={saving}
                    />
                  </div>
                )}
                <div className="PixelSubscriptionPage__notes-table mt-2 flex flex-col gap-2">
                  {(subscription?.notes || []).length > 0 ? (
                    subscription?.notes?.map(
                      ({ content, type, userName, updateTime, id }) => {
                        if (!isAdmin && type === 'NOTE_TYPE_ADMIN') return null
                        return (
                          <div
                            className={classNames(
                              'bg-tint-gray-100 flex items-center justify-between rounded p-2',
                              {
                                'bg-base-info-50': type === 'NOTE_TYPE_ADMIN',
                              },
                            )}
                            key={id}
                          >
                            <div className="p-1 text-sm">
                              <p>{content}</p>
                              <p className="text-tint-gray-700 mt-1 text-xs">
                                {userName}{' '}
                                {(() => {
                                  if (type === 'NOTE_TYPE_ADMIN')
                                    return (
                                      <span className="text-base-info font-bold">
                                        (Admin)
                                      </span>
                                    )
                                  if (type === 'NOTE_TYPE_PUBLISHER')
                                    return (
                                      <span className="font-bold">
                                        (Publisher)
                                      </span>
                                    )
                                  return null
                                })()}{' '}
                                -{' '}
                                {dayjs(updateTime).format(
                                  'MM/DD/YYYY, H:mm:ss A',
                                )}
                              </p>
                            </div>
                            {!isReadOnly && (
                              <button
                                onClick={() => id && handleDeleteNote(id)}
                                disabled={saving}
                                className="text-danger self-end pb-2 text-sm"
                              >
                                Delete
                              </button>
                            )}
                          </div>
                        )
                      },
                    )
                  ) : (
                    <span>No notes</span>
                  )}
                </div>
              </div>
            </div>

            {/* Rulesets */}
            {!isPartner && (
              <div className="mb-6 rounded-lg bg-[color:var(--base-bg)] shadow">
                <div className="p-6">
                  <h2 className="mb-4 text-lg font-semibold text-[color:var(--base-text)]">
                    Rule Sets
                  </h2>
                  <RuleSets
                    parentRuleSet={parentRuleSet}
                    showLabel={false}
                    onDeleteRule={handleDeleteRule}
                    onDeleteRuleSet={handleDeleteRuleSet}
                    {...setters}
                  />
                </div>
              </div>
            )}

            {/* Geographic Filters */}
            {!isPartner && (
              <div className="mb-6 rounded-lg bg-[color:var(--base-bg)] shadow">
                <div className="p-6">
                  <h2 className="mb-4 text-lg font-semibold text-[color:var(--base-text)]">
                    Geographic Filters
                  </h2>
                  <PixelGeoLocationFilters
                    zipsFiltered={subscription?.zipsFiltered || []}
                    statesFiltered={subscription?.statesFiltered || []}
                    handleZipsFiltered={(zips) =>
                      setSubscription((prev) => ({
                        ...prev,
                        zipsFiltered: zips,
                      }))
                    }
                    handleStatesFiltered={(states) =>
                      setSubscription((prev) => ({
                        ...prev,
                        statesFiltered: states,
                      }))
                    }
                  />
                </div>
              </div>
            )}

            {/* Processing Settings */}
            <div className="mb-6 rounded-lg bg-[color:var(--base-bg)] shadow">
              <div className="p-6">
                <h2 className="mb-4 text-lg font-semibold text-[color:var(--base-text)]">
                  Processing Settings
                </h2>
                <div className="flex items-center">
                  <Toggle
                    isChecked={enabled}
                    onChange={(checked) => setEnabled(checked)}
                    size="s"
                    label="Enable Daily Processing"
                    isDisabled={isReadOnly}
                  />
                </div>
                {enabled && (
                  <div className="px-4 py-2">
                    <PixelFormItem htmlFor="subscription-ftp" label="FTP Path">
                      <TextInput
                        value={ftpPath}
                        onChange={(e) => setFtpPath(e.target.value)}
                      />
                    </PixelFormItem>
                    {(!isPartner || isAdmin) && (
                      <div className="mt-2 flex">
                        <Toggle
                          isChecked={excludeDuplicates}
                          onChange={(checked) => {
                            setExcludeDuplicates(checked)
                            setExcludedTime(undefined)
                          }}
                          size="s"
                          label="Exclude Duplicates"
                          isDisabled={isReadOnly}
                        />
                      </div>
                    )}
                    {excludeDuplicates && (
                      <select
                        className="mt-2 rounded-md border border-[color:var(--color-grey-200)] bg-[color:var(--base-bg)] px-3 py-1.5 text-sm"
                        onChange={(e) => setExcludedTime(+e.target.value)}
                      >
                        {excludeTimeOptions.map((option, i) => (
                          <option
                            key={`${option.value}-${i}`}
                            value={option.value}
                          >
                            {option.label}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* Administrative Settings */}
            {/* TODO:  https://eltorocorp.atlassian.net/browse/PLAPP-108 */}
            {/* In order to update the subscription from the partner view, we need to be able to filter on list subscriptions. */}
            {/* this page has no knowledge of the subscription's id */}
            {isAdmin && !isPartner && (
              <div className="mb-6 rounded-lg bg-[color:var(--base-bg)] shadow-lg">
                <div className="p-6">
                  <h2 className="mb-4 text-lg font-semibold text-[color:var(--base-text)]">
                    Administrative Settings
                  </h2>
                  <div className="space-y-6">
                    <DateRangePicker
                      min={new Date()}
                      startDate={new Date(trialPeriodDates.start)}
                      endDate={new Date(trialPeriodDates.end)}
                      onStartDateChange={(start) => {
                        setTrialPeriodDates((prev) => {
                          return { ...prev, start }
                        })
                      }}
                      onEndDateChange={(end) => {
                        setTrialPeriodDates((prev) => {
                          return { ...prev, end }
                        })
                      }}
                    />
                    <div className="PixelSubscriptionsPage__admin-toggles flex items-center gap-6">
                      <Toggle
                        isChecked={hidden}
                        onChange={(checked) => setHidden(checked)}
                        size="s"
                        label="Hide Subscription"
                        isDisabled={isReadOnly}
                      />
                      <Toggle
                        isChecked={locked}
                        onChange={(checked) => setLocked(checked)}
                        size="s"
                        label="Lock Subscription"
                        isDisabled={isReadOnly}
                      />
                      <Toggle
                        isChecked={autoEnableAfterTrial}
                        onChange={(checked) => setAutoEnableAfterTrial(checked)}
                        size="s"
                        label="Automatically enable daily processing after trial"
                        isDisabled={isReadOnly}
                      />
                      <Toggle
                        isChecked={showMatchCap}
                        onChange={(checked) => {
                          setShowMatchCap(checked)
                          setMatchCap(0)
                        }}
                        size="s"
                        label="Match Cap"
                        isDisabled={isReadOnly}
                      />
                    </div>
                    {showMatchCap && (
                      <div className="w-max">
                        <label className="FormItem__label mb-1 block text-sm">
                          Match Cap Value
                        </label>
                        <input
                          type="number"
                          min={1}
                          value={matchCap}
                          onChange={(e) => setMatchCap(+e.target.value)}
                          className="w-full rounded-md border border-[color:var(--color-grey-200)] bg-[color:var(--base-bg)] px-3 py-2 text-[color:var(--base-text)]"
                          disabled={isReadOnly}
                        />
                      </div>
                    )}
                    <div>
                      <label className="FormItem__label mb-1 block text-sm">
                        Publisher
                      </label>
                      <select
                        value={selectedPublisher?.id}
                        onChange={(e) => {
                          const pub = publishers.find(
                            (publisher) => publisher.id === e.target.value,
                          )
                          setSelectedPublisher(pub)
                        }}
                        className="w-full rounded-md border border-[color:var(--color-grey-200)] bg-[color:var(--base-bg)] px-3 py-1.5 text-sm text-[color:var(--base-text)] transition-colors hover:border-[color:var(--color-grey-300)]"
                        disabled={isReadOnly}
                        // this may need to be hidden for non-admins
                      >
                        <option value="">Select Publisher</option>
                        {publishers.map((publisher) => (
                          <option value={publisher.id} key={publisher.id}>
                            {publisher.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Button
              onClick={handleUpdateSubscription}
              className="btn-primary w-full"
              disabled={isReadOnly || saving}
              kind="primary"
            >
              Save Changes
            </Button>
          </div>
        </div>
      </div>
      {supportModal && (
        <SupportRequestModal handleClose={() => setSupportModal(false)} />
      )}
    </div>
  )
}
