/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { V1Upcharge } from '../models/V1Upcharge';
import { HttpFile } from '../http/http';

export class V1CpmPlus {
    'createTime'?: Date;
    'updateTime'?: Date;
    'deleteTime'?: Date;
    /**
    * NOTE(morgan): https://github.com/googleapis/googleapis/blob/master/google/type/money.proto The three-letter currency code defined in ISO 4217.
    */
    'currencyCode'?: string;
    /**
    * The whole units of the amount. For example if `currencyCode` is `\"USD\"`, then 1 unit is one US dollar.
    */
    'units'?: string;
    /**
    * Number of nano (10^-9) units of the amount. The value must be between -999,999,999 and +999,999,999 inclusive. If `units` is positive, `nanos` must be positive or zero. If `units` is zero, `nanos` can be positive, zero, or negative. If `units` is negative, `nanos` must be negative or zero. For example $-1.75 is represented as `units`=-1 and `nanos`=-750,000,000.
    */
    'nanos'?: number;
    /**
    * A list of the upcharges that make up the CpmPlus On creation or when updating a CpmPlus, if you do not use the default values, you are required to follow a few rules. The sum of upcharges must equal the total units and nanos of the cpm plus.  You are not required to set the data source, but if you dont, you wont have a way to know what each upcharge refers to. If you want to add an upcharge without relating it to a data source, you can. Leave that field empty. You cannot update individual fields on the CpmPlus. On update or creation, pass the entire CpmPlus and the array of upcharges through the request. 
    */
    'upcharges'?: Array<V1Upcharge>;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "createTime",
            "baseName": "create_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "updateTime",
            "baseName": "update_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "deleteTime",
            "baseName": "delete_time",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "currencyCode",
            "baseName": "currency_code",
            "type": "string",
            "format": ""
        },
        {
            "name": "units",
            "baseName": "units",
            "type": "string",
            "format": "int64"
        },
        {
            "name": "nanos",
            "baseName": "nanos",
            "type": "number",
            "format": "int32"
        },
        {
            "name": "upcharges",
            "baseName": "upcharges",
            "type": "Array<V1Upcharge>",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1CpmPlus.attributeTypeMap;
    }

    public constructor() {
    }
}

