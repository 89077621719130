import { useState } from 'react'
import {
  Button,
  Modal,
  TabType,
  Tabs,
  TextHeader,
  showErrorMessage,
  showInfoAlert,
  showInfoMessage,
  showSuccessMessage,
  showWarningMessage,
} from '@eltoro-ui/components'
import {
  Campaignservicev1OrderLine,
  Targetjobservicev1Audience,
  V1ProductType,
} from 'next-gen-sdk'
import { PolygonMap, WarningModal, AudienceUploadStepper } from 'Components'
import { AudienceLibraryPage, audienceUploadConfigs } from 'Pages'
import { useAppContext } from 'Contexts'
import './EditOLAudiences.scss'
import {
  checkIfJobIsAttached,
  DetermineIfAllowedToAttachAudience,
  getCPMForAudience,
  getHighestCPMProductType,
  getProductTypes,
  getTypeMoneyCost,
  styleTailwind,
  userFriendlyAudienceName,
} from 'Helpers'
import { ETIcon } from 'Assets'

const AudienceCat = styleTailwind(
  'span',
  'AudienceHeader__category whitespace-nowrap',
)

export const EditOLAudiences: React.FC<{
  orderLine: Campaignservicev1OrderLine
  onClose: () => void
  refreshTableOL: () => void
  startingState?: 'library' | 'create'
}> = ({ orderLine, onClose, startingState, refreshTableOL }) => {
  const [addError, setAddError] = useState<string>()
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [confirmModalText, setConfirmModalText] = useState('')
  const [confirmedWarning, setConfirmedWarning] = useState(false)
  const [
    pendingAudience,
    setPendingAudience,
  ] = useState<Targetjobservicev1Audience>()
  const { campaignServiceApi, currentOrg, isAdmin, roles } = useAppContext()

  // note- if we change this to allow any audiences to be added from here, may still need to block adding retargeting to political
  const filterByProductType = undefined
  const handleAddAudience = (
    audiences: Targetjobservicev1Audience[],
    confirmation?: boolean,
  ) => {
    if (!campaignServiceApi || !orderLine.id || !currentOrg?.id) return

    let audiencesToAttach: Targetjobservicev1Audience[] = []
    let audiencesRejected: {
      audience: Targetjobservicev1Audience
      reason: string
    }[] = []

    audiences.forEach((audience) => {
      if (!orderLine.id) return
      const allowedToAttach = DetermineIfAllowedToAttachAudience(
        orderLine.audiences || [],
        audience,
        isAdmin,
      )
      const olPrice = getTypeMoneyCost(
        orderLine?.highestCpmAudience?.audienceBasePrice || {},
        [orderLine?.highestCpmAudience?.audienceUpcharge],
      )
      const audTypeMoneyCpm = getCPMForAudience(audience, currentOrg, orderLine)
      const audPrice = getTypeMoneyCost(audTypeMoneyCpm?.base || {}, [
        audTypeMoneyCpm?.upcharge || {},
      ])
      if (allowedToAttach) {
        if (orderLine.paid) {
          if (audience.dataProduct === 'DATA_PRODUCT_CUSTOM_AUDIENCE') {
            if (!isAdmin) {
              audiencesRejected = [
                ...audiencesRejected,
                {
                  audience,
                  reason:
                    'Unable to Attach Premium Audience, Please contact support if you want to attach this premium audience',
                },
              ]
              return
            }
            if (isAdmin) {
              const foundDataSource = orderLine.dataSourcesHighestUpcharge?.find(
                (ds) => {
                  return ds.dataSource === audience.dataSource
                },
              )
              if (!foundDataSource) {
                if (!confirmation && !confirmedWarning) {
                  setShowConfirmModal(true)
                  setPendingAudience(audience)
                  setConfirmModalText(
                    'Attaching this audience will increase the budget. Are you sure?',
                  )
                  return
                } else {
                  audiencesToAttach = [...audiencesToAttach, audience]
                  return
                }
              }
              if (
                foundDataSource &&
                foundDataSource.upcharge &&
                audience.cpmPlus
              ) {
                const olUpchargePrice = getTypeMoneyCost(
                  foundDataSource.upcharge,
                )
                const currentAudUpchargePrice = getTypeMoneyCost(
                  audience.cpmPlus,
                )

                if (
                  olUpchargePrice <= currentAudUpchargePrice &&
                  !confirmation &&
                  !confirmedWarning
                ) {
                  setShowConfirmModal(true)
                  setPendingAudience(audience)
                  setConfirmModalText(
                    'Attaching this audience will increase the budget. Are you sure?',
                  )
                  return
                } else {
                  audiencesToAttach = [...audiencesToAttach, audience]
                  return
                }
              }
            }
          }
          if (audPrice <= olPrice) {
            const audiences =
              audience.type === 'AUDIENCE_TYPE_VR' && audience.audiences
                ? audience.audiences.map(({ id }) => ({ id, exclude: false }))
                : [{ id: audience.id, exclude: false }]

            audiencesToAttach = [...audiencesToAttach, ...audiences]
            return
          }
          if (audPrice > olPrice) {
            audiencesRejected = [
              ...audiencesRejected,
              {
                audience,
                reason: `Attaching product type "
              ${userFriendlyAudienceName(audience.productType || '')}" is
              not allowed on this order line`,
              },
            ]
            return
          }
        }
        // non pre-pay and deployed
        if (orderLine.status !== 'ORDERLINE_STATUS_DRAFT') {
          if (audience.dataProduct === 'DATA_PRODUCT_CUSTOM_AUDIENCE') {
            if (!isAdmin) {
              if (!confirmation && !confirmedWarning) {
                setShowConfirmModal(true)
                setPendingAudience(audience)
                setConfirmModalText(
                  'Attaching new custom audiences may increase the budget. Are you sure?',
                )
                return
              } else {
                audiencesToAttach = [...audiencesToAttach, audience]
              }
            }
            if (isAdmin) {
              const foundDataSource = orderLine.dataSourcesHighestUpcharge?.find(
                (ds) => {
                  return ds.dataSource === audience.dataSource
                },
              )
              if (!foundDataSource) {
                if (!confirmation && !confirmedWarning) {
                  setShowConfirmModal(true)
                  setPendingAudience(audience)
                  setConfirmModalText(
                    'Attaching this audience will increase the budget. Are you sure?',
                  )
                  return
                } else {
                  audiencesToAttach = [...audiencesToAttach, audience]
                  return
                }
              }
              if (
                foundDataSource &&
                foundDataSource.upcharge &&
                audience.cpmPlus
              ) {
                const olUpchargePrice = getTypeMoneyCost(
                  foundDataSource.upcharge,
                )
                const currentAudUpchargePrice = getTypeMoneyCost(
                  audience.cpmPlus,
                )
                if (
                  olUpchargePrice < currentAudUpchargePrice &&
                  !confirmation &&
                  !confirmedWarning
                ) {
                  setShowConfirmModal(true)
                  setPendingAudience(audience)
                  setConfirmModalText(
                    'Attaching this audience will increase the budget. Are you sure?',
                  )
                  return
                } else {
                  audiencesToAttach = [...audiencesToAttach, audience]
                  return
                }
              }
            }
          }
        }
        const audiences =
          audience.type === 'AUDIENCE_TYPE_VR' && audience.audiences
            ? audience.audiences.map(({ id }) => ({ id, exclude: false }))
            : [{ id: audience.id, exclude: false }]

        audiencesToAttach = [...audiencesToAttach, ...audiences]
      } else {
        audiencesRejected = [
          ...audiencesRejected,
          {
            audience,
            reason: `Attaching product type "
          ${userFriendlyAudienceName(audience.productType || '')}" is
          not allowed on this order line`,
          },
        ]
      }
    })

    const audienceText = `audience${audiences.length > 0 ? 's' : ''}`
    if (audiencesToAttach.length > 0) {
      showInfoAlert(
        `Adding ${audienceText}...`,
        `Your ${audienceText} is being added to the order line`,
      )
      campaignServiceApi
        .advertisingPlatformServiceBatchAddAudiences(orderLine.id, {
          orgId: currentOrg.id,
          audiences: audiencesToAttach.map(({ id }) => ({
            id,
            exclude: false,
          })),
        })
        .then(() => {
          if (audiencesRejected.length > 0) {
            showWarningMessage(
              `Some ${audienceText} Attached`,
              <div>
                <TextHeader type={6}>Successfully attached:</TextHeader>
                <ul className="list-inside list-disc leading-tight">
                  {audiencesToAttach.map((audience) => (
                    <li>{audience.name || audience.id} </li>
                  ))}
                </ul>
                {audiencesRejected.length > 0 ? (
                  <>
                    <TextHeader type={6}>Unable to attach:</TextHeader>
                    <ul className="list-inside list-disc leading-tight">
                      {audiencesRejected.map(({ audience, reason }) => (
                        <li>
                          <strong>{audience.name || audience.id}</strong>:{' '}
                          {reason}
                        </li>
                      ))}
                    </ul>
                  </>
                ) : null}
              </div>,
            )
          } else {
            showSuccessMessage(
              `${audiences.length > 1 ? 'Audiences' : 'Audience'} Attached`,
              `Your ${
                audiences.length > 1 ? 'audiences were' : 'audience was'
              } attached to the order line`,
            )
          }
          refreshTableOL()
        })
        .catch((e) =>
          showErrorMessage(
            `Error adding audience${audiences.length > 1 ? 's' : ''}`,
            e?.message || e?.body?.message || '',
          ),
        )
    } else if (audiencesRejected.length > 0) {
      showErrorMessage(
        'Unable to Attach',
        <ul className="list-inside list-disc leading-tight">
          {audiencesRejected.map(({ audience, reason }) => (
            <li>
              <strong>{audience.name || audience.id}</strong>: {reason}
            </li>
          ))}
        </ul>,
      )
    }
  }

  const handleRemoveAudience = (
    audience: Targetjobservicev1Audience,
    confirmation?: boolean,
  ) => {
    if (!campaignServiceApi || !orderLine.id || !currentOrg?.id || !audience.id)
      return
    showInfoAlert(
      'Removing audience...',
      'Your audience is being removed from the order line',
    )
    const audsIfDetached =
      audience.productType === 'PRODUCT_TYPE_VENUE_REPLAY' && !confirmation
        ? orderLine.audiences?.filter(
            (aud) => aud.productType !== audience?.productType,
          ) || []
        : orderLine.audiences?.filter((aud) => aud.id !== audience?.id) || []
    const productsIfDetached = getProductTypes(audsIfDetached)
    if (
      orderLine?.highestCpmAudience &&
      !productsIfDetached.includes(
        orderLine?.highestCpmAudience?.audienceProductType,
      )
    ) {
      if (orderLine.status !== 'ORDERLINE_STATUS_DRAFT') {
        showWarningMessage(
          'Unable to detach',
          <>
            <p className="flex flex-col gap-3 text-sm">
              Unable to remove the last{' '}
              {userFriendlyAudienceName(audience.productType || '')} audience,
              Please attach another &nbsp;
              {userFriendlyAudienceName(audience.productType || '')} audience
              before removing this audience
            </p>
          </>,
        )
        return
      }
    }
    if (
      !DetermineIfAllowedToAttachAudience(
        audsIfDetached,
        audience,
        roles?.includes('nextgen_admin'),
      ) &&
      !confirmation
    ) {
      if (orderLine.status !== 'ORDERLINE_STATUS_DRAFT') {
        showWarningMessage(
          'Unable to detach',
          <>
            <p className="flex flex-col gap-3 text-sm">
              Removing this audience will remove an admin only audience
              combination, Please attach another
              {userFriendlyAudienceName(audience.productType || '')} before
              removing this audience
            </p>
          </>,
        )
      } else {
        showWarningMessage(
          'Unable to detach',
          <>
            <p className="flex flex-col gap-3 text-sm">
              Removing this audience will remove an admin only audience
              combination, Please attach another &nbsp;
              {userFriendlyAudienceName(audience.productType || '')} before
              removing this audience or if you are sure:
              <Button
                onClick={() => {
                  handleRemoveAudience(audience, true)
                }}
              >
                Confirm Detachment
              </Button>
            </p>
          </>,
        )
      }
      return
    }
    const audienceIds =
      audience.type === 'AUDIENCE_TYPE_VR' && audience.audiences
        ? audience.audiences.reduce((acc: string[], curr) => {
            if (!curr.id) return acc
            if (checkIfJobIsAttached(orderLine, curr.id)) {
              return [...acc, curr.id]
            }
            return acc
          }, [])
        : [audience.id]
    campaignServiceApi
      .advertisingPlatformServiceBatchRemoveAudiences(orderLine.id, {
        orgId: currentOrg.id,
        audienceIds,
      })
      .then(() => {
        showSuccessMessage(
          `${audienceIds.length > 1 ? 'Audiences' : 'Audience'} Removed`,
          `Your ${
            audienceIds.length > 1 ? 'audiences were' : 'audience was'
          } removed from the order line`,
        )
        refreshTableOL()
      })
      .catch(() =>
        showErrorMessage(
          `Error removing audience${audienceIds.length > 1 ? 's' : ''}`,
          '',
        ),
      )
  }

  return (
    <Modal
      className="EditOLAudiences min-h-[85vh] min-w-[85vw] p-4"
      offClick={onClose}
      header={<TextHeader type={3}>Add Audiences: {orderLine.name}</TextHeader>}
    >
      <div className="EditOLAudiences__selections mb-4 mt-4 grid grid-cols-3 gap-2 px-5"></div>
      {startingState === 'library' && (
        <AudienceLibraryPage
          selectedAudiences={orderLine.audiences}
          filterByProductType={filterByProductType && [filterByProductType]}
          onAddAudienceToAttach={(audiences) => handleAddAudience(audiences)}
          onRemoveAudienceFromAttach={handleRemoveAudience}
        />
      )}
      {startingState === 'create' && (
        <>
          {(() => {
            if (!currentOrg) return null
            const productList = getHighestCPMProductType(orderLine, currentOrg)
            if (productList.products) {
              const tabs: TabType[] = []
              const { products } = productList

              if (
                products.some(
                  (c) => c.productType === 'PRODUCT_TYPE_DIGITAL_CANVASSING',
                ) &&
                !isAdmin
              ) {
                return (
                  <Tabs
                    tabs={[
                      {
                        id: 'dc',
                        label: (
                          <>
                            <ETIcon icon="digital-canvassing" />
                            <span className="AudienceTypeSelector__tabs--text flex flex-col">
                              <span className="AudienceTypeSelector__tabs-category Tabs-category">
                                Location
                              </span>
                              <AudienceCat>Digital Canvassing</AudienceCat>
                            </span>
                          </>
                        ),
                        component: (
                          <AudienceUploadStepper
                            audienceType="dc"
                            onAddAudienceToAttach={handleAddAudience}
                            useInCampaignButton={false}
                          />
                        ),
                      },
                    ]}
                  ></Tabs>
                )
              }
              if (
                products.some(
                  (c) => c.productType === 'PRODUCT_TYPE_VENUE_REPLAY',
                ) &&
                !isAdmin
              ) {
                return (
                  <Tabs
                    tabs={[
                      {
                        id: 'vr',
                        label: (
                          <>
                            <ETIcon icon="venue-replay" />
                            <span className="AudienceTypeSelector__tabs--text flex flex-col">
                              <span className="AudienceTypeSelector__tabs-category Tabs-category">
                                Behavioral
                              </span>
                              <AudienceCat>Venue Replay</AudienceCat>
                            </span>
                          </>
                        ),
                        component: (
                          <PolygonMap
                            vr
                            onAddAudienceToAttach={handleAddAudience}
                          />
                        ),
                      },
                    ]}
                  ></Tabs>
                )
              }
              if (
                products.some(
                  (c) => c.productType === 'PRODUCT_TYPE_NEW_MOVERS',
                ) &&
                !isAdmin
              ) {
                return (
                  <Tabs
                    tabs={[
                      {
                        id: 'movers',
                        label: (
                          <>
                            <ETIcon
                              className="AudienceTypeSelector__tabs--icon"
                              icon="movers"
                            />
                            <span className="AudienceTypeSelector__tabs--text flex flex-col">
                              <span className="AudienceTypeSelector__tabs-category Tabs-category">
                                Behavioral
                              </span>
                              <AudienceCat>Digital New Mover</AudienceCat>
                            </span>
                          </>
                        ),
                        component: (
                          <AudienceUploadStepper
                            audienceType="new-mover"
                            onAddAudienceToAttach={handleAddAudience}
                          />
                        ),
                      },
                    ]}
                  ></Tabs>
                )
              }
              products.map((currentProduct) => {
                if (
                  (currentProduct.productType as V1ProductType) ===
                    'PRODUCT_TYPE_MAP_POLYGON' &&
                  products.length === 1
                ) {
                  tabs.push({
                    id: 'map',
                    label: (
                      <>
                        <ETIcon icon="map-polygon" />
                        <span className="AudienceTypeSelector__tabs--text flex flex-col">
                          <span className="AudienceTypeSelector__tabs-category Tabs-category">
                            Location
                          </span>
                          <AudienceCat>Map Polygon</AudienceCat>
                        </span>
                      </>
                    ),
                    component: (
                      <PolygonMap
                        vr={false}
                        onAddAudienceToAttach={handleAddAudience}
                      />
                    ),
                  })
                }
                if (
                  (currentProduct.productType as V1ProductType) ===
                  'PRODUCT_TYPE_VENUE_REPLAY'
                ) {
                  tabs.push({
                    id: 'vr',
                    label: (
                      <>
                        <ETIcon icon="venue-replay" />
                        <span className="AudienceTypeSelector__tabs--text flex flex-col">
                          <span className="AudienceTypeSelector__tabs-category Tabs-category">
                            Behavioral
                          </span>
                          <AudienceCat>Venue Replay</AudienceCat>
                        </span>
                      </>
                    ),
                    component: (
                      <PolygonMap
                        vr
                        onAddAudienceToAttach={handleAddAudience}
                      />
                    ),
                  })
                }
                if (
                  (currentProduct.productType as V1ProductType) ===
                  'PRODUCT_TYPE_NEW_MOVERS'
                ) {
                  tabs.push({
                    id: 'new-mover',
                    label: (
                      <>
                        <ETIcon
                          className="AudienceTypeSelector__tabs--icon"
                          icon="movers"
                        />
                        <span className="AudienceTypeSelector__tabs--text flex flex-col">
                          <span className="AudienceTypeSelector__tabs-category Tabs-category">
                            Behavioral
                          </span>
                          <AudienceCat>Digital New Mover</AudienceCat>
                        </span>
                      </>
                    ),
                    component: (
                      <AudienceUploadStepper
                        audienceType="new-mover"
                        onAddAudienceToAttach={handleAddAudience}
                      />
                    ),
                  })
                }
                if (
                  (currentProduct.productType as V1ProductType) ===
                  'PRODUCT_TYPE_DIGITAL_CANVASSING'
                ) {
                  tabs.push({
                    id: 'dc',
                    label: (
                      <>
                        <ETIcon icon="digital-canvassing" />
                        <span className="AudienceTypeSelector__tabs--text flex flex-col">
                          <span className="AudienceTypeSelector__tabs-category Tabs-category">
                            Location
                          </span>
                          <AudienceCat>Digital Canvassing</AudienceCat>
                        </span>
                      </>
                    ),
                    component: (
                      <AudienceUploadStepper
                        audienceType="dc"
                        onAddAudienceToAttach={handleAddAudience}
                        useInCampaignButton={false}
                      />
                    ),
                  })
                }
                if (
                  (currentProduct.productType as V1ProductType) ===
                  'PRODUCT_TYPE_IP_TARGETING'
                ) {
                  const uploadConfigs = audienceUploadConfigs.filter(
                    ({ id }) => id === 'address' || id === 'zip' || id === 'ip',
                  )
                  tabs.push({
                    id: 'Ip Targeting',
                    label: (
                      <>
                        <ETIcon
                          className="AudienceTypeSelector__tabs--icon"
                          icon="sale-doc"
                        />
                        <span className="AudienceTypeSelector__tabs--text flex flex-col">
                          <span className="AudienceTypeSelector__tabs-category Tabs-category">
                            Address
                          </span>
                          <AudienceCat>IP Address List</AudienceCat>
                        </span>
                      </>
                    ),
                    component: (
                      <Tabs
                        tabs={uploadConfigs.map((config) => {
                          return {
                            id: config.id,
                            label: (
                              <>
                                {config.icon}
                                <span className="flex flex-col">
                                  <div className="EditOLTagets__tabs-category Tabs-category">
                                    {config.tagLabel}
                                  </div>
                                  <span>{config.title}</span>
                                </span>
                              </>
                            ),
                            component: (
                              <AudienceUploadStepper
                                audienceType={config.id}
                                onAddAudienceToAttach={handleAddAudience}
                                useInCampaignButton={false}
                              />
                            ),
                          }
                        })}
                      />
                    ),
                  })
                  tabs.push({
                    id: 'map',
                    label: (
                      <>
                        <ETIcon icon="map-polygon" />
                        <span className="AudienceTypeSelector__tabs--text flex flex-col">
                          <span className="AudienceTypeSelector__tabs-category Tabs-category">
                            Location
                          </span>
                          <AudienceCat>Map Polygon</AudienceCat>
                        </span>
                      </>
                    ),
                    component: (
                      <PolygonMap
                        vr={false}
                        onAddAudienceToAttach={handleAddAudience}
                      />
                    ),
                  })
                }
                return []
              })
              return <Tabs tabs={tabs}></Tabs>
            }
          })()}
        </>
      )}
      {addError && (
        <WarningModal onConfirm={() => setAddError(undefined)}>
          {addError}
        </WarningModal>
      )}
      {showConfirmModal && (
        <WarningModal
          onConfirm={() => {
            setConfirmModalText('')
            if (pendingAudience) {
              handleAddAudience([pendingAudience], true)
            }
            setShowConfirmModal((prevValue) => !prevValue)
            setConfirmedWarning((prevVal) => !prevVal)
            showInfoMessage('', 'Attaching your audience')
          }}
          onCancel={() => {
            setShowConfirmModal(false)
            setConfirmModalText('')
            setPendingAudience(undefined)
          }}
          confirm
        >
          <p className="text-md flex flex-col gap-3">{confirmModalText}</p>
        </WarningModal>
      )}
    </Modal>
  )
}
