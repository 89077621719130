/**
 * AdvertisingPlatform
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GoogletypeMoney } from '../models/GoogletypeMoney';
import { V1DataSource } from '../models/V1DataSource';
import { HttpFile } from '../http/http';

export class V1DataSourcesHighestUpcharge {
    'dataSource'?: V1DataSource;
    'upcharge'?: GoogletypeMoney;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "dataSource",
            "baseName": "data_source",
            "type": "V1DataSource",
            "format": ""
        },
        {
            "name": "upcharge",
            "baseName": "upcharge",
            "type": "GoogletypeMoney",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return V1DataSourcesHighestUpcharge.attributeTypeMap;
    }

    public constructor() {
    }
}

