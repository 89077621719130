import { useEffect, useRef, useState } from 'react'
import {
  // AutoScroller,
  Button,
  Checkbox,
  Fa,
  Modal,
  Text,
  TextHeader,
  showErrorMessage,
  showSuccessMessage,
} from '@eltoro-ui/components'
import { useScrollClass } from 'Hooks'
import {
  Campaignservicev1Campaign,
  Campaignservicev1OrderLine,
} from 'next-gen-sdk'
import { useLocation } from 'react-router-dom'
import { capitalize, styleTailwind } from 'Helpers'
import { ETIcon } from 'Assets'
import { useAppContext, useCampaignContext } from 'Contexts'
import { OLCard } from 'Components/OLCard'
import {
  AudienceEdit,
  CreativeEdit,
  BudgetEdit,
  ImpressionsEdit,
  FlightDatesEdit,
  OptionalIdEdit,
} from './components'
import classNames from 'classnames'
import './OrderLineEditWrapper.scss'
import { dayjs } from 'Tools/dateUtils'

const EditOptionButton = styleTailwind(
  Button,
  'hover:bg-secondary-300 text-s rounded-full disabled:bg-transparent',
)

const StackBtn = styleTailwind(
  'div',
  'StackedButtonIcon OLEditorIcons border-none group',
)

const IconLabel = styleTailwind(
  'p',
  'IconLabel text-m  pt-1 text-tint-gray-600 hover-group:text-tint-gray-800',
)

const SectionTitle = styleTailwind('p', 'xl:block hidden')

export const OrderLineEditWrapper = ({
  className = '',
  orderLines,
  campaign,
  removeOptions,
  setRefreshCampaign,
}: {
  className?: string
  orderLines: Campaignservicev1OrderLine[]
  campaign: Campaignservicev1Campaign
  removeOptions?: string[]
  setRefreshCampaign?: (x: boolean) => void
}) => {
  const [selectedOls, setSelectedOls] = useState<Campaignservicev1OrderLine[]>(
    [],
  )
  const [selectAll, setSelectAll] = useState(false)
  const [editSelection, setEditSelection] = useState<string>()
  const {
    campaignServiceApi,
    currentOrg,
    isOnHold,
    isAdmin,
    isReadOnly,
  } = useAppContext()
  const {
    refresh,
    setRefresh,
    setSubmissionRemovals,
    submissionRemovals,
  } = useCampaignContext()
  const containerRef = useRef<HTMLDivElement | null>(null)

  const stickyClass = useScrollClass(containerRef, 'isSticky', () => {
    const container = containerRef.current
    if (container) {
      return container.scrollTop > 0
    }
    return false
  })

  const handleOnCheck = (ol: Campaignservicev1OrderLine, selected: boolean) => {
    if (selectAll) {
      setSelectAll(false)
    }
    if (selected) {
      setSelectedOls((prev) =>
        ol.locked && !isAdmin ? [...prev] : [...prev, ol],
      )
    } else {
      setSelectedOls((prev) =>
        prev.filter((p) =>
          isAdmin ? p.id !== ol.id : p.id !== ol.id && !ol.locked,
        ),
      )
    }
  }
  const { pathname } = useLocation()
  const isInCampaignCreation =
    pathname.includes('/campaigns/create') ||
    pathname.includes('/campaigns/edit')

  const handleRemoveFromCart = () => {
    if (submissionRemovals && submissionRemovals.length > 0) {
      const justRemovedIds = selectedOls
        .map((c) => {
          if (c.id) {
            return c.id
          }
          return ''
        })
        .filter((c) => c !== undefined)
      const newRemoves = [
        ...new Set([...submissionRemovals, ...justRemovedIds]),
      ]
      setSubmissionRemovals(newRemoves)
    } else {
      const justRemovedIds = selectedOls
        .map((c) => {
          if (c.id) {
            return c.id
          }
          return ''
        })
        .filter((c) => c !== undefined)
      setSubmissionRemovals(justRemovedIds)
    }
    setRefresh(true)
  }
  const handleAddBackToCart = () => {
    if (selectedOls) {
      if (selectedOls.length > 1) {
        const newRemovals: string[] = []
        selectedOls.map((currentSelection) => {
          const removal = submissionRemovals.filter(
            (currentRemoval) => currentRemoval !== currentSelection.id,
          )
          return [...newRemovals, ...removal]
        })
        setSubmissionRemovals(newRemovals)
      }
      if (selectedOls.length === 1) {
        const newRemovals = submissionRemovals.filter(
          (currentRemoval) => currentRemoval !== selectedOls[0].id,
        )
        setSubmissionRemovals(newRemovals)
      }
    }
    setRefresh(true)
  }

  const handleDelete = async () => {
    const removeAllAndDelete = async (
      orderLine: Campaignservicev1OrderLine,
    ) => {
      if (!orderLine.id || !orderLine.orgId) return
      // remove creatives
      if (orderLine.creatives && (orderLine.creatives || []).length > 0) {
        const creativeIdsToDetach = orderLine.creatives
          .map((c) => c.id)
          .filter((c) => c !== undefined) as string[]
        await campaignServiceApi?.advertisingPlatformServiceBatchRemoveCreatives(
          orderLine.id,
          {
            orgId: orderLine.orgId,
            creativeIds: creativeIdsToDetach,
          },
        )
      }

      // remove audiences
      if (orderLine.audiences && (orderLine.audiences || []).length > 0) {
        const audiencesIdsToDetach = orderLine.audiences
          .map((c) => c.id)
          .filter((c) => c !== undefined) as string[]
        await campaignServiceApi?.advertisingPlatformServiceBatchRemoveAudiences(
          orderLine.id,
          {
            orgId: orderLine.orgId,
            audienceIds: audiencesIdsToDetach,
          },
        )
      }

      // remove from campaign
      if (campaign.id) {
        await campaignServiceApi?.advertisingPlatformServiceRemoveOrderLine(
          campaign.id,
          {
            orderLineId: orderLine.id,
            orgId: orderLine.orgId,
          },
        )
      }

      // delete ol
      await campaignServiceApi?.advertisingPlatformServiceDeleteOrderLine(
        orderLine.id,
        orderLine.orgId,
      )
    }
    await Promise.all(
      selectedOls.reduce((acc: Promise<void>[], orderLine) => {
        const { id, orgId } = orderLine
        if (!id || !campaignServiceApi || !orgId) return acc
        return [...acc, removeAllAndDelete(orderLine)]
      }, []),
    )
      .then(() => {
        if (isInCampaignCreation) setRefresh(true)
        showSuccessMessage('Deleted order lines', '')
      })
      .catch(() => showErrorMessage('Error deleting order lines', ''))
  }

  const handleDuplicate = async () => {
    if (selectedOls.length > 5) {
      showErrorMessage(
        'Unable to duplicate selections',
        `You have selected ${selectedOls.length} order lines. Please select 5 or fewer to complete this operation`,
      )
      return
    }
    selectedOls.forEach((currentSelection) => {
      if (
        currentSelection &&
        currentSelection.id &&
        currentSelection.campaign &&
        currentOrg?.id
      ) {
        campaignServiceApi
          ?.advertisingPlatformServiceCloneOrderLine(currentSelection.id, {
            campaignId: currentSelection.campaign.id,
            orgId: currentOrg.id,
          })
          .then((res) => {
            setRefresh(true)
          })
      }
    })
  }
  const handleLockOrderline = async () => {
    if (campaignServiceApi && isAdmin) {
      await Promise.all(
        selectedOls.reduce((acc: Promise<any>[], orderLine) => {
          const { id, orgId } = orderLine
          if (!id || !orderLine?.id || !campaignServiceApi || !orgId) return acc
          return [
            ...acc,
            campaignServiceApi.advertisingPlatformServiceLockOrderLine(
              orderLine.id,
              { orgId },
            ),
          ]
        }, []),
      )
        .then(() => isInCampaignCreation && setRefresh(true))
        .catch(() => showErrorMessage('Error unlocking order lines', ''))
    }
  }
  const handleUnLockOrderline = async () => {
    if (campaignServiceApi && isAdmin) {
      await Promise.all(
        selectedOls.reduce((acc: Promise<any>[], orderLine) => {
          const { id, orgId } = orderLine
          if (!id || !orderLine?.id || !campaignServiceApi || !orgId) return acc
          return [
            ...acc,
            campaignServiceApi.advertisingPlatformServiceUnlockOrderLine(
              orderLine.id,
              { orgId },
            ),
          ]
        }, []),
      )
        .then(() => isInCampaignCreation && setRefresh(true))
        .catch(() => showErrorMessage('Error unlocking order lines', ''))
    }
  }

  const handleSubmitForReview = async () => {
    await Promise.all(
      selectedOls.reduce((acc: Promise<any>[], orderLine) => {
        const { id, orgId } = orderLine
        if (!id || !orderLine?.id || !campaignServiceApi || !orgId) return acc
        return [
          ...acc,
          campaignServiceApi.advertisingPlatformServiceRequestReview(
            orderLine.id,
            { orgId },
          ),
        ]
      }, []),
    )
      .then(() => isInCampaignCreation && setRefresh(true))
      .catch(() =>
        showErrorMessage('Error submitting order lines for review', ''),
      )
  }

  useEffect(() => {
    // replace updated order lines in selectedOls
    // allows the user to keep selection
    setSelectedOls((prev) => {
      return orderLines.reduce(
        (acc: Campaignservicev1OrderLine[], updatedOl) => {
          if (prev.find((ol) => ol.id === updatedOl.id))
            return [...acc, updatedOl]
          return acc
        },
        [],
      )
    })
  }, [orderLines])

  const someOLsPaid = selectedOls.some((ol) => ol.paid)
  const hasRejectedOrderlines = selectedOls.some(
    (ol) => ol.status === 'ORDERLINE_STATUS_REJECTED',
  )
  const someRejectedOlsNotUpdated = selectedOls.some((ol) => {
    if (!ol.notes) return false
    const rejectionNotes = ol.notes.filter(
      (currentNote) => currentNote.type === 'NOTE_TYPE_REJECT',
    )
    const mostRecentRejection = rejectionNotes.slice(-1)
    if (
      ol.status === 'ORDERLINE_STATUS_REJECTED' &&
      mostRecentRejection.length > 0 &&
      ol.paid &&
      dayjs.tz(mostRecentRejection[0].createTime).isSame(ol.updateTime)
    ) {
      return true
    }
    if (
      !ol.paid &&
      ol.status === 'ORDERLINE_STATUS_REJECTED' &&
      mostRecentRejection.length > 0 &&
      dayjs.tz(mostRecentRejection[0].createTime).isSame(ol.updateTime)
    ) {
      return true
    }
    return false
  })

  const isBudgetEditDisabled = () =>
    !isAdmin && (!!selectedOls.find((ol) => !ol.costRange) || someOLsPaid)

  const isCreativeEditDisabled = () => {
    return !selectedOls.every(
      (ol) => ol.adType && ol.adType === selectedOls[0].adType,
    )
  }
  const isEditingDisabled = () => {
    return !isAdmin && (isReadOnly || isOnHold)
  }
  const isAddToCartDisabled = () =>
    // some ols have no click thru
    selectedOls.some((ol) => !ol.clickThroughUrl) ||
    // some ols have no creatives
    selectedOls.some((ol) => !ol.creatives?.length) ||
    // some ols have no audiences
    selectedOls.some((ol) => !ol.audiences?.length) ||
    // some ols have no cost
    selectedOls.some((ol) => !ol.costRange) ||
    // some ols have no impressions
    selectedOls.some((ol) => !ol.impressions) ||
    // some ols are not draft
    selectedOls.some((ol) => ol.status !== 'ORDERLINE_STATUS_DRAFT') ||
    // some ols are paid
    someOLsPaid

  const selectedCreativeType = selectedOls[0]?.creatives?.[0]?.adType
  const selectedAudienceType = selectedOls[0]?.audiences?.[0]?.productType

  return (
    <div className="OrderLine__edit-ribbon">
      {/* <AutoScroller
        className="OrderLine__edit-ribbon"
        shouldScroll={selectedOls.length < 1}
        scrollToTop
      > */}
      <div
        className={classNames('OrderLineEditWrapper', className, {
          'pointer-events-none opacity-50': isInCampaignCreation && refresh,
        })}
      >
        {selectedOls.length > 0 && (
          <div
            className="OrderLines__edit-ribbon bg-primary-50 animate-slidedown sticky top-0 mb-3 flex flex-wrap items-center gap-x-2 pl-4 pt-3 shadow-md"
            style={{ zIndex: 110 }}
          >
            <div className="OrderLines__edit-row--header  flex items-end">
              <TextHeader className="OrderLines__ols-selected-count text-l RightVerticalPipe flex items-center">
                Order Line editor
              </TextHeader>
              <Text className="ml-auto" tag="p">
                {selectedOls.length} Order Line
                {selectedOls.length === 1 ? '' : 's'} selected
              </Text>
            </div>
            <div className="OrderLines__edit-row--body gap-0">
              {isInCampaignCreation &&
                !removeOptions?.includes('remove_from_checkout') &&
                !removeOptions?.includes('add_to_checkout') && (
                  <StackBtn
                    className="RemoveFromCart"
                    data-tooltip="top"
                    aria-label="Remove Order Line from cart"
                  >
                    <EditOptionButton
                      iconCenter={
                        <Fa icon="cart-xmark" size={2} type="duotone" />
                      }
                      onClick={handleRemoveFromCart}
                    >
                      <IconLabel>Remove</IconLabel>
                    </EditOptionButton>
                  </StackBtn>
                )}
              {isInCampaignCreation &&
                removeOptions?.includes('add_to_checkout') && (
                  <StackBtn
                    className={`AddToCart ${
                      isAddToCartDisabled() || selectedOls.some((c) => c.locked)
                        ? 'tooltip-warning isDisabled'
                        : null
                    }`}
                    data-tooltip="top"
                    aria-label={(() => {
                      if (isAddToCartDisabled()) {
                        return 'One or more of your selected order lines cannot be added'
                      }
                      if (selectedOls.some((c) => c.locked)) {
                        return 'Some of your selected order lines are locked and can not be added to the cart'
                      }
                      return 'Add Order Line to cart'
                    })()}
                  >
                    <EditOptionButton
                      iconCenter={
                        <Fa icon="cart-plus" size={2} type="duotone" />
                      }
                      onClick={handleAddBackToCart}
                      disabled={(() => {
                        if (isAddToCartDisabled()) {
                          return isAddToCartDisabled()
                        }
                        return selectedOls.some((c) => c.locked)
                      })()}
                    >
                      <IconLabel className="relative">
                        Add
                        {(isAddToCartDisabled() ||
                          selectedOls.some((c) => c.locked)) && (
                          <Fa
                            className="!text-warning absolute -top-4 -right-6"
                            icon="comment-exclamation"
                            size={1}
                          />
                        )}
                      </IconLabel>
                    </EditOptionButton>
                  </StackBtn>
                )}
              {[
                'creatives',
                'audiences',
                'budget',
                'impressions',
                'flight_dates',
                // 'creative_priority_audit',
                'optional_IDs',
              ].map((type) => {
                const getIcon = () => {
                  if (type === 'impressions')
                    return <ETIcon icon="impressions" />
                  if (type === 'flight_dates')
                    return <Fa icon="calendar-days" size={2} type="duotone" />
                  if (type === 'optional_IDs')
                    return <Fa icon="pen-field" size={2} type="duotone" />
                  return <Fa icon="ad" size={2} type="duotone" />
                }
                if (removeOptions?.includes(type)) return null
                if (type === 'budget') {
                  return (
                    <StackBtn
                      className={`${type} ${
                        isBudgetEditDisabled() ||
                        selectedOls.some((c) => c.locked)
                          ? 'tooltip-warning isDisabled'
                          : null
                      }`}
                      key={type}
                      data-tooltip="top"
                      aria-label={(() => {
                        if (isBudgetEditDisabled()) {
                          return 'One of your selected order lines does not have a cost or is already paid for'
                        }
                        if (selectedOls.some((c) => c.locked)) {
                          return 'Some of your selected order lines are locked and can not be edited'
                        }
                        return 'Edit Budget'
                      })()}
                    >
                      <EditOptionButton
                        iconCenter={
                          <Fa
                            icon="envelope-open-dollar"
                            size={2}
                            type="duotone"
                            className="StackedButtonIcon__icon"
                          />
                        }
                        onClick={() => setEditSelection(type)}
                        disabled={
                          isBudgetEditDisabled() ||
                          selectedOls.some((c) => c.locked)
                        }
                      >
                        <IconLabel className="relative">
                          {type
                            .split('_')
                            .map((word) => `${capitalize(word)} `)}

                          {(isBudgetEditDisabled() ||
                            selectedOls.some((c) => c.locked)) && (
                            <Fa
                              className="!text-warning absolute -top-4 -right-3"
                              icon="comment-exclamation"
                              size={1}
                            />
                          )}
                        </IconLabel>
                      </EditOptionButton>
                    </StackBtn>
                  )
                }
                if (type === 'creatives') {
                  return (
                    <StackBtn
                      className={`${type} ${
                        isCreativeEditDisabled() ||
                        selectedOls.some((c) => c.locked)
                          ? 'tooltip-warning isDisabled'
                          : null
                      }`}
                      key={type}
                      data-tooltip="top"
                      aria-label={(() => {
                        if (selectedOls.some((c) => c.locked)) {
                          return 'Some of your selected order lines are locked and can not be edited'
                        }
                        if (isCreativeEditDisabled()) {
                          if (someOLsPaid)
                            return 'Some selected order lines are paid for and cannot be edited'
                          return 'Cannot edit incompatible creative types'
                        }
                        return 'Edit Creatives'
                      })()}
                    >
                      <EditOptionButton
                        iconCenter={
                          <Fa
                            icon="ad"
                            size={2}
                            type="duotone"
                            className="StackedButtonIcon__icon"
                          />
                        }
                        onClick={() => setEditSelection(type)}
                        disabled={
                          isCreativeEditDisabled() ||
                          selectedOls.some((c) => c.locked)
                        }
                      >
                        <IconLabel className="relative">
                          {type
                            .split('_')
                            .map((word) => `${capitalize(word)} `)}
                          {(isCreativeEditDisabled() ||
                            selectedOls.some((c) => c.locked)) && (
                            <Fa
                              className="!text-warning absolute -top-4 -right-3"
                              icon="comment-exclamation"
                              size={1}
                            />
                          )}
                        </IconLabel>
                      </EditOptionButton>
                    </StackBtn>
                  )
                }
                if (type === 'audiences') {
                  return (
                    <StackBtn
                      className={`${type} ${
                        selectedOls.some((c) => c.locked)
                          ? 'tooltip-warning isDisabled'
                          : null
                      }`}
                      key={type}
                      data-tooltip="top"
                      aria-label={(() => {
                        if (selectedOls.some((c) => c.locked)) {
                          return 'Some of your selected order lines are locked and can not be edited'
                        }
                        return 'Edit Audiences'
                      })()}
                    >
                      <EditOptionButton
                        iconCenter={
                          <ETIcon
                            icon="target"
                            className="StackedButtonIcon__icon"
                          />
                        }
                        onClick={() => setEditSelection(type)}
                        disabled={
                          selectedOls.some((c) => c.locked) || someOLsPaid
                        }
                      >
                        <IconLabel className="relative">
                          {type
                            .split('_')
                            .map((word) => `${capitalize(word)} `)}
                          {selectedOls.some((c) => c.locked) && (
                            <Fa
                              className="!text-warning absolute -top-4 -right-3"
                              icon="comment-exclamation"
                              size={1}
                            />
                          )}
                        </IconLabel>
                      </EditOptionButton>
                    </StackBtn>
                  )
                }
                return (
                  <StackBtn
                    className={`${type} ${
                      (!isAdmin && someOLsPaid) ||
                      selectedOls.some((c) => c.locked)
                        ? 'tooltip-warning isDisabled'
                        : null
                    }`}
                    key={type}
                    data-tooltip="top"
                    aria-label={(() => {
                      if (!isAdmin && someOLsPaid)
                        return 'One of your selected order lines is paid for and cannot be edited'
                      if (selectedOls.some((c) => c.locked)) {
                        return 'Some of your selected order lines are locked and can not be edited'
                      }
                      return `Edit ${type
                        .split('_')
                        .map(
                          (word) =>
                            word.charAt(0).toUpperCase() + word.slice(1),
                        )
                        .join(' ')}`
                    })()}
                  >
                    <EditOptionButton
                      iconCenter={getIcon()}
                      onClick={() => setEditSelection(type)}
                      key={type}
                      disabled={
                        (!isAdmin && someOLsPaid) ||
                        selectedOls.some((c) => c.locked)
                      }
                    >
                      <IconLabel className="relative">
                        {type.split('_').map((word) => `${capitalize(word)} `)}
                        {((!isAdmin && someOLsPaid) ||
                          selectedOls.some((c) => c.locked)) && (
                          <Fa
                            className="!text-warning absolute -top-4 -right-3"
                            icon="comment-exclamation"
                            size={1}
                          />
                        )}
                      </IconLabel>
                    </EditOptionButton>
                  </StackBtn>
                )
              })}
              {!removeOptions?.includes('duplicate_order_line') && (
                <StackBtn
                  className={`hover:text-primary ${
                    selectedOls.length > 5 ? 'tooltip-warning' : null
                  }`}
                  data-tooltip="top"
                  aria-label={
                    selectedOls.length > 5
                      ? 'Please select 5 or fewer to duplicate'
                      : 'Duplicate Order Line'
                  }
                >
                  <EditOptionButton
                    iconCenter={
                      <Fa
                        icon="clone"
                        size={2}
                        type="duotone"
                        className="StackedButtonIcon__icon"
                      />
                    }
                    onClick={handleDuplicate}
                    disabled={selectedOls.length > 5}
                  >
                    <IconLabel className="relative">
                      <span>Duplicate</span>
                      {selectedOls.length > 5 ? (
                        <Fa
                          className="!text-warning absolute -top-4 -right-3"
                          icon="comment-exclamation"
                          size={1}
                        />
                      ) : null}
                    </IconLabel>
                  </EditOptionButton>
                </StackBtn>
              )}
              {!removeOptions?.includes('delete') && (
                <StackBtn
                  className={`group${
                    (!isAdmin && someOLsPaid) ||
                    selectedOls.some((c) => c.locked)
                      ? ' tooltip-warning isDisabled'
                      : ''
                  }`}
                  data-tooltip="top"
                  aria-label={(() => {
                    if (someOLsPaid)
                      return 'One of your selected order lines is paid for and cannot be deleted'
                    if (selectedOls.some((c) => c.locked)) {
                      return 'Some of your selected order lines are locked and can not be edited'
                    }
                    return 'Delete Order Line'
                  })()}
                >
                  <EditOptionButton
                    iconCenter={
                      <Fa
                        icon="trash-xmark"
                        size={2}
                        className="StackedButtonIcon__icon fa-solid group-hover:!text-danger-600"
                      />
                    }
                    onClick={handleDelete}
                    disabled={someOLsPaid || selectedOls.some((c) => c.locked)}
                  >
                    <IconLabel className="group-hover:!text-danger-600 relative">
                      Delete
                      {(someOLsPaid || selectedOls.some((c) => c.locked)) && (
                        <Fa
                          className="!text-warning absolute -top-4 -right-3"
                          icon="comment-exclamation"
                          size={1}
                        />
                      )}
                    </IconLabel>
                  </EditOptionButton>
                </StackBtn>
              )}
              {(someOLsPaid ||
                someRejectedOlsNotUpdated ||
                hasRejectedOrderlines) &&
                !removeOptions?.includes('reSubmit') && (
                  <StackBtn
                    className={`hover:text-primary${
                      someOLsPaid ||
                      someRejectedOlsNotUpdated ||
                      selectedOls.some((c) => c.locked)
                        ? ' tooltip-warning isDisabled'
                        : ''
                    } ${
                      someRejectedOlsNotUpdated
                        ? ''
                        : 'border-success animate-bounce'
                    }`}
                    data-tooltip="top"
                    aria-label={(() => {
                      if (
                        currentOrg?.prepay &&
                        !selectedOls.every((ol) => ol.paid)
                      ) {
                        return 'Some of your selected order lines cannot be submitted for review'
                      }
                      if (someRejectedOlsNotUpdated) {
                        return 'Some of your selected order lines have not been updated'
                      }
                      if (selectedOls.some((c) => c.locked)) {
                        return 'Some of your selected order lines are locked and can not be edited'
                      }
                      return 'Submit for review'
                    })()}
                  >
                    <EditOptionButton
                      iconCenter={
                        <Fa
                          icon="paper-plane"
                          size={2}
                          type="duotone"
                          className="StackedButtonIcon__icon"
                        />
                      }
                      onClick={handleSubmitForReview}
                      disabled={
                        (currentOrg?.prepay &&
                          !selectedOls.every((ol) => ol.paid)) ||
                        someRejectedOlsNotUpdated ||
                        selectedOls.some((c) => c.locked)
                      }
                    >
                      <IconLabel className="relative">
                        <span>Submit</span>
                        {(currentOrg?.prepay &&
                          !selectedOls.every((ol) => ol.paid)) ||
                        someRejectedOlsNotUpdated ||
                        selectedOls.some((c) => c.locked) ? (
                          <Fa
                            className="!text-warning absolute -top-4 -right-3"
                            icon="comment-exclamation"
                            size={1}
                          />
                        ) : null}
                      </IconLabel>
                    </EditOptionButton>
                  </StackBtn>
                )}
              {isAdmin && (
                <StackBtn
                  className="Lock"
                  data-tooltip="top"
                  aria-label={(() => {
                    if (selectedOls.every((c) => c.locked)) {
                      return 'Unlock your selected orderlines'
                    }
                    if (selectedOls.every((c) => !c.locked)) {
                      return 'Lock your selected Orderlines'
                    }
                    return 'Unable to lock/unlock your selection'
                  })()}
                  //"Lock Order Line"
                >
                  <EditOptionButton
                    iconCenter={
                      !!selectedOls.every((c) => c.locked) ? (
                        <Fa icon="lock-open" size={2} type="duotone" />
                      ) : (
                        <Fa icon="lock" size={2} type="duotone" />
                      )
                    }
                    onClick={
                      !selectedOls.every((c) => c.locked)
                        ? handleLockOrderline
                        : handleUnLockOrderline
                    }
                    disabled={
                      selectedOls.some((c) => c.locked) &&
                      selectedOls.some((c) => !c.locked)
                    }
                  >
                    <IconLabel>
                      {!!selectedOls.every((c) => c.locked) ? 'Unlock' : 'Lock'}
                    </IconLabel>
                  </EditOptionButton>
                </StackBtn>
              )}
            </div>
          </div>
        )}
        <div className="OrderLines__CardBox">
          <div
            className={`OrderLines__ol-section-titles text-grey-400 border-b-thin border-grey-200 text-s items-center gap-4 py-1 font-bold uppercase ${
              !isEditingDisabled()
                ? 'auto-grid-order-lines'
                : 'auto-grid-order-lines-no-checkbox'
            }`}
          >
            {!isEditingDisabled() ? (
              <div
                className={`OrderLines__select-all flex items-center ${
                  orderLines.length > 1
                    ? 'Checkbox-is-shown'
                    : 'Checkbox-is-hidden'
                }`}
              >
                {orderLines.length > 1 ? (
                  <>
                    <Checkbox
                      className="OrderLines__select-all--checkbox !w-7"
                      classNameWrap={`OrderLines__checkbox-wrap before:w-24 ${
                        selectAll ? 'tooltip-secondary' : ''
                      }`}
                      aria-label={
                        selectAll
                          ? 'Selected all: Go to Order Line editor'
                          : 'Select all to edit'
                      }
                      data-tooltip="right"
                      checked={selectAll}
                      disabled={isEditingDisabled()}
                      onChange={(checked) => {
                        if (!orderLines) return
                        setSelectAll(checked)
                        if (checked) {
                          setSelectedOls([])
                          orderLines.forEach((ol) => handleOnCheck(ol, true))
                        } else {
                          setSelectedOls([])
                        }
                      }}
                    />
                    <p className="block whitespace-nowrap pl-1 xl:hidden">
                      Select all
                    </p>
                  </>
                ) : null}
              </div>
            ) : null}
            <SectionTitle>Creative</SectionTitle>
            <SectionTitle>Audience</SectionTitle>
            <SectionTitle>Budget</SectionTitle>
            <SectionTitle>Impressions</SectionTitle>
            <SectionTitle>Flight Dates</SectionTitle>
            <SectionTitle>Order Line Name</SectionTitle>
          </div>
          {orderLines?.map((currentOl, index) => {
            return (
              <div
                key={`OtherOrderLine_${currentOl.id}_${index}`}
                className={`OrderLineEditWrapper__OLCard-wrapper ${
                  !!selectedOls.find((ol) => ol.id === currentOl.id)
                    ? 'Row--checked'
                    : ''
                } ${
                  !isEditingDisabled()
                    ? 'auto-grid-order-lines'
                    : 'auto-grid-order-lines-no-checkbox'
                }`}
              >
                {!isEditingDisabled() && !currentOl.locked ? (
                  <div className="OL__Checkbox-CTU flex flex-col items-center gap-2">
                    <Checkbox
                      className="OrderLine__checkbox self-start"
                      classNameWrap={`OrderLines__checkbox-wrap before:w-24 ${
                        !!selectedOls.find((ol) => ol.id === currentOl.id)
                          ? 'tooltip-secondary'
                          : ''
                      }`}
                      aria-label={
                        !!selectedOls.find((ol) => ol.id === currentOl.id)
                          ? 'Selected: Go to Order Line editor'
                          : 'Select this Order Line'
                      }
                      data-tooltip="right"
                      checked={
                        !!selectedOls.find((ol) => ol.id === currentOl.id)
                      }
                      disabled={isEditingDisabled()}
                      onChange={(checked) => {
                        handleOnCheck(currentOl, checked)
                      }}
                    />
                    {(currentOl.status === 'ORDERLINE_STATUS_CREATING' ||
                      (currentOl.paid &&
                        currentOl.status === 'ORDERLINE_STATUS_DRAFT') ||
                      !currentOl.clickThroughUrl) && (
                      <span
                        className="Tooltip-css-wrap pt-2"
                        data-tooltip="top"
                        aria-label={(() => {
                          if (
                            currentOl.paid &&
                            currentOl.status === 'ORDERLINE_STATUS_DRAFT'
                          ) {
                            return 'Order line is paid for but not submitted for review. Please use editor to re-submit.'
                          }
                          if (currentOl.status === 'ORDERLINE_STATUS_CREATING')
                            return 'Order line is still being created. Please submit a support request if this does not resolve momentarily.'
                          if (!currentOl.clickThroughUrl)
                            return 'Missing click-thru URL. Please add one via the creatives editor.'
                        })()}
                      >
                        <Fa
                          icon="fa-circle-exclamation"
                          size={1}
                          className={
                            currentOl.status === 'ORDERLINE_STATUS_CREATING'
                              ? 'text-danger-500'
                              : 'text-warning-500'
                          }
                        />
                      </span>
                    )}
                  </div>
                ) : (
                  <div className={isAdmin ? 'flex flex-col' : ''}>
                    {isAdmin && (
                      <span>
                        <Checkbox
                          className="OrderLine__checkbox self-start"
                          classNameWrap={`OrderLines__checkbox-wrap before:w-24 ${
                            !!selectedOls.find((ol) => ol.id === currentOl.id)
                              ? 'tooltip-secondary'
                              : ''
                          }`}
                          aria-label={
                            !!selectedOls.find((ol) => ol.id === currentOl.id)
                              ? 'Selected: Go to Order Line editor'
                              : 'Select this Order Line'
                          }
                          data-tooltip="right"
                          checked={
                            !!selectedOls.find((ol) => ol.id === currentOl.id)
                          }
                          disabled={isEditingDisabled()}
                          onChange={(checked) => {
                            handleOnCheck(currentOl, checked)
                          }}
                        />
                      </span>
                    )}
                    <span aria-label="Locked" data-tooltip="right">
                      <Fa icon={'lock'} size={3} type="duotone" />
                    </span>
                  </div>
                )}

                <OLCard orderLine={currentOl} />
              </div>
            )
          })}
        </div>
      </div>
      {/* Editing modal */}
      {editSelection && (
        <Modal
          noStyles
          offClick={() => setEditSelection(undefined)}
          classNameWrap={`OrderLineEditWrapper__modal OrderLineEditWrapper__${editSelection}`}
          classNameContent={`OrderLineEditWrapper__modalContent pr-7 -mr-6 ModalContent__${editSelection}`}
          header={
            <TextHeader type={3} className="flex gap-2 capitalize">
              <span>Edit</span>
              <span>
                {editSelection.split('_').map((word) => `${capitalize(word)} `)}
              </span>
              <hr className="border-tint-gray-200 mb-2" />
            </TextHeader>
          }
          modalContentRef={containerRef}
        >
          {(() => {
            const sharedProps = {
              className: stickyClass,
              selectedOls,
              setRefreshCampaign,
              onCloseModal: () => setEditSelection(undefined),
            }

            if (editSelection === 'budget')
              return <BudgetEdit {...sharedProps} />
            if (editSelection === 'creatives')
              return (
                <CreativeEdit
                  {...sharedProps}
                  campaign={campaign}
                  filterRules={selectedCreativeType}
                />
              )
            if (editSelection === 'audiences')
              return (
                <AudienceEdit
                  {...sharedProps}
                  campaign={campaign}
                  filterRules={selectedAudienceType}
                />
              )
            if (editSelection === 'flight_dates')
              return <FlightDatesEdit {...sharedProps} />
            if (editSelection === 'impressions')
              return <ImpressionsEdit {...sharedProps} />
            // TODO: not needed for parity
            // if (editSelection === 'creative_priority_audit')
            //   return <div>edit creative priority audit</div>
            if (editSelection === 'optional_IDs')
              return <OptionalIdEdit {...sharedProps} />
          })()}
        </Modal>
      )}
      {/* </AutoScroller> */}
    </div>
  )
}
